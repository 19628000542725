<template>
  <!-- prettier-ignore -->
  <div class="container">
    <!--content area start-->
    <div class="mt-2">
      <!--      <div class="custom-row">
              <router-link :to="{ name: 'Employees' }">
                <span class="btn custom-back-btn custom-btn-gotopage"
                ><arrow-left-icon
                    size="1.5x"
                    class="custom-class"
                ></arrow-left-icon>
                </span>
              </router-link>

            </div>-->

      <div
          class="custom-row custom-options-menu justify-content-between align-items-center"
      >
        <div class="customHeading">
          <nav>
            <div
                class="nav nav-tabs custom-nav-tabs"
                id="nav-tab"
                role="tablist"
            >
              <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading active"
                  id="experience-detail-tab"
                  data-toggle="tab"
                  href="#experience-detail"
                  role="tab"
                  aria-controls="experience-detail"
                  aria-selected="true"
              >
                <span> Experience </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div class="mb-5">
      <div class="tab-content" id="nav-tabContent">
        <div
            class="tab-pane fade show active"
            id="experience-detail"
            role="tabpanel"
            aria-labelledby="experience-detail-tab"
        >
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <form @reset="onResetModal" v-if="show" autocomplete="off">

                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3 form-group row no-gutters" v-if="jobTitle_input">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="jobTitle_id">Job Title</label>
                          <div class="col-sm-9">
                            <input
                                type="text"
                                class="form-control form-control-sm custom-input-gotopage"
                                id="jobTitle_id"
                                placeholder="Job Title"
                                v-model="exp.jobTitle"
                                required
                            />
                          </div>

                        </div>
                        <div class="mb-3  form-group row  no-gutters" v-if="company_input">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="company_id">Company</label>
                          <div class="col-sm-9">
                            <input
                                type="text"
                                class="form-control form-control-sm custom-input-gotopage"
                                id="company_id"

                                v-model="exp.company"

                                placeholder="Company"
                            />
                          </div>

                        </div>
                        <div class="mb-3 form-group row no-gutters" v-if="industry_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="industry_id">Industry</label>
                          <div class="col-sm-9">
                            <select id="industry_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedIndustry"
                            >
                              <option
                                  v-for="option in skillsCategories"
                                  :key="option.skillsCategoryId"
                                  :value="option.skillsCategoryId"
                              >
                                {{ option.skillsCategoryTitle }}
                              </option>
                            </select>
                          </div>

                        </div>
                        <div class="mb-3 form-group row no-gutters" v-if="manageTeam_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="manageTeam_id">Did you dirctly manage a team?</label>
                          <div class="col-sm-9">
                            <select id="manageTeam_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedManageTeam"
                            >
                              <option
                                  v-for="option in manageTeams"
                                  :key="option.value"
                                  :value="option.value"
                              >
                                {{ option.text }}
                              </option>
                            </select>
                          </div>

                        </div>
                        <div class="mb-3 form-group row no-gutters" v-if="salary_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="salary_id">Salary</label>
                          <div class="col-sm-9">
                            <select id="salary_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedSalary"
                            >
                              <option
                                  v-for="option in salaries"
                                  :key="option.value"
                                  :value="option.value"
                              >
                                {{ option.text }}
                              </option>
                            </select>
                          </div>

                        </div>
                        <div class="mb-3 form-group row no-gutters w3-animate-opacity" v-if="location_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="location_id">Location</label>

                          <div class="col-sm-9">
                            <select id="location_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedLocation"
                            >
                              <option
                                  v-for="option in cities"
                                  :key="option.cityId"
                                  :value="option.cityId"
                              >
                                {{ option.cityName }}
                              </option>
                            </select>
                          </div>

                        </div>
                        <div class="mb-3 form-group text-right">
                          <a href="javascript:void(0);" class="mb-0 text-primary" @click="getOtherCountries"><span v-if="location_dd == true">Not in Pakistan?</span><span v-else="location_dd == false">In Pakistan?</span></a>
                        </div>
                        <div class="mb-3 form-group row no-gutters w3-animate-opacity" v-if="country1_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="country1_id">Country</label>
                          <div class="col-sm-9">
                            <select id="country1_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedCountry1"
                            >
                              <option
                                  v-for="option in countries"
                                  :key="option.gnId"
                                  :value="option.gnId"
                              >
                                {{ option.name }}
                              </option>
                            </select>
                          </div>

                        </div>
                        <!--    City Input            -->
                        <div class="mb-3 form-group row no-gutters w3-animate-opacity" v-if="city_input">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="cityInput_id">City</label>
                          <div class="col-sm-9">
                            <input
                                type="text"
                                class="form-control form-control-sm custom-input-gotopage"
                                id="cityInput_id"
                                placeholder="City"
                                v-model="cityInput"
                            />
                          </div>

                        </div>

                        <div class="mb-3  form-group row no-gutters" v-if="startDate_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" >Start Date</label>
                          <div class="col-sm-9">
                            <b-form-datepicker
                                id="startDate_id"
                                size="sm"

                                class="custom-input-gotopage custom-datepicker"
                                placeholder="Choose Start Date"
                                v-model="exp.startDateMonthYear"

                            ></b-form-datepicker>
                          </div>


                        </div>
                        <div class="mb-3  form-group row no-gutters" v-if="endDate_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" >End Date</label>
                          <div class="col-sm-9">
                            <b-form-datepicker
                                id="endDate_id"
                                size="sm"

                                class="custom-input-gotopage custom-datepicker"
                                placeholder="Choose End Date"
                                v-model="exp.endDateMonthYear"

                            ></b-form-datepicker>
                          </div>


                        </div>
                      </div>
                    </div>


                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3" v-if="currentlyWorking_radio">
<!--                          <label class="" >Currently Working</label>-->
                          <div class="custom-row">
                            <div class="mr-2">
                              <b-form-checkbox
                                  id="checkbox-2"
                                  v-model="exp.currentlyWorking"
                                  name="checkbox-2"
                                  value=true
                                  unchecked-value=false
                              >
                                Currently Working
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3" v-if="description_input">
                          <label class="" for="description_id">Description</label>
                          <div class="">
                            <textarea
                                type="text"
                                class="form-control form-control-sm custom-input-gotopage"
                                id="description_id"
                                v-model="exp.experienceDescription"
                                placeholder="Description"
                                required
                            />
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="mb-3 form-group row no-gutters" v-if="referenceEmail_input">
                          <label class="col-sm-4 col-form-label col-form-label-sm" for="referenceEmail_id">Reference Email</label>
                          <div class="col-sm-8">
                            <input
                                type="text"
                                class="form-control form-control-sm custom-input-gotopage"
                                id="referenceEmail_id"
                                placeholder="Reference Email"
                                v-model="exp.referenceEmail"
                                required
                            />
                          </div>

                        </div>
                      </div>
                      <div class= "col-md-4">

                        <div class="mb-3 form-group row no-gutters" v-if="referenceNumber_input">
                          <label class="col-sm-4 col-form-label col-form-label-sm" for="referenceNumber_id">Reference Phone #</label>
                          <div class="col-sm-8">
                            <input
                                type="text"
                                class="form-control form-control-sm custom-input-gotopage"
                                id="referenceNumber_id"
                                placeholder="Reference Phone #"
                                v-model="exp.referenceNumber"
                            />
                          </div>

                        </div>


                      </div>
                      <div class= "col-md-4">
                        <div class="mb-3 form-group row no-gutters" v-if="country2_dd">
                          <label class="col-sm-4 col-form-label col-form-label-sm" for="country2_id">Country</label>
                          <div class="col-sm-8">
                            <select id="country2_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedCountry2"
                            >
                              <option
                                  v-for="option in countries"
                                  :key="option.gnId"
                                  :value="option.gnId"
                              >
                                {{ option.name }}
                              </option>
                            </select>
                          </div>

                        </div>


                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-12">
                        <h4>Project</h4>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3 form-group row no-gutters" v-if="projectTitle_input">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="projectTitle_id">Project Title</label>
                          <div class="col-sm-9">
                            <input
                                type="text"
                                class="form-control form-control-sm custom-input-gotopage"
                                id="projectTitle_id"
                                placeholder="Project Title"
                                v-model="exp.projectTitle"
                            />
                          </div>

                        </div>
                        <div class="mb-3  form-group row no-gutters" v-if="projectStartDate_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" >Start Date</label>
                          <div class="col-sm-9">
                            <b-form-datepicker
                                id="projectStartDate_id"
                                size="sm"

                                class="custom-input-gotopage custom-datepicker"
                                placeholder="Choose Start Date"
                                v-model="exp.projectStartDateMonthYear"

                            ></b-form-datepicker>
                          </div>


                        </div>


                      </div>
                      <div class="col-md-6">

                        <div class="mb-3 form-group row no-gutters" v-if="position_input">
                          <label class="col-sm-3 col-form-label col-form-label-sm" for="position_id">Position</label>
                          <div class="col-sm-9">
                            <input
                                type="text"
                                class="form-control form-control-sm custom-input-gotopage"
                                id="position_id"
                                placeholder="Position"
                                v-model="exp.position"
                            />
                          </div>

                        </div>
                        <div class="mb-3  form-group row no-gutters" v-if="projectEndDate_dd">
                          <label class="col-sm-3 col-form-label col-form-label-sm" >End Date</label>
                          <div class="col-sm-9">
                            <b-form-datepicker
                                id="projectEndDate_id"
                                size="sm"

                                class="custom-input-gotopage custom-datepicker"
                                placeholder="Choose End Date"
                                v-model="exp.projectEndDateMonthYear"

                            ></b-form-datepicker>
                          </div>


                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">

                        <div class="mb-3" v-if="currentlyOngoing_radio">
<!--                          <label class="" >Currently Ongoing</label>-->
                          <div class="custom-row">
                            <div class="mr-2">
                              <b-form-checkbox
                                  id="checkbox-1"
                                  v-model="exp.isProjectOngoing"
                                  name="checkbox-1"
                                  value=true
                                  unchecked-value=false
                              >
                                Currently Ongoing
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3" v-if="pdescription_input">
                          <label class="" for="pdescription_id">Description</label>
                          <div class="">
                            <textarea
                                type="text"
                                class="form-control form-control-sm custom-input-gotopage"
                                id="pdescription_id"
                                v-model="exp.projectDescription"
                                placeholder="Description"
                                required
                            />
                          </div>

                        </div>
                      </div>
                    </div>


                    <div class="modal-footer">
                      <div>
                        <b-button
                            id="resetbtn"
                            class="form-control custom-form-btn custom-btn-gotopage"
                            @click="onResetModal"
                            type="reset"

                            variant="outline-dark"
                        >Reset
                        </b-button>
                      </div>

                      <div>
                        <b-button
                            class="form-control custom-form-btn custom-btn-gotopage"
                            @click="addExperience"

                            variant="outline-dark"
                        >Save
                        </b-button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--content area end-->
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import {mask} from 'vue-the-mask'
// import { ArrowLeftIcon } from "vue-feather-icons";
Vue.use(VueAxios, axios);

//import vSelect from "vue-select";
// import { ModelListSelect } from 'vue-search-select'

// import 'vue-search-select/dist/VueSearchSelect.css'



export default {
  name: "Experience",
  components: {
    // ArrowLeftIcon,
    // ModelListSelect,
    //vSelect
  },
  directives: {mask},
  data() {
    return {
      apiURL: "",
      currentPage: 1,
      total: 1,

      list: [],
      PageOfItems: [],
      info: [],
      option: 0,
      //bool
      jobTitle_input:true,
      industry_dd:true,
      salary_dd:true,
      startDate_dd:true,
      projectStartDate_dd:true,
      company_input:true,
      manageTeam_dd:true,
      location_dd:true,
      endDate_dd:true,
      projectEndDate_dd:true,
      currentlyWorking_radio:true,
      description_input:true,
      referenceEmail_input:true,
      referenceNumber_input:true,
      projectTitle_input:true,
      position_input:true,
      currentlyOngoing_radio:true,
      pdescription_input:true,



      city_dd:true,
      city_input:false,
      country1_dd:false,
      country2_dd:true,


      selectedCountry1:null,
      selectedCountry2:null,
      countries:null,
      selectedLocation:null,
      cities:null,


      selectedIndustry:null,
      skillsCategories: null,

      cityInput:"",
      exp:{
        EmployeeId:0,
        jobTitle: "",
        company: "",
        industryId: "",
        teamManage: 0,
        salary: 0,
        cityId: "",
        isPakistan: true,
        startDateMonthYear: new Date(),
        endDateMonthYear: new Date(),
        currentlyWorking: false,
        experienceDescription: "",
        referenceEmail: "",
        referenceNumber: "",
        countryId: "",
        projectTitle: "",
        position: "",
        projectStartDateMonthYear: new Date(),
        projectEndDateMonthYear: new Date(),
        isProjectOngoing: false,
        projectDescription: ""
      },

      selectedManageTeam:-1,
      manageTeams:[
        { text: 'Choose One...', value: -1 },
        { text: 'Yes', value: 1 },
        { text: 'No', value: 2 }
      ],
      selectedSalary : -1,
      salaries:[
        { text: 'Choose One...', value: -1 },
        { text: '1000-5000', value: 1 },
        { text: '5000-10000', value: 2 },
        { text: '10000-25000', value: 3 },
        { text: '25000-50000', value: 4 },
        { text: '50000-100000', value: 5 },
        { text: '100000-1000000', value: 6 }
      ],


      //form
      show: true,

      loading: true,
      errored: false,

      rowError: false,
      rowid: 0,

      totalCount: 0,
      componentKey: 0,
      employeelist:null,
      employeeSkill:null,
      employeeExperience:null,
      employeeEducation:null,

      employeeId:0,

      //searchable dropdown
      options:[],

    };
  },

  //shows record first time with default values
  mounted() {
    this.getAll();
    this.getCategories();
    this.getCountries();
    this.getCities();
    this.getSkillCategories();

  },

  methods: {
    getAll(){
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);


              this.employeeId = this.employeelist.employeeId;

              this.employeeSkill = res.data.eMPSkills;
              console.log("employeeSkill : ", this.employeeSkill);
              this.employeeExperience = res.data.eMPExperiences;
              console.log("employeeExperience : ", this.employeeExperience);
              this.employeeEducation = res.data.empEducations;
              console.log("employeeEducation : ", this.employeeEducation);



            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
    getCategories() {
      this.categories = null;
      axios.get('/api/JobType/GetAll')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.categories = res.data.data;
            console.log("categories : ", this.categories);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },

    getCountries() {
      this.countries = null;
      axios.get('/api/GeoNames/GetAllCountries')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            res.data
            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.countries = res.data.data;
            console.log("countries : ", this.countries);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },
    getCities() {
      this.cities = null;


        axios.get('/api/GeoNames/GetCountryCities/').then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }

          this.cities = res.data.data;
          console.log("cities : ",this.cities)
        }).catch(error => {
          console.log(error);
          this.errored = true;
        })
            .finally(() => {
              if (this.errored) {

                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              }
            });



    },
    addExperience(){
      if (

          this.selectedCountry2 != null &&
          this.selectedLocation != null &&
          this.selectedIndustry != null &&
          this.selectedSalary != -1 &&
          this.selectedManageTeam != -1
      ) {
        this.info = [];
        console.log("emloyeeid : ",this.employeeId)
        this.exp.EmployeeId = Number(this.employeeId);
        this.exp.countryId = Number(this.selectedCountry2);
        this.exp.cityId = Number(this.selectedLocation);
        this.exp.industryId = Number(this.selectedIndustry);
        this.exp.salary = Number(this.selectedSalary);
        this.exp.teamManage = Number(this.selectedManageTeam);
        this.exp.currentlyWorking = Boolean(this.exp.currentlyWorking);
        this.exp.isProjectOngoing = Boolean(this.exp.isProjectOngoing);

        axios
            .post("/api/EMPExperience/InsertEMPExperience", this.exp)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {

                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {
                this.$bvToast.toast("Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });
                if(this.selectedCategory==1){
                  this.$router.push({name:'Graduate'});
                }
                else if(this.selectedCategory==2){
                  this.$router.push({name:'MumsReturntoWork'});
                }
                else if(this.selectedCategory==3){
                  this.$router.push({name:'SpecialneedPerson'});
                }
                else if(this.selectedCategory==4){
                  this.$router.push({name:'LabourManPower'});
                }

              }
            });
      } else {
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });
      }
    },

    getOtherCountries(){
      if(this.location_dd == true){
        this.location_dd= false;
        this.country1_dd = true;
        this.city_input = true;
      }
      else {
        this.location_dd= true;
        this.country1_dd = false;
        this.city_input = false;
      }

    },

    getSkillCategories() {
      this.skillsCategories = null;
      axios.get('/api/SkillsCategory/GetSkillsCategorysServices')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.skillsCategories = res.data.data;
            console.log("skillsCategories : ", this.skillsCategories);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },
    //Binding Dropdown
    // onBindDropdown() {
    //   //this.getUserList();
    //   /*this.getManagerList();*/
    //   //this.getClientList();
    // },
    //User DropDown List
    // getUserList() {
    //   this.loading = true;
    //   this.usersDropDown = [];
    //
    //   axios
    //       .get("/User/GetAll")
    //       .then(res => {
    //         console.log("response : " , res.data.status);
    //         if (res.data.status == 1) {
    //           //INSERT ONLY EMPLOYEES NOT CUSTOMERS
    //           /* for (let i = 0; i < res.data.data; i++) {
    //             if(res.data.data[i].role === 201){
    //               continue;
    //             }else{
    //               this.usersDropDown.push(res.data.data[i]) ;
    //               console.log("users are: ");
    //               console.log(res.data.data[i]);
    //             }
    //           }*/
    //
    //           this.usersDropDown = res.data.data;
    //
    //           console.log("drpdn : " + this.usersDropDown);
    //         } else if (res.data.Status == 0) {
    //           this.$bvToast.toast("Sorry there is no response", {
    //             toaster: "b-toaster-top-center",
    //             variant: "danger",
    //             title: "Error",
    //             solid: true
    //           });
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error);
    //         this.errored = true;
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       });
    // },
    // //Manager DropDown List
    // getManagerList() {
    //   this.loading = true;
    //
    //
    //   console.log("Entered in managerid");
    //   console.log("Role is",this.roleselected)
    //
    //   //PM
    //   if(this.roleselected === "100" || this.roleselected === "104" || this.roleselected === "-1"){
    //     this.employeesBasedOnRoleSelected = 0;
    //     this.selectedManager = 0;
    //     this.managerDropDown = [];
    //     this.mgr_dd=false;
    //   }else if(this.roleselected === "105"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 104;
    //   }else if(this.roleselected === "106"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 105;
    //   }else if(this.roleselected === "103" || this.roleselected === "107" || this.roleselected === "108"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 106;
    //   }else if(this.roleselected === "101"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 103;
    //   }else if(this.roleselected === "102"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 101;
    //   }
    //
    //   axios
    //       .get("/Employee/Get/"+this.employeesBasedOnRoleSelected)
    //       .then(res => {
    //         //   , {
    //         //   params:{
    //         //     role:this.employeesBasedOnRoleSelected
    //         //   }
    //         // }
    //         console.log("response : " + res.data.status);
    //         if (res.data.status == 1) {
    //           this.managerDropDown = res.data.data;
    //
    //           console.log("drpdn : " , this.managerDropDown);
    //         } else if (res.data.Status == 0) {
    //           console.log("Sorry there is no response");
    //           // this.$bvToast.toast("Sorry there is no response", {
    //           //   toaster: "b-toaster-top-center",
    //           //   variant: "danger",
    //           //   title: "Error",
    //           //   solid: true
    //           // });
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error);
    //         this.errored = true;
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       });
    // },
    // getManagerId(){
    //   //GET MANAGER ID
    //   this.getManagerList();
    //   //ALSO GET DYNAMIC DROPDOWN
    //   this.getDynamicDropdownOnRoleBasis();
    // },
    // getDynamicDropdownOnRoleBasis(){
    //   if(this.roleselected === "105"){
    //     //GET PROJECTS
    //     axios.get("/Project/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //       } else if (res.data.status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   }
    //   else if(this.roleselected === "106"){
    //     //GET REGIONS
    //     axios.get("/Region/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //       } else if (res.data.Status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   } else if(this.roleselected === "103" || this.roleselected === "107" || this.roleselected === "108"){
    //     axios.get("/Area/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //
    //       } else if (res.data.status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   }else if(this.roleselected === "101"){
    //     axios.get("/SubArea/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //
    //       } else if (res.data.status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   }else if(this.roleselected === "102"){
    //     axios.get("/Site/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //       } else if (res.data.Status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   }
    // },
    // //Client DropDown List
    // getClientList() {
    //   this.loading = true;
    //   this.clientDropDown = [];
    //
    //   axios
    //       .get("/User/GetAll")
    //       .then(res => {
    //         console.log("response : " + res.data.status);
    //         if (res.data.status == 1) {
    //           this.clientDropDown = res.data.data;
    //
    //           console.log("drpdn : " + this.clientDropDown);
    //         } else if (res.data.Status == 0) {
    //           this.$bvToast.toast("Sorry there is no response", {
    //             toaster: "b-toaster-top-center",
    //             variant: "danger",
    //             title: "Error",
    //             solid: true
    //           });
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error);
    //         this.errored = true;
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       });
    // },
    //
    //
    //
    //
    // //GET ROLES
    // getRoles(role) {
    //   if (role === 100) {
    //     return "Admin";
    //   } else if (role === 101) {
    //     return "Cluster Owner";
    //   } else if (role === 102) {
    //     return "Site Supervisor";
    //   } else if (role === 103) {
    //     return "Assistant Manager";
    //   } else if (role === 104) {
    //     return "HOD";
    //   } else if (role === 105) {
    //     return "PM";
    //   } else if (role === 106) {
    //     return "RM";
    //   } else if (role === 107) {
    //     return "Grid Lead";
    //   } else if (role === 108) {
    //     return "Team Lead";
    //   }
    // },
    // //GET DEPARTMENTS
    // getDepartment(dep) {
    //   if (dep === 100) {
    //     return "Administration";
    //   } else if (dep === 101) {
    //     return "OSU";
    //   } else if (dep === 102) {
    //     return "Finance";
    //   } else if (dep === 103) {
    //     return "Extra Work";
    //   } else if (dep === 104) {
    //     return "Warehouse";
    //   } else if (dep === 105) {
    //     return "OM";
    //   } else if (dep === 106) {
    //     return "EHS";
    //   }
    // },
    //
    // //Create
    // onCreate() {
    //   if (
    //       this.employeeCode != "" &&
    //       this.fullName != "" &&
    //       this.emailAddress != "" &&
    //       this.password != "" &&
    //       this.roleselected != "-1" &&
    //       this.selectedDepartment != "-1" &&
    //       this.selectedEmployeeType != "-1"
    //   ) {
    //     if (this.password == this.confirmPassword) {
    //       if (
    //           this.password.match(this.pass_validtion) &&
    //           this.confirmPassword.match(this.pass_validtion)
    //       ) {
    //         let model = {
    //           employee:{
    //             employeeId: 0,
    //             employeeCode: this.employeeCode,
    //             department: Number(this.selectedDepartment),
    //             employeeType: this.selectedEmployeeType,
    //             managerId: this.selectedManager,
    //             isDeleted: false,
    //             isActive: true,
    //             userRefId: 0,
    //             user: {
    //               userId: 0,
    //               fullName: this.fullName,
    //               email: this.emailAddress,
    //               emailConfirmed:true,
    //               phoneNumber: this.phoneNo,
    //               lockoutEndDateUtc: "2021-05-03T03:42:00.754Z",
    //               phoneNumberConfirmed:true,
    //               twoFactorEnabled: true,
    //               passwordHash: this.confirmPassword,
    //               accessFailedCount: 0,
    //               securityStamp: "string",
    //               lockoutEnabled: true,
    //               accessEnabled: true,
    //               role: Number(this.roleselected)
    //             },
    //             //clientRefId: this.selectedClient,
    //           }
    //
    //         }
    //
    //         if(this.selectedClient!=0){
    //           model.employee.clientRefId=this.selectedClient;
    //         }
    //
    //         if(this.roleselected==='105'){
    //           model.accessId=this.dynamicItem.projectId;
    //         }else if(this.roleselected==='106'){
    //           model.accessId=this.dynamicItem.regionId;
    //         }else if(this.roleselected === '103' || this.roleselected === '107' || this.roleselected === '108'){
    //           model.accessId=this.dynamicItem.areaId;
    //         }else if(this.roleselected === '101'){
    //           model.accessId=this.dynamicItem.subAreaId;
    //         }else if(this.roleselected==='102'){
    //           model.accessId=this.dynamicItem.siteId;
    //         }
    //
    //         //if(model.employeeType)
    //         console.log("dynamicItem : ", this.dynamicItem);
    //         axios
    //             .post("/Employee/Add", model)
    //             .then(res => {
    //               console.log(res.data);
    //
    //               // $("#addorEditModal").modal("hide");
    //               this.$router.push({ name: "Employees" });
    //             })
    //             .catch(error => {
    //               console.log(error);
    //               this.errored = true;
    //             })
    //             .finally(() => {
    //               if (this.errored) {
    //                 this.$bvToast.toast("Employee Added Successfully ", {
    //                   toaster: "b-toaster-top-center",
    //                   variant: "success",
    //
    //                   solid: true
    //                 });
    //                 console.log("Sorry there is no response");
    //                 //   this.getAll();
    //                 // this.$bvToast.toast("Sorry there is no response ", {
    //                 //   toaster: "b-toaster-top-center",
    //                 //   variant: "danger",
    //
    //                 //   solid: true
    //                 // });
    //               } else {
    //                 console.log("Employee Added Successfully");
    //                 //this.getAll();
    //                 this.$bvToast.toast("Employee Added Successfully ", {
    //                   toaster: "b-toaster-top-center",
    //                   variant: "success",
    //
    //                   solid: true
    //                 });
    //               }
    //             });
    //       } else {
    //         this.$bvToast.toast(
    //             "password must be between 7 to 16 characters which contain only characters, numeric digits, underscore and first character must be a letter",
    //             {
    //               toaster: "b-toaster-top-center",
    //               variant: "danger",
    //
    //               solid: true
    //             }
    //         );
    //       }
    //     } else {
    //       this.$bvToast.toast("Passwords did not match", {
    //         toaster: "b-toaster-top-center",
    //         variant: "danger",
    //
    //         solid: true
    //       });
    //     }
    //   } else {
    //     this.$bvToast.toast("Please Fill all required fields", {
    //       toaster: "b-toaster-top-center",
    //       variant: "danger",
    //
    //       solid: true
    //     });
    //   }
    // },
    // //Reset
    onResetModal() {
      this.emp = {
        fullName:"",
        fatherName:"",
        cnic:"",
        dateofBirth:new Date(),
        gender:0,
        maritalStatus:0,
        address:"",
        area:"",
        cityId:0,
        countryId:0,
        phoneNumber:"",
        domicile:"",
        languages:"",
        sector:"",
        yearOfExperience:0,
        experienceLevel:"",
        currentSalary:0,
        expectedSalary:0,
        jobTypId:0,
        professionalSummary:"",
        linkedInLink:"",
        facebookLink:"",
        googlePlusLink:"",
        twitterLink:""
      };

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    //
    //
    //
    // //searchable dropdown
    // getData(){
    //   axios.get("/Site/GetAll").then((res) => {
    //     console.log("response : "+res.data.status);
    //     if (res.data.status == 1) {
    //       this.options = res.data.data;
    //       console.log(this.list);
    //     } else if (res.data.Status == 0) {
    //
    //       this.$bvToast.toast("Sorry there is no response", {
    //         toaster: "b-toaster-top-center",
    //         variant: "danger",
    //         title:"Error",
    //         solid: true,
    //       });
    //     }
    //
    //   })
    // },
    // codeAndNameAndDesc (item) {
    //   return `${item.siteId} - ${item.siteName}`
    // },
    // reset1 () {
    //   this.dynamicItem = {}
    // },
    // selectFromParentComponent1 () {
    //   // select option from parent component
    //   this.dynamicItem = this.options[0]
    // },
  }
};
</script>

<style scoped>
.custom-back-btn {
  padding: 0 1.5rem;
  font-size: 1.2rem;
  /* color: white;
  background-color: #212529; */
  border-radius: 0;
}
/* .custom-back-btn:hover {

  color: white;
  background-color: #212529;

} */
.custom-owner-name {
  margin: 10px 20px;
}
.custom-nav-link {
  padding: 0 0.5rem !important;
}

.custom-nav-tabs .custom-nav-link {
  height: 100%;
  padding: 10px !important;
  /* margin: 0px 10px; */
  border: 2px solid transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.custom-nav-tabs {
  /* border-bottom: 3px solid #19aa4b !important; */
}

.custom-nav-tabs .custom-nav-link:hover {
  color: black;
}

.custom-nav-tabs .custom-nav-link.active {
  font-weight: 700;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  border-color: #0047ab;
}
.custom-table td,
.custom-table th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
  width: 50%;
}
.custom-table-stock td,
.custom-table-stock th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
}
svg {
  color: white;
}
</style>


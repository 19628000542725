<template>
  <div>



    <div>
      <div class="spinner-overlay" v-if="isLoading">
        <b-spinner class="custom-spinner" label="Loading..."></b-spinner>
      </div>

      <div class="limiter">

        <div class="container-login100">
          <div class="wrap-login100">
            <div class="w-100 text-center p-b-32">
              <img
                  src="../../assets/images/Hecancylogo.png"
                  alt=""
                  height="80"
              />
            </div>


            <form
                class="login100-form "
                autocomplete="off"


            >

              <span class="txt1 p-b-5"> Old Pasword </span>

              <div class="m-b-10">

                <input
                    type="password"
                    class="form-control  custom-input-gotopage "
                    v-model="emp.oldPassword"
                    required="required"
                />
              </div>


              <span class="txt1 p-b-5"> New Password </span>
              <div class="m-b-30">

                <input
                    type="password"
                    class="form-control  custom-input-gotopage "


                    v-model="emp.password"
                    required="required"
                />
              </div>
              <span class="txt1 p-b-5"> Confirm Password </span>
              <div class="m-b-30">

                <input
                    type="password"
                    class="form-control  custom-input-gotopage "
                    v-model="confirmPassword"
                    required="required"
                    @input="matchPassword"
                />

                <div class="mt-2" v-if="showPasswordMatchMsg">
                  <div v-if="isPasswordMatched">
                    <i class="text-success">Password Matched</i>
                  </div>
                  <div v-else>
                    <i class="text-danger">Password did not match</i>
                  </div>
                </div>
              </div>





              <div class="container-login100-form-btn ml-auto m-b-20">
                <button @click.prevent="changePassword" :disabled="disabled" :class="[!disabled ? 'login100-form-btn':'', disabled ? 'custom-login100-form-btn':'']">
                  <span>Change</span>
                </button>
              </div>

            </form>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
/*global $*/
import axios from "axios";

export default {
  name:"ChangePassword",
  components: {},
  data() {
    return {
      info:null,
      emp:{
        employeeId:0,
        password: "",
        oldPassword: ""
      },
      confirmPassword:"",
      isPasswordMatched:false,
      showPasswordMatchMsg:false,
      state: false,

      employeelist:null,
      employeeId:0,

      has_error: false,
      isLoading: false,

    };
  },
  computed: {

    disabled() {
      return this.state === false;
    },
  },
  mounted() {


  },
  methods: {
    getAllEmployees(){
      //this.rerender();
      //this.profileStrength = 0;
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }



            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);


            this.employeeId = this.employeelist.employeeId;




            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
    matchPassword(){
      if(this.confirmPassword !== ""){
        this.showPasswordMatchMsg = true;
        if(this.confirmPassword === this.emp.password){
          this.state = true;
          this.isPasswordMatched = true;
        }
        else {
          this.isPasswordMatched = false;
        }
      }
      else {
        this.showPasswordMatchMsg = false;
      }


    },
    changePassword() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      this.emp.employeeId = Number(this.employeeId);
      axios
          .post("/api/Employee/UpdateEmployeePassword", this.emp)
          .then(res => {
            // code that we will 'try' to run
            this.info = res.data;

            console.log("Add Response portion");

            console.log("response : ", this.info);
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {
              console.log("Error : Sorry there is no response");
              /*setTimeout(this.$auth.logout,3000)*/
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {

              this.$bvToast.toast("Password Changed Successfully ", {
                toaster: "b-toaster-top-center",
                variant: "success",

                solid: true
              });
              setTimeout(this.$auth.logout,3000)

            }
          });
    },

  },
};
</script>

<style scoped>
.spinner-overlay {
  /*margin: -60px 0 0 -215px;*/
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 25;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
}
.custom-spinner {
  position: relative;
  top: 50%;
  left: unset;
}

@media (max-width: 991px) {
  .limiter {
    margin: 0 auto;
  }
}
a.hiddenanchor {
  display: none;
}
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: #f5f7f8;
  position: relative;
}
.wrap-login100 {
  width: 565px;
  /* height: 500px; */
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d4dbe0;
  position: relative;
  padding: 40px 48px;
}
.login100-form-title {
  font-family: gd-sage, "Times New Roman", serif !important;
  font-size: 3rem;
  color: #111;
  font-weight: 700;
  line-height: 2.75rem;
  /* text-transform: uppercase; */
  text-align: left;
  width: 100%;
  display: block;
}
.login100-form {
  width: 100%;
}
.wrap-input100 {
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}
.validate-input {
  position: relative;
}

.input100 {
  /* font-family: Raleway-Medium; */
  color: #555555;
  line-height: 1.2;
  font-size: 15px;

  display: block;
  width: 100%;
  background: transparent;
  height: 40px;
  padding: 0 25px 0 25px;
  border: none;
}

.input100:focus-visible{
  color: transparent !important;
  border: none;
  outline: none;
}
.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #0047ab;
  box-shadow: inset 0 -1px 0 0 #0047ab;
  border-radius: 0px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  /* -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);*/
}

.input100:focus + .focus-input100 {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
/*custom text fields css*/
.txt1 {
  /* font-family: Raleway-SemiBold; */
  display: inline-block;
  font-weight: 500;
  font-size: 1rem;
  color: #495057;
  line-height: 1.4;
  /* text-transform: uppercase; */
}
/*Custom-button-css*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  /* display: flex; */
  flex-wrap: wrap;
  /* text-align: -webkit-right; */
}
.login100-form-btn {
  /* font-family: Raleway-Bold; */
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 1.2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 100%;
  height: 45px;
  background-color: #0047ab;
  border:none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background-color: #0047ab;
  box-shadow: 0px 3px 6px 0px rgb(240 88 144);
}



/*for Disabled Button*/
.custom-login100-form-btn{
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 1.2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 100%;
  height: 45px;
  background-color: #0047ab;
  border:none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  cursor: not-allowed !important;
}
/*Responsive*/
@media (max-width: 576px) {
  .wrap-login100 {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 376px) {
  .wrap-login100 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 376px) {
  .login100-form-title {
    font-size: 25px;
  }
}

/*Padding Section*/
.p-b-5 {
  padding-bottom: 5px;
}
.p-b-32 {
  padding-bottom: 32px;
}
/*margin Section*/
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-30 {
  margin-bottom: 30px;
}
</style>
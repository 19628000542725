<template>
  <div >
    <div class="row no-gutters w-100">
      <div class="col-md-3 w3-card-2">
        <div
            class=" w3-bar-block w3-animate-left"
            id="mySidebar1"
        >
        <div
            id="sidebar-tabs"
            class="nav nav-tabs custom-sidebar-nav-tabs"
            role="tablist"
        >
          <!-- JobType/Category Filter-->
        <a
            href="#"
            class="w3-bar-item w3-button custom-bar-item custom-active text-decoration-none"
            data-toggle="collapse"
            data-target="#id-category"
            aria-expanded="true"
            ariacontrols="id-category"
        >
          <div class="custom-sidebar-heading">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
                <b-icon icon="shop"></b-icon>
              </div>
              <div class="custom-sidebar-text">
                <span>Category</span>
              </div>
            </div>
            <div class="custom-sidebar-icons custom-add-btn">
              <a href="#" class="custom-sidebar-link">
                <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
              </a>
            </div>
          </div>
        </a>
        <div id="id-category" class="collapse custom-collapse show" aria-labelledby="sidebar-tabs"
              data-parent="#sidebar-tabs">
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 24">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  Mums Back to Work is specifically designed to cater to the needs of moms who have taken a break from
                  their careers to raise children, and are now ready to jump back into the job market.
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 25">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  Paid Internship Placement for minimum 3 months in the relevant field of expertise.
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 26">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  It is a paid internship in any relevant field of expertise with 8-10 Weeks of internship in any of the
                  company.
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 27">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  This is an excellent resource for students/graduates who are looking for short-term, project-based work
                  that can be done remotely or with flexible hours.
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 30">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  This section offers a range of job listings from companies that prioritize diversity, equity, and
                  inclusion, and are committed to providing reasonable accommodations for employees with disabilities
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 105">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  We provide employers with access to a pool of talented and diverse male candidates
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 254">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  Labour Man Power - Technical Staff
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 259">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  Others
                </div>
              </div>
              <b-form-group class="border-0">
                <b-form-checkbox v-for="(option, index) in categories" v-model="filter.JobSubTypes" :key="option.key"
                  :value="option.key" class="mt-2 custom-checkbox-color w3-animate-opacity" @change="getJobsByFilters">
                  <span>
                    {{ option.value }}
                  </span>


                </b-form-checkbox>
              </b-form-group>
            </div>
        <!-- <div

            id="id-category"
            class="collapse custom-collapse show"
            aria-labelledby="sidebar-tabs"
            data-parent="#sidebar-tabs"
        >
          <b-form-group
              class="border-0"
          >
            <b-form-checkbox
                v-for="(option, index) in categories"
                v-model="filter.JobSubTypes"
                :key="option.key"
                :value="option.key"
                class="mt-2 custom-checkbox-color w3-animate-opacity"
                @change="getJobsByFilters"

            >
                  <span>
                    {{ option.value }}
                  </span>


            </b-form-checkbox>
          </b-form-group>
        </div> -->
          <!-- JobSubType/JobType Filter-->
          <a
              href="#"
              class="w3-bar-item w3-button custom-bar-item custom-active text-decoration-none"
              data-toggle="collapse"
              data-target="#id-jobtype"
              aria-expanded="true"
              ariacontrols="id-jobtype"
          >
            <div class="custom-sidebar-heading">
              <div class="custom-row">
                <div class="custom-sidebar-icons">
                  <b-icon icon="shop"></b-icon>
                </div>
                <div class="custom-sidebar-text">
                  <span>Job Type</span>
                </div>
              </div>
              <div class="custom-sidebar-icons custom-add-btn">
                <a href="#" class="custom-sidebar-link">
                  <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                </a>
              </div>
            </div>
          </a>
          <div
              id="id-jobtype"
              class="collapse custom-collapse show"
              aria-labelledby="sidebar-tabs"
              data-parent="#sidebar-tabs"

          >
            <b-form-group
                class="border-0"
            >
              <b-form-checkbox
                  v-for="option in jobTypes"
                  v-model="filter.JobTypes"
                  :key="option.key"
                  :value="option.key"
                  class="mt-2 custom-checkbox-color w3-animate-opacity"
                  @change="getJobsByFilters"

              >
                {{ option.value }}
              </b-form-checkbox>
            </b-form-group>
          </div>
          <!-- Experience Filter-->
          <a
              href="#"
              class="w3-bar-item w3-button custom-bar-item custom-active text-decoration-none"
              data-toggle="collapse"
              data-target="#id-experience"
              aria-expanded="true"
              ariacontrols="id-experience"
          >
            <div class="custom-sidebar-heading">
              <div class="custom-row">
                <div class="custom-sidebar-icons">
                  <b-icon icon="shop"></b-icon>
                </div>
                <div class="custom-sidebar-text">
                  <span>Experience</span>
                </div>
              </div>
              <div class="custom-sidebar-icons custom-add-btn">
                <a href="#" class="custom-sidebar-link">
                  <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                </a>
              </div>
            </div>
          </a>
          <div
              id="id-experience"
              class="collapse custom-collapse show"
              aria-labelledby="sidebar-tabs"
              data-parent="#sidebar-tabs"
          >
            <b-form-group
                class="border-0"
            >
              <b-form-checkbox
                  v-for="(option, index) in visibleExperiences"
                  v-model="filter.EmpExperiences"
                  :key="option.key"
                  :value="option.key"
                  class="mt-2 custom-checkbox-color"
                  @change="getJobsByFilters"
              >
                {{ option.value }}


              </b-form-checkbox>
              <!--  View More Button    -->
              <div v-if="visibleExperiences != null">
                <div v-if="experiencesVisible < filteredExperiences.length"  >
                  <div class="text-center clear-bottom">
                    <a href="javascript:void(0)"
                       @click="experiencesVisible += step"
                       class="text-decoration-none">
                      View More
                    </a>
                  </div>
                </div>
              </div>
            </b-form-group>
          </div>
          <!-- cityFilter-->
          <a
              href="#"
              class="w3-bar-item w3-button custom-bar-item custom-active text-decoration-none"
              data-toggle="collapse"
              data-target="#id-city"
              aria-expanded="true"
              ariacontrols="id-city"
          >
            <div class="custom-sidebar-heading">
              <div class="custom-row">
                <div class="custom-sidebar-icons">
                  <b-icon icon="shop"></b-icon>
                </div>
                <div class="custom-sidebar-text">
                  <span>City</span>
                </div>
              </div>
              <div class="custom-sidebar-icons custom-add-btn">
                <a href="#" class="custom-sidebar-link">
                  <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                </a>
              </div>
            </div>
          </a>
          <div
              id="id-city"
              class="collapse custom-collapse show"
              aria-labelledby="sidebar-tabs"
              data-parent="#sidebar-tabs"
          >
            <b-form-group
                class="border-0"
            >
              <b-form-checkbox
                  v-for="option in filteredCities"
                  v-model="filter.Cities"
                  :key="option.key"
                  :value="option.key"
                  class="mt-2 custom-checkbox-color w3-animate-opacity"
                  @change="getJobsByFilters"

              >
                {{ option.value }}
              </b-form-checkbox>
              <b-form-checkbox
                  v-for="option in otherOptions"
                  v-model="filter.isOthersSelected"
                  value-field="item"
                  text-field="value"
                  class="mt-2 custom-checkbox-color w3-animate-opacity"
                  @change="getJobsByFilters"
              >
                {{ option.value }}
              </b-form-checkbox>
<!--              <b-form-checkbox
                  v-model="isSelectedOther"
                  value=true
                  unchecked-value=false
                  @change="getJobsByFilters"
              >
                Others
              </b-form-checkbox>-->

            </b-form-group>
          </div>

          <!-- Degree Level Filter-->
          <a
              href="#"
              class="w3-bar-item w3-button custom-bar-item custom-active text-decoration-none"
              data-toggle="collapse"
              data-target="#id-degreelevel"
              aria-expanded="true"
              ariacontrols="id-degreelevel"

          >
            <div class="custom-sidebar-heading">
              <div class="custom-row">
                <div class="custom-sidebar-icons">
                  <b-icon icon="shop"></b-icon>
                </div>
                <div class="custom-sidebar-text">
                  <span>Degree Level</span>
                </div>
              </div>
              <div class="custom-sidebar-icons custom-add-btn">
                <a href="#" class="custom-sidebar-link">
                  <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                </a>
              </div>
            </div>
          </a>
          <div
              id="id-degreelevel"
              class="collapse custom-collapse show"
              aria-labelledby="sidebar-tabs"
              data-parent="#sidebar-tabs"
          >
            <b-form-group
                class="border-0"
            >
              <b-form-checkbox
                  v-for="option in degreeLevels"
                  v-model="filter.DegreeLevels"
                  :key="option.key"
                  :value="option.key"
                  class="mt-2 custom-checkbox-color"
                  @change="getJobsByFilters"

              >
                {{ option.value }}
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
        </div>

      </div>
      <div class="col-md-9">
        <div class="">
          <div class="custom-category-title" v-if="isCategoryVisible">
            <span style="color: #0047ab"><b-icon icon="caret-right-fill"></b-icon></span>
            <span v-if="categoryValue != []" class="text-capitalize" >
              <span v-for="(data, index) in categoryValue" :key="data.id">
                <span>{{ data.title }}<span class="ml-1 mr-1">  </span></span>
              </span>
            </span>
          </div>
          <div class="custom-jobs-heading text-center  pt-4 pb-4">


            <div class="container">
              <h1><b>Find Your Next Job, Fast</b></h1>
              <p>View by Experience, Search by Skills</p>
<!--              <p>Search By Skills, View Salaries, One-Click Apply</p>-->
              <form class="" >
                <div class="row">
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="mb-2">
                      <!--                <label  class="sr-only">Email</label>-->
                      <input type="text" v-model="jobSearchByNameOrCity.jobTitle" @keydown.enter.prevent="getJobsByNameAndCity" class="form-control custom-input-gotopage custom-job-search-input" placeholder="Job Title">
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mb-2">
                      <!--                <label  class="sr-only">Password</label>-->
                      <select id="city_id"
                              class="custom-select-option custom-select-city-option form-control"
                              v-model="selectedCity"
                      >
                        <option value=0 >Select City</option>
                        <option
                            v-for="option in cities"
                            :key="option.key"
                            :value="option.key"
                        >
                          {{ option.value }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-2">
                      <button type="button" @click="getJobsByNameAndCity" class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-search mb-2">
                        Search
                        <!--                      <b-icon icon="search"></b-icon>-->

                      </button>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                </div>



                <!--              <button type="submit" class=" btn btn-primary btn-apply mb-2">Search</button>-->
              </form>
            </div>



          </div>
          <hr>
          <div>
            <div class="container pt-3 pb-3 ml-auto mr-auto ":key="componentKey" >


              <div v-if="visibleRecords.length != 0">
                <div v-for="(job, jobIndex) in visibleRecords" :key="job.jobId">

                  <div class="w3-card-2 pl-4 pr-4 pt-3 pb-3 w3-container mb-3 custom-job-card" >



                    <div  class="row align-items-center">
                      <div class="col-md-10">
                        <router-link
                            class="text-decoration-none"
                            :to="{ name: 'JobDetail',
                               params: { id: job.jobId }
                             }"
                            title="Job Detail">
                          <div class="mb-2">

                            <span class="b-avatar badge-info rounded-circle" style="width:3rem;height: 3rem; background-color: #fff">
                              <span  class="b-avatar-img">
                                <span v-if="job.companyLogoAddress != null && job.companyLogoAddress != ''">
                                   <img :src="url+job.companyLogoAddress">
                                 </span>
                                 <span v-else>

                                      <img src="../assets/images/no-image.png" >


                                 </span>

                              </span>

                            </span>
<!--                            <img v-if="job.companyLogoAddress != null || job.companyLogoAddress != ''" :src="url+job.companyLogoAddress" alt="Avatar" class="rounded-circle" style="width:60px; height:60px">
                            <img v-else src="../assets/images/no-image.png" alt="Avatar" class=" w3-circle" style="width:60px">-->
                          </div>
                          <div  class="mb-2 custom-muted-text">
                            <span>{{ job.companyName }}</span>
                            <!--                        <span>Itroos</span>-->
                          </div>
                          <div  class="mb-2">
                            <h5 class="custom-job-title text-dark text-capitalize  m-0">{{ job.jobTitle }}</h5>
                          </div>



                          <div class="custom-row mb-2 custom-muted-text">
                            <span class="mr-2"><i class="fas fa-map-marker-alt"></i></span>
                            <span>{{ job.cityName }}, {{ job.countryName }}</span>
                          </div>
                          <div class="custom-row mb-2 custom-muted-text">
                            <span class="mr-2"><i class="fas fa-money-bill"></i></span>
                            <span>Rs {{ job.packageFrom }} - {{ job.packageTo }}/Month </span>
                          </div>
                          <div class="custom-row mb-2">
                        <span>
                          <b-badge class="custom-status-badge" variant="primary">{{ job.category }}</b-badge>
                        </span>
                            <div class="pl-1 pr-1"></div>
                            <span v-if="job.experiencelevel !== null">
                          <b-badge class="custom-status-badge" variant="primary">
<!--                            <span v-for="exp in yearsOfExperiences" :key="exp.key">
                              <span v-if="exp.key === job.requiredExperience">
                                <span v-if="exp.key > 1">Min </span>
                                <span v-else></span>
                                <span>{{ exp.value }}</span>

                              </span>
                            </span>-->

                            Experience: {{ job.experiencelevel }}
                          </b-badge>
                        </span>
                            <div class="pl-1 pr-1"></div>
                            <span>
                          <b-badge class="custom-status-badge" variant="primary">{{ job.jobTypeTitle }}</b-badge>
                        </span>
                          </div>
                        </router-link>
                        <div v-if="job.jobDescription != null" class="custom-muted-text text-justify">
                          <span class="font-weight-bold">Job Description:</span>
                          <br>
                          <span v-if="job.jobDescription.length > 100">
                            <span>{{ job.jobDescription.substr(0, 103) }}</span>
                            <span class="collapse w3-animate-opacity" :id="'readMoreSection'+jobIndex+1">
                              {{ job.jobDescription.substr(103, job.jobDescription.length) }}
                            </span>
                            <span>
                              <a @click="onAreaExpand" class="custom-text-color text-decoration-none" data-toggle="collapse" :href="'#readMoreSection'+jobIndex+1" role="button" aria-expanded="false" aria-controls="collapseExample">

                                <span class="w3-animate-opacity" v-if="isAreaExpanded == false">Read More>></span>
                                <span class="w3-animate-opacity" v-else><< Read Less</span>
                              </a>
                            </span>

                          </span>
                          <span v-else>
                            {{ job.jobDescription }}
                          </span>
                        </div>
                        <div v-else></div>
                      </div>


                      <div class="col-md-2 text-center">
                        <!--                    <div class="mb-2" v-for="(date, dateIndex) in dateDifferences" :key="dateIndex">
                                              <i class="font-weight-bold custom-muted-text " v-if="jobIndex == dateIndex">
                                                {{ date }}
                                                <span v-if="date > 1">Days</span>
                                                <span v-else>Day</span>
                                                Ago
                                              </i>
                                            </div>-->

                        <div class="mb-3" >
                      <span class="flex-grow-1" >

                          <span >
                            <a @click="showOrHideModal(job)" class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2  ">Apply Now</a>
                          </span>
                        <!--                          <span  v-else>
                                                    <a type="button" class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2 custom-btn-bookmark ">Applied</a>
                                                  </span>-->

                      </span>


                        </div>

                        <div class="">
                          <a class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-bookmark" @click="onSavingJob(job)">Save</a>
                        </div>
                        <!--                    <div class="mb-3">
                                              <router-link class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-bookmark">More</router-link>
                                            </div>-->
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <div v-else>

              </div>
              <!--   Spacer   -->
              <div class="pt-5"></div>

              <div class="text-center clear-bottom">
                <div class="row no-gutters ">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-2"></div>
                  <div class="col-lg-2" v-if="visibleRecords != null">
                    <!--  View More Button    -->
                    <div >
                      <div v-if="recordsVisible < jobsList.length"  >
                        <div class="text-center clear-bottom">
                          <a href="javascript:void(0)"
                             @click="recordsVisible += step"
                             class="btn btn-theme text-decoration-none">
                            View More
                          </a>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-lg-2"></div>
                  <div class="col-lg-3"></div>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
    <CPModal v-if="isModalVisible" :save-job-status="isSaveJobStatus" :signInOrSignUpSection="isSignInOrSignUpSection" :employeeProfileFormSection="isEmployeeProfileFormSection" :show-alert-msg="isShowAlertMsg" :show-profile-info-card="isShowProfileInfoCard" :apply-job-confirmation-section="isApplyJobConfirmationSection" :selected-job-record="jobRecord" @get-strength="recievedStrength"  @toggle-modal="toggleModal">

    </CPModal>
    <ConfirmationModal v-if="isConfirmationModalVisible"  @toggle-modal="closeModal"></ConfirmationModal>

  </div>
</template>
<script>
import Vue from 'vue';
import axios from "axios";
import moment from "moment";
//Vue.use(moment);
import CPModal from "../components/employeeComponents/CompleteProfileModalComponent";
import _ from "underscore";
import ConfirmationModal from "../components/ConfirmationModal";
export default {
  name:"AllJobs",
  components: {
    ConfirmationModal,
    CPModal,
  },
  data(){
    return{
      url:axios.defaults.baseURL,
      info:null,
      list:[],

      //alert msg
      dismissSecs: 5,
      dismissCountDown: 0,



      isCategoryVisible:false,
      isCategoryChecklistVisible:false,
      categoryValue:{
        value:[]
      },
      selectedCity:0,
      jobSearchByNameOrCity:{
        jobTitle: "",
        cityId: null
      },
      isSelectedOther:false,
      otherOptions:[
        { item: false, value:"Others"}
      ],
      filter:{
        JobTypes: [],
        JobSubTypes: [],
        Cities:[],
        DegreeLevels:[],
        EmpExperiences: [],
        isOthersSelected: false
      },
      //cities:null,
      //categories:[],
      categoriesCount:null,
      //jobTypes:[],
      //filteredExperiences:[],
      //yearsOfExperiences:[],

      //filteredCities:[],
      //degreeLevels:[],

      currentDate:new Date(),
      insertionDates:[],
      dateDifferences:[],
      isApplyNow_btn:true,
      isApplied_btn:false,
      job:{

        JobId: 0,
        EmployeeId: 0,
        ExpectedSalary: 0,
        Status: 1,
        IsApplicantViewed: false
      },

      isSaveJobStatus:false,


      saveJob:{
        saveJobId: 0,
        jobId: 0,
        employeeId: 0,
        isActive: true

      },
      jobID:0,
      jobRecord:null,
      jobsStatuses:null,
      employeelist:null,
      employeeId:0,
      employeeSkill:null,
      employeeExperience:null,
      employeeEducation:null,
      profileStrength:0,




      //combined list with status
      listWithStatus:[],
      //Complete Profile Modal
      isModalVisible:false,
      isConfirmationModalVisible:false,
      isSignInOrSignUpSection:false,
      isEmployeeProfileFormSection:false,
      isShowAlertMsg:false,
      isShowProfileInfoCard:false,
      isApplyJobConfirmationSection:false,
      modalMsg:"",
      showAlertMsg:false,
      modalDescription:"",
      errored:false,
      componentKey:0,

      //Read More Collapse
      isAreaExpanded:false,

      // Show More Functionality
      isShowMoreBtnVisible:false,
      recordsVisible: 5,
      experiencesVisible: 10,
      step: 5,

    };
  },
  mounted() {

    this.getAllEmployees();
    this.getCategoryList();
    this.getJobTypesList();
    this.getExperienceList();
    this.getCityList();
    this.getDegreeLevelsList();
    if(this.$route.params.key != undefined){
      this.filter.JobSubTypes = [];
      this.filter.JobSubTypes.push(this.$route.params.key);
      this.getJobsByFilters()
    }
    else {
      this.getAll();
    }


  },
  beforeCreate () {
    return this.$options.computed.visibleRecords;

  },

  computed:{
    jobsList(){

      console.log("joblist : ", this.$store.state.jobList)

        return this.$store.state.jobList;
    },
    visibleRecords() {
      return this.jobsList.slice(0, this.recordsVisible)
    },
    cities(){
      return this.$store.getters.availableCities
    },
    categories(){
      return this.$store.getters.availableCategories
    },

    jobTypes(){
      return this.$store.getters.availableJobTypes
    },
    filteredExperiences(){
      return this.$store.getters.availableExperiences
    },
    visibleExperiences() {
      return this.filteredExperiences.slice(0, this.experiencesVisible)
    },

    filteredCities(){
      return this.$store.getters.availableFilteredCities
    },
    degreeLevels(){
      return this.$store.getters.availableDegreeLevels
    },
    categoryFilter(){
      console.log("this.$store.state.categoryFilter : ", this.$store.state.categoryFilter)
      return this.$store.state.categoryFilter;
    },


  },
  /*created() {

    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },*/
  methods:{
    getAll(){
      this.rerender();



      console.log("this.$route.params : ", this.$route.params);
      console.log("this.$route.params.cId : ", this.$route.params.cId);
      if (this.$route.params.cId != undefined){
        this.$store.dispatch("getJobsByCompanyId", this.$route.params.cId);
      }
      else {
        this.$store.dispatch("getAll");
      }

      /*if(this.$route.params.searchString != undefined){
        console.log("jobTitle : ",this.$route.params.searchString)
        console.log("selectedCity : ",this.$route.params.selectedCity)
        this.jobSearchByNameOrCity.jobTitle = "";
        this.jobSearchByNameOrCity.cityId = null;
        this.jobSearchByNameOrCity.jobTitle = this.$route.params.searchString;
        this.jobSearchByNameOrCity.cityId = (this.$route.params.selectedCity == 0 || this.$route.params.selectedCity == null) ? null : Number(this.$route.params.selectedCity);
        this.getJobsByNameAndCityOnHomePage();
      }


      console.log("this.$route.params : ", this.$route.params);

      if (this.$route.params.setCompanyId != undefined){
        this.getJobsByCompanyId(this.list);
      }*/
      /*this.list = [];


      axios.get('/api/Job/GetAlljobsOfEmployeer')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /!*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*!/

            let jobList = res.data.data;
            let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
            for(let job in jobList){
              if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                this.list.push(jobList[job]);
              }
            }

            console.log("list : ", this.list);





           /!* for(let i in this.list){
              this.insertionDates[i] = this.list[i].insertionDate;

            }
            console.log("insertionDate : ", this.insertionDates);
            for(let t in this.insertionDates){
              var a = moment(this.currentDate);
              var b = moment(this.insertionDates[t]);
              this.dateDifferences[t] = a.diff(b,'days');
            }*!/


            //console.log("dateDifferences : ", this.dateDifferences);
            /!*console.log("duplicate items : ", _.keys(_.countBy(this.list, function(data) { return data.jobTypeTitle; })));
            console.log("count : ",_.countBy(this.list, function(data) { return data.jobTypeTitle; }))*!/


            /!*const obj1 = _.keys(_.countBy(this.list, function(data) { return data.jobTypeTitle; }));
            this.categories = Object.entries(obj1).map(([key, value]) => ({id : Number(key), name : value}));
*!/
            /!*let catList = [];
            this.list.forEach((item, index) => {
              const scate = {
                id: parseInt(item.jobTypeId),
                catName: item.jobTypeTitle
              };
              catList.push(scate);
            });
            console.log("this.categories : ",catList);*!/






            /!*const obj2 = _.countBy(this.list, function(data) { return data.jobTypeId; });


            this.categoriesCount = Object.entries(obj2).map(([key, value]) => ({id : Number(key), text: String(value)}));*!/


            //console.log("this.categoriesCount : ",this.categoriesCount);
            this.getCategories(this.list);
            this.getJobTypes(this.list);
            this.getExperiences(this.list);
            this.getFilteredCities(this.list);
            //this.getDegreeLevel(this.list);
            this.getAllEmployees();

            if(this.$route.params.searchString != "list"){
              console.log("jobTitle : ",this.$route.params.searchString)
              console.log("selectedCity : ",this.$route.params.selectedCity)
              this.jobSearchByNameOrCity.jobTitle = "";
              this.jobSearchByNameOrCity.cityId = null;
              this.jobSearchByNameOrCity.jobTitle = this.$route.params.searchString;
              this.jobSearchByNameOrCity.cityId = (this.$route.params.selectedCity == 0 || this.$route.params.selectedCity == null) ? null : Number(this.$route.params.selectedCity);
              this.getJobsByNameAndCityOnHomePage();
            }


            console.log("this.$route.params : ", this.$route.params);

            if (this.$route.params.setCompanyId != undefined){
              this.getJobsByCompanyId(this.list);
            }



            //this.appliedJobsStatus();

            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });*/
    },
    showMore(){
      this.recordsVisible += 5;
    },
    getAllEmployees(){
      //this.rerender();
      //this.profileStrength = 0;
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }



            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);


            this.employeeId = this.employeelist.employeeId;
            this.job.ExpectedSalary = this.employeelist.expectedSalary;

            this.employeeSkill = res.data.eMPSkills;
            console.log("employeeSkill : ", this.employeeSkill);
            this.employeeExperience = res.data.eMPExperiences;



            this.employeeEducation = res.data.empEducations;
            console.log("employeeEducation : ", this.employeeEducation);


            this.CalculatePercentage();


            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
    getJobsByNameAndCity(){
      //this.list = [];
      if(this.jobSearchByNameOrCity.jobTitle != "" || this.selectedCity != 0){

          this.jobSearchByNameOrCity.cityId = (this.selectedCity == 0 || this.selectedCity == null) ? null : Number(this.selectedCity)


        this.$store.dispatch("getJobsByNameAndCityId", this.jobSearchByNameOrCity)

     /* axios
          .post("/api/Job/GetJobsSearch", this.jobSearchByNameOrCity)
          .then(res => {
            // code that we will 'try' to run

            let jobList = res.data.data;
            let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
            for(let job in jobList){
              if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                this.list.push(jobList[job]);
              }
            }


              console.log("Add Response portion");

              console.log("response : ", this.list);
            this.getCategories(this.list);
            this.getJobTypes(this.list);
            this.getExperiences(this.list);
            this.getFilteredCities(this.list);
            //this.getDegreeLevel(this.list);

            if(this.list.length == 0){
              this.$bvToast.toast("No Record Found ", {
                toaster: "b-toaster-top-center",
                variant: "warning",

                solid: true
              });
            }

          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {

              /!*this.$bvToast.toast("Updated Successfully ", {
                toaster: "b-toaster-top-center",
                variant: "success",

                solid: true
              });*!/


            }
          });*/
      }
      else {

        this.$route.params.jobTitle = undefined;
        this.$route.params.selectedCity = undefined;
        this.getAll();
      }
    },
   /* getJobsByNameAndCityOnHomePage(){
      if(this.$route.params.searchString != undefined){
        console.log("jobTitle : ",this.$route.params.searchString)
        console.log("selectedCity : ",this.$route.params.selectedCity)
        this.jobSearchByNameOrCity.jobTitle = "";
        this.jobSearchByNameOrCity.cityId = null;
        this.jobSearchByNameOrCity.jobTitle = this.$route.params.searchString;
        this.jobSearchByNameOrCity.cityId = (this.$route.params.selectedCity == 0 || this.$route.params.selectedCity == null) ? null : Number(this.$route.params.selectedCity);
        this.getJobsByNameAndCityOnHomePage();
      }


      console.log("this.$route.params : ", this.$route.params);



      /!*this.list = [];
      if(this.jobSearchByNameOrCity.cityId != 0 || this.jobSearchByNameOrCity.jobTitle != "" ){




        axios
            .post("/api/Job/GetJobsSearch", this.jobSearchByNameOrCity)
            .then(res => {
              // code that we will 'try' to run

              let jobList = res.data.data;
              let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
              for(let job in jobList){
                if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                  this.list.push(jobList[job]);
                }
              }

              console.log("Add Response portion");

              console.log("response : ", this.list);
              this.getCategories(this.list);
              this.getJobTypes(this.list);
              this.getExperiences(this.list);
              this.getFilteredCities(this.list);
              //this.getDegreeLevel(this.list);

              if(this.list.length == 0){
                this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
                });
              }

            })
            .catch(error => {
              console.log("Errorrrrrr : ",error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {

                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {

                /!*this.$bvToast.toast("Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });*!/


              }
            });
      }
      else {
        this.getAll();
      }*!/
    },
    getJobsByName(title){
      this.rerender();
      this.list = [];
      if(title != ""){
        this.jobSearchByNameOrCity.jobTitle = title;
        this.jobSearchByNameOrCity.cityId = null



        axios
            .post("/api/Job/GetJobsSearch", this.jobSearchByNameOrCity)
            .then(res => {
              // code that we will 'try' to run

              let jobList = res.data.data;
              let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
              for(let job in jobList){
                if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                  this.list.push(jobList[job]);
                }
              }


              console.log("Add Response portion");

              console.log("response : ", this.list);
              this.getCategories(this.list);
              this.getJobTypes(this.list);
              this.getExperiences(this.list);
              this.getFilteredCities(this.list);
              //this.getDegreeLevel(this.list);

              if(this.list.length == 0){
                this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
                });
              }

            })
            .catch(error => {
              console.log("Errorrrrrr : ",error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {

                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {

                /!*this.$bvToast.toast("Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });*!/


              }
            });
      }
      else {

        this.$route.params.jobTitle = undefined;
        this.$route.params.selectedCity = undefined;
        this.getAll();
      }
    },*/
    /*getJobsByCompanyId(jobRecord){
      this.list = [];

      console.log("jobRecord in company : ",jobRecord)

      let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
      console.log("currentDate Format : ",currentDate)
      for (let data in jobRecord){

        console.log("expired date: ", moment(String(jobRecord[data].jobExpiryDate)).format('YYYY-MM-DD') ,": current date : ", currentDate)
        if((jobRecord[data].companyId === this.$route.params.setCompanyId && jobRecord[data].status == "Approved") && moment(String(jobRecord[data].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
          /!*this.list[data] = jobRecord [data];*!/
          this.list.push(jobRecord [data]);
        }

      }
      console.log("this.list in company : ",this.list);

    },*/



    //Get Dropdowns List
    getCityList(){

      this.$store.dispatch("getCityList")

     /* this.cities = null;
      let model = {
        type: "City"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.cities = res.data.data;

            console.log("Add Response portion");

            console.log("cities : ", this.cities);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });*/
    },
    getCategoryList(){

      this.$store.dispatch("getCategories")

    },
    getExperienceList(){

      this.$store.dispatch("getExperiences")
    },
    getJobTypesList(){

      this.$store.dispatch("getJobTypes")

    },

    getDegreeLevelsList(){

      this.$store.dispatch("getDegreeLevel")


    },


    getJobsByFilters(){
      this.rerender();
      this.isCategoryVisible = false;
      this.list = [];
      console.log("this.filter in jobboard : ", this.filter);
      //this.jobSearchByNameOrCity.cityId = Number(this.selectedCity);
      if(this.filter.JobTypes != [] || this.filter.JobSubTypes != [] || this.filter.Cities != [] || this.filter.EmpExperiences != []){

        /*console.log("this.isSelectedOther : ", this.isSelectedOther)
        this.filter.isOthersSelected = !Boolean(this.isSelectedOther);*/


        this.$store.dispatch("getJobsByFilters", this.filter)

        this.categoryValue = []


        for (const item of this.filter.JobSubTypes) {
          for (const data of this.categories){

            if(item == data.key){
              this.categoryValue.push({
                id : data.key,
                title : data.value
              });
            }
          }

        }

        console.log("categoryValueList : ", this.categoryValue)
        if(this.categoryValue.length != 0){
          this.isCategoryVisible = true;
        }
        else {
          this.isCategoryVisible = false;
        }


      }
      else {

        this.getAll();

      }
    },


    showOrHideModal(jobrecord){
      this.jobRecord = jobrecord;

      if(this.$auth.check()){
        console.log("chek : ",this.$auth.check());
        if(this.$auth.user().user.role != 'Employer'){
          if(this.profileStrength > 80){
            console.log("total strength inside if statement after Employee Skill section: ", this.profileStrength)

            this.isModalVisible = true;

            this.isSignInOrSignUpSection = false;
            this.isEmployeeProfileFormSection = true;
            this.isShowAlertMsg = false;
            this.isShowProfileInfoCard = true;
            this.isApplyJobConfirmationSection = true;
            console.log("job strength inside if statement: ",this.profileStrength)
            console.log("jobRecord inside if statement: ",this.jobRecord)
          }
          else{
            this.$bvToast.toast("Your profile Strength Must be Over 80%, Please Update your Profile", {
              toaster: "b-toaster-top-center",
              variant: "warning",

              solid: true
            });

            this.isModalVisible = true;
            console.log("job strength inside else statement: ",this.profileStrength)

            this.isSignInOrSignUpSection = false;
            this.isEmployeeProfileFormSection = true;
            this.isShowAlertMsg = true;
            this.isShowProfileInfoCard = false;
            this.isApplyJobConfirmationSection = false;



          }
        }
        else {
          this.isConfirmationModalVisible = true;
        }



      }
      else{
        console.log("chek : ",this.$auth.check());
        this.isModalVisible = true;
        this.isEmployeeProfileFormSection = false;
        //this.modalTitle = "Attention";
        this.isSignInOrSignUpSection=true;
        this.isShowAlertMsg = false;
        this.isShowProfileInfoCard = false;
        this.isApplyJobConfirmationSection = false;
        //this.modalDescription = "Please Register/Login Before Applying a Job"
      }
    },

    onSavingJob(jobrecord){
      this.jobRecord = jobrecord;
      if(this.$auth.check()){
        this.saveJob.jobId = Number(jobrecord.jobId);
        this.saveJob.employeeId = Number(this.employeeId);
        console.log("jobId + Emp ID : ", this.saveJob.jobId + this.saveJob.employeeId)
        axios
            .post("/api/Job/SaveJob", this.saveJob)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info.msg);
            })
            .catch(error => {
              console.log("Errorrrrrr : ",error);
              this.errored = true;
            })
            .finally(() => {


              if(this.info.msg == "Job Already Save"){
                this.$bvToast.toast("You have Saved to the Job Already. ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
                });
              }
              else {
                this.$bvToast.toast("Job Saved Successfully", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });
              }
              this.getAll();
              this.getAllEmployees();


            });
      }
      else {
        this.$bvToast.toast("Please Sign Up / Sign In, Before Saving a job", {
          toaster: "b-toaster-top-center",
          variant: "warning",

          solid: true
        });
        setTimeout(this.showSignUpOrSignInModal(),1000)
      }

    },

    showSignUpOrSignInModal(){
      this.isModalVisible = true;
      this.isSaveJobStatus = true;
      this.isEmployeeProfileFormSection = false;

      this.isSignInOrSignUpSection=true;
      this.isShowAlertMsg = false;
      this.isShowProfileInfoCard = false;
      this.isApplyJobConfirmationSection = false;
    },



    toggleModal(){
      this.isModalVisible = !this.isModalVisible;
      this.getAll();
    },



    recievedStrength(value){
      this.profileStrength = value;
      console.log("On All JOb Page: ", this.profileStrength);

    },
    CalculatePercentage() {

      //Employee

      let profilePercentUponSignUp = 20;
      let profilePercent = 30;
      let expPercent = 20;
      let eduPercent = 20;
      let skillPercent = 10;

      let percent = 0;

      if(this.employeelist != {}){
        if((this.employeelist.fullName != "" || this.employeelist.fullName != null) && (this.employeelist.mobileNumber != "" || this.employeelist.mobileNumber != null)){
          percent += profilePercentUponSignUp;
          console.log("percent in signup : ", percent)
        }

        this.profileStrength = Math.ceil(percent);
        console.log("total after Employee section : ", this.profileStrength);



        if (this.employeelist.fatherName != "" && this.employeelist.fatherName != null && this.employeelist.cnic != "" && this.employeelist.dateofBirth != null && this.employeelist.gender != 0 && this.employeelist.gender != null) {
          percent += profilePercent;

          console.log("percent in Profile : ", percent)
        }
        this.profileStrength = Math.ceil(percent);
        console.log("total after Employee section : ", this.profileStrength);



      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee section : ", this.profileStrength);




      if(this.employeeExperience.length != 0){
        percent += expPercent;

      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee Experience section : ", this.profileStrength);





      if(this.employeeEducation.length != 0){
        percent += eduPercent;

      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee Education section : ", this.profileStrength);





      if(this.employeeSkill.length != 0){
        percent += skillPercent;

      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee Skill section : ", percent);


    },

    onAreaExpand(){
      if(this.isAreaExpanded == false){
        this.isAreaExpanded = true;
      }
      else
      {
        this.isAreaExpanded = false;
      }
    },
    rerender() {
      this.componentKey += 1;
    },
    closeModal(){
      // $("#addorEditModal").modal("hide");
      this.isConfirmationModalVisible = false;
    },



  //  For Mobile Responsive
    /*handleResize() {
      if(window.innerWidth <= 991)
      {

        this.contentClass = '';
      }
      else
      {

        this.contentClass = 'show';
      }

      // this.window.height = window.innerHeight;
    },
    handleMobile()
    {
      if(window.innerWidth <= 991)
      {
        this.isCollapsed = true;
      }
    }*/
  }
}
</script>

<style scoped>
.custom-collapse{
  background-color: #f7f7f7;
}
</style>

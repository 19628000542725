var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDataFetched)?_c('div',{key:_vm.componentKey,staticClass:"container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"tab-content cusform",attrs:{"id":"nav-tabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"employee-detail","role":"tabpanel","aria-labelledby":"employee-detail-tab"}},[_c('div',{staticClass:"mt-30"},[_c('div',{staticClass:"w3-card"},[_c('div',{staticClass:"card-body"},[_c('form',{staticClass:"custom-validation",attrs:{"novalidate":""}},[_vm._m(2),_c('h3',[_vm._v("Company Info")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Company Logo")]),_c('div',{staticClass:"col-sm-9"},[(!_vm.disCompanyLogo)?_c('img',{staticStyle:{"max-width":"100px","max-height":"100px"},attrs:{"src":require("../../assets/images/no-image.png"),"alt":"","title":""}}):_c('img',{staticStyle:{"max-width":"100px","max-height":"100px"},attrs:{"src":_vm.disCompanyLogo,"alt":"","title":""}})])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Change Company Logo")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{attrs:{"type":"file","accept":"image/*","id":"file-input"},on:{"change":function($event){return _vm.getCompanyImage($event)}}})])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Company Name")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.companyName),expression:"body.companyName"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.companyName.$error,
                          },attrs:{"placeholder":"Company Name","name":"name","type":"text"},domProps:{"value":(_vm.body.companyName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "companyName", $event.target.value)}}}),(_vm.submitted && !_vm.$v.body.companyName.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Company Name is required ")]):_vm._e(),(!_vm.$v.body.companyName.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Company Name must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.body.companyName.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 350 characters is reached ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Company Email")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.companyEmail),expression:"body.companyEmail"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.companyEmail.$error,
                          },attrs:{"id":"name","placeholder":"Company Email","name":"name","type":"email"},domProps:{"value":(_vm.body.companyEmail)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "companyEmail", $event.target.value)}}}),(_vm.submitted && !_vm.$v.body.companyEmail.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Company Email is required ")]):_vm._e(),(!_vm.$v.body.companyEmail.email)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid Email Address ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("NTN")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.ceoName),expression:"body.ceoName"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && _vm.$v.body.ceoName.$error,
                          },attrs:{"id":"ceo","placeholder":"Company CEO","name":"NTN","type":"text"},domProps:{"value":(_vm.body.ceoName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "ceoName", $event.target.value)}}}),(_vm.submitted && !_vm.$v.body.ceoName.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" NTN is required ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Industry")]),_c('div',{staticClass:"col-sm-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.industryId),expression:"body.industryId"}],staticClass:"custom-select-option form-control form-control-sm",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.industryId.$error,
                          },attrs:{"id":"industry_id"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.body, "industryId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Select Industry")]),_vm._l((_vm.industries),function(option){return _c('option',{key:option.key,domProps:{"value":option.key}},[_vm._v(" "+_vm._s(option.value)+" ")])})],2),(_vm.submitted && !_vm.$v.body.industryId.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Industry is required ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Who's Hiring")]),_c('div',{staticClass:"col-sm-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOwnership),expression:"selectedOwnership"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.selectedOwnership.$error,
                          },attrs:{"required":"","id":"ownership_type_id","name":"ownership_type_id"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedOwnership=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.ownerships),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0),(_vm.submitted && !_vm.$v.selectedOwnership.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Who's Hiring is required ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Company Established In")]),_c('div',{staticClass:"col-sm-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.establishedInYear),expression:"body.establishedInYear"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.establishedInYear.$error,
                          },attrs:{"id":"established_in","name":"established_in"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.body, "establishedInYear", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"-1"}},[_vm._v("Select Year")]),_vm._l((_vm.yearList),function(option,index){return _c('option',{key:index,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])})],2),(
                            _vm.submitted && !_vm.$v.body.establishedInYear.required
                          )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Year is required ")]):_vm._e()])])]),_c('hr'),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Description")]),_c('div',{staticClass:""},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.companyDesciption),expression:"body.companyDesciption"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.companyDesciption.$error,
                          },attrs:{"id":"description","placeholder":"Company details","name":"description","rows":"5","maxlength":"2000"},domProps:{"value":(_vm.body.companyDesciption)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "companyDesciption", $event.target.value)}}}),(
                            _vm.submitted && !_vm.$v.body.companyDesciption.required
                          )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description is required ")]):_vm._e(),(!_vm.$v.body.companyDesciption.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.body.companyDesciption.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 2000 characters is reached ")]):_vm._e()])])]),_c('hr'),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Address")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.address),expression:"body.address"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && _vm.$v.body.address.$error,
                          },attrs:{"placeholder":"Address","name":"location","type":"text"},domProps:{"value":(_vm.body.address)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "address", $event.target.value)}}}),(_vm.submitted && !_vm.$v.body.address.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Address is required ")]):_vm._e(),(!_vm.$v.body.address.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Address must contain at least 3 characters ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Website URL")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.websiteUrl),expression:"body.websiteUrl"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.websiteUrl.$error,
                          },attrs:{"placeholder":"http://www.comapnydomain.com","name":"website","type":"url"},domProps:{"value":(_vm.body.websiteUrl)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "websiteUrl", $event.target.value)}}}),(!_vm.$v.body.websiteUrl.url)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter valid URL ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Phone")]),_c('div',{staticClass:"col-sm-9"},[_c('vue-tel-input',_vm._b({class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.contactNumber.$error,
                          },model:{value:(_vm.body.contactNumber),callback:function ($$v) {_vm.$set(_vm.body, "contactNumber", $$v)},expression:"body.contactNumber"}},'vue-tel-input',_vm.vueTellProps,false)),(_vm.submitted && !_vm.$v.body.contactNumber.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Phone is required ")]):_vm._e()],1)])]),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Country")]),_c('div',{staticClass:"col-sm-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.countryId),expression:"body.countryId"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.countryId.$error,
                          },attrs:{"id":"country_id","name":"country_id"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.body, "countryId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Select Country")]),_vm._l((_vm.countries),function(option){return _c('option',{key:option.key,domProps:{"value":option.key}},[_vm._v(" "+_vm._s(option.value)+" ")])})],2),(_vm.submitted && !_vm.$v.body.countryId.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Country is required ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("City")]),_c('div',{staticClass:"col-sm-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.cityId),expression:"body.cityId"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && _vm.$v.body.cityId.$error,
                          },attrs:{"id":"city_id","name":"city_id"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.body, "cityId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Select City")]),_vm._l((_vm.cities),function(option){return _c('option',{key:option.key,domProps:{"value":option.key}},[_vm._v(" "+_vm._s(option.value)+" ")])})],2),(_vm.submitted && !_vm.$v.body.cityId.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" City is required ")]):_vm._e()])])]),_c('hr'),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("linkedin")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.linkedInLink),expression:"body.linkedInLink"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.linkedInLink.$error,
                          },attrs:{"placeholder":"linkedin url","name":"linkedin","type":"url"},domProps:{"value":(_vm.body.linkedInLink)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "linkedInLink", $event.target.value)}}}),(!_vm.$v.body.linkedInLink.url)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter valid URL ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("facebook")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.facebookLink),expression:"body.facebookLink"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.facebookLink.$error,
                          },attrs:{"placeholder":"facebook url","name":"facebook","type":"url"},domProps:{"value":(_vm.body.facebookLink)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "facebookLink", $event.target.value)}}}),(!_vm.$v.body.facebookLink.url)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter valid URL ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Google Plus")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.googlePlusLink),expression:"body.googlePlusLink"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.googlePlusLink.$error,
                          },attrs:{"id":"location","placeholder":"google plus url","name":"linkedin","type":"url"},domProps:{"value":(_vm.body.googlePlusLink)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "googlePlusLink", $event.target.value)}}}),(!_vm.$v.body.googlePlusLink.url)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter valid URL ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Twitter")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.twitterLink),expression:"body.twitterLink"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.twitterLink.$error,
                          },attrs:{"placeholder":"twitter url","name":"website","type":"url"},domProps:{"value":(_vm.body.twitterLink)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "twitterLink", $event.target.value)}}}),(!_vm.$v.body.twitterLink.url)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter valid URL ")]):_vm._e()])])]),_c('hr'),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Employee Picture")]),_c('div',{staticClass:"col-sm-9"},[(!_vm.disEmployeeLogo)?_c('img',{staticStyle:{"max-width":"100px","max-height":"100px"},attrs:{"src":require("../../assets/images/no-image.png"),"alt":"","title":""}}):_c('img',{staticStyle:{"max-width":"100px","max-height":"100px"},attrs:{"src":_vm.disEmployeeLogo,"alt":"","title":""}})])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Change Employee Picture")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{attrs:{"type":"file","accept":"image/*","id":"file-input1"},on:{"change":function($event){return _vm.getEmployeeImage($event)}}})])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Who's Making this profile")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.fullName),expression:"body.fullName"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.fullName.$error,
                          },attrs:{"placeholder":"Employee Name","name":"empname","type":"text"},domProps:{"value":(_vm.body.fullName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "fullName", $event.target.value)}}}),(_vm.submitted && !_vm.$v.body.fullName.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Employee Name is required ")]):_vm._e(),(!_vm.$v.body.fullName.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Employee Name must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.body.fullName.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 100 characters is reached ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Employee Email")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.email),expression:"body.email"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && _vm.$v.body.email.$error,
                          },attrs:{"id":"facebook","placeholder":"Employee Email","name":"empemail","type":"text"},domProps:{"value":(_vm.body.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "email", $event.target.value)}}}),(_vm.submitted && !_vm.$v.body.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Employee Email is required ")]):_vm._e(),(!_vm.$v.body.email.email)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter valid Email Address ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Employee Mobile Number")]),_c('div',{staticClass:"col-sm-9"},[_c('vue-tel-input',_vm._b({class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.mobileNumber.$error,
                          },model:{value:(_vm.body.mobileNumber),callback:function ($$v) {_vm.$set(_vm.body, "mobileNumber", $$v)},expression:"body.mobileNumber"}},'vue-tel-input',_vm.vueTellProps,false)),(_vm.submitted && !_vm.$v.body.mobileNumber.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Mobile is required ")]):_vm._e()],1)])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 form-group row no-gutters"},[_c('label',{staticClass:"col-sm-3 col-form-label col-form-label-sm"},[_vm._v("Designation")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.designation),expression:"body.designation"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.body.designation.$error,
                          },attrs:{"placeholder":"Designation","type":"text"},domProps:{"value":(_vm.body.designation)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "designation", $event.target.value)}}}),(_vm.submitted && !_vm.$v.body.designation.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Designation is required ")]):_vm._e()])])]),_vm._m(3),_c('hr'),_c('div',{staticClass:"modal-footer",staticStyle:{"width":"100%","border-top":"0"}},[_c('div',[_c('b-button',{staticClass:"form-control custom-form-btn custom-btn-gotopage",attrs:{"disabled":_vm.isLoading,"variant":"outline-dark"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("Save ")])],1)])])])])])])])])]),_c('div',{staticClass:"style-spacer"})]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"custom-row custom-options-menu justify-content-between align-items-center"},[_c('div',{staticClass:"customHeading"},[_c('nav',[_c('div',{staticClass:"nav nav-tabs custom-nav-tabs",attrs:{"id":"nav-tab","role":"tablist"}},[_c('a',{staticClass:"nav-item nav-link custom-nav-link custom-nav-heading active",attrs:{"id":"employee-detail-tab","data-toggle":"tab","href":"#employee-detail","role":"tab","aria-controls":"employee-detail","aria-selected":"true"}},[_c('span',[_vm._v(" Employer Information ")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-accordion-separator"},[_c('div',{staticClass:"custom-separator"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 mt-4"},[_c('div',{staticClass:"formrow text-center"})])
}]

export { render, staticRenderFns }
<template>
  <div>
    <!-- Banner Section For Web Start -->
    <!--    <section class="webSection container-fluid custom-banner-section">
      <div class="custom-section">
        <div class="custom-row-wrap">

        </div>

      </div>

    </section>-->
    <!-- Banner Section For Web End -->

    <div>
      <div class="limiter">
        <!--                 &lt;!&ndash; hidden anchor to stop jump http://www.css3create.com/Astuce-Empecher-le-scroll-avec-l-utilisation-de-target#wrap4  &ndash;&gt;-->

        <div class="container-login100">
          <div class="wrap-login100">
            <div class="w-100 text-center p-b-32">
              <img src="../assets/images/Hecancylogo.png" alt="" height="80" />
            </div>

            <!--                     <strong>
                                   <h3 class="login100-form-title p-b-32 text-center">

                                     Sign In
                                   </h3>
                                 </strong>-->

            <form
              class="login100-form needs-validation"
              :class="[
                !isValidated ? '' : '',
                isValidated ? 'was-validated' : '',
              ]"
              autocomplete="off"
              novalidate
            >
              <div
                v-if="rememberItPassword"
                class="w3-animate-opacity alert alert-success text-center mb-4"
                role="alert"
              >
                Enter your Email and instructions will be sent to you!
              </div>
              <div class="custom-row p-b-5">
                <span class="txt1 flex-grow-1">
                  Email Address
                  <span class="text-danger">*</span>
                </span>
                <div v-if="rememberItPassword">
                  <a
                    href="javascript:void(0);"
                    class="
                      w3-animate-opacity
                      custom-text-color
                      text-decoration-none
                    "
                    @click="hideOrShow"
                    ><span>Remember It?</span></a
                  >
                </div>
              </div>
              <div class="m-b-10 w3-animate-opacity">
                <input
                  v-model="emailAddress"
                  class="form-control custom-input-gotopage"
                  type="email"
                  name="Email Address"
                  required="required"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                />
                <div class="invalid-feedback">
                  <span v-if="emailAddress == ''" class="w3-animate-opacity"
                    >Please enter your Email address</span
                  >
                  <span
                    v-if="
                      emailAddress != '' &&
                      !emailAddress.match(
                        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/
                      )
                    "
                    class="w3-animate-opacity"
                    >Invalid Email Address</span
                  >
                </div>
              </div>
              <div v-if="forgetPassword">
                <div class="w3-animate-opacity custom-row p-b-5">
                  <span class="txt1 flex-grow-1">
                    Password
                    <span class="text-danger">*</span>
                  </span>
                  <div>
                    <a
                      href="javascript:void(0);"
                      class="custom-text-color text-decoration-none"
                      @click="hideOrShow"
                      ><span>Forgot Password?</span></a
                    >
                  </div>
                </div>

                <div class="m-b-30 w3-animate-opacity">
                  <div class="input-group mb-3">
                    <input
                      v-if="showPassword"
                      type="text"
                      class="form-control custom-input-gotopage"
                      v-model="password"
                      required="required"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
                    />

                    <input
                      v-else
                      type="password"
                      class="form-control custom-input-gotopage"
                      v-model="password"
                      required="required"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
                    />

                    <div class="input-group-append">
                      <button
                        class="
                          btn btn-outline-secondary
                          form-control
                          custom-input-gotopage
                        "
                        type="button"
                        @click="togglePasswordShow"
                      >
                        <span class="is-small is-right">
                          <i
                            class="fas"
                            :class="{
                              'fa-eye-slash': showPassword,
                              'fa-eye': !showPassword,
                            }"
                          ></i>
                        </span>
                      </button>
                    </div>
                    <div class="invalid-feedback">
                      <span v-if="password == ''" class="w3-animate-opacity"
                        >Please enter your Password</span
                      >
                      <span
                        v-if="
                          password != '' &&
                          !password.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)
                        "
                        class="w3-animate-opacity"
                        >Invalid Password, Must contain at least one number and
                        one uppercase and lowercase letter, and at least 8 or
                        more characters</span
                      >
                    </div>
                  </div>
                </div>

                <!-- <div class="flex-sb-m w-full p-b-48">
                <div class="contact100-form-checkbox">
                  <input
                    class="input-checkbox100"
                    id="ckb1"
                    type="checkbox"
                    name="remember-me"
                  />
                  <label class="label-checkbox100" for="ckb1"> Remember me </label>
                </div>

                <div>
                  <a href="#" class="txt3"> Forgot Password? </a>
                </div>
              </div> -->
              </div>
              <div v-if="forgetPassword" class="mb-3">
                <b-form-checkbox
                  id="checkbox-4"
                  v-model="state"
                  name="checkbox-1"
                  class="custom-checkbox-color"
                  required
                >
                  <span style="color: #212529 !important">
                    I have read and accept the
                    <a
                      href="#policiesModal"
                      data-toggle="modal"
                      @click="termsModal"
                      title="Terms & Conditions"
                      class="custom-text-color text-decoration-none"
                      >Terms & Conditions</a
                    >
                    of use,
                    <a
                      href="#policiesModal"
                      data-toggle="modal"
                      @click="privacyModal"
                      title="Privacy Policy"
                      class="custom-text-color text-decoration-none"
                      >Privacy Policy</a
                    >,
                    <a
                      href="#policiesModal"
                      data-toggle="modal"
                      @click="cookieModal"
                      title="Cookie Policy"
                      class="custom-text-color text-decoration-none"
                      >Cookie Policy</a
                    >
                    and
                    <a
                      href="#policiesModal"
                      data-toggle="modal"
                      @click="disclaimerModal"
                      title="Disclaimer"
                      class="custom-text-color text-decoration-none"
                      >disclaimer</a
                    >
                    which will apply on the proceeding of my personal data in
                    the provision of service.
                  </span>
                </b-form-checkbox>

                <div class="mt-2" v-if="emailAddress != '' && password != ''">
                  <div
                    v-if="!state"
                    class="
                      custom-row
                      w3-animate-opacity
                      alert alert-warning
                      mb-4
                    "
                    role="alert"
                  >
                    <span class="custom-sidebar-icons"
                      ><b-icon
                        icon="exclamation-triangle"
                        scale="2"
                        variant="danger"
                      ></b-icon
                    ></span>
                    <span class="ml-2 font-weight-bold font-italic"
                      >Please accept the terms and conditions before
                      proceeding.</span
                    >
                  </div>
                </div>
              </div>
              <div class="container-login100-form-btn ml-auto m-b-20">
                <button
                  @click.prevent="submitForm"
                  :disabled="disabled"
                  :class="[
                    !disabled ? 'login100-form-btn' : '',
                    disabled ? 'custom-login100-form-btn' : '',
                  ]"
                >
                  <span v-if="isLoading">
                    <b-spinner small></b-spinner>
                  </span>

                  <span v-if="forgetPassword" class="ml-2">Sign In</span>
                  <span v-else class="ml-2">Reset</span>
                </button>
              </div>
              <div v-if="forgetPassword" class="w3-animate-opacity">
                <div class="mt-4 text-center">
                  <div class="signin-other-title">
                    <h5 class="font-size-14 mb-3 title">Sign in with</h5>
                  </div>

                  <!-- <GoogleLogin
                    :params="params"
                    :onSuccess="onSuccess"
                  ></GoogleLogin> -->
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <!-- https://smw.demo.itrooscloud.net/api/EmployeeSocialAuthentication/GoogleLogin -->
                      <a
                        @click.prevent="googleLogin"
                        href="javascript:void(0)"
                        class="
                          custom-googleplus-icon
                          custom-icon-style
                          custom-social-link
                        "
                      >
                        <i class="fab fa-google"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        href="javascript:void(0)"
                        class="
                          custom-linkedin-icon
                          custom-icon-style
                          custom-social-link
                        "
                      >
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="text-center">
                  <p class="text-muted mb-0">
                    Don't have an account ?
                    <router-link
                      :to="{ name: 'SignUp' }"
                      class="fw-medium custom-text-color"
                    >
                      Register Now</router-link
                    >
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade cpModal"
      id="policiesModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addorEditModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title font-weight-bold" id="exampleModalLongTitle">
              {{ modalTitle }}
            </h3>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="handleClickSignOut"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-justify">
            <div v-if="selectRoleSection">
              <form
                class="needs-validation"
                :class="[
                  !isValidated ? '' : '',
                  isValidated ? 'was-validated' : '',
                ]"
                autocomplete="off"
                novalidate
              >
                <span class="txt1 p-b-5">
                  Register As
                  <span class="text-danger">*</span>
                </span>
                <div class="m-b-10">
                  <select
                    v-model="selectedRoleForSocialMedia"
                    class="form-control custom-select-option"
                    required
                  >
                    <option value="">Select User Role</option>
                    <option
                      v-for="option in roles"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>

                  <div class="invalid-feedback">
                    <span
                      v-if="selectedRoleForSocialMedia == ''"
                      class="w3-animate-opacity"
                      >Please Select your Role</span
                    >
                  </div>
                </div>

                <div class="modal-footer">
                  <div>
                    <b-button
                      class="
                        form-control form-control-sm
                        custom-form-btn custom-close-btn
                      "
                      type="button"
                      size="sm"
                      variant="secondary"
                      data-dismiss="modal"
                      @click="handleClickSignOut"
                    >
                      Close
                    </b-button>
                  </div>
                  <div>
                    <b-button
                      class="
                        form-control form-control-sm
                        custom-form-btn custom-btn-gotopage
                      "
                      @click.prevent="onSubmitRole"
                      type="button"
                      size="sm"
                      variant="outline-dark"
                    >
                      Submit
                    </b-button>
                  </div>
                </div>
              </form>
            </div>
            <div v-if="termsSection">
              Updated at 2021-01-04
              <h5 class="font-weight-bold">General Terms</h5>
              <p>
                By accessing and placing an order with, you confirm that you are
                in agreement with and bound by the terms of service contained in
                the Terms & Conditions outlined below. These terms apply to the
                entire website and any email or other type of communication
                between you and Under no circumstances shall team be liable for
                any direct, indirect, special, incidental or consequential
                damages, including, but not limited to, loss of data or profit,
                arising out of the use, or the inability to use, the materials
                on this site, even if team or an authorized representative has
                been advised of the possibility of such damages. If your use of
                materials from this site results in the need for servicing,
                repair or correction of equipment or data, you assume any costs
                thereof. will not be responsible for any outcome that may occur
                during the course of usage of our resources. We reserve the
                rights to change prices and revise the resources usage policy in
                any moment
              </p>
              <h5 class="font-weight-bold">License</h5>
              ‘Shemeanswork grants you a revocable, non-exclusive, non-
              transferable, limited license to download, install and use our
              service strictly in accordance with the terms of this Agreement.
              ‘These Terms & Conditions are a contract between you and
              Shemeanswork (referred to in these Terms & Conditions as
              "Shemeanswork’, "us", "we" or “our’), the provider of the
              Shemeanswork website and the services accessible from the
              ‘Shemeanswork website (which are collectively referred to in these
              Terms & Conditions as the "Shemeanswork Service") You are agreeing
              to be bound by these Terms & Conditions. If you do not agree to
              these Terms & Conditions, please do not use the Service. In these
              Terms & Conditions, "you refers both to you as an individual and
              to the entity you represent. If you violate any of these Terms &
              Conditions, we reserve the right to cancel your account or block
              access to your account without notice You are agreeing to be bound
              by these Terms & Conditions. If you do not agree to these Terms &
              Conditions, please do not use the Service. In these Terms &
              Conditions, "you refers both to you as an individual and to the
              entity you represent. If you violate any of these Terms &
              Conditions, we reserve the right to cancel your account or block
              access to your account without notice

              <h5 class="font-weight-bold">Definitions and key terms</h5>
              For this Terms & Conditions:
              <ul>
                <li>
                  <b>Cookie:</b> small amount of data generated by a website and
                  saved by your web browser. It is used to identify your
                  browser, provide analytics, remember information about you
                  such as your language preference or login information
                </li>
                <li>
                  <b>Company:</b> when this policy mentions “Company,” “we,”
                  “us,” or “our,” it refers to LBS Pvt LTD, 23 The Mall Road
                  Lahore that is responsible for your information under this
                  Privacy Policy.
                </li>
                <li>
                  <b>Country:</b> where Shemeanswork or the owners/founders of
                  Shemeanswork are based, in this case is Pakistan
                </li>
                <li>
                  <b>Customer:</b> refers to the company, organization or person
                  that signs up to use the Shemeanswork Service to manage the
                  relationships with your consumers or service users.
                </li>
                <li>
                  <b>Device:</b> any internet connected device such as a phone,
                  tablet, computer or any other device that can be used to visit
                  ‘Shemeanswork and use the services.
                </li>
                <li>
                  <b>IP address:</b> Every device connected to the Internet is
                  assigned a number known as an Internet protocol (IP) address.
                  These ‘numbers are usually assigned in geographic blocks. An
                  IP address can often be used to identify the location from
                  which a device is connecting to the Internet.
                </li>
                <li>
                  <b>Personnel:</b> refers to those individuals who are employed
                  by Shemeanswork or are under contract to perform a service on
                  behalf of one of the parties.
                </li>
                <li>
                  <b>Personal Data:</b> any information that directly,
                  indirectly, or in connection with other information
                  identification number — allows for the identification or
                  identifiability of a natural person.
                </li>
                <li>
                  <b>Service:</b> refers to the service provided by Shemeanswork
                  as described in the relative terms (if available) and on this
                  platform.
                </li>
                <li>
                  <b>Third-party service:</b> refers to advertisers, contest
                  sponsors, promotional and marketing partners, and others who
                  provide our content or whose products or services we think may
                  interest you.
                </li>
                <li>
                  <b>Website:</b> Shemeanswork's site, which can be accessed via
                  this URL:
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="https://www.shemeanswork.com"
                    target="_blank"
                  >
                    <!--              <span>Phone: </span> -->
                    <span class="custom-user-contacts"
                      >https://www.shemeanswork.com</span
                    > </a
                  >.
                </li>
                <li>
                  <b>You:</b> a person or entity that is registered with
                  Shemeanswork to use the Services including a personal.
                </li>
              </ul>

              <h5 class="font-weight-bold">Restrictions</h5>
              You agree not to, and you will not permit others to:
              <ul>
                <li>
                  License, sell, rent, lease, assign, distribute, transmit,
                  host, outsource, disclose or otherwise commercially exploit
                  the service or ‘make the platform available to any third party
                </li>
                <li>
                  Modify, make derivative works of, disassemble, decrypt,
                  reverse compile or reverse engineer any part of the service.
                </li>
                <li>
                  Remove, alter or obscure any proprietary notice (including any
                  notice of copyright or trademark) of or its affiliates,
                  partners, suppliers or the licensors of the service.
                </li>
              </ul>
              <h5 class="font-weight-bold">Payment</h5>
              <p>
                If you register to any of our recurring payment plans, you agree
                to pay all fees or charges to your account for the Service in
                accordance with the fees, charges and billing terms in effect at
                the time that each fee or charge is due and payable. Unless
                otherwise indicated in an order form, you must provide us with a
                valid credit card (Visa, MasterCard, or any other issuer
                accepted by us) (‘Payment Provider’) as a condition to signing
                up for the Premium plan. Your Payment Provider agreement governs
                your use of the designated credit card account, and you must
                refer to that agreement and not these Terms to determine your
                rights and liabilities with respect to your Payment Provider. By
                providing us with your credit card number and associated payment
                information, you agree that we are authorized to verify
                information immediately, and subsequently invoice your account
                for all fees and charges due and payable to us hereunder and
                that no additional notice or consent is required. You agree to
                immediately notify us of any change in your billing address or
                the credit card used for payment hereunder. We reserve the right
                at any time to change its prices and billing methods, either
                immediately upon posting on our Site or by e-mail delivery to
                your organization's administrator(s). Any attorney fees, court
                costs, or other costs incurred in collection of delinquent
                undisputed amounts shall be the responsibility of and paid for
                by you. No contract will exist between you and us for the
                Service until we accept your order by a confirmatory e-mail,
                SMS/MMS message, or other appropriate means of communication.
                You are responsible for any third-party fees that you may incur
                when using the Service.
              </p>

              <h5 class="font-weight-bold">Return and Refund Policy</h5>
              ‘Thanks for shopping with us. We appreciate the fact that you like
              to buy the stuff we build, We also want to make sure you have a
              rewarding experience while you're exploring, evaluating, and
              purchasing our products. ‘As with any shopping experience, there
              are terms and conditions that apply to transactions at our
              company. We'll be as brief as our attorneys will allow. The main
              thing to remember is that by placing an order or making a purchase
              from us, you agree to the terms along with our Privacy Policy. If,
              for any reason, You are not completely satisfied with any good or
              service that we provide, don't hesitate to contact us and we will
              discuss any of the issues you are going through with our product.
              <h5 class="font-weight-bold">Your Suggestions</h5>
              ‘Any feedback, comments, ideas, improvements or suggestions
              (collectively, "Suggestions”) provided by you to us with respect
              to the service shall remain the sole and exclusive property of us.
              We shall be free to use, copy, modify, publish, or redistribute
              the ‘Suggestions for any purpose and in any way without any credit
              or any compensation to you.
              <h5 class="font-weight-bold">Your Consent</h5>
              We've updated our Terms & Conditions to provide you with complete
              transparency into what is being set when you visit our site and
              how it's being used. By using our service, registering an account,
              or making a purchase, you hereby consent to our Terms &
              Conditions,
              <h5 class="font-weight-bold">Links to Other Websites</h5>
              ‘Our service may contain links to other websites that are not
              operated by Us. If You click on a third party ink, You will be
              directed to that third party's site. We strongly advise You to
              review the Terms & Conditions of every site You visit. We have no
              control over and assume no responsibility for the content, Terms &
              Conditions or practices of any third party sites or services.
              <h5 class="font-weight-bold">Cookies</h5>
              We use "Cookies" to identify the areas of our website that you
              have visited. A Cookie is a small piece of data stored on your
              computer ‘or mobile device by your web browser. We use Cookies to
              enhance the performance and functionality of our service but are
              non- essential to their use. However, without these cookies,
              certain functionality like videos may become unavailable or you
              would be required to enter your login details every time you visit
              our platform as we would not be able to remember that you had
              logged in previously. Most web browsers can be set to disable the
              use of Cookies. However, if you disable Cookies, you may not be
              able to ‘access functionality on our website correctly or at all.
              We never place Personally Identifiable Information in Cookies.
              <h5 class="font-weight-bold">
                Changes To Our Terms & Conditions
              </h5>
              You acknowledge and agree that we may stop (permanently or
              temporarily) providing the Service (or any features within the
              Service) to you or to users generally at our sole discretion,
              without prior notice to you. You may stop using the Service at any
              time. You do not need to specifically inform us when you stop
              using the Service. You acknowledge and agree that if we disable
              access to your ‘account, you may be prevented from accessing the
              Service, your account details or any files or other materials
              which is contained in your account. If we decide to change our
              Terms & Conditions, we will post those changes on this page,
              and/or update the Terms & Conditions modification date below.
              <h5 class="font-weight-bold">Modifications to Our service</h5>
              We reserve the right to modify, suspend or discontinue,
              temporarily or permanently, the service or any service to which it
              connects, with or without notice and without liability to you.
              <h5 class="font-weight-bold">Updates to Our service</h5>
              We may from time to time provide enhancements or improvements to
              the features/ functionality of the service, which may include
              patches, bug fixes, updates, upgrades and other modifications
              ("Updates"). Updates may modify or delete certain features and/or
              functionalities of the service. You agree that we have no
              obligation to (i) provide any Updates, or (ji) continue to provide
              or enable any particular features and/or functionalities of the
              service to you. You further agree that all Updates will be (i)
              deemed to constitute an integral part of the service, and (i)
              subject to the terms and conditions of this Agreement.
              <h5 class="font-weight-bold">Third-Party Services</h5>
              We may display, include or make available third-party content
              (including data, information, applications and other products
              services) or provide links to third-party websites or services
              ("Third-Party Services"). You acknowledge and agree that we shall
              not be responsible for any Third-Party Services, including their
              accuracy, completeness, timeliness, validity, copyright
              compliance, legality, decency, quality or any other aspect
              thereof. We do not assume and shall not have any liability or
              responsibility to you or any other person or entity for any
              Third-Party Services. Third-Party Services and links thereto are
              provided solely as a convenience to you and you access and use
              them entirely at your own risk and subject to such third parties’
              terms and conditions.

              <h5 class="font-weight-bold">Term and Termination</h5>
              This Agreement shall remain in effect until terminated by you or
              us. We may, in its sole discretion, at any time and for any or no
              reason, suspend or terminate this Agreement with or without prior
              notice. This Agreement will terminate immediately, without prior
              notice from us, in the event that you fail to comply with any
              provision of this Agreement. You may also terminate this Agreement
              by deleting the service and all copies thereof from your computer.
              Upon termination of this Agreement, you shall cease all use of the
              service and delete all copies of the service from your computer.
              Termination of this Agreement will not limit any of our rights or
              remedies at law or in equity in case of breach by you (during the
              term of this Agreement) of any of your obligations under the
              present Agreement.

              <h5 class="font-weight-bold">Term and Termination</h5>
              If you are a copyright owner or such owner's agent and believe any
              material from us constitutes an infringement on your copyright,
              please contact us setting forth the following information: (a) a
              physical or electronic signature of the copyright owner or a
              person authorized to act on his behalf; (b) identification of the
              material that is claimed to be infringing: (c) your contact
              information, including your address, telephone number, and an
              email: (d) a statement by you that you have a good faith belief
              that use of the material is not authorized by the copyright
              owners; and (e) the a statement that the information in the
              notification is accurate, and, under penalty of perjury you are
              authorized to act on behalf of the owner.

              <h5 class="font-weight-bold">Indemnification</h5>
              You agree to indemnify and hold us and our parents, subsidiaries,
              affiliates, officers, employees, agents, partners and licensors
              (if any) harmless from any claim or demand, including reasonable
              attorneys’ fees, due to or arising out of your: (a) use of the
              service; (b) violation of this Agreement or any law or regulation;
              or (c) violation of any right of a third party.

              <h5 class="font-weight-bold">No Warranties</h5>
              ‘The service is provided to you "AS IS" and "AS AVAILABLE” and
              with all faults and defects without warranty of any kind. To the
              ‘maximum extent permitted under applicable law, we, on our own
              behalf and on behalf of our affiliates and our respective
              licensors and service providers, expressly disclaims all
              warranties, whether express, statutory or otherwise, with respect
              to the service, including all implied warranties of
              merchantability, fitness for a particular purpose, title and
              non-infringement, and warranties that may arise out of course of
              dealing, course of performance, usage or trade practice. Without
              limitation to the foregoing, we provide no warranty or
              undertaking, and makes no representation of any kind that the
              service will meet your requirements, achieve any intended results,
              be compatible or work with any other software, websites, systems
              or services, operate without interruption, meet any performance or
              reliability standards or be error free or that any errors or
              defects can or will be corrected Without limiting the foregoing,
              neither us nor any provider makes any representation or warranty
              of any kind, express or implied: (i) as to the operation or
              availability of the service, or the information, content, and
              materials or products included thereon; (ii) that the service will
              be uninterrupted or error-free; (iii) as to the accuracy,
              reliability, or currency of any information or content provided
              through the service: or (iv) that the service, its servers, the
              content, or e-mails sent from or on behalf of us are free of
              viruses, scripts, Trojan horses, worms, malware, time bombs or
              other harmful components. Some jurisdictions do not allow the
              exclusion of or limitations on implied warranties or the
              limitations on the applicable statutory rights of a consumer, so
              some or all of the above exclusions and limitations may not apply
              to you

              <h5 class="font-weight-bold">Limitation of Liability</h5>
              Notwithstanding any damages that you cur, the entire liability of
              us and any of our suppliers under any provision of this,
              ‘Agreement and your exclusive remedy for all of the foregoing
              shall be limited to the amount actually paid by you for the
              service. To the maximum extent permitted by applicable law, in no
              event shall we or our suppliers be liable for any special,
              incidental, indirect, or ‘consequential damages whatsoever
              (including, but not limited to, damages for loss of profits, for
              loss of data or other information, for business interruption, for
              personal injury, for loss of privacy arising out of or in any way
              related to the use of or in ability to use the service,
              third-party software and or third-party hardware used with the
              service, or otherwise in connection with any provision of this
              Agreement), even if we or any supplier has been advised of the
              possibility of such damages and even if the remedy fails of its
              essential purpose. Some states/jurisdictions do not allow the
              exclusion or limitation of incidental or consequential damages, so
              the above limitation or exclusion may not apply to you.

              <h5 class="font-weight-bold">Severability</h5>
              If any provision of this Agreement is held to be unenforceable or
              invalid, such provision will be changed and interpreted to
              accomplish the objectives of such provision to the greatest extent
              possible under applicable law and the remaining provisions will
              continue in full force and effect. This Agreement, together with
              the Privacy Policy and any other legal notices published by us on
              the Services, shall constitute the ‘competent jurisdiction, the
              invalidity of such provision shall not affect the validity of the
              remaining shall remain in full force and effect. No waiver of any
              term of this Agreement shall be deemed a further or continuing
              waiver of such term or any other term, and our failure to assert
              any right or provision under this Agreement shall not constitute a
              waiver of such right or provision. YOU AND US AGREE THAT ANY CAUSE
              OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE
              WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE,
              SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.

              <h5 class="font-weight-bold">Waiver</h5>
              Except as provided herein, the failure to exercise a right or to
              require performance of an obligation under this Agreement shall
              not effect a party's ability to exercise such right or require
              such performance at any time thereafter nor shall be the waiver of
              a breach constitute waiver of any subsequent breach. No failure to
              exercise, and no delay in exercising, on the part of either party,
              any right or any power under this Agreement shall ‘operate as a
              waiver of that right or power. Nor shall any single or partial
              exercise of any right or power under this Agreement preclude
              further exercise of that or any other right granted herein. In the
              event of a conflict between this Agreement and any applicable
              purchase or other terms, the terms of this Agreement shall govern
              <h5 class="font-weight-bold">Amendments to this Agreement</h5>
              We reserve the right, at its sole discretion, to modify or replace
              this Agreement at any time. If a revision ‘material we will
              provide at least 30 days’ notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion. By continuing to access or use our service
              after any revisions become effective, you agree to be bound by the
              revised terms. If you do not agree to the new terms, you are no
              longer authorized to use our service.
              <h5 class="font-weight-bold">Entire Agreement</h5>
              ‘The Agreement constitutes the entire agreement between you and us
              regarding your use of the service and supersedes all prior and
              contemporaneous written or oral agreements between you and us. You
              may be subject to additional terms and conditions that apply when
              you use or purchase other services from us, which we will provide
              to you at the time of such use or purchase.

              <h5 class="font-weight-bold">Updates to Our Terms</h5>
              We may change our Service and policies, and we may need to make
              changes to these Terms so that they accurately reflect our Service
              and policies. Unless otherwise required by law, we will notify you
              (for example, through our Service) before we make changes to these
              Terms and give you an opportunity to review them before they go
              into effect. Then, if you continue to use the Service, you will be
              bound by the updated Terms. If you do not want to agree to these
              or any updated Terms, you can delete your account.
              <h5 class="font-weight-bold">Intellectual Property</h5>
              Our platform and its entire contents, features and functionality
              (including but not limited to all information, software, text,
              displays, images, video and audio, and the design, selection and
              arrangement thereof), are owned by us, its licensors or other
              providers of such material and are protected by and international
              copyright, trademark, patent, trade secret and other intellectual
              property or proprietary rights laws. The material may not be
              copied, modified, reproduced, downloaded or distributed in any
              way, in whole or in part, without the express prior written
              permission of us, unless and except as is expressly provided in
              these Terms & Conditions. Any unauthorized use of the material is
              prohibited.
              <h5 class="font-weight-bold">Agreement to Arbitrate</h5>
              This section applies to any dispute EXCEPT IT DOESN'T INCLUDE A
              DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF
              REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR’'s INTELLECTUAL
              PROPERTY RIGHTS. The term “dispute” means any dispute, action, or
              other controversy between you and us concerning the Services or
              this agreement, whether in contract, warranty, tort, statute,
              regulation, ordinance, or any other legal or equitable basis.
              “Dispute” will be given the broadest possible meaning allowable
              under law.
              <h5 class="font-weight-bold">Notice of Dispute</h5>
              In the event of a dispute, you or us must give the other a Notice
              of Dispute, which is a written statement that sets forth the name,
              address, and contact information of the party giving it, the facts
              giving rise to the dispute, and the relief requested. You must
              send any Notice of Dispute via email to: info@shemeanswork.com. We
              will send any Notice of Dispute to you by mail to your address if
              we have it, or otherwise to your ‘email address. You and us will
              attempt to resolve any dispute through informal negotiation within
              sixty (60) days from the date the Notice of Dispute is sent. After
              sixty (60) days, you or us may commence arbitration.
              <h5 class="font-weight-bold">Binding Arbitration</h5>
              If you and us don't resolve any dispute by informal negotiation,
              any other effort to resolve the dispute will be conducted
              exclusively by binding arbitration as described in this section.
              You are giving up the right to litigate (or participate in as a
              party or class member) all disputes in court before a judge or
              jury. The dispute shall be settled by binding arbitration in
              accordance with the commercial arbitration rules of the American
              Arbitration Association. Either party may seek any interim or
              preli junctive relief from any court of competent jurisdiction, as
              necessary to protect the party's rights or property pending the
              completion of arbitration. Any and all legal, accounting, and
              other costs, fees, and expenses incurred by the prevailing party
              shall be borne by the non-prevailing party
              <h5 class="font-weight-bold">Submissions and Privacy</h5>
              In the event that you submit or post any ideas, creative
              suggestions, designs, photographs, information, advertisements,
              data or proposals, including ideas for new or improved products,
              services, features, technologies or promotions, you expressly
              agree that such submissions will automatically be treated as non-
              confidential and non-proprietary and will become the sole property
              of us without any compensation or credit to you whatsoever. We and
              our affiliates shall have no obligations with respect to such
              submissions or posts and may use the ideas contained in such
              submissions or posts for any purposes in any medium in perpetuity,
              including, but not limited to, developing, manufacturing, and
              marketing products and services using such ideas.

              <h5 class="font-weight-bold">Promotions</h5>
              We may, from time to time, include contests, promotions,
              sweepstakes, or other activities (‘Promotions’) that require you
              to submit formation concerning yourself. Please note that all
              Promotions may be governed by separate rules that may contain ity
              requirements, such as restrictions as to age and geographic
              location. You are responsible to read all Promotions rules to
              determine whether or not you are eligible to participate. If you
              enter any Promotion, you agree to abide by and to comply with all
              Promotions Rules. Additional terms and conditions may apply to
              purchases of goods or services on or through the Services, which
              terms and conditions are made a part of this Agreement by this
              reference.
              <h5 class="font-weight-bold">Typographical Errors</h5>
              In the event a product and/or service is listed at an incorrect
              price or with incorrect information due to typographical error, we
              shall have the right to refuse or cancel any orders placed for the
              product and/ or service listed at the incorrect price. We shall
              have the right to refuse or cancel any such order whether or not
              the order has been confirmed and your credit card charged. If your
              credit card has already been charged for the purchase and your
              order is canceled, we shall immediately issue a credit to your
              credit card ‘account or other payment account in the amount of the
              charge.
              <h5 class="font-weight-bold">Miscellaneous</h5>
              If for any reason a court of competent jurisdiction finds any
              provision or portion of these Terms & Conditions to be
              unenforceable, the remainder of these Terms & Conditions will
              continue in full force and effect. Any waiver of any provision of
              these Terms & Conditions will be effective only if in writing and
              signed by an authorized representative of us. We will be entitled
              to injunctive or other equitable relief (without the obligations
              of posting any bond or surety) in the event of any breach or
              anticipatory breach by you. We operate and control our Service
              from our offices in . The Service is not intended for distribution
              to or use by any person or entity in any jurisdiction or country
              where such distribution or use would be contrary to law or
              regulation. Accordingly, those persons who choose to access ‘our
              Service from other locations do so on their own initiative and are
              solely responsible for compliance with local laws, if and to the
              extent local laws are applicable. These Terms & Conditions (which
              include and incorporate our Privacy Policy) contains the entire
              understanding, and supersedes all prior understandings, between
              you and us concerning its subject matter, and cannot be changed
              ‘or modified by you. The section headings used in this Agreement
              are for convenience only and will not be given any legal import.
              <h5 class="font-weight-bold">Disclaimer</h5>
              We are not responsible for any content, code or any other
              imprecision. We do not provide warranties or guarantees. In no
              event shall we be liable for any special, direct, indirect,
              consequential, or incidental damages or any damages whatsoever,
              whether in an action ‘of contract, negligence or other tort,
              arising out of or in connection with the use of the Service or the
              contents of the Service. We reserve the right to make additions,
              deletions, or modifications to the contents on the Service at any
              time without prior notice. ‘Our Service and its contents are
              provided "as is” and "as available” without any warranty or
              representations of any kind, whether express or implied. We are a
              distributor and not a publisher of the content supplied by third
              parties; as such, our exercises no editorial control over such
              content and makes no warranty or representation as to the
              accuracy, reliability or currency of any information, content,
              service or merchandise provided through or accessible via our
              Service. Without limiting the foregoing, We ‘specifically disclaim
              all warranties and representations in any content transmitted on
              or in connection with our Service or on sites that ‘may appear as
              links on our Service, or in the products provided as a part of, or
              otherwise in connection with, our Service, including without
              limitation any warranties of merchantability fitness for a
              particular purpose or non-infringement of third party rights. No
              oral advice or written information given by us or any of its
              affiliates, employees, officers, directors, agents, or the like
              will create a warranty. Price and availability information is
              subject to change without notice. Without limiting the foregoing,
              we do not warrant that ‘our Service will be uninterrupted,
              uncorrupted, timely, or error-free.

              <h5 class="font-weight-bold">Contact Us</h5>
              Don't hesitate to contact us if you have any questions.
              <ul>
                <li>
                  Via
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="mailto:info@shemeanswork.com"
                  >
                    <span
                      ><i class="fas fa-envelope fa-md fa-fw mr-2"></i
                    ></span>
                    <!--            <span>Email:</span>-->

                    <span class="custom-user-contacts"
                      >info@shemeanswork.com</span
                    >
                  </a>
                </li>
                <li>
                  Via
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="tel:0092 423 7243810"
                  >
                    <span
                      ><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i
                    ></span>
                    <!--              <span>Phone: </span> -->
                    <span class="custom-user-contacts">0092 423 7243810</span>
                  </a>
                </li>
                <li>
                  Via
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="https://www.shemeanswork.com"
                    target="_blank"
                  >
                    <span><i class="fas fa-globe fa-md fa-fw mr-2"></i></span>
                    <!--              <span>Phone: </span> -->
                    <span class="custom-user-contacts"
                      >https://www.shemeanswork.com</span
                    >
                  </a>
                </li>
                <li>
                  Via
                  <span
                    ><i class="fas fa-map-marker fa-md fa-fw mr-2"></i
                  ></span>
                  23 The Mall Road Lahore
                </li>
              </ul>
             
            </div>
            <div v-if="privacySection">
              Updated at 2021-01-04

              <p class="mt-2">
                Shemeanswork. (“we”,”our”,or “us”) is committed to protecting
                your privacy. This privacy Policy explains how your personal
                information is collected, used, and disclosed by Shemeanswork.
              </p>

              <p>
                The Privacy Policy applies to our website,
                <a
                  class="text-decoration-none w3-hover-text-gray"
                  href="https://www.shemeanswork.com"
                  target="_blank"
                >
                  <!--              <span>Phone: </span> -->
                  <span class="custom-user-contacts"
                    >https://www.shemeanswork.com</span
                  >
                </a>
                , and its associated subdomains (collectively, our “Service”).
                By accessing or using our Service, you signify that you have
                read, understood, and agree to our collection, storage, use, and
                disclosure of your personal information as descried in this
                Privacy Policy and our Terms of Service.
              </p>
              <h5 class="font-weight-bold">Definitions and key terms</h5>

              For this Privacy Policy:

              <ul>
                <li>
                  <b>Cookie:</b> small amount of data generated by a website and
                  saved by your web browser. It is used to identify your
                  browser, provide analytics, remember information about you
                  such as your language preference or login information
                </li>
                <li>
                  <b>Company:</b> when this policy mentions “Company,” “we,”
                  “us,” or “our,” it refers to LBS Pvt LTD, 23 The Mall Road
                  Lahore that is responsible for your information under this
                  Privacy Policy.
                </li>
                <li>
                  <b>Country:</b> where Shemeanswork or the owners/founders of
                  Shemeanswork are based, in this case is Pakistan
                </li>
                <li>
                  <b>Customer:</b> refers to the company, organization or person
                  that signs up to use the Shemeanswork Service to manage the
                  relationships with your consumers or service users.
                </li>
                <li>
                  <b>Device:</b> any internet connected device such as a phone,
                  tablet, computer or any other device that can be used to visit
                  ‘Shemeanswork and use the services.
                </li>
                <li>
                  <b>IP address:</b> Every device connected to the Internet is
                  assigned a number known as an Internet protocol (IP) address.
                  These ‘numbers are usually assigned in geographic blocks. An
                  IP address can often be used to identify the location from
                  which a device is connecting to the Internet.
                </li>
                <li>
                  <b>Personnel:</b> refers to those individuals who are employed
                  by Shemeanswork or are under contract to perform a service on
                  behalf of one of the parties.
                </li>
                <li>
                  <b>Personal Data:</b> any information that directly,
                  indirectly, or in connection with other information
                  identification number — allows for the identification or
                  identifiability of a natural person.
                </li>
                <li>
                  <b>Service:</b> refers to the service provided by Shemeanswork
                  as described in the relative terms (if available) and on this
                  platform.
                </li>
                <li>
                  <b>Third-party service:</b> refers to advertisers, contest
                  sponsors, promotional and marketing partners, and others who
                  provide our content or whose products or services we think may
                  interest you.
                </li>
                <li>
                  <b>Website:</b> Shemeanswork's site, which can be accessed via
                  this URL:
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="https://www.shemeanswork.com"
                    target="_blank"
                  >
                    <!--              <span>Phone: </span> -->
                    <span class="custom-user-contacts"
                      >https://www.shemeanswork.com</span
                    > </a
                  >.
                </li>
                <li>
                  <b>You:</b> a person or entity that is registered with
                  Shemeanswork to use the Services including a personal.
                </li>
              </ul>
              <h5 class="font-weight-bold">What Information Do we Collect.?</h5>

              We collect information from you when you visit our services,
              register, place an order, subscribe to our newsletter, respond to
              a survey or fill out a form.
              <ul>
                <li>Name / Username</li>
                <li>Phone Numbers</li>
                <li>Email Addresses</li>
                <li>Mailing Addresses</li>
                <li>Job Titles</li>
                <li>Age</li>
                <li>Password</li>
              </ul>
              We also collect information from mobile devices for a better user
              experience, although these features are completely optional:
              <h5 class="font-weight-bold">
                How Do We Use The Information We Collect?
              </h5>
              Any of the information we collect from you may be used in one of
              the followings ways: To personalize your experience (your
              information helps us to better respond to your individual’s needs)
              <ul>
                <li>
                  To improve our service (we continually strive to impose our
                  service offerings based on the information and feedback we
                  receive from you)
                </li>
                <li>
                  To improve customer service (your information helps us to more
                  effectively respond to your customers service requests and
                  support needs)
                </li>
                <li>To process transactions</li>
                <li>
                  To administers a contest, promotion, survey or other site
                  features
                </li>
                <li>To send periodic emails</li>
              </ul>
              <h5 class="font-weight-bold">
                When do we use customer’s information from third parties?
              </h5>

              We receive some information from the third parities when you
              contact us. For example, when you submit your email address to us
              to show interest in becoming our customer, we receive information
              from third party that provides automated fraud detection services
              to us. We also occasionally collect information that is made
              publicly available on social media websites. You can control how
              much your information social media website make public by visiting
              these websites and changing your privacy settings.
              <h5 class="font-weight-bold">
                Do we share the information we collect with third parties?
              </h5>
              <p>
                We may share the information that we collect, both personal and
                Non-Personal, with third parties such as advertisers, contest
                sponsors, promotional and marketing partners, and others who
                provide our content or whose products or services we think may
                interest you. We may also share it with our current and future
                affiliated companies and business partners, and if we are
                involved in a merger, asset sale or other business
                reorganization, we may also share or transfer your personal and
                non-personal information to our successors-in-interest.
              </p>
              <p>
                We may engage trusted third party service providers to perform
                functions and provide services to us, such as hosting and
                maintaining our services and our service, database storage and
                management, e-mail management, storage marketing, credit card
                processing, customer service and fulfilling orders for products
                and services you may purchase through our platform. We will
                likely share your personal information, and possibly some
                non-personal information, with these third parties to enable
                them to perform these services for us and for you.
              </p>
              <p>
                We may share portions of our log file data, including IP
                addresses, for analytics purposes with third parties such as web
                analytics partners, application developers and ad networks. If
                your IP address is shared, it may be used to estimate general
                location and other technographic such as connection speed,
                whether you have visited the service in a shared location, and
                type of device used to visit the service. They may aggregate
                information about our advertising and what you see on the
                service and then provide auditing, research and reporting for us
                and our advertisers.
              </p>
              <p>
                We may also disclose personal and non-personal information about
                you to government or law enforcement official or private parties
                as we, in our sole discretion, believe necessary or appropriate
                in order to respond to claims, legal process (including
                subpoenas) to protect our rights and interests or those of a
                third party, the safety of the public or any person, to prevent
                or stop ant illegal, unethical, or legally actionable activity,
                or to otherwise with applicable courts orders, laws, rules and
                regulations.
              </p>
              <h5 class="font-weight-bold">
                Where and when information is collected customers and end users?
              </h5>
              We will collect personal information that you submit to us. We may
              also receive personal information about you from third parties as
              described above.
              <h5 class="font-weight-bold">
                How do we use your Email Address?
              </h5>
              By submitting your email address on this website, you agrees to
              receive emails from us. You can cancel your participation in any
              of these email lists at any time by clicking on the opt-out link
              or other unsubscribe option that is included in the respective
              email. We only send emails to people who have authorized us to
              contact them, either directly, or through a third part, we do not
              send unsolicited commercial emails, because we hate spam as much
              as you do. By submitting your email address, you also agree to
              allow us to use your email address for customer audience targeting
              on sites like Facebook, where we display custom advertising to
              specific people who have opted-in to receive communications from
              us. Email addresses submitted only through the order processing
              page will be used for the sole purpose of sending you information
              and updates pertaining to your order. If, however, you have
              provided the same email to us through another method , we may use
              it for any of the purpose stated in this Policy. Note: If at any
              time you would like to unsubscribe from receiving future emails,
              we include detailed unsubscribe instructions at the bottom of each
              email.
              <h5 class="font-weight-bold">
                Could my information be transferred to other Countries?
              </h5>
              We are incorporated in Pakistan. Information collected via our
              website, through direct interactions with you, or from use of our
              help services may be transferred from time to time to our offices
              or personnel, o to third parties, located throughout the world,
              and may be viewed and hosted anywhere in the world, including
              countries that may not have laws of general applicability
              regulating the use and transfer of such data. To the fullest
              extent allowed by applicable law, by using any of the above, you
              voluntarily consent to the trans- border transfer and hosting of
              such information.

              <h5 class="font-weight-bold">
                Is the information collected through our service secure?
              </h5>
              ‘We take precautions to protect the security of your information.
              We have physical, electronic, and managerial procedures to help
              safeguard, prevent unauthorized access, maintain data security,
              and correctly use your information. However, neither people nor
              security systems are foolproof, including encryption systems. In
              addition, people can commit intentional crimes, make mistakes or
              fail to follow policies. Therefore, while we use reasonable
              efforts to protect your personal information, we cannot guarantee
              its absolute security. If applicable law imposes any non-disclaim
              able duty to protect your personal information, you agree that
              intentional misconduct will be the standards used to measure our
              compliance with that duty.

              <h5 class="font-weight-bold">
                Can I update or correct my information?
              </h5>
              ‘The rights you have to request updates or corrections to the
              information we collect depend on your relationship with us.
              Personnel may update or correct their information as detailed in
              our internal company employment policies. Customers have the right
              to request the restriction of certain uses and disclosures of
              personally identifiable information as follows. You can contact us
              in order to (1) update or correct your personally identifiable
              information, (2) change your preferences with respect to
              communications and other information you receive from us, or (3)
              delete the personally identifiable information maintained about
              you on our systems (Subject to the following paragraph), by
              cancelling your account. Such updates, corrections, changes and
              deletions will have no effect on other information that we
              maintain, or information that we have provided to third parties in
              accordance with this Privacy Policy prior to such update,
              correction, change or deletion. To protect your privacy and
              security, we may take reasonable steps (such as requesting a
              unique password) to verify your identity before granting you
              profile access or making corrections. You are responsible for
              maintaining the secrecy of your unique password and account
              information at all times. You should be aware that it is not
              technologically possible to remove each and every record of the
              information you have provided to us from our system. The need to
              back up our systems to protect information from inadvertent loss
              means that a copy of your information may exist in a non-erasable
              form that will be difficult ‘or impossible for us to locate.
              Promptly after receiving your request. all personal information
              stored in databases we actively use, and other readily searchable
              media will be updated, corrected, changed or deleted, as
              appropriate, as soon as and to the extent, reasonably and
              technically practicable. If you are an end user and wish to
              update, delete, or receive any information we have about you, you
              may do so by contacting the organization of which you are a
              customer.
              <h5 class="font-weight-bold">Personnel</h5>
              If you are one of our workers or applicants, we collect
              information you voluntarily provide to us. We use the information
              collected for Human Resources purposes in order to administer
              benefits to workers and screen applicants. You may contact us in
              order to (1) update or correct your information, (2) change your
              preferences with respect to communications and other information
              you receive from us, or (3) receive a record of the information we
              have relating to you. Such updates, corrections, changes and
              deletions which have no effect on other information that we
              maintain, or information that we have provided to third parties in
              accordance with this Privacy Policy prior to such update,
              correction, change or deletion

              <h5 class="font-weight-bold">Sale of Business</h5>
              We reserve the right to transfer information to a third party in
              the event of a sale, merger or other transfer of all or
              substantially all of the assets of us or any of its Corporate
              Affiliates (as defined herein), or that portion of us or any of
              its Corporate Affiliates to which the Service relates, or in the
              event that we discontinue our business or file a petition or have
              filed against us a petition in bankruptcy, reorganization or
              similar proceeding, provided that the third party agrees to adhere
              to the terms of this Privacy Policy
              <h5 class="font-weight-bold">Affiliates</h5>
              We may disclose information (including personal information) about
              you to our Corporate Affiliates. For purposes of this Privacy
              Policy, "Corporate Affiliate” means any person or entity which
              directly or indirectly controls, is controlled by or is under
              common control with us, whether by ownership or otherwise. Any
              information relating to you that we provide to our Corporate
              Affiliates will be treated by those Corporate Affiliates in
              accordance with the terms of this Privacy Policy.

              <h5 class="font-weight-bold">
                How Long Do We Keep Your Information?
              </h5>
              We keep your information only so long as we need it to provide
              service to you and fulfil the purposes described in this policy.
              This is also the case for anyone that we share your information
              with and who carries out services on our behalf. When we no longer
              need to use your information and there is no need for us to keep
              it to comply with our legal or regulatory obligations, we'll
              either remove it from our systems or depersonalize it so that we
              can't identify you.
              <h5 class="font-weight-bold">
                How Do We Protect Your Information?
              </h5>
              We implement a variety of security measures to maintain the safety
              of your personal information when you place an order or enter,
              ‘submit, or access your personal information. We offer the use of
              a secure server. All supplied sensitive/credit information is
              transmitted via Secure Socket Layer (SSL) technology and then
              encrypted into our Payment gateway provider’s database only to be
              ‘accessible by those authorized with special access rights to such
              systems, and are required to keep the information confidential.
              After a transaction, your private information (credit cards,
              social security numbers, financials, etc.) is never kept on fle.
              We cannot, however, ensure or warrant the absolute security of any
              information you transmit to us or guarantee that your information
              on the ‘Service may not be accessed, disclosed, altered, or
              destroyed by a breach of any of our physical, technical, or
              managerial safeguards.
              <h5 class="font-weight-bold">Governing Law</h5>
              ‘The laws of Pakistan, excluding its conflicts of law rules, shall
              govern this Agreement and your use of our service. Your use of our
              service may also be subject to other local, stale, national, or
              international laws.
              <h5 class="font-weight-bold">Your Consent</h5>
              ‘By using our service, registering an account, or making a
              purchase, you consent to this Privacy Policy.
              <h5 class="font-weight-bold">Links to Other Websites</h5>
              ‘This Privacy Policy applies only to the Services. The Services
              may contain links to other websites not operated or controlled by
              We are not responsible for the content, accuracy of opinions
              expressed in such websites, and such websites are not
              investigated, ‘monitored or checked for accuracy of completeness
              by us. Please remember that when you use a link to go from the
              Services to ‘another website, our Privacy Policy is no longer in
              effect. Your browsing and interaction on any other website,
              including those that have a link on our platform, is subject to
              that website's own rules and policies. Such third parties may use
              their own cookies or other ‘methods to collect information about
              you.
              <h5 class="font-weight-bold">Cookies</h5>
              We use "Cookies" to identify the areas of our website that you
              have visited. A Cookie is a small piece of data stored on your
              computer ‘or mobile device by your web browser. We use Cookies to
              personalize the Content that you see on our website. Most web
              browsers, ‘can be set to disable the use of Cookies. However, if
              you disable Cookies, you may not be able to access functionality
              on our website correctly or at all. We never place Personally
              Identifiable Information in Cookies.
              <h5 class="font-weight-bold">Advertising</h5>
              ‘Advertising keeps us and many of the websites and services you
              use free of charge. We work hard to make sure that ads are safe,
              ‘unobtrusive, and as relevant as possible.
              <h5 class="font-weight-bold">Cookies for Advertising</h5>
              ‘Cookies help to make advertising more effective. Without cookies,
              it’s really hard for an advertiser to reach its audience, or to
              know how many ads were shown and how many clicks they received.
              <h5 class="font-weight-bold">Remarketing Services</h5>
              We use remarketing services. What Is Remarketing? In digital
              marketing, remarketing (or retargeting) is the practice of serving
              ads ‘across the internet to people who have already visited your
              website It allows your company to seem like they're following’
              people ‘around the internet by serving ads on the websites and
              platforms they use most.

              <h5 class="font-weight-bold">Payment Details</h5>
              In respect to any credit card or other payment processing details
              you have provided us, we commit that this confidential information
              will be stored in the mast secure manner possible.

              <h5 class="font-weight-bold">Kids' Privacy</h5>
              We collect information from kids under the age of 13 just to
              better our services. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data without
              your permission, please contact Us. If we become aware that We
              have collected Personal Data from anyone under the age of 13
              without verification of parental consent, We take steps to remove
              that information from our servers.

              <h5 class="font-weight-bold">Changes To Our Privacy Policy</h5>
              If we decide to change our privacy policy, we will post those
              changes on this page, and/or update the Privacy Policy
              modification date below.
              <h5 class="font-weight-bold">Third-Party Services</h5>
              <p>
                We may display, include or make available third-party content
                (including data, information, applications and other products
                services) ‘or provide links to third-party websites or services
                ("Third- Party Services’) You acknowledge and agree that we
                shall not be responsible for any Third-Party Services, including
                their accuracy, completeness, timeliness, validity, copyright
                compliance, legality, decency, quality or any other aspect
                thereof. We do not assume and shall not have any liability or
                responsibility to you or any other person or entity for any
                Third-Party Services.
              </p>
              <p>
                Third party Services and links thereto are provided solely as a
                convenience to you and you access and use them entirely at your
                own risk and subject to such third parties’ terms and
                conditions,
              </p>
              <h5 class="font-weight-bold">Facebook Pixel</h5>
              Facebook pixel is an analytics tool that allows you to measure the
              effectiveness of your advertising by understanding the actions
              people take on your website. You can use the pixel to: Make sure
              your ads are shown to the right people. Facebook pixel may
              collect, information from your device when you use the service.
              Facebook pixel collects information that is held in accordance
              with its Privacy Policy.

              <h5 class="font-weight-bold">Tracking Technologies</h5>
              <b>-- Google Maps APIs</b>
              <p>
                Google Maps API is a robust tool that can be used to create a
                custom map, a searchable map, check-in functions, display live
                data ‘synching with location, plan routes, or create a mashup
                just to name a few:
              </p>
              <p>
                Google Maps API may collect information from You and from Your
                Device for security purposes.
              </p>
              <p>
                Google Maps API collects information that is held in accordance
                with its Privacy Policy
              </p>

              <b>-- Cookies</b>
              <p>
                We use Cookies to enhance the performance and functionality of
                our service but are non-essential to their use. However, without
                these cookies, certain functionality like videos may become
                unavailable or you would be required to enter your login details
                every time you visit our service as we would not be able to
                remember that you had logged in previously.
              </p>
              <b>-- Local storage</b>
              <p>
                Local storage sometimes know as DOM storage, provides web pages
                with methods and protocols for storing client-side data. Web
                storage supports persistent data storage, similar to cookies but
                with a greatly enhanced capacity and no information stored in
                the HTTO request header.
              </p>
              <b>-- Sessions</b>
              <p>
                We use “sessions” to identify the areas of our website that you
                have visited. A Session is a small piece of data stored on our
                computer or mobile device by your web browser.
              </p>

              <h5 class="font-weight-bold">
                Information about General Data Protection Regulation (GDPR)
              </h5>
              We may be collecting and using information from you if you are
              from the European Economic Area (EEA), and in this section of our
              Privacy Policy we are going to explain exactly how and why is this
              data collected, and how we maintain this data under protection
              from being replicated or used in the wrong way.
              <h5 class="font-weight-bold">What is GDPR?</h5>
              GDPR is an EU-wide privacy and data protection law that regulates
              how EU residents’ data is protected by companies and enhances the
              control the EU residents have, over their personal data.
              <h5 class="font-weight-bold">What is personal data?</h5>
              Any data that relates to an identifiable or identified individual.
              GDPR covers a broad spectrum of information that could be used on
              its ‘own, or in combination with other pieces of information, to
              identify a person. Personal data extends beyond a person’s name or
              email address. Some examples include financial information,
              political opinions, genetic data, biometric data, IP addresses,
              physical address, sexual orientation, and ethnicity. The Data
              Protection Principles include requirements such as:
              <ul>
                <li>
                  Personal data collected must be processed in a fair, legal,
                  and transparent way and should only be used in a way that a
                  person would reasonably expect.
                </li>
                <li>
                  Personal data should only be collected to fulfil a specific
                  purpose and it should only be used for that purpose.
                  Organizations ‘must specify why they need the personal data
                  when they collect it
                </li>
                <li>
                  Personal data should be held no longer than necessary to
                  fulfil its purpose
                </li>
                <li>
                  People covered by the GDPR have the right to access their own
                  personal data. They can also request a copy of their data, and
                  that their data be updated, deleted, restricted, or moved to
                  another organization.
                </li>
              </ul>

              <h5 class="font-weight-bold">Why is GDPR important?</h5>
              GDPR adds some new requirements regarding how companies should
              protect individuals’ personal data that they collect and process.
              It also raises the stakes for compliance by increasing enforcement
              and imposing greater fines for breach. Beyond these facts i's
              simply the right thing to do. At Help Scout we strongly believe
              that your data privacy is very important and we already have solid
              security and privacy practices in place that go beyond the
              requirements of this new regulation.
              <h5 class="font-weight-bold">
                Individual Data Subject's Rights - Data Access, Portability and
                Deletion
              </h5>
              <p>
                We are committed to helping our customers meet the data subject
                rights requirements of GDPR. We process or store all personal
                data in fully vetted, DPA compliant vendors. We do store all
                conversation and personal data for up to 6 years unless your
                account is deleted. In which case, we dispose of all data in
                accordance with our Terms of Service and Privacy Policy, but we
                will not hold it longer than 60 days.
              </p>
              <p>
                We are aware that if you are working with EU customers, you need
                to be able to provide them with the ability to access, update,
                retrieve and remove personal data. We got you! We've been set up
                as self-service from the start and have always given you access
                to your data and your customers data. Our customer support team
                is here for you to answer any questions you might have about
                working with the API
              </p>

              <h5 class="font-weight-bold">Contact Us</h5>
              Don't hesitate to contact us if you have any questions.
              <ul>
                <li>
                  Via
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="mailto:info@shemeanswork.com"
                  >
                    <span
                      ><i class="fas fa-envelope fa-md fa-fw mr-2"></i
                    ></span>
                    <!--            <span>Email:</span>-->

                    <span class="custom-user-contacts"
                      >info@shemeanswork.com</span
                    >
                  </a>
                </li>
                <li>
                  Via
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="tel:0092 423 7243810"
                  >
                    <span
                      ><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i
                    ></span>
                    <!--              <span>Phone: </span> -->
                    <span class="custom-user-contacts">0092 423 7243810</span>
                  </a>
                </li>
                <li>
                  Via
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="https://www.shemeanswork.com"
                    target="_blank"
                  >
                    <span><i class="fas fa-globe fa-md fa-fw mr-2"></i></span>
                    <!--              <span>Phone: </span> -->
                    <span class="custom-user-contacts"
                      >https://www.shemeanswork.com</span
                    >
                  </a>
                </li>
                <li>
                  Via
                  <span
                    ><i class="fas fa-map-marker fa-md fa-fw mr-2"></i
                  ></span>
                  23 The Mall Road Lahore
                </li>
              </ul>
            </div>
            <div v-if="cookieSection">
              Updated at 2021-01-04

              <h5 class="font-weight-bold">Definitions and key terms</h5>

              To help explain things as clearly as possible as is Cookie Policy,
              every time any of these terms are referenced, are strictly defined

              <ul>
                <li>
                  <b>Cookie:</b> small amount of data generated by a website and
                  saved by your web browser. It is used to identify your
                  browser, provide analytics, remember information about you
                  such as your language preference or login information
                </li>
                <li>
                  <b>Company:</b> when this policy mentions “Company,” “we,”
                  “us,” or “our,” it refers to LBS Pvt LTD, 23 The Mall Road
                  Lahore that is responsible for your information under this
                  Privacy Policy.
                </li>

                <li>
                  <b>Device:</b> any internet connected device such as a phone,
                  tablet, computer or any other device that can be used to visit
                  ‘Shemeanswork and use the services.
                </li>

                <li>
                  <b>Personal Data:</b> any information that directly,
                  indirectly, or in connection with other information
                  identification number — allows for the identification or
                  identifiability of a natural person.
                </li>
                <li>
                  <b>Service:</b> refers to the service provided by Shemeanswork
                  as described in the relative terms (if available) and on this
                  platform.
                </li>
                <li>
                  <b>Third-party service:</b> refers to advertisers, contest
                  sponsors, promotional and marketing partners, and others who
                  provide our content or whose products or services we think may
                  interest you.
                </li>
                <li>
                  <b>Website:</b> Shemeanswork's site, which can be accessed via
                  this URL:
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="https://www.shemeanswork.com"
                    target="_blank"
                  >
                    <!--              <span>Phone: </span> -->
                    <span class="custom-user-contacts"
                      >https://www.shemeanswork.com</span
                    > </a
                  >.
                </li>
                <li>
                  <b>You:</b> a person or entity that is registered with
                  Shemeanswork to use the Services including a personal.
                </li>
              </ul>

              <h5 class="font-weight-bold">Introduction</h5>
              This Cookie Policy explains how Shemeanswork and its affiliates
              (collectively "Shemeanswork’, "we", "us", and “ours"), use cookies
              and similar technologies to recognize you when you visit our
              platform, including without limitation http://www.shemeanswork.com
              and any related URLs, mobile or localized versions and related
              domains / sub-domains (‘Websites’). It explains what these
              technologies, are and why we use them, as well as the choices for
              how to control them.
              <h5 class="font-weight-bold">What is a cookie?</h5>
              A cookie is a small text file that is stored on your computer or
              other internet connected device in order to identify your browser,
              provide analytics, remember information about you such as your
              language preference or login information. They're completely safe
              and can't be used to run programs or deliver viruses to your
              device.
              <h5 class="font-weight-bold">Why do we use cookies?</h5>
              We use first party and/or third party cookies on our platform for
              various purposes such as:
              <ul>
                <li>
                  To facilitate the operation and functionality of our platform;
                </li>
                <li>
                  To improve your experience of our platform and make navigating
                  around them quicker and easier,
                </li>
                <li>
                  To allow us to make a bespoke user experience for you and for
                  us to understand what is useful or of interest to you:
                </li>
                <li>
                  To analyze how our platform is used and how best we can
                  customize it;
                </li>
                <li>
                  To identify future prospects and personalize marketing and
                  sales interactions with it;
                </li>
                <li>
                  To facilitate the tailoring of online advertising to your
                  interests.
                </li>
              </ul>

              <h5 class="font-weight-bold">
                What type of cookies does Shemeanswork use?
              </h5>
              <p>
                Cookies can either be session cookies or persistent cookies. A
                session cookie expires automatically when you close your
                browser. A persistent cookie will remain until it expires or you
                delete your cookies. Expiration dates are set in the cookies
                themselves; some may expire after a few minutes while others may
                expire after multiple years. Cookies placed by the website
                you're visiting are called “first party cookies”
              </p>
              <p>
                Strictly Necessary cookies are necessary for our platform to
                function and cannot be switched off in our systems. They are
                essential in order to enable you to navigate around the platform
                and use its features. If you remove or disable these cookies, we
                cannot ‘guarantee that you will be able to use our platform.
              </p>
              We use the following types of cookies in our platform
              <h5 class="font-weight-bold">Essential Cookies</h5>
              <p>
                We use essential cookies to make our platform work. These
                cookies are strictly necessary to enable core functionality such
                as security, network management, your cookie preferences and
                accessibility. Without them you wouldn't be able to use basic
                services.
              </p>
              <p>
                You may disable these by changing your browser settings, but
                this may affect how the Websites function
              </p>
              <h5 class="font-weight-bold">
                Performance and Functionality Cookies
              </h5>
              ‘These cookies are used to enhance the performance and
              functionality of our platform but are non-essential to their use.
              However, without these cookies, certain functionality like videos
              may become unavailable or you would be required to enter your
              login details every time you visit the platform as we would not be
              able to remember that you had logged in previously
              <h5 class="font-weight-bold">Marketing Cookies</h5>
              ‘These account-based marketing cookies enable us to identify
              future prospects and personalize sales and marketing interactions
              with them.
              <h5 class="font-weight-bold">
                Analytics and Customization Cookies
              </h5>
              <p>
                ‘These cookies collect information that is used to help us
                understand how our platform is being used or how effective our
                marketing campaigns are, or to help us customize our platform
                for you.
              </p>
              <p>
                We use cookies served by Google Analytics to collect limited
                data directly from end-user browsers to enable us to better
                understand your use of our platform. Further information on how
                Google collects and uses this data can be foundation:
                https://www.google.com/policies/privacy/partners/You can opt-out
                of all Google supported analytics on our Websites by visiting:
                https:/tools.google.com/id/page/gaoptout.
              </p>
              <h5 class="font-weight-bold">Advertising Cookies</h5>
              These cookies collect information over time about your online
              activity on the platform and other online services to make online
              advertisements more relevant and effective to you. This is known
              as interest-based advertising. They also perform functions like
              preventing the same ad from continuously reappearing and ensuring
              that ads are properly displayed for advertisers. Without cookies,
              its really hard for an advertiser to reach its audience, or to
              know how many ads were shown and how many clicks they received.
              <h5 class="font-weight-bold">Social Media Cookies</h5>
              ‘These cookies are used when you share information using a social
              media sharing button or “like” button on our Site or you link your
              ‘account or engage with our content on or through a social
              networking website such as Facebook, Twitter or Google+. The
              social network will record that you have done this. These cookies
              may also include certain code that has been placed on the platform
              to help track conversions from ads, optimize ads based on
              collected data, build targeted audiences for future ads, and
              remarket to ‘qualified users who have already taken certain action
              on the platform.
              <h5 class="font-weight-bold">Third Party Cookies</h5>
              ‘Some cookies that have been set on our platform are not set on a
              first party basis by Shemeanswork. The Websites can be ‘embedded
              with content from third parties to serve advertising. These third
              party service providers may set their own cookies on your web
              browser. Third party service providers control many of the
              performance and functionality, advertising, marketing and
              analytics cookies described above. We do not control the use of
              these third party cookies as cookies can only be accessed by the
              third party that originally set them.
              <h5 class="font-weight-bold">How you can manage cookies?</h5>
              <p>
                ‘Most browsers allow you to control cookies through their
                settings’ preferences. However, if you limit the ability of
                websites to set, ‘cookies, you may worsen your overall user
                experience, since it will no longer be personalized to you. It
                may also stop you from saving customized settings like login
                information. Browser manufacturers provide help pages relating
                to cookie management in their products.
              </p>
              <p>
                Browser manufacturers provide help pages relating to cookie
                management in their products. Please see below for more
                information.
              </p>
              <ul>
                <li>Google Chrome</li>
                <li>Internet Explorer</li>
                <li>Mozilla Firefox</li>
                <li>Safari (Desktop)</li>
                <li>Safari (Mobile)</li>
                <li>Android Browser</li>
                <li>Opera</li>
                <li>Opera Mobile</li>
              </ul>

              <h5 class="font-weight-bold">
                Blocking and disabling cookies and similar technologies
              </h5>
              Wherever you're located you may also set your browser to block
              cookies and similar technologies, but this action may block our
              essential cookies and prevent our website from functioning
              properly, and you may not be able to fully utilize all of its
              features and services. You should also be aware that you may also
              lose some saved information (e.g. saved login details, site
              preferences) if you block cookies on your browser. Different
              browsers make different controls available to you. Disabling a
              cookie or category of cookie does not delete the cookie from your
              browser, you will need to do this yourself from within your
              browser, you should visit your browser's help menu for more
              information
              <h5 class="font-weight-bold">Changes to Our Cookie Policy</h5>
              We may change our Service and policies, and we may need to make
              changes to this Cookie Policy so that they accurately reflect our
              Service and policies. Unless otherwise required by law, we will
              notify you (for example, through our Service) before we make
              changes to this Cookie Policy and give you an opportunity to
              review them before they go into effect. Then, if you continue to
              use the Service, you will be bound by the updated Cookie Policy.
              If you do not want to agree to this or any updated Cookie Policy,
              you can delete your account.
              <h5 class="font-weight-bold">Your Consent</h5>
              By using our platform, registering an account, or making a
              purchase, you hereby consent to our Cookie Policy and agree to its
              terms.

              <h5 class="font-weight-bold">Contact Us</h5>
              Don't hesitate to contact us if you have any questions regarding
              our Cookie Policy.
              <ul>
                <li>
                  Via
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="mailto:info@shemeanswork.com"
                  >
                    <span
                      ><i class="fas fa-envelope fa-md fa-fw mr-2"></i
                    ></span>
                    <!--            <span>Email:</span>-->

                    <span class="custom-user-contacts"
                      >info@shemeanswork.com</span
                    >
                  </a>
                </li>
                <li>
                  Via
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="tel:0092 423 7243810"
                  >
                    <span
                      ><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i
                    ></span>
                    <!--              <span>Phone: </span> -->
                    <span class="custom-user-contacts">0092 423 7243810</span>
                  </a>
                </li>
                <li>
                  Via
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="https://www.shemeanswork.com"
                    target="_blank"
                  >
                    <span><i class="fas fa-globe fa-md fa-fw mr-2"></i></span>
                    <!--              <span>Phone: </span> -->
                    <span class="custom-user-contacts"
                      >https://www.shemeanswork.com</span
                    >
                  </a>
                </li>
                <li>
                  Via
                  <span
                    ><i class="fas fa-map-marker fa-md fa-fw mr-2"></i
                  ></span>
                  23 The Mall Road Lahore
                </li>
              </ul>
            </div>
            <div v-if="disclaimerSection">
              Updated at 2021-01-04
              <h5 class="font-weight-bold">Definitions and key terms</h5>
              To help explain things as clearly as possible in this Return &
              Refund Policy, every time any of these terms are referenced, are
              strictly defined as:
              <ul>
                <li>
                  <b>Company:</b> when this policy mentions “Company,” “we,”
                  “us,” or “our,” it refers to LBS Pvt LTD, 23 The Mall Road
                  Lahore that is responsible for your information under this
                  Privacy Policy.
                </li>

                <li>
                  <b>Customer:</b> refers to the company, organization or person
                  that signs up to use the Shemeanswork Service to manage the
                  relationships with your consumers or service users.
                </li>
                <li>
                  <b>Device:</b> any internet connected device such as a phone,
                  tablet, computer or any other device that can be used to visit
                  ‘Shemeanswork and use the services.
                </li>

                <li>
                  <b>Service:</b> refers to the service provided by Shemeanswork
                  as described in the relative terms (if available) and on this
                  platform.
                </li>

                <li>
                  <b>Website:</b> Shemeanswork's site, which can be accessed via
                  this URL:
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="https://www.shemeanswork.com"
                    target="_blank"
                  >
                    <!--              <span>Phone: </span> -->
                    <span class="custom-user-contacts"
                      >https://www.shemeanswork.com</span
                    > </a
                  >.
                </li>
                <li>
                  <b>You:</b> a person or entity that is registered with
                  Shemeanswork to use the Services including a personal.
                </li>
              </ul>

              <h5 class="font-weight-bold">Return & Refund Policy</h5>
              <p>
                ‘Thanks for shopping at Shemeanswork. We appreciate the fact
                that you like to buy the stuff we build, we also want to make
                sure you have a rewarding experience while you're exploring,
                evaluating, and purchasing our products.
              </p>
              <p>
                ‘As with any shopping experience, there are terms and conditions
                that apply to transactions at Shemeanswork. We'll be as brief as
              </p>
              <p>
                ‘our attorneys will allow. The main thing to remember is that by
                placing an order or making a purchase at Shemeanswork, you agree
                to the terms set forth below along with Shemeanswork’s Privacy
                Policy.
              </p>
              <p>
                If there's something wrong with the product you bought, or if
                you are not happy with it, you have () to issue a refund and
                return your item.
              </p>
              If you would like to return a product, the only way would be if
              you follow the next guidelines:
              <ul>
                <li>
                  The package needs to come back with the original receipt of
                  the purchase.
                </li>
                <li>
                  The product has to be in the packaging we sent in the first
                  place.
                </li>
                <li>
                  The product has to be damage free, if we find any damage on
                  the product we will cancel your refund immediately.
                </li>
              </ul>

              <h5 class="font-weight-bold">Refunds</h5>
              <p>
                We at Shemeanswork commit ourselves to serving our customers
                with the best products. Every single product that you choose is
                thoroughly inspected, checked for defects and packaged with
                utmost care. We do this to ensure that you fall in love with our
                products.
              </p>
              <p>
                Sadly, there are times when we may not have the product(s) that
                you choose in stock, or may face some issues with our inventory
                ‘and quality check. In such cases, we may have to cancel your
                order. You will be intimated about it in advance so that you
                don't have to worry unnecessarily about your order. If you have
                purchased via Online payment (not Cash on Delivery), then you
                will be refunded ‘once our team confirms your request.
              </p>
              <p>
                We carry out thorough quality check before processing the
                ordered item. We take utmost care while packing the product. At
                the same time we ensure that the packing is good such that the
                items won't get damaged during transit. Please note that
                Shemeanswork is not liable for damages that are caused to the
                items during transit or transportation.
              </p>
              <p>
                We will revise your returned product as soon as we receive it
                and if it follows the guidelines addressed above, we will
                proceed to issue a refund of your purchase. Your refund may take
                a couple of days to process but you will be notified when you
                receive your money.
              </p>
              <h5 class="font-weight-bold">Shipping</h5>
              ‘Shemeanswork is not responsible for return shipping costs. Every
              shipping has to be paid by the customer, even if the item had free
              shipping in the first place, the customer has to pay for the
              shipping in return.!
              <h5 class="font-weight-bold">Your Consent</h5>
              By using our platform, registering an account, or making a
              purchase, you hereby consent to our Return & Refund Policy and
              agree to its terms.

              <h5 class="font-weight-bold">
                Changes To Our Return & Refund Policy
              </h5>
              ‘Should we update, amend or make any changes to this document so
              that they accurately reflect our Service and policies. Unless
              otherwise required by law, those changes will be prominently
              posted here. Then, if you continue to use the Service, you will be
              bound by the updated Return & Refund Policy. If you do not want to
              agree to this or any updated Return & Refund Policy, you can
              delete your account.

              <h5 class="font-weight-bold">Contact Us</h5>
              If, for any reason, You are not completely satisfied with any good
              or service that we provide, don't hesitate to contact us and we
              will discuss any of the issues you are going through with our
              product.
              <ul>
                <li>
                  Via
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="mailto:info@shemeanswork.com"
                  >
                    <span
                      ><i class="fas fa-envelope fa-md fa-fw mr-2"></i
                    ></span>
                    <!--            <span>Email:</span>-->

                    <span class="custom-user-contacts"
                      >info@shemeanswork.com</span
                    >
                  </a>
                </li>
                <li>
                  Via
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="tel:0092 423 7243810"
                  >
                    <span
                      ><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i
                    ></span>
                    <!--              <span>Phone: </span> -->
                    <span class="custom-user-contacts">0092 423 7243810</span>
                  </a>
                </li>
                <li>
                  Via
                  <a
                    class="text-decoration-none w3-hover-text-gray"
                    href="https://www.shemeanswork.com"
                    target="_blank"
                  >
                    <span><i class="fas fa-globe fa-md fa-fw mr-2"></i></span>
                    <!--              <span>Phone: </span> -->
                    <span class="custom-user-contacts"
                      >https://www.shemeanswork.com</span
                    >
                  </a>
                </li>
                <li>
                  Via
                  <span
                    ><i class="fas fa-map-marker fa-md fa-fw mr-2"></i
                  ></span>
                  23 The Mall Road Lahore
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*global $*/
/*global google*/
import axios from "axios";
import _ from "underscore";

export default {
  name:"SignIn",
  components: {
  
  },
  data() {
    return {

      emailAddress: "",
      password: "",
      showPassword:false,
      userRole:null,
      state: false,
      responseMsg:"",
      has_error: false,
      isLoading: false,

      //Forget password Bool
      forgetPassword:true,
      rememberItPassword:false,
      //Form Validation Bool
      isValidated:false,
      socialMediaData:null,

      selectRoleSection:false,
      selectedRoleForSocialMedia:'',
      roles:[

        {value:2, text:"Employee"},
        {value:3, text:"Employer"}
      ],
      //Modal
      modalTitle:"",
      termsSection:false,
      privacySection:false,
      cookieSection:false,
      disclaimerSection:false,
    };
  },
  computed: {

    disabled() {
      return this.state === false;
    },

  },
  mounted() {
    //

    /*this.$nextTick(() =>{
      var showPass = 0;
      $('.btn-show-pass').on('click', function(){
        if(showPass == 0) {
          $(this).next('input').attr('type','text');
          $(this).find('i').removeClass('fa-eye');
          $(this).find('i').addClass('fa-eye-slash');
          showPass = 1;
        }
        else {
          $(this).next('input').attr('type','password');
          $(this).find('i').removeClass('fa-eye-slash');
          $(this).find('i').addClass('fa-eye');
          showPass = 0;
        }

      });
    });*/
  },
  methods: {
    /*redirectToSignUp: function() {
      this.$router.push({ name: "register" });
    },*/
    CloseModal(){
      debbuger
      $("#policiesModal").modal("hide");
    },
    togglePasswordShow() {
      this.showPassword = !this.showPassword;
    },
    login() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      var app = this;
      if(app.emailAddress != "" && app.password != ""){
        this.$auth
            .login({
              data: {
                FullName:"Danish",
                Email: app.emailAddress,

                PasswordHash: app.password


              },
              staySignedIn: true,
              fetchUser: true,
            })
            .then(res => {
              this.isLoading = false;
              // const redirectTo = "EmployeeDashboard";
              // const redirectTo = "EmployeeDashboard";
              console.log("response : ",res.data.data);
              this.userRole = res.data.data.user.role;
              this.responseMsg = res.data.msg;
              console.log(this.responseMsg);
              console.log("userRole : ",this.userRole);
              /*if(this.responseMsg == "successfully Retrieved"){
                this.$bvToast.toast("Login Successfully", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  solid: true,

                });*/

              if(this.userRole == "Employee"){
                this.$router.push({ name: "EmployeeDashboard" });
              }
              else if(this.userRole == "Employer"){
                this.$router.push({ name: "EmployerDashboard" });
              }

              // }

            })
            .catch(error => {
              this.isLoading = false;
              this.$bvToast.toast("Invalid Email Address or Password.", {
                toaster: "b-toaster-top-center",
                variant: "danger",

                solid: true,
              });
              console.log("error", error);
            });
      }
      else{
        this.isValidated=true;
        this.$bvToast.toast("Please Fill All the Required Fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true,
        });
        this.isLoading = false;
      }

    },
   async signInWithSocialMedia(emailAddress){
    await this.$auth
            .login({
              data: {
                FullName:"Danish",
                Email: emailAddress,

                PasswordHash: null


              },
              staySignedIn: true,
              fetchUser: true,
            })
            .then(res => {
              this.isLoading = false;
              // const redirectTo = "EmployeeDashboard";
              // const redirectTo = "EmployeeDashboard";
              console.log("response : ",res.data.data);
              this.userRole = res.data.data.user.role;
              this.responseMsg = res.data.msg;
              console.log(this.responseMsg);
              console.log("userRole : ",this.userRole);
              if(this.responseMsg != "user does not exist"){
                   this.$bvToast.toast("Login Successfully", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  solid: true,

                });

                if(this.userRole == "Employee"){
                  this.$router.push({ name: "EmployeeDashboard" });
                  $("#policiesModal").modal("hide");
                }
                else if(this.userRole == "Employer"){
                  this.$router.push({ name: "EmployerDashboard" });
                  $("#policiesModal").modal("hide");
                }

               }
               

            })
            .catch(error => {
              this.isLoading = false;
              
              this.$bvToast.toast("User does not exist, please select a Role for Sign Up", {
                toaster: "b-toaster-top-center",
                variant: "danger",

                solid: true,
              });
              console.log("error", error);

              $("#policiesModal").modal("show");
                this.modalTitle = "Select Role";
                this.selectRoleSection =  true;
                this.termsSection=false;
                this.privacySection=false;
                this.cookieSection=false;
                this.disclaimerSection=false;
            });
   },
   async signUpWithSocialMedia(username, emailAddress){
        await this.$auth
              .register({
                data: {
                  FullName:username,
                  Email:emailAddress,
                  Code:1,
                  NetworkCode:1,
                  MobileNumber:"",
                  Password:"",
                  SignUpWith:1,
                  Role:Number(this.selectedRoleForSocialMedia)

                },
                staySignedIn: true,
                fetchUser: true,
              })
              .then(res => {
                this.responseMsg = res.data.msg;
                console.log(this.responseMsg);
                if(this.responseMsg == "Inserted Successfully"){

                  this.$bvToast.toast("SignUp Complete. Verification mail sent on your email address.", {
                    toaster: "b-toaster-top-center",
                    variant: "success",
                    solid: true,
                  });
                  this.isLoading = false;
                  this.signInWithSocialMedia(emailAddress);
                }
                else if(this.responseMsg == "Email Already Registered"){
                  this.$bvToast.toast("Email already registered, Redirecting to your Dashboard", {
                    toaster: "b-toaster-top-center",
                    variant: "warning",
                    solid: true,
                  });
                
                  this.isLoading = false;
                  this.signInWithSocialMedia(emailAddress);
                }

              })
              .catch(error => {
                    this.isLoading = false;
                    console.log("errored",error);
                  }
              );
    },
    async googleLogin() {
      this.socialMediaData=null;
       try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        this.socialMediaData = googleUser;
        console.log("googleUser", this.socialMediaData);
        console.log("getId", this.socialMediaData.getId());
        console.log("getBasicProfile", this.socialMediaData.getBasicProfile());
        console.log("getAuthResponse", this.socialMediaData.getAuthResponse());
        console.log(
          "getAuthResponse",
          this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
        );
        this.isSignIn = this.$gAuth.isAuthorized;
        console.log("is Sign In : ", this.isSignIn)
        if(this.isSignIn){
           await this.signInWithSocialMedia(this.socialMediaData.getBasicProfile().gw);
           
        }
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }


    },

    async onSubmitRole(){
      console.log("socialMediaData : ",this.socialMediaData.getBasicProfile())
if(this.selectedRoleForSocialMedia != ''){
      await this.signUpWithSocialMedia(this.socialMediaData.getBasicProfile().Af, this.socialMediaData.getBasicProfile().gw)
}
      else{
        this.isValidated=true;
        this.$bvToast.toast("Please Select a Role", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true,
        });
        this.isLoading = false;
      }
    },
    async handleClickSignOut() {
      try {
        await this.$gAuth.signOut();
        this.isSignIn = this.$gAuth.isAuthorized;
        console.log("isSignIn", this.$gAuth.isAuthorized);
      } catch (error) {
        console.error(error);
      }
    },
    resetPassword(){

      let model = {
        email: this.emailAddress
      };

      axios
          .post("/api/User/ResetPassword", model)
          .then(res => {
            // code that we will 'try' to run


            console.log("Add Response portion");

            console.log("Response : ", res.data.data);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });
    },
    hideOrShow(){
      if(this.forgetPassword == true){
        this.forgetPassword=false;
        this.rememberItPassword=true;
        this.state = true;
      }
      else{
        this.forgetPassword=true;
        this.rememberItPassword=false;
        this.state = false;
      }

    },
    submitForm(){
      if(this.forgetPassword == true){
        this.login();
      }
      else {
        this.resetPassword();
      }
    },

    onSocialLogin(){
      axios.get('/api/EmployeeSocialAuthentication/GoogleLogin')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }
            console.log("resoponse", res)

          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
    termsModal(){
      this.modalTitle = "Terms & Conditions";
      this.termsSection=true;
      this.privacySection=false;
      this.cookieSection=false;
      this.disclaimerSection=false;
    },
    privacyModal(){
      this.modalTitle = "Privacy Policy";
      this.termsSection=false;
      this.privacySection=true;
      this.cookieSection=false;
      this.disclaimerSection=false;
    },
    cookieModal(){
      this.modalTitle = "Cookie Policy";
      this.termsSection=false;
      this.privacySection=false;
      this.cookieSection=true;
      this.disclaimerSection=false;
    },
    disclaimerModal(){
      this.modalTitle = "Disclaimer";
      this.termsSection=false;
      this.privacySection=false;
      this.cookieSection=false;
      this.disclaimerSection=true;
    },
  },
};
</script>

<style scoped>
.spinner-overlay {
  /*margin: -60px 0 0 -215px;*/
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 25;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
}
.custom-spinner {
  position: relative;
  top: 50%;
  left: unset;
}

@media (max-width: 991px) {
  .limiter {
    margin: 0 auto;
  }
}
a.hiddenanchor {
  display: none;
}
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: #f5f7f8;
  position: relative;
}
.wrap-login100 {
  width: 565px;
  /* height: 500px; */
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d4dbe0;
  position: relative;
  padding: 40px 48px;
}
.login100-form-title {
  font-family: gd-sage, "Times New Roman", serif !important;
  font-size: 3rem;
  color: #111;
  font-weight: 700;
  line-height: 2.75rem;
  /* text-transform: uppercase; */
  text-align: left;
  width: 100%;
  display: block;
}
.login100-form {
  width: 100%;
}
.wrap-input100 {
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}
.validate-input {
  position: relative;
}

.input100 {
  /* font-family: Raleway-Medium; */
  color: #555555;
  line-height: 1.2;
  font-size: 15px;

  display: block;
  width: 100%;
  background: transparent;
  height: 40px;
  padding: 0 25px 0 25px;
  border: none;
}

.input100:focus-visible {
  color: transparent !important;
  border: none;
  outline: none;
}
.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #21388f;
  box-shadow: inset 0 -1px 0 0 #21388f;
  border-radius: 0px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  /* -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);*/
}

.input100:focus + .focus-input100 {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
/*custom text fields css*/
.txt1 {
  /* font-family: Raleway-SemiBold; */
  display: inline-block;
  font-weight: 500;
  font-size: 1rem;
  color: #495057;
  line-height: 1.4;
  /* text-transform: uppercase; */
}
/*Custom-button-css*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  /* display: flex; */
  flex-wrap: wrap;
  /* text-align: -webkit-right; */
}
.login100-form-btn {
  /* font-family: Raleway-Bold; */
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 1.2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 100%;
  height: 45px;
  background-color: #21388f;
  border: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background-color: #21388f;
  box-shadow: 0px 3px 6px 0px #21388f;
}

/*for Disabled Button*/
.custom-login100-form-btn {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 1.2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 100%;
  height: 45px;
  background-color: #444;
  border-radius: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  cursor: not-allowed !important;
}
/*------------------------------------------------------------------
[ Alert validate ]*/

/*for Disabled Button*/
.custom-login100-form-btn {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 1.2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 100%;
  height: 45px;
  background-color: #21388f;
  border: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  cursor: not-allowed !important;
}

/*Responsive*/
@media (max-width: 576px) {
  .wrap-login100 {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 376px) {
  .wrap-login100 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 376px) {
  .login100-form-title {
    font-size: 25px;
  }
}

/*Padding Section*/
.p-b-5 {
  padding-bottom: 5px;
}
.p-b-32 {
  padding-bottom: 32px;
}
/*margin Section*/
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-30 {
  margin-bottom: 30px;
}
</style>

<template xmlns="http://www.w3.org/1999/html">
  <div class="container">
    <div class="mt-2">


      <div
          class="custom-row custom-options-menu justify-content-between align-items-center"
      >
        <div class="customHeading">
          <nav>
            <div
                class="nav nav-tabs custom-nav-tabs"
                id="nav-tab"
                role="tablist"
            >
              <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading active"
                  id="employee-detail-tab"
                  data-toggle="tab"
                  href="#employee-detail"
                  role="tab"
                  aria-controls="employee-detail"
                  aria-selected="true"
              >
                <span>CV Search </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div class="mb-5">
      <div class="tab-content cusform" id="nav-tabContent">
        <div
            class="tab-pane fade show active"
            id="employee-detail"
            role="tabpanel"
            aria-labelledby="employee-detail-tab"
        >
          <div class="  mt-30">
            <div class="w3-card">
              <div class="card-body">
                <!-- <h2>Job Details</h2> -->
                <form class="custom-validation"

                      @submit.prevent="filterCandidates"
                      @reset="resetForm"
                      method="post" novalidate="">
                  <div class="row mt-3">

                    <!--              <div class="col-md-2">
                                    <div class="mb-3 form-group row no-gutters">
                                      <label class=" ">Keyword</label
                                    >
                                  <input required=""
                                      type="text"
                                      class="form-control"
                                      id="txtremarks"
                                      name="txtremarks"
                                      placeholder="Keyword"
                                    />
                                  </div>
                                  </div>-->

                    <div class="col-md-2">
                      <div class="mb-3 form-group row no-gutters">
                        <label class=" ">Expected Salary</label
                        >
                        <input required="" v-model="bodyCandiFilter.expectedSalary"
                               type="number"
                               class="form-control"

                               name="expectedSalary"
                               placeholder="Expected Salary"
                        />
                      </div>
                    </div>


                    <div class="col-md-2">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="">City</label
                        >

                        <select class="form-control" required="" v-model="bodyCandiFilter.cityId">
                          <option value=0>Select City</option>
                          <option v-for="option in cities"
                                  :key="option.key"
                                  :value="option.key"
                          >
                            {{ option.value }}
                          </option>
                        </select>

                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="">Department</label
                        >

                        <select class="form-control" v-model="bodyCandiFilter.departments" id="">
                          <option value=0>
                            Select Department
                          </option>
                          <option
                              v-for="option in departments"
                              :key="option.key"
                              :value="option.key"
                          >
                            {{ option.value }}
                          </option>


                        </select>
                      </div>
                    </div>


                    <div class="col-md-3">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="">Experience</label
                        >

                        <select class="form-control" v-model="bodyCandiFilter.yearOfExperience" required="">
                          <option value=0>
                            Select Required job experience
                          </option>
                          <option v-for="option in yearOfExperiences"
                                  :key="option.key"
                                  :value="option.key"
                          >
                            {{ option.value }}
                          </option>
                        </select>

                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="">Gender</label
                        >

                        <select class="form-control" v-model="bodyCandiFilter.gender" required="">
                          <option value=0>Please select</option>
                          <option v-for="option in genderList"
                                  :key="option.key"
                                  :value="option.key"
                          >
                            {{ option.value }}
                          </option>
                        </select>

                      </div>
                    </div>


                  </div>
                  <div class="row">
                    <div class="col-md-2">
                      <label class="">&nbsp;</label
                      >
                      <b-button type="submit"
                                class="form-control custom-form-btn custom-btn-gotopage"


                                variant="outline-dark"
                      >
                  <span v-if="isLoadingCandiButton">
                    <b-spinner small></b-spinner>
                  </span>
                        <span class="ml-2">Search</span>
                      </b-button>

                    </div>
                    <div class="col-md-2">
                      <label class="">&nbsp;</label
                      >
                      <b-button type="reset"
                                class="form-control custom-form-btn custom-btn-gotopage"


                                variant="outline-dark"
                      >Reset
                      </b-button>

                    </div>
                    <div class="col-md-8"></div>
                  </div>


                  <!-- <div class="text-end mt-5">
                    <button
                      type="submit"
                      class="btn btn-primary waves-effect waves-light me-1"
                      :disabled="isLoading"
                    >
                      Submit</button
                    ><button type="reset" class="btn btn-secondary waves-effect">
                      Cancel
                    </button>
                  </div> -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-30">
      <div class="style-spacer"></div>
      <div class="col-md-12  ">


        <!-- <div class="w3-card openjobs-public p-3">
       <h3>Open Jobs </h3>
         <a href="#"> <p> Job 1 </p>
         <button class="btn btn-primary right"> Apply</button>
         </a>
         <a href="#"> <p> Job 1 </p></a>
         <a href="#"> <p> Job 1 </p></a>
         <a href="#"> <p> Job 1 </p></a>
         <a href="#"> <p> Job 1 </p></a>
         <hr>
         <a href="#"> <p> view all </p></a>

     </div> -->

        <div
            class="w3-card-2 pt-3 pb-3 ml-auto mr-auto"
            style=" "
        >
          <header class="w3-container custom-row">
            <h3>Candidates</h3>


          </header>
          <hr/>
          <div class="w3-container custom-row">

            <table class="table table-bordered">
              <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Years Of Experience</th>
                <th scope="col">Professional Summary</th>
                <th scope="col">Address</th>
                <th scope="col">Action</th>

              </tr>
              </thead>
              <tbody>

              <tr v-for="appli in applicants" :key="appli.jobApplicants">
                <td>{{ appli.employeeName }}</td>
                <td>{{ appli.emp.email }}</td>
                <td>{{ appli.yearOfExperience }}</td>
                <td>
                  <div class="">
                    <a
                        href="#professionalSummaryModal"
                        data-toggle="modal"
                        title="Show Professional Summary"
                        @click="showSummary(appli)"
                    >
                      <div class="custom-icon icon">
                        <span>Click To View</span>
                      </div>
                    </a>
                  </div>
                </td>
                <td>{{ appli.emp.address }}</td>
                <td>
                  <a :href="url+appli.emp.resume" download target="_blank">
                    <div class="custom-icon icon">
                      <span>Download CV</span>
                    </div>

                  </a>
                </td>


              </tr>


              </tbody>
            </table>

          </div>
          <p>

          </p>
        </div>

      </div>

      <div class="style-spacer"></div>
    </div>
    <div
        class="modal fade p-0"
        id="professionalSummaryModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="professionalSummaryModalLabel"
        aria-hidden="true"

    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div
            class="modal-content"
        >
          <div class="modal-header">
            <h5 class="modal-title" >
              {{ modalTitle }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <p class="text-justify">
              {{ professionalSummary }}
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "CvSearch",
  components: {},
  data() {
    return {
      url: axios.defaults.baseURL,
      userName: null,
      emailAddress: null,
      password: null,
      phoneNo: null,
      selectedUserRole: null,
      has_error: false,
      isLoading: false,
      EmployeerId: 8,
      jobTitle: null,
      jobDetail: null,
      countryId: 106,
      cityId: 2,
      packageFrom: null,
      packageTo: null,
      noOfJobs: null,
      preference: null,
      jobExpiryDate: null,
      degreeLevelId: null,
      requiredJobExperience: null,
      jobTypeId: null,
      jobSubTypeId: null,
      jobTypeDetailId: null,
      weeklyWorkingHour: null,
      workLocation: null,
      travelMiles: null,
      travelTimePercent: null,
      projectTitle: null,
      estimatedDurationInMonth: null,
      projectDescription: null,
      isSignLanguageRequrired: null,
      disabilityTypeId: null,
      jobHiringType: null,
      assistedHiringType: null,
      employerJobPostPackageId: 2,


      //Dropdown Lists
      // cities:[],
      departments: [],
      yearOfExperiences: [],

      genderList: [],


      applicants: [],
      isLoadingCandiButton: false,
      bodyCandiFilter: {
        jobId: 0,
        cityId: 0,
        yearOfExperience: 0,
        experienceLevel: 0,
        currentSalary: 0,
        expectedSalary: 0,
        departments: 0,
        careerLevels: 0,
        educationLevel: 0,
        applicantStatus: 0,
        gender: 0

      },
      body: {
        EmployerId: this.$auth.user().employeeId,
        jobTitle: null,
        jobDetail: null,
        countryId: 106,
        cityId: 2,
        packageFrom: null,
        packageTo: null,
        noOfJobs: null,
        preference: null,
        jobExpiryDate: null,
        degreeLevelId: null,
        requiredJobExperience: null,
        jobTypeId: null,
        jobSubTypeId: null,
        jobTypeDetailId: null,
        weeklyWorkingHour: 2,
        workLocation: 2,
        travelMiles: 1,
        travelTimePercent: 1,
        projectTitle: "dwe",
        estimatedDurationInMonth: 1,
        projectDescription: "asd",
        isSignLanguageRequrired: 1,
        disabilityTypeId: 1,
        jobHiringType: 1,
        assistedHiringType: 1,
        employerJobPostPackageId: 2
      },
      modalTitle:"",
      professionalSummary:"",
    };
  },
  computed: {
    cities() {
      return this.$store.getters.availableCities
    },
  },
  mounted() {

    //Dropdown Lists
    this.getCities();
    this.getDepartmentList();
    this.getYearOfExperience();
    this.getGender();


  },
  methods: {

    filterCandidates() {


      if (this.isLoadingCandiButton) {
        return;
      }
      this.isLoadingCandiButton = true;
      var app = this;
      this.bodyCandiFilter.jobId = Number(0)
      this.bodyCandiFilter.cityId = Number(this.bodyCandiFilter.cityId)
      this.bodyCandiFilter.yearOfExperience = Number(this.bodyCandiFilter.yearOfExperience)
      this.bodyCandiFilter.experienceLevel = Number(this.bodyCandiFilter.experienceLevel)
      this.bodyCandiFilter.currentSalary = Number(this.bodyCandiFilter.currentSalary)
      this.bodyCandiFilter.expectedSalary = Number(this.bodyCandiFilter.expectedSalary)
      this.bodyCandiFilter.departments = Number(this.bodyCandiFilter.departments)
      this.bodyCandiFilter.careerLevels = Number(this.bodyCandiFilter.careerLevels)
      this.bodyCandiFilter.educationLevel = Number(this.bodyCandiFilter.educationLevel)
      this.bodyCandiFilter.gender = Number(this.bodyCandiFilter.gender)
      this.bodyCandiFilter.applicantStatus = this.bodyCandiFilter.applicantStatus == '0' ? Number(0) : this.bodyCandiFilter.applicantStatus

      console.log(this.bodyCandiFilter)
      console.log(Date.now())

      axios
          .post("/api/Employee/SearchCvs", this.bodyCandiFilter)
          .then((res) => {

            console.log("Add bodyCandiFilter portion");
            console.log(res.data.data)
            this.applicants = res.data.data

          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.isLoadingCandiButton = false;
            this.$bvToast.toast("Filter Applied! ", {
              toaster: "b-toaster-top-center",
              variant: "success",
              title: 'Candidates',
              solid: true
            });


          });

    },
    resetForm() {

      this.bodyCandiFilter = {

        cityId: 0,
        yearOfExperience: 0,
        experienceLevel: 0,
        currentSalary: 0,
        expectedSalary: 0,
        departments: 0,
        careerLevels: 0,
        educationLevel: 0,
        applicantStatus: 0,
        gender: 0
      };
    },
    postjob() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      ///this.body.jobTitle = this.jobTitle;

      var app = this;
      this.body.countryId = Number(this.body.countryId)
      this.body.cityId = Number(this.body.cityId)
      this.body.packageFrom = Number(this.body.packageFrom)
      this.body.packageTo = Number(this.body.packageTo)
      this.body.noOfJobs = Number(this.body.noOfJobs)
      this.body.preference = Number(this.body.preference)
      this.body.degreeLevelId = Number(this.body.degreeLevelId)
      this.body.requiredJobExperience = Number(this.body.requiredJobExperience)
      this.body.jobTypeId = Number(this.body.jobTypeId)
      this.body.jobSubTypeId = Number(this.body.jobSubTypeId)
      this.body.jobTypeDetailId = Number(this.body.jobTypeDetailId)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour)
      console.log(this.body)

      axios
          .post("/api/Job/InsertNewJob", this.body)
          .then(res => {
            // code that we will 'try' to run
            this.info = res.data;

            console.log("Add Response portion");

            console.log("response : ", this.info);
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => {
            this.isLoading = false
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {
              this.$bvToast.toast("Posted Successfully ", {
                toaster: "b-toaster-top-center",
                variant: "success",
                title: "Job",
                solid: true
              });
              /*if(this.selectedCategory==1){
                this.$router.push({name:'Graduate'});
              }
              else if(this.selectedCategory==2){
                this.$router.push({name:'MumsReturntoWork'});
              }
              else if(this.selectedCategory==3){
                this.$router.push({name:'SpecialneedPerson'});
              }
              else if(this.selectedCategory==4){
                this.$router.push({name:'LabourManPower'});
              }*/

            }
          });


    },

    //Dropdown Lists
    getCities() {
      this.$store.dispatch("getCityList");
      /*this.cities = [];
      let ddBody = {
        type : "City"
      }
      // console.log("selected Country : ",country);
      axios.post('/api/KeyValuePair/GetDropDownListByIds', ddBody)
          .then(res => {
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            console.log("cities new: ",res)
            this.cities = res.data.data;
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });*/
      /*if (country === 106){
        this.city_input = false;
        this.city_dd = true;

      }
      else{
        this.city_input = true;
        this.city_dd = false;
      }*/


    },
    getDepartmentList() {
      this.departments = [];
      let model = {
        type: "Department"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.departments = res.data.data;

            console.log("Add Response portion");

            console.log("departments : ", this.departments);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },
    getYearOfExperience() {
      this.yearOfExperiences = [];
      let ddBody = {
        type: "YearOfExperience"
      }
      axios.post('/api/KeyValuePair/GetDropDownListByIds', ddBody)
          .then(res => {
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            console.log("yearOfExperiences new: ", res.data.data[0].key)
            this.yearOfExperiences = res.data.data
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
            }
          });


    },
    getGender() {
      this.genderList = [];
      let ddBody = {
        type: "Gender"
      }
      axios.post('/api/KeyValuePair/GetDropDownListByIds', ddBody)
          .then(res => {
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            console.log("genderList new: ", res.data.data[0].key)
            this.genderList = res.data.data
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
            }
          });


    },
    showSummary(empRecord){
      this.modalTitle = empRecord.employeeName
      this.professionalSummary = empRecord.emp.professionalSummary;
    },
  },
};

</script>

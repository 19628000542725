

<template>
  <header  class="custom-mainmenu">
    <div class="custom-header">
<!--      :class="[!$auth.check() ? 'custom-header-wrap':'', $auth.check() ? 'custom-header-height':'']"-->
      <div class="custom-header-wrap"  >
        <nav class="custom-navbar navbar navbar-expand-lg navbar-light">

          <div></div>
          <router-link class="navbar-brand custom-navbar-brand py-0" to="/">
            <img
                src="../assets/images/Hecancylogo.png"
                class="d-inline-block align-top custom-navbar-logo"
            />
          </router-link>

          <!-- <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button> -->

          <div
              class="navbar-collapse custom-multi-navbar ml-5"
              id="headerSupportedContent"
          >



            <div id="ad-banner-id" class="mr-auto">
              <div >
                <a href="www.shemeanswork.com"  target="_blank" class="navbar-brand mr-0">
                  <img src="../assets/images/hecency new.jpg" class="w-100" style="height: 90px;object-fit: cover" />
                </a>
              </div>
              <!-- <div v-if="topBarAdd.length != 0">
                <a :href="topBarAdd[0].url" :title="topBarAdd[0].url" target="_blank" class="navbar-brand mr-0">
                  <img :src="url+topBarAdd[0].image" class="w-100" style="height: 90px;object-fit: cover" />
                </a>
              </div>
              <div v-else>
                <router-link to="/" target="_blank" class="navbar-brand mr-0">
                  <img src="../assets/images/wide.f3b806b1.png" class="w-100" />
                </router-link>
              </div> -->

            </div>

          </div>

          <div></div>
        </nav>

      </div>
    </div>
    <!--    shows on mobile view-->
    <div class="custom-header-ad">
      <div class="custom-header-wrap">
        <nav class="custom-navbar navbar navbar-expand-lg navbar-light justify-content-center">
          <div class="custom-mobile-view-ad">
            <div class="" style="">

              <div v-if="topBarAdd.length != 0">
                <a :href="topBarAdd[0].url" :title="topBarAdd[0].url" target="_blank" class="navbar-brand mr-0">
                  <img :src="url+topBarAdd[0].image" class="w-100" />
                </a>
              </div>
              <div v-else>
                <router-link to="/" target="_blank" class="navbar-brand mr-0">
                  <img src="../assets/images/wide.f3b806b1.png" class="w-100" />
                </router-link>
              </div>
            </div>
          </div>
        </nav>

      </div>
    </div>
  </header>
</template>

<script>
// var sidebar = document.getElementById("mySidebar");

import axios from "axios";
export default {
  name: "BannerHeader",
  components:{

  },
  data(){
    return{


      url:axios.defaults.baseURL,



    };

  },
  computed:{
    topBarAdd(){
      console.log("topbarAdd : ", this.$store.getters.availableTopBarAdds)
      return this.$store.getters.availableTopBarAdds
    }
  },
  mounted(){
    this.getAdds();



  },



  methods: {
    getAdds(){
      this.$store.dispatch("fetchAdds");
    },



  },
};
</script>

<style>

</style>

<template>
  <div class="container-fluid style-black-bg style-brand-spaces">
    <div class="container">
      <div class="row">
        <div class="col-md-3 align-self-center">
          <div class="style-logo-footer-section mt-3 mb-3">
            <router-link to="/" class="">
              <img style="height: 66px;" src="../assets/images/Hecancylogo.png" />
            </router-link>

          </div>
        </div>
        <div class="col-md-3 ">
          <div class="style-section-1">
            <div class="menu-footer-1-container">
              <ul id="menu-footer-1" class="menu">
                <li>
                  <router-link :to="{ name: 'AllJobs'}">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon>
                    Jobs in your area
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'AllJobs'}">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Search By City</router-link
                  >
                </li>
                <li>
                  <router-link  :to="{ name: 'AllJobs'}">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >International Jobs
                  </router-link>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="setCategory(26)">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Internships
                  </a>
                </li>
                <li>
                  <a href="https://viilearn.com" target="_blank">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Upskill with Vii Learn</a
                  >
                </li>
                <li>
                  <a href="https://harshehar.com/#/directory" target="_blank">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Har Shehar Directory
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="style-section-2">
            <div class="menu-footer-2-container">
              <ul id="menu-footer-2" class="menu">

                <li>
                  <a href="javascript:void(0)" @click="setCategory(24)">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Mums Return to Work
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="setCategory(30)">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Persons With Disabilities (PWD’s)
                  </a>
                </li>
                <li>
                  <a href="https://ventureher.com" target="_blank">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Mumpreneurs (Venture(H)er)
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="setCategory(27)">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Graduate
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="setCategory(254)">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Labour Man Work
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="style-section-3">
            <div class="menu-footer-3-container">
              <ul id="menu-footer-3" class="menu">


                <li>
                  <router-link :to="{ name: 'TermsAndConditions'}">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Terms & Conditions
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'PrivacyPolicy'}">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Privacy Policy
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'CookiePolicy'}">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Cookie Policy
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Disclaimer'}">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Disclaimer
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'EndUserLicenseAgreement'}">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >End-User License Agreement
                  </router-link>
                </li>

                <li>
                  <a title="" href="#">
                    <b-icon
                        icon="play-fill"
                        class="footer-icon"
                        aria-hidden="true"
                    ></b-icon
                    >Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <hr class="style-hr" />

      <div class="row style-light-grey">
        <div class="col-lg-5">
          <div class="style-right-reserved">
            <p>© {{ new Date().getFullYear() }}. All Rights Reserved.</p>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="style-up-icon">
<!--            <router-link :to="{ name: 'Home',
                              params: { id: 'ourServices_id' }
                            }"
                         @click="scrollTo($event.target.dataset.id)"
                         data-id="ourServices_id"
                         class="btn link-theme text-decoration-none">Explore Services</router-link>-->
            <a href="javascript:void(0)" @click="scrollTo($event.target.dataset.id)" :data-id="componentId" id="back-to-top" class="add-fix-top active">
              <i class="fa fa-angle-up" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="social">
              <span class="style-social-icons"
              ><a href="https://www.facebook.com/Shemeanswork.offical" class="facebook" target="_blank"> <i class="fab fa-facebook"></i></a>
              </span>
            <span class="style-social-icons"
            ><a href="https://twitter.com/shemeanswork1" class="twitter" target="_blank"> <i class="fab fa-twitter"></i> </a
            ></span>
            <span class="style-social-icons"
            ><a href="https://www.youtube.com/channel/UCetNHbRcnPDyPdPlKwhGPjw" class="youtube" target="_blank"> <i class="fab fa-youtube"></i></a>
              </span>
            <span class="style-social-icons"
            ><a href="https://www.linkedin.com/company/shemeanswork/" class="linkedin" target="_blank"> <i class="fab fa-linkedin"></i></a
            ></span>

            <span class="style-social-icons"
            ><a href="https://www.instagram.com/shemeanswork.official/" class="instagram" target="_blank"> <i class="fab fa-instagram"></i></a>
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"Footer",
  data(){
    return{
      filter:{
        JobTypes: [],
        JobSubTypes: [],
        Cities:[],
        DegreeLevels:[],
        EmpExperiences: [],
        isOthersSelected: false
      },
    };
  },
  props:{
    componentId:""
  },
  methods:{
    scrollTo(){
      this.$emit('scrollTo-top');
    },

    setCategory(key){
      this.filter.JobSubTypes = [];
      console.log("type", this.$route.name)

      if(this.$route.name == "AllJobs"){
        /*this.$router.push(
            { name: 'AllJobs', params: { searchString: this.jobTitle }
            })*/
        this.filter.JobSubTypes.push(key);
        this.getJobsByCategory(this.filter);
        setTimeout(this.scrollTo, 1000);
      }
      else {
        // this.filter.JobSubTypes.push(key);
        this.$router.push(
            { name: 'AllJobs', params:{key}})

        //this.getJobsByCategory(this.filter);


      }

    },
    getJobsByCategory(jobFilter){
      console.log("jobFilter : ", jobFilter)
      this.$store.dispatch("getJobsByFilters", jobFilter)
      //this.$router.push({name: 'AllJobs', params: { jobTitle }})
    },


  },
  mounted() {
    console.log("id : ", this.componentId)
  }
}
</script>

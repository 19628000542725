<template>

  <!-- <div class="m-5" :key="componentKey"> -->
  <div class="mt-5 mb-5 ml-auto mr-auto" :key="componentKey">
    <div class="container">
      <div v-if="showCompleteProfileAlert"><div class="alert alert-warning" role="alert">
        Please complete your profile first. Click Here to <router-link  to="/employer-profile" class="custom-text-color text-decoration-none"><i>Update Your Profile.</i></router-link>
      </div></div>
      <div v-if="showDashboard">
        <div class="row no-gutters w-100 mt-2">
          <div class="col-md-2">

            <div class="">


              <div class="card p-0 mb-20 w3-center custom-profile-card h-100">
                <div class="card-body pt-4 pb-4">

                  <div>

                    <div class="custom-profile-image-wrap custom-company-image-wrap" >
                      <div v-if="body.companyLogoAddress !== null">
                        <img :src="url+body.companyLogoAddress" alt="" class="rounded-circle custom-profile-image">

                      </div>
                      <div v-else>
                        <img src="../../assets/images/no-image.png" alt="" class="w3-circle custom-profile-image">
                      </div>



                    </div>

                  </div>
                  <div class=" w3-center custom-profile-detail">
                    <h5 class="font-weight-bold text-capitalize">

                      {{ body.companyName }}

                    </h5>

                    <!--                <section class="cd-intro">
                                      <h5 class="cd-headline loading-bar">

                                        <span class="cd-words-wrapper">
                                          <b class="is-visible">.Net Developer</b>
                                          <b v-for="exp in employeeExperience" :key="exp.empExperienceId">
                                            {{ exp.jobTitle }}
                                          </b>

                                        </span>
                                      </h5>

                                    </section>-->

                  </div>



                </div>
                <div class="card-footer border-0 pl-0 pr-0">
                  <div class="custom-profile-contacts custom-profile-detail">
                    <div class="">
                      <a class="text-decoration-none w3-hover-text-light-gray" :href="phoneNumberLink+body.contactNumber">
                        <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>

                        <span class="">{{ body.contactNumber }}</span>
                      </a>


                    </div>

                    <div class=" ">
                      <a class="text-decoration-none w3-hover-text-light-gray" :href="emailLink+body.companyEmail">
                        <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                        <span>{{ body.companyEmail }}</span>
                      </a>
                    </div>
                  </div>
                  <div class="custom-row justify-content-center mt-2 ">
                    <a :href="'https://'+body.facebookLink" target="_blank" class="custom-facebook-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-facebook-f"></i>
                    </a>

                    <a :href="'https://'+body.googlePlusLink" target="_blank" class="custom-googleplus-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-google"></i>
                    </a>
                    <a :href="'https://'+body.linkedInLink" target="_blank" class="custom-linkedin-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                    <a :href="'https://'+body.twitterLink" target="_blank" class="custom-twitter-icon custom-icon-style custom-social-link">
                      <i class="fab fa-twitter"></i>
                    </a>

                  </div>
                </div>
              </div>
              <div class="card p-0 mb-20">
                <div class="card-body text-center" v-if="isPackageDataFetched ">
                  <div class="  text-dark">
                    <h5 class="font-weight-bold text-capitalize">

                      <!--                  {{ fullName }}-->
                      Active Package
                    </h5>

                    <!--                <section class="cd-intro">
                                      <h5 class="cd-headline loading-bar">

                                        <span class="cd-words-wrapper">
                                          <b class="is-visible">.Net Developer</b>
                                          <b v-for="exp in employeeExperience" :key="exp.empExperienceId">
                                            {{ exp.jobTitle }}
                                          </b>

                                        </span>
                                      </h5>

                                    </section>-->

                  </div>

                </div>
                <div class="card-footer text-center text-muted" v-if="packages.length > 0">
                  {{packages[0].jobPostPackages.packageName}}
                  <br>
                  <br>
                  <p v-if="packages[0].unlimitedPosting">Jobs Limit: Unlimited Jobs </p>
                  <p v-else> Jobs Limit:  {{packages[0].jobPostPackages.jobPostingLimit}} </p>
                  <br>


                  <p  > Jobs Consumed:  {{packages[0].totalPostedJobs}} </p>
                  <br>
                  Expiry Date: {{packages[0].employerJobPostPackages.expiryDate  | formatDateAndTime}}
                </div>
                <div class="card-footer text-center text-muted" v-else>
                  No Package Found
                </div>

              </div>
              <div class="card p-0">
                <div v-if="employerDashboardLeftSide.length != 0" class="text-center">
                  <a :href="employerDashboardLeftSide[0].url" :title="employerDashboardLeftSide[0].url" target="_blank">
                    <img :src="url+employerDashboardLeftSide[0].image" width="100%" />
                  </a>
                </div>
                <div v-else>
                  <img
                      src="../../assets/images/no-image.png"
                      width="100%"
                  />
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-8">
            <div class="m-4 mt-0">
              <div class="row mb-2">
                <div class="col-lg-2 col-md-6"></div>
                <div class="col-lg-4 col-md-6">
                
                  <div class="card emp-dash-card mb-3 h-100">
                    <div class="card-body p-2 text-center text-dark">
                      <img
                          src="../../assets/images/hecancy dashboard.jpg"
                      />
                      <p class="">
                        Step by step guide to your Dashboard
                      </p>


                    </div>

                    <div class="card-footer bg-white">

                      <a href="#showDetailModal"
                         data-toggle="modal"
                         @click="tutorialGuideModal"
                         title="Tutorial Guide"
                      >view more
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6">
                  <div class="card emp-dash-card mb-3 h-100">
                    <div class="card-body p-2 text-center text-dark">
                      <img style="margin-top:20%"
                          src="../../assets/images/hencancydashboard2.jpg"
                      />
                      <p style="margin-top:15%" class="">
                        Why hire a male candidate?
                      </p>


                    </div>
                    <div class="card-footer bg-white">

                      <a href="#showDetailModal"
                         data-toggle="modal"
                         @click="mumsBacktoWorkModal"
                         title="Mums Back to Work"
                      >view more
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6"></div>
               
              </div>
              <!--          <div class="row">
                          <div class="col-lg-3 col-md-6">
                            <div class="card emp-dash-card mb-3 h-100">
                              <div class="card-body text-center text-dark">
                                <img
                                  src="https://image.freepik.com/free-vector/young-teenage-woman-uses-laptop-work-chatting-with-friends-flat-cartoon-vector-illustration_102172-416.jpg"
                                />
                                <p class="">
                                  Looking for a remote resource for a short-term, one time
                                  project, try our remote and flexible internship program.
                                </p>

                              </div>
                              <div class="card-footer bg-white">

                                <a href="#showDetailModal"
                                   data-toggle="modal"
                                   @click="remoteModal"
                                   title="Remote & Flexible Internship"
                                >view more
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-6">
                            <div class="card emp-dash-card mb-3 h-100">
                              <div class="card-body text-center text-dark">
                                <img
                                  src="https://image.freepik.com/free-vector/young-teenage-woman-uses-laptop-work-chatting-with-friends-flat-cartoon-vector-illustration_102172-416.jpg"
                                />
                                <p class="">
                                  ViiLearn- an E-Academy catering to the capacity building of
                                  your candidates
                                </p>

                              </div>
                              <div class="card-footer bg-white">

                                <a href="#showDetailModal"
                                   data-toggle="modal"
                                   @click="viiLearnModal"
                                   title="ViiLearn- an E-Academy"
                                >view more
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-6">
                            <div class="card emp-dash-card mb-3 h-100">
                              <div class="card-body text-center text-dark">
                                <img
                                  src="https://image.freepik.com/free-vector/young-teenage-woman-uses-laptop-work-chatting-with-friends-flat-cartoon-vector-illustration_102172-416.jpg"
                                />
                                <p class="">
                                  ViiLearn- an E-Academy catering to the capacity building of
                                  your candidates
                                </p>

                              </div>
                              <div class="card-footer bg-white">

                                <a href="javascript:void(0)"

                                >view more
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-6">
                            <div class="card emp-dash-card mb-3 h-100">
                              <div class="card-body text-center text-dark">
                                <img
                                  src="https://image.freepik.com/free-vector/young-teenage-woman-uses-laptop-work-chatting-with-friends-flat-cartoon-vector-illustration_102172-416.jpg"
                                />
                                <p class="">
                                  Shehri Directory � Feature your Business on our online
                                  magazine
                                </p>

                              </div>
                              <div class="card-footer bg-white">

                                <a href="#showDetailModal"
                                   data-toggle="modal"
                                   @click="shehriDirectoryModal"
                                   title="Shehri Directory"
                                >view more
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>-->
            </div>
            <!--Spacer-->
            <div class="pt-4"></div>
            <div class="m-4">
              <div class="row mb-4">
                <div class="col">
                  <div class="text-center clear-bottom">
                    <router-link class="btn btn-theme" :to="{ name: 'EmployerPostJob'}">
                      Post A Job</router-link
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="card h-100">
                    <div class="card-body pb-0">


                      <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">{{dashboardData.jobStats.totalJobs}}</span>
                      </h4>


                    </div>
                    <div class="card-footer bg-white border-0 pt-0 text-muted " style="font-size: 18px">

                      Total Jobs

                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card h-100">
                    <div class="card-body pb-0">


                      <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">{{dashboardData.jobStats.cVsRecieved}}</span>
                      </h4>




                    </div>
                    <div class="card-footer bg-white border-0 pt-0 text-muted " style="font-size: 18px">
                      Received CVs
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card h-100">
                    <div class="card-body pb-0">


                      <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">{{dashboardData.jobStats.shortlistedCandidates}}</span>
                      </h4>

                    </div>
                    <div class="card-footer bg-white border-0 pt-0 text-muted " style="font-size: 18px">
                      Candidates shortlisted
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card h-100">
                    <div class="card-body pb-0">


                      <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">{{dashboardData.jobStats.upcomingInterviews}}</span>
                      </h4>

                    </div>
                    <div class="card-footer bg-white border-0 pt-0 text-muted " style="font-size: 18px">
                      Upcoming interviews
                    </div>
                  </div>
                </div>
              </div>

              <div class="pt-3">
                <!-- <div
                  class="w3-card-2 pt-3 pb-3 ml-auto mr-auto"
                  style="width: 100%; height: 30%; overflow: auto"
                >
                  <header class="w3-container custom-row">
                    <h3>Applications</h3>
                    <router-link
                      to="/all-applications"
                      class="btn btn-outline-secondary ml-auto"
                      >View All Applications</router-link
                    >
                  </header>
                  <hr />
                  <div class="w3-container custom-row">
                    <img
                      src="../../assets/images/avatar-2.jpg"
                      alt="Avatar"
                      class="w3-left w3-circle w3-margin-right"
                      style="width: 60px"
                    />
                    <div>
                      <p class="font-weight-bold w3-text-pink m-0">Profile Name</p>
                      <span>Job Name</span>
                    </div>

                    <div class="ml-auto">
                      <p class="font-weight-bold text-black m-0">4 Hours ago</p>
                      <a href="#">Portfolio</a>
                    </div>
                  </div>
                  <hr />
                  <div class="w3-container custom-row">
                    <img
                      src="../../assets/images/avatar-2.jpg"
                      alt="Avatar"
                      class="w3-left w3-circle w3-margin-right"
                      style="width: 60px"
                    />
                    <div>
                      <p class="font-weight-bold w3-text-pink m-0">Profile Name</p>
                      <span>Job Name</span>
                    </div>

                    <div class="ml-auto">
                      <p class="font-weight-bold text-black m-0">4 Hours ago</p>
                      <a href="#">Portfolio</a>
                    </div>
                  </div>
                  <hr />
                  <div class="w3-container custom-row">
                    <img
                      src="../../assets/images/avatar-2.jpg"
                      alt="Avatar"
                      class="w3-left w3-circle w3-margin-right"
                      style="width: 60px"
                    />
                    <div>
                      <p class="font-weight-bold w3-text-pink m-0">Profile Name</p>
                      <span>Job Name</span>
                    </div>

                    <div class="ml-auto">
                      <p class="font-weight-bold text-black m-0">4 Hours ago</p>
                      <a href="#">Portfolio</a>
                    </div>
                  </div>
                </div> -->
              </div>
              <!--SPACER-->
              <!-- <div class="style-spacer"></div> -->

              <!--ADVERTISEMENT START--->
              <div class="style-advertisement-portion">
                <div class="">
                  <div class="style-category-wrapper-item">
                    <div v-if="employerDashboardCenter.length != 0" class="w-100 text-center">
                      <a :href="employerDashboardCenter[0].url" :title="employerDashboardCenter[0].url" target="_blank" class="navbar-brand mr-0">
                        <img :src="url+employerDashboardCenter[0].image" class="w-100" />
                      </a>
                    </div>
                    <a v-else href="#">
                      <div class="text-center">Advertisement</div>
                    </a>
                  </div>
                </div>
              </div>
              <!--SPACER-->
              <div class="style-spacer"></div>

              <!--SPACER-->
              <div class="style-spacer"></div>
              <div>
                <div class="row no-gutters">
                  <div class="col-xl-2"></div>
                  <div class="col-xl-8">
                    <div class="w3-card-2 w-100 h-100 ">
                      <div class="card-body">
                        <h4 class="card-title mb-4 text-center">
                          CVs recieved on my jobs
                        </h4>
                        <apexchart
                            width="100%"
                            type="bar"
                            :options="lineChartOptions"
                            :series="lineChartSeries"
                        ></apexchart>
                        <!--                    <div id="column_chart" class="apex-charts" dir="ltr"></div>-->
                      </div>
                    </div>
                    <!--end card-->
                  </div>
                  <div class="col-xl-2"></div>
                </div>
                <div class="style-spacer"></div>
                <div class="row">

                  <div class="col-lg-4 ">
                    <div class="w3-card-2 w-100 h-100">
                      <div class="card-body">
                        <h4 class="card-title mb-5 text-center">
                          Employees by cities
                        </h4>
                        <table  class="table">
                          <thead>
                          <tr>
                            <td>Name</td>
                            <td>Count</td>


                          </tr>
                          </thead>
                          <tbody>
                          <tr   v-for="(job , index) in dashboardData.employeesByCity" :key="index">

                            <td><b> {{ job.name }} </b>
                            </td>
                            <td> {{ job.count }}

                            </td>

                          </tr>

                          </tbody>
                        </table>

                        <!-- <apexchart
                            type="pie"
                            width="100%"
                            :options="pieChartOptions"
                            :series="pieChartSeries"
                        ></apexchart> -->
                        <!--                    <div id="pie_chart" class="apex-charts" dir="ltr" style="margin-bottom:35px;">
                        </div>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 ">
                    <div class="w3-card-2 w-100 h-100">
                      <div class="card-body">
                        <h4 class="card-title mb-5 text-center">Universities</h4>
                        <table  class="table">
                          <thead>
                          <tr>
                            <td>Name</td>
                            <td>Count</td>


                          </tr>
                          </thead>
                          <tbody>
                          <tr   v-for="(job , index) in dashboardData.employeesByUniversity" :key="index">

                            <td><b> {{ job.name }} </b>
                            </td>
                            <td> {{ job.count }}

                            </td>

                          </tr>

                          </tbody>
                        </table>
                        <!-- <apexchart
                            type="pie"
                            width="100%"
                            :options="pieChartOptions"
                            :series="pieChartSeries"
                        ></apexchart> -->
                        <!--                    <div id="pie_chart" class="apex-charts" dir="ltr" style="margin-bottom:35px;">
                        </div>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 ">
                    <div class="w3-card-2 w-100 h-100">
                      <div class="card-body">
                        <h4 class="card-title mb-5 text-center">
                          Applications by experience
                        </h4>
                        <table  class="table">
                          <thead>
                          <tr>
                            <td>Name</td>
                            <td>Count</td>


                          </tr>
                          </thead>
                          <tbody>
                          <tr   v-for="(job , index) in dashboardData.employeesByExperiance" :key="index">

                            <td><b> {{ job.name }} </b>
                            </td>
                            <td> {{ job.count }}

                            </td>

                          </tr>

                          </tbody>
                        </table>
                        <!-- <apexchart
                            type="pie"
                            width="100%"
                            :options="pieChartOptions"
                            :series="pieChartSeries"
                        ></apexchart> -->
                        <!--                    <div id="pie_chart" class="apex-charts" dir="ltr" style="margin-bottom:35px;">
                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-2">
            <div class="">


              <div class="card p-0 mb-20 w3-center h-100 ">
                <div class="card-body pt-4 pb-4">

                  <div>
                    <!--                <div class="custom-profile-image-wrap" v-if="userImage != null">
                                      <img :src="url+userImage" alt="" class="w3-circle custom-profile-image">
                                      &lt;!&ndash;                  <img src="../../assets/images/avatar-2.jpg" alt="" class="w3-circle custom-profile-image">&ndash;&gt;
                                      &lt;!&ndash;                  <div class="custom-change-profile-image-btn-wrap">
                                                          <a
                                                              class="custom-change-profile-image-btn"
                                                              href="#addorEditModal"
                                                              data-toggle="modal"
                                                              @click="NewModalImage"
                                                              title="Edit Skill"
                                                              data-backdrop="static"
                                                              data-keyboard="false"
                                                          >
                                                            <b-icon
                                                                font-scale="1.5"
                                                                class="text-white"
                                                                icon="camera-fill"
                                                            ></b-icon
                                                            ></a>
                                                        </div>&ndash;&gt;
                                    </div>-->
                    <div class="custom-profile-image-wrap" >
                      <div  v-if="disEmployeeLogo !== null">
                        <img :src="url+disEmployeeLogo" alt="" class="rounded-circle custom-profile-image">

                      </div>
                      <div v-else>
                        <img src="../../assets/images/no-image.png" alt="" class="w3-circle custom-profile-image">
                      </div>



                    </div>

                  </div>
                  <div class=" w3-center text-dark" style="width: 114%;margin-left: -3%;">
                    <h5 class="font-weight-bold text-capitalize">

                      {{ body.fullName }}

                    </h5>
                    <div class=" ">

                      <span>{{ body.designation }}</span>

                    </div>
                    <div>
                      <span>{{ body.email }}</span>
                    </div>
                    <div>
                      <span>{{ body.mobileNumber }}</span>
                    </div>
                    <!--                <section class="cd-intro">
                                      <h5 class="cd-headline loading-bar">

                                        <span class="cd-words-wrapper">

                                          <b class="is-visible" v-for="job in allJobsList" :key="job.jobId">
                                            {{ job.jobTitle }}
                                          </b>

                                        </span>
                                      </h5>

                                    </section>-->

                  </div>



                </div>

                <!--            <div class="card-footer border-0 bg-white pl-0 pr-0">
                              <div class="custom-profile-contacts text-muted">
                                <div class="">
                                  <a class="text-decoration-none w3-hover-text-dark-gray" :href="phoneNumberLink+body.mobileNumber">
                                    <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>

                                    <span class="">{{ body.mobileNumber }}</span>
                                  </a>


                                </div>

                                <div class=" ">
                                  <a class="text-decoration-none w3-hover-text-dark-gray" :href="emailLink+body.email">
                                    <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                                    <span>{{ body.email }}</span>
                                  </a>
                                </div>
                              </div>
                              <div class="custom-row justify-content-center mt-2 ">
                                <a :href="body.facebookLink" class="custom-facebook-icon custom-icon-style mr-2 custom-social-link">
                                  <i class="fab fa-facebook-f"></i>
                                </a>

                                <a :href="body.googlePlusLink" class="custom-googleplus-icon custom-icon-style mr-2 custom-social-link">
                                  <i class="fab fa-google"></i>
                                </a>
                                <a :href="body.linkedInLink" class="custom-linkedin-icon custom-icon-style mr-2 custom-social-link">
                                  <i class="fab fa-linkedin-in"></i>
                                </a>
                                <a :href="body.twitterLink" class="custom-twitter-icon custom-icon-style custom-social-link">
                                  <i class="fab fa-twitter"></i>
                                </a>

                              </div>
                            </div>-->
              </div>
              <div class="card">
                <div v-if="employerDashboardRightSide.length != 0" class="text-center">
                  <a :href="employerDashboardRightSide[0].url" :title="employerDashboardRightSide[0].url" target="_blank">
                    <img :src="url+employerDashboardRightSide[0].image" width="100%" />
                  </a>
                </div>
                <div v-else>
                  <img
                      src="../../assets/images/no-image.png"
                      width="100%"
                  />
                </div>
              </div>

            </div>

          </div>
        </div>

        <div class="row no-gutters w-100 mt-4">
          <div class="col-md-2">

          </div>
          <div class="col-md-8">

          </div>
          <div class="col-md-2">

          </div>
        </div>



        <!-- Modal -->
        <div class="modal fade" id="showDetailModal" tabindex="-1" role="dialog" aria-labelledby="showDetailModalTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="showDetailModalTitle">{{ modalTitle }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">

                <div v-if="isTutorialGuideVisible"></div>
                <div v-if="isMumsBacktoWorkVisible"></div>
                <div v-if="isAbledCandidatesVisible"></div>
                <div v-if="isFreshGraduateVisible"></div>
                <div v-if="isRemoteVisible"></div>
                <div v-if="isViiLearnVisible"></div>
                <div v-if="isShehriDirectoryVisible"></div>




              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import Vue from "vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import { ArrowLeftCircleIcon } from "vue-feather-icons";
import { ArrowRightCircleIcon } from "vue-feather-icons";
import { VueperSlide, VueperSlides } from "vueperslides";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
export default {
  name: "EmployerDashboard",
  components: {
    VueSlickCarousel,
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,
  },
  data() {
    return {
      url:axios.defaults.baseURL,
      modalTitle:"",
      isTutorialGuideVisible:false,
      isMumsBacktoWorkVisible:false,
      isAbledCandidatesVisible:false,
      isFreshGraduateVisible:false,
      isRemoteVisible:false,
      isViiLearnVisible:false,
      isShehriDirectoryVisible:false,
      showDashboard: false,
      showCompleteProfileAlert: false,
      lineChartOptions: {
        chart: {
          type: "bar",
          toolbar: {
            show: !1,
          },
        },
        plotOptions: {
          bar: {
            horizontal: !1,
            columnWidth: "45%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: !1,
        },
        stroke: {
          show: !0,
          width: 2,
          colors: ["transparent"],
        },
        colors: ["#5b73e8", "#f1b44c"],
        grid: {
          borderColor: "#f1f1f1",
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (e) {
              return e;
            },
          },
        },
        xaxis: {
          categories: ['job 1', 2, 3, 4, 5, 6, 7, 8, 9]
        }
      },
      lineChartSeries: [
        {
          name: "CVs Received",
          data: [46, 57, 59, 54, 62, 58, 64, 60, 66],
        },
      ],
      pieChartSeries: [44, 55, 13, 43, 22],
      pieChartOptions: {
        chart: {
          type: "pie",
        },
        labels: ["Jul-20", "Aug-20", "Sep-20", "Oct-20", "Nov-20"],
        colors: ["#34c38f", "#5b73e8", "#f1b44c", "#50a5f1", "#f46a6a"],
        legend: {
          show: !0,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: !1,
          fontSize: "14px",
          offsetX: 0,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: !1,
              },
            },
          },
        ],
      },


      phoneNumberLink: "tel:",
      emailLink: "mailto:",
      employerList:null,
      disCompanyLogo:"",
      dashboardData : [],

      disEmployeeLogo:null,
      packages: [],
      isPackageDataFetched: false,
      body: {
        employerCompanyId: 0,
        companyEmail: "",
        companyName: "",
        companyLogoAddress:null,
        ceoName: "",
        employeeId: this.$auth.user().employeeId,
        industryId: 0,
        ownerShipTypeId: 0,
        companyDesciption: "",
        countryId: 106,
        cityId: 0,
        address: "",
        establishedInYear: 0,
        websiteUrl: "",
        contactNumber: "",
        googleMapIframe: "",
        linkedInLink: "",
        facebookLink : "",
        googlePlusLink: "",
        twitterLink : "",
        employeeImage : "",
        fullName : "",
        email : "",
        mobileNumber : null,

        designation:"",


      },

      allJobsList:null,

      componentKey:0,
    };
  },
  computed:{
    employerDashboardLeftSide(){
      console.log("topbarAdd : ", this.$store.getters.availableEmployerDashboardLeftSide)
      return this.$store.getters.availableEmployerDashboardLeftSide
    },
    employerDashboardRightSide(){
      console.log("topbarAdd : ", this.$store.getters.availableEmployerDashboardRightSide)
      return this.$store.getters.availableEmployerDashboardRightSide
    },
    employerDashboardCenter(){
      console.log("topbarAdd : ", this.$store.getters.availableEmployerDashboardCenter)
      return this.$store.getters.availableEmployerDashboardCenter
    }
  },
  methods: {
    getAdds(){
      this.$store.dispatch("fetchAdds");
    },
    getAll() {
      this.rerender();
      this.employerList= null;
      axios.get('/api/EmployerCompany/GetEmployerCompany')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            let respData = res.data.data;
            console.log("res.data : ",  respData);


            if(respData != 0){
              //this.body = resdata
              this.employerList = respData;
              this.body.employerCompanyId = this.employerList.employerCompanyId

              this.body.companyEmail = this.employerList.companyEmail
              this.body.companyName = this.employerList.companyName
              this.body.ceoName = this.employerList.ceoName

              this.body.address = this.employerList.address

              this.body.contactNumber = this.employerList.contactNumber

              this.body.linkedInLink = this.employerList.linkedInLink;
              this.body.facebookLink = this.employerList.facebookLink;
              this.body.twitterLink = this.employerList.twitterLink;
              this.body.googlePlusLink = this.employerList.googlePlusLink;

              this.body.companyLogoAddress = this.employerList.companyLogoAddress;


              this.body.fullName = this.employerList.employee.fullName
              this.body.designation = this.employerList.employee.designation;
              this.body.mobileNumber = this.employerList.employee.mobileNumber;
              this.body.email = this.employerList.employee.email;
              this.disEmployeeLogo = this.employerList.employee.employeeImage;

              this.allJobsList = this.employerList.jobs;
              console.log("allJobsList : ",this.allJobsList);
              this.showDashboard = true;

            }
            else {
              //this.body.companyEmail = 'test';
               this.showCompleteProfileAlert=true;
                this.showDashboard = false;
              /*this.$bvToast.toast("No Data Found ", {
                toaster: "b-toaster-top-center",
                variant: "warning",

                solid: true,
              });*/

              console.log("No Data Found..")
            }


            // });
          }).catch(error => {
        console.log(error);
      })
          .finally(() => {

          });
    },
    getGraphData() {
      this.rerender();
      //this.employerList= null;
      axios.get('/api/EmployerCompany/DashboardGraphs')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            let respData = res.data.data;
            this.dashboardData = respData;

            //this.lineChartSeries.data = this.dashboardData.cVsRecievedOnJobs.map(({ count }) => count);

            this.lineChartSeries = [
        {
          data: this.dashboardData.cVsRecievedOnJobs.map(({ count }) => count),
        },
      ];

      //this.lineChartOptions.xaxis = this.dashboardData.cVsRecievedOnJobs.map(({ job }) => job)

     this.lineChartOptions = {
      xaxis: {
          categories: this.dashboardData.cVsRecievedOnJobs.map(({ job }) => job)
        }
        }
            console.log("res.data graph: ",  respData);
            console.log("this.lineChartSeries.data: ",   this.lineChartSeries);
            //console.log("res graph: ",  JSON.parse(res));


            if(respData != 0){

            }
            else {
            }


            // });
          }).catch(error => {
        console.log(error);
      })
          .finally(() => {

          });
    },
    getPackagesInfo(){
      axios.get('/api/EmployerJobPostPackage/GetAllJobPostPackagesByEmployeeId')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            this.packages = res.data.data;
            console.log("PACKAGES : ",  this.packages.length  );
            this.isPackageDataFetched = true
          }).catch(error => {
        console.log(error);
      })
          .finally(() => {

          });
    },
    //Modals
    tutorialGuideModal(){
      this.modalTitle = "Tutorial Guide";
      this.isTutorialGuideVisible = true;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    mumsBacktoWorkModal(){
      this.modalTitle = "Why hire a male candidate?";
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = true;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    abledCandidatesModal(){
      this.modalTitle = "Abled Candidates";
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = true;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    freshGraduateModal(){
      this.modalTitle = "Fresh Graduate";
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = true;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    remoteModal(){
      this.modalTitle = "Remote & Flexible Internship Program";
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = true;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    viiLearnModal(){
      this.modalTitle = "Vii Learn";
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = true;
      this.isShehriDirectoryVisible = false;
    },
    shehriDirectoryModal(){
      this.modalTitle = "Shehri Directory";
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = true;
    },









//Rerender Component
    rerender() {
      this.componentKey += 1;
    },


  },
  mounted() {
    this.getAdds();
    this.getAll();
    this.getPackagesInfo();
    this.getGraphData();
    /*this.$nextTick(()=>{
      //set animation timing
      var animationDelay = 2500,
          //loading bar effect
          barAnimationDelay = 3800,
          barWaiting = barAnimationDelay - 3000, //3000 is the duration of the transition on the loading bar - set in the scss/css file
          //letters effect
          lettersDelay = 50,
          //type effect
          typeLettersDelay = 150,
          selectionDuration = 500,
          typeAnimationDelay = selectionDuration + 800,
          //clip effect
          revealDuration = 600,
          revealAnimationDelay = 1500;

      initHeadline();


      function initHeadline() {
        //insert <i> element for each letter of a changing word
        singleLetters($('.cd-headline.letters').find('b'));
        //initialise headline animation
        animateHeadline($('.cd-headline'));
      }

      function singleLetters($words) {
        $words.each(function () {
          var word = $(this),
              letters = word.text().split(''),
              selected = word.hasClass('is-visible');
          for (let i in letters) {
            if (word.parents('.rotate-2').length > 0) letters[i] = '<em>' + letters[i] + '</em>';
            letters[i] = (selected) ? '<i class="in">' + letters[i] + '</i>' : '<i>' + letters[i] + '</i>';
          }
          var newLetters = letters.join('');
          word.html(newLetters);
        });
      }

      function animateHeadline($headlines) {
        var duration = animationDelay;
        $headlines.each(function () {
          var headline = $(this);

          if (headline.hasClass('loading-bar')) {
            duration = barAnimationDelay;
            setTimeout(function () {
              headline.find('.cd-words-wrapper').addClass('is-loading')
            }, barWaiting);
          } else if (headline.hasClass('clip')) {
            var spanWrapper = headline.find('.cd-words-wrapper'),
                newWidth = spanWrapper.width() + 10
            spanWrapper.css('width', newWidth);
          } else if (!headline.hasClass('type')) {
            //assign to .cd-words-wrapper the width of its longest word
            var words = headline.find('.cd-words-wrapper b'),
                width = 0;
            words.each(function () {
              var wordWidth = $(this).width();
              if (wordWidth > width) width = wordWidth;
            });
            headline.find('.cd-words-wrapper').css('width', width);
          };

          //trigger animation
          setTimeout(function () {
            hideWord(headline.find('.is-visible').eq(0))
          }, duration);
        });
      }

      function hideWord($word) {
        var nextWord = takeNext($word);

        if ($word.parents('.cd-headline').hasClass('type')) {
          var parentSpan = $word.parent('.cd-words-wrapper');
          parentSpan.addClass('selected').removeClass('waiting');
          setTimeout(function () {
            parentSpan.removeClass('selected');
            $word.removeClass('is-visible').addClass('is-hidden').children('i').removeClass('in').addClass('out');
          }, selectionDuration);
          setTimeout(function () {
            showWord(nextWord, typeLettersDelay)
          }, typeAnimationDelay);

        } else if ($word.parents('.cd-headline').hasClass('letters')) {
          var bool = ($word.children('i').length >= nextWord.children('i').length) ? true : false;
          hideLetter($word.find('i').eq(0), $word, bool, lettersDelay);
          showLetter(nextWord.find('i').eq(0), nextWord, bool, lettersDelay);

        } else if ($word.parents('.cd-headline').hasClass('clip')) {
          $word.parents('.cd-words-wrapper').animate({
            width: '2px'
          }, revealDuration, function () {
            switchWord($word, nextWord);
            showWord(nextWord);
          });

        } else if ($word.parents('.cd-headline').hasClass('loading-bar')) {
          $word.parents('.cd-words-wrapper').removeClass('is-loading');
          switchWord($word, nextWord);
          setTimeout(function () {
            hideWord(nextWord)
          }, barAnimationDelay);
          setTimeout(function () {
            $word.parents('.cd-words-wrapper').addClass('is-loading')
          }, barWaiting);

        } else {
          switchWord($word, nextWord);
          setTimeout(function () {
            hideWord(nextWord)
          }, animationDelay);
        }
      }

      function showWord($word, $duration) {
        if ($word.parents('.cd-headline').hasClass('type')) {
          showLetter($word.find('i').eq(0), $word, false, $duration);
          $word.addClass('is-visible').removeClass('is-hidden');

        } else if ($word.parents('.cd-headline').hasClass('clip')) {
          $word.parents('.cd-words-wrapper').animate({
            'width': $word.width() + 10
          }, revealDuration, function () {
            setTimeout(function () {
              hideWord($word)
            }, revealAnimationDelay);
          });
        }
      }

      function hideLetter($letter, $word, $bool, $duration) {
        $letter.removeClass('in').addClass('out');

        if (!$letter.is(':last-child')) {
          setTimeout(function () {
            hideLetter($letter.next(), $word, $bool, $duration);
          }, $duration);
        } else if ($bool) {
          setTimeout(function () {
            hideWord(takeNext($word))
          }, animationDelay);
        }

        if ($letter.is(':last-child') && $('html').hasClass('no-csstransitions')) {
          var nextWord = takeNext($word);
          switchWord($word, nextWord);
        }
      }

      function showLetter($letter, $word, $bool, $duration) {
        $letter.addClass('in').removeClass('out');

        if (!$letter.is(':last-child')) {
          setTimeout(function () {
            showLetter($letter.next(), $word, $bool, $duration);
          }, $duration);
        } else {
          if ($word.parents('.cd-headline').hasClass('type')) {
            setTimeout(function () {
              $word.parents('.cd-words-wrapper').addClass('waiting');
            }, 200);
          }
          if (!$bool) {
            setTimeout(function () {
              hideWord($word)
            }, animationDelay)
          }
        }
      }

      function takeNext($word) {
        return (!$word.is(':last-child')) ? $word.next() : $word.parent().children().eq(0);
      }

      function takePrev($word) {
        return (!$word.is(':first-child')) ? $word.prev() : $word.parent().children().last();
      }

      function switchWord($oldWord, $newWord) {
        $oldWord.removeClass('is-visible').addClass('is-hidden');
        $newWord.removeClass('is-hidden').addClass('is-visible');
      }
    });*/
  },
};
</script>

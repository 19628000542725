<template>
  <div class="custom-background" :key="componentKey">
    <!--   Job Title Section Start   -->
    <div class="container">

      <div class=" pt-3 pb-3 w3-container">
        <div class="row no-gutters align-items-center">
          <div class="col-md-2">
            <span class="b-avatar badge-info rounded-circle" style="width:7rem;height: 7rem; background-color: #fff">
              <span  class="b-avatar-img">
                <span v-if="jobData.companyLogoAddress != null && jobData.companyLogoAddress != ''">
                   <img :src="url+jobData.companyLogoAddress"  >
                 </span>
                 <span v-else>

                      <img src="../../assets/images/no-image.png"  >


                 </span>
              </span>

            </span>
          </div>
          <div class="col-md-7">
            <div class="">
              <h2 class="custom-job-title text-uppercase mb-2">
                <span v-if="jobData.jobTitle != null">
                  {{ jobData.jobTitle }}
                </span>
                <span v-else>--</span>
              </h2>
              <div  class="mb-2 custom-muted-text">
                <span v-if="$auth.check()">
                <router-link
                    v-if="$auth.user().user.role == 'Employee'"
                    class="text-decoration-none"
                    :to="{ name: 'AboutEmployer',
                               params: { id: setCompanyId }
                             }"
                    title="Company Detail">
                  <span>{{ jobData.companyName }}</span>
                </router-link>

                <span v-else>{{ jobData.companyName }}</span>
                </span>
                <span v-else>{{ jobData.companyName }}</span>
              </div>
              <div class="row no-gutters custom-muted-text mb-2">
                <div class="col-sm-4">
                  <div class="custom-row">
                    <span class="mr-2"><i class="fas fa-map-marker-alt"></i></span>
                    <span>{{ jobData.cityName}}, {{jobData.countryName}}</span>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="custom-row">
                    <span class="mr-2"><b-icon icon="clock-history"></b-icon></span>
                    <span>{{ jobData.postedDate | formatDate}}</span>
                  </div>

                </div>
                <div class="col-sm-5">
                  <div class="custom-row">
                    <span class="mr-2"><i class="fas fa-money-bill"></i></span>
                    <span>Rs {{ jobData.packageFrom }} - {{ jobData.packageTo }}/Month </span>
                  </div>
                </div>

              </div>

              <div class="row no-gutters mb-2">
                <div class="col-lg-3">
                <b-badge class="custom-status-badge" variant="primary">{{ jobData.category }}</b-badge>
              </div>
                <div  class="col-lg-3">
<!--                   <span class="pl-1 pr-1"></span>-->
                    <span>
                      <b-badge class="custom-status-badge" variant="primary">
                        Experience: {{ jobData.experiencelevel}}

                      </b-badge>
                    </span>
                </div>
                <div  class="col-lg-3">
                  <b-badge class="custom-status-badge" variant="primary">{{ jobData.jobTypeTitle }}</b-badge>
                </div>
                <div class="col-lg-3"></div>
              </div>

            </div>
          </div>
          <div class="col-md-3">
            <div class="text-right">
              <span class="custom-heading-text text-black mb-2">Application ends: </span>
              <i class="text-danger">{{ jobData.jobExpiryDate | formatDate}}</i>

            </div>
            <div v-if="$auth.check()">
              <div v-if="$auth.user().user.role == 'Employee'">
                <div class="custom-row mb-3">
                  <span class="flex-grow-1">

                            <span  >
                              <a @click="showOrHideModal(jobData)" class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2  ">Apply Now</a>
                            </span>
  <!--                          <span  v-else>
                              <a type="button" class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2 custom-btn-bookmark ">Applied</a>
                            </span>-->

                  </span>

  <!--                <div class=" flex-grow-1">
                    <button class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2">Apply Now</button>
                  </div>-->
                  <div class="pl-2 pr-2"></div>
                  <div class="">
                    <button class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-bookmark mb-2" @click="onSavingJob(jobData)">
                      <b-icon icon="bookmark"></b-icon>
                    </button>
                  </div>



                </div>

                <div class="custom-row">
  <!--                <div class="flex-grow-1">
                    <router-link :to="{name:'EmployeeJobs', params:{status:2}}" class="btn btn-primary btn-apply mb-2">Shortlisted Jobs</router-link>
                  </div>-->
                  <div class="pl-1 pr-1"></div>
                  <div class="flex-grow-1">
                    <button id="popover-target-1" class="btn btn-primary btn-apply mb-2">Share Job</button>
                    <b-popover target="popover-target-1" triggers="hover" placement="bottom">

                      <div class="custom-row justify-content-center mt-2 ">
                        <a :href="whatsAppUrl+url+jobUrl" type="url" target="_blank" class="custom-whatsapp-icon custom-icon-style mr-2 custom-social-link">
                          <i class="fab fa-whatsapp"></i>
                        </a>


                        <ShareNetwork
                            class="custom-facebook-icon custom-icon-style mr-2 custom-social-link"

                            network="facebook"
                            :url="domainUrl+'/#'+jobUrl"
                            :title="jobData.jobTitle"
                            :description="jobData.jobDescription"
                        >
                          <i class="fab fa-facebook-f"></i>
                        </ShareNetwork>

<!--                        <a :href="linkedInUrl+fullJobUrl" type="url" target="_blank" class="custom-linkedin-icon custom-icon-style custom-social-link">
                          <i class="fab fa-linkedin-in"></i>
                        </a>-->
                        <ShareNetwork
                            class="custom-linkedin-icon custom-icon-style custom-social-link"
                            network="linkedin"
                            :url="domainUrl+'/#'+jobUrl"
                            :title="jobData.jobTitle"
                            :description="jobData.jobDescription"

                        >
                          <i class="fab fa-linkedin-in"></i>
                        </ShareNetwork>

                      </div>
                    </b-popover>
                  </div>

                  <div class="pl-1 pr-1"></div>
                  <div class="flex-grow-1">
                    <a href="javascript:void(0)" @click="postCompanyId(setCompanyId)" class="btn btn-primary btn-apply mb-2">View All Jobs</a>
                  </div>



                </div>
              </div>
            </div>
            <div v-else>
              <div class="custom-row mb-3">
                  <span class="flex-grow-1">

                            <span  >
                              <a @click="showOrHideModal(jobData)" class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2  ">Apply Now</a>
                            </span>
                    <!--                          <span  v-else>
                                                <a type="button" class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2 custom-btn-bookmark ">Applied</a>
                                              </span>-->

                  </span>

                <!--                <div class=" flex-grow-1">
                                  <button class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2">Apply Now</button>
                                </div>-->
                <div class="pl-2 pr-2"></div>
                <div class="">
                  <button class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-bookmark mb-2" @click="onSavingJob(jobData)">
                    <b-icon icon="bookmark"></b-icon>
                  </button>
                </div>



              </div>

              <div class="custom-row">
                <!--                <div class="flex-grow-1">
                                  <router-link :to="{name:'EmployeeJobs', params:{status:2}}" class="btn btn-primary btn-apply mb-2">Shortlisted Jobs</router-link>
                                </div>-->
                <div class="pl-1 pr-1"></div>
                <div class="flex-grow-1">
                  <button id="popover-target-2" class="btn btn-primary btn-apply mb-2">Share Job</button>
                  <b-popover target="popover-target-2" triggers="hover" placement="bottom">

                    <div class="custom-row justify-content-center mt-2 ">
                      <a :href="whatsAppUrl+fullJobUrl" type="url" target="_blank" class="custom-whatsapp-icon custom-icon-style mr-2 custom-social-link">
                        <i class="fab fa-whatsapp"></i>
                      </a>

                      <ShareNetwork
                          class="custom-facebook-icon custom-icon-style mr-2 custom-social-link"
                          network="facebook"
                          :url="domainUrl+'/#'+jobUrl"
                          :title="jobData.jobTitle"
                          :description="jobData.jobDescription"

                      >
                        <i class="fab fa-facebook-f"></i>
                      </ShareNetwork>


                      <ShareNetwork
                          class="custom-linkedin-icon custom-icon-style custom-social-link"
                          network="linkedin"
                          :url="domainUrl+'/#'+jobUrl"
                          :title="jobData.jobTitle"
                          :description="jobData.jobDescription"

                      >
                        <i class="fab fa-linkedin-in"></i>
                      </ShareNetwork>

                    </div>
                  </b-popover>
                </div>
                <div class="pl-1 pr-1"></div>
                <div class="flex-grow-1">
                  <a href="javascript:void(0)" @click="postCompanyId(setCompanyId)" class="btn btn-primary btn-apply mb-2">View All Jobs</a>
                </div>



              </div>
            </div>
          </div>
        </div>


      </div>



    </div>
    <!--   Job Title Section End   -->
    <!--  Job Detail Section Start   -->
    <div class="bg-white pt-2 mb-4">


      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class=" pt-2 pb-4  mb-4">
              <div class="">

<!--                <ShareNetwork
                    network="twitter"
                    :url="domainUrl+'/#'+jobUrl"
                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                    quote="The hot reload is so fast it\'s near instant. - Evan You"

                >
                  <i class="fab fah fa-lg fa-twitter"></i>
                  <span>Share on Twitter</span>
                </ShareNetwork>
                <ShareNetwork
                    network="facebook"
                    url="https://harshehar.com/#/post-detail/239"
                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                    media="https://img.youtube.com/vi/QvaazAV6n60/0.jpg"

                >
                  Share on Facebook
                </ShareNetwork>
                <ShareNetwork
                    network="LinkedIn"
                    url="https://news.vuejs.org/issues/180"
                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                    quote="The hot reload is so fast it\'s near instant. - Evan You"


                >
                  Share on linkedIn
                </ShareNetwork>
                <ShareNetwork
                    network="WhatsApp"
                    url="https://harshehar.com/#/post-detail/239"
                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                    quote="The hot reload is so fast it\'s near instant. - Evan You"

                >
                  Share on whatsapp
                </ShareNetwork>-->
                <div class="custom-heading-text custom-job-detail-heading mb-4">
                  <span class=" custom-underline w-100 pb-2">
                    Category:
                  </span>
                </div>
                <div class="w3-container bg-white">
                  <div class="custom-job-detail-text">

                    <span class="custom-heading-text">{{ jobData.category }}</span>
                  </div>
                </div>

              </div>
            </div>
            <div v-if="jobData.category != 'Labour Man Power'">
              <div class="custom-heading-text custom-job-detail-heading mb-4">
                <span class=" custom-underline w-100 pb-2">
                  Job Specifications:
                </span>
              </div>
              <div class="custom-job-detail-text">

                <div class="text-justify">{{ jobData.jobSpecification }}</div>
              </div>

            </div>
            <div v-if="jobData.category != 'Graduate - Remote and Flexible Project & Internship'">
              <div class="custom-heading-text custom-job-detail-heading mb-4">
                <span class=" custom-underline w-100 pb-2">
                  Job Description:
                </span>
              </div>
              <div class="custom-job-detail-text">
                <div class="text-justify"> {{ jobData.jobDescription }}</div>
              </div>
            </div>

            <div v-if="jobData.category == 'Graduate - Remote and Flexible Project & Internship'">
              <div class="custom-heading-text custom-job-detail-heading mb-4">
                <span class=" custom-underline w-100 pb-2">
                  Project Details:
                </span>
              </div>
              <div class="custom-job-detail-text">

                <span>{{ jobData.projectDescription }}</span>
              </div>

            </div>
            <div >
              <div class="custom-heading-text custom-job-detail-heading mb-4">
              <span class=" custom-underline w-100 pb-2">
                Roles & Responsibilities:
              </span>
              </div>
              <div class="custom-job-detail-text">
                <span>{{ jobData.jobResponsibility }}</span>

              </div>

            </div>

            <div>
              <div class="custom-heading-text custom-job-detail-heading mb-4">
              <span class=" custom-underline w-100 pb-2">
                Rewards And Benefits:
              </span>
              </div>
              <div class="custom-job-detail-text">
                <span>{{ jobData.reward }}</span>

              </div>

            </div>
            <div v-if="jobData.category == 'Person with Disability'">
              <div >
                <div class="custom-heading-text custom-job-detail-heading mb-4">
              <span class=" custom-underline w-100 pb-2">
                Suitable disability as per job required:
              </span>
                </div>
                <div class="custom-job-detail-text">
                  <span>{{ jobData.disabilityType }}</span>

                </div>

              </div>
              <div >
                <div class="custom-heading-text custom-job-detail-heading mb-4">
              <span class=" custom-underline w-100 pb-2">
                Disability Certificate Obtained:
              </span>
                </div>
                <div class="custom-job-detail-text">
                  <span v-if="jobData.fitToWorkCertificate == 1">Yes</span>
                  <span v-else>No</span>

                </div>

              </div>
              <div >
                <div class="custom-heading-text custom-job-detail-heading mb-4">
              <span class=" custom-underline w-100 pb-2">
                Facilities available for PWD's on premises:
              </span>
                </div>
                <div class="custom-job-detail-text">
                  <span>{{ jobData.specialFacilityAvailable }}</span>

                </div>

              </div>
              <div >
                <div class="custom-heading-text custom-job-detail-heading mb-4">
              <span class=" custom-underline w-100 pb-2">
                Special Instructions:
              </span>
                </div>
                <div class="custom-job-detail-text">
                  <span>{{ jobData.specialInstruction }}</span>

                </div>

              </div>
            </div>

            <div>
              <div class="custom-heading-text custom-job-detail-heading mb-4">
              <span class=" custom-underline w-100 pb-2">
                Vii Learn Courses:
              </span>
              </div>
              <div class="custom-job-detail-text">
                <span>{{ jobData.vLearnCourseRequired }}</span>

              </div>

            </div>
          </div>
          <div class="col-lg-4">
            <div class="bg-light">
              <div class="pt-4 pb-4 pl-4 pr-4">
                <div class="custom-heading-text custom-job-overview-heading mb-4">
                  <span class=" custom-underline w-100 pb-2">
                    Job Overview
                  </span>
                </div>

                <div>
                  <!--                <div class="custom-row">
                                    <div class="custom-job-icons"><i class="fas fa-border-all"></i></div>
                                    <span>Department</span>
                                  </div>
                                  <div class="custom-row">
                                    <div class="custom-job-icons"></div>
                                    <span class="custom-muted-text">{{ jobData.department }}</span>
                                  </div>-->
                  <div class="custom-row">
                    <div class="custom-job-icons"><i class="far fa-calendar"></i></div>
                    <span>Date Posted</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"></div>
                    <span class="custom-muted-text">{{ jobData.postedDate | formatDate }}</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"><i class="fas fa-map-marker-alt"></i></div>
                    <span>Location</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"></div>
                    <span class="custom-muted-text">{{ jobData.cityName }}</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"><i class="fas fa-money-bill"></i></div>
                    <span>Offered Salary</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"></div>
                    <span class="custom-muted-text">Rs {{ jobData.packageFrom }} - {{ jobData.packageTo }}/Month </span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"><i class="fas fa-hourglass-end"></i></div>
                    <span>Expiration Date</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"></div>
                    <span class="custom-muted-text">{{ jobData.jobExpiryDate | formatDate }}</span>
                  </div>
                  <div>
                    <div class="custom-row">
                      <div class="custom-job-icons"><i class="fas fa-user-tie"></i></div>
                      <span>Experience</span>
                    </div>
                    <div class="custom-row">
                      <div class="custom-job-icons"></div>
                      <span class="custom-muted-text">
                        {{ jobData.experiencelevel}}
                      </span>
                    </div>
                  </div>

                  <div class="custom-row">
                    <div class="custom-job-icons"><i class="far fa-user"></i></div>
                    <span>Gender</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"></div>
                    <span class="custom-muted-text" >

                      {{ jobData.gender }}

                  </span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"><i class="fas fa-sitemap"></i></div>
                    <span>Degree Level</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"></div>
                    <span class="custom-muted-text">
                   {{ jobData.degreeLevels }}
                  </span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"><i class="fas fa-sitemap"></i></div>
                    <span>Career Level</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"></div>
                    <span class="custom-muted-text">
                    {{ jobData.careerLevel }}
                  </span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"><i class="fab fa-telegram-plane"></i></div>
                    <span>Traveling Required</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"></div>
                    <span class="custom-muted-text">
                    <span v-if="jobData.jobRequireTraveling === true">Yes</span>
                    <span v-else-if="jobData.jobRequireTraveling === false">No</span>
                    <span v-else>--</span>

                  </span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"><i class="far fa-building"></i></div>
                    <span>Industry</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"></div>
                    <span class="custom-muted-text">
                    {{ jobData.industry }}
                  </span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"><i class="far fa-clock"></i></div>
                    <span>Shift</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"></div>
                    <span class="custom-muted-text">{{ jobData.shift }}</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"><i class="fas fa-grip-horizontal"></i></div>
                    <span>Total Vacancies</span>
                  </div>
                  <div class="custom-row">
                    <div class="custom-job-icons"></div>
                    <span class="custom-muted-text">{{ jobData.noOfPosition }}</span>
                  </div>

                </div>

              </div>
            </div>

            <div class="bg-light mt-2">
              <div class="pt-4 pb-4 pl-4 pr-4">
                <div class="custom-heading-text custom-job-overview-heading mb-4">
                  <span class="custom-underline w-100 pb-2">
                    Skills:
                  </span>
                </div>
                <div class="w3-container bg-white">
                  <div class="custom-job-detail-text">

                    <span class="custom-heading-text">{{ jobData.skill }}</span>
                  </div>
                </div>
              </div>

            </div>


          </div>
        </div>

      </div>

    </div>
    <!--  Job Detail Section End   -->

    <CPModal v-if="isModalVisible" :save-job-status="isSaveJobStatus" :signInOrSignUpSection="isSignInOrSignUpSection" :employeeProfileFormSection="isEmployeeProfileFormSection" :show-alert-msg="isShowAlertMsg" :show-profile-info-card="isShowProfileInfoCard" :apply-job-confirmation-section="isApplyJobConfirmationSection" :selected-job-record="jobRecord" @get-strength="recievedStrength"  @toggle-modal="toggleModal">

    </CPModal>

  </div>

</template>

<script>

import Vue from 'vue'
import axios from "axios";
import CPModal from "../../components/employeeComponents/CompleteProfileModalComponent"

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

export default {
  name:"JobDetail",
  components:{
    CPModal
  },

  data(){
    return{
      url:axios.defaults.baseURL,
      whatsAppUrl:"https://wa.me/?text=",
      // linkedInUrl:"https://www.linkedin.com/sharing/share-offsite/?url=",
      linkedInUrl:"http://www.linkedin.com/shareArticle?mini=true&url=",
     // http://www.linkedin.com/shareArticle?mini=true&url=&title=Web%20Application%20Developer
      domainUrl:"https://shemeanswork.com",

      fullJobUrl:"",
      jobUrl:this.$route.fullPath,
      jobData:[],
      loading: true,
      errored: false,

      rowError: false,
      rowid: 1,

      //Job Data
      /*jobData:{

        assistedHiringType: 0,
        cityId: 0,
        cityName: "",
        companyId: 0,
        companyLogoAddress: "",
        companyName: "",
        countryId: 0,
        countryName: "",
        degreeLevelId: 0,
        degreeLevelTitle: "",
        disabilityTypeId: 0,
        disabilityTypeTitle:  "",
        estimatedDurationInMonth: 0,
        insertionDate: "",
        isSignLanguageRequrired: 0,
        jobDescription:  "",
        jobExpiryDate:  "",
        jobHiringType: 0,
        jobId:0,
        jobSubTypeId: 0,
        jobSubTypeTitle:  "",
        jobTitle:  "",
        jobTypeDetailId: 0,
        jobTypeDetailTitle:  "",
        jobResponsibility:"",
        jobTypeId: 0,
        category:  "",
        noOfJobs: 0,
        packageFrom: 0,
        packageTo: 0,
        preference: 0,
        projectDescription:  "",
        projectTitle:  "",
        requiredJobExperience: 0,
        travelMiles: 0,
        travelTimePercent: 0,
        weeklyWorkingHour: 0,
        workLocation: 0,
      },*/

      setCompanyId:0,

      degreeLevels:null,
      selectedGenderType:0,
      genderType:[

        { value: 1, text: "Male" },
        { value: 2, text: "Female" },
        { value: 3, text: "Other" },
      ],

      //Apply job
      job:{

        jobId: 0,
        employeeId: 0,
        expectedSalary: 0,
        status: 1,
        isApplicantViewed: false
      },

      saveJob:{
        saveJobId: 0,
        jobId: 0,
        employeeId: 0,
        isActive: true

      },
      isApplyNow_btn:true,

      //Complete Profile Modal
      jobID:0,
      jobRecord:null,
      jobsStatuses:null,
      employeelist:null,
      employeeId:0,
      employeeSkill:null,
      employeeExperience:null,
      employeeEducation:null,
      profileStrength:0,


      isSaveJobStatus:false,
      isModalVisible:false,
      isSignInOrSignUpSection:false,
      isEmployeeProfileFormSection:false,
      isShowAlertMsg:false,
      isShowProfileInfoCard:false,
      isApplyJobConfirmationSection:false,
      modalTitle:"",
      showAlertMsg:false,
      modalDescription:"",
      componentKey:0,
    };
  },
  mounted() {
    this.getJobDetail();
    this.getDegreeLevel();
    this.getAllEmployees();
  },
  methods:{
    //shows detail per record
    getJobDetail() {

      console.log("jobUrl : ",this.jobUrl)
      this.fullJobUrl = encodeURIComponent(this.domainUrl+'/#'+this.jobUrl);
      console.log("encUrl : ",this.fullJobUrl)



      this.rowid = this.$route.params.id;
      console.log("params : ",this.$route.fullPath);
      console.log("rowid : ",this.rowid);
      axios
          .get("/api/Job/Get?jobId="+this.rowid)
          .then((response) => {
            this.jobData = response.data.data;
            console.log("one", this.jobData);
            this.setCompanyId= this.jobData.companyId;
            console.log("setCompanyId : ",this.setCompanyId);
            /*this.jobData.assistedHiringType = this.info.assistedHiringType;
            this.jobData.cityId = this.info.cityId;
            this.jobData.cityName = this.info.cityName;
            console.log("this.jobData.cityName : ",this.info)
            this.jobData.companyId = this.info.companyId;
            this.setCompanyId=this.info.companyId;
            console.log("setCompanyId : ",this.setCompanyId);

            this.jobData.companyName = this.info.companyName;
            this.jobData.companyLogoAddress = this.info.companyLogoAddress;
            this.jobData.countryId = this.info.countryId;
            this.jobData.countryName = this.info.countryName;
            this.jobData.degreeLevelId = this.info.degreeLevelId;
            this.jobData.degreeLevelTitle = this.info.degreeLevelTitle;
            this.jobData.disabilityTypeId = this.info.disabilityTypeId;
            this.jobData.disabilityTypeTitle = this.info.disabilityTypeTitle;
            this.jobData.estimatedDurationInMonth = this.info.estimatedDurationInMonth;
            this.jobData.insertionDate = this.info.insertionDate;
            this.jobData.isSignLanguageRequrired = this.info.isSignLanguageRequrired;
            this.jobData.jobDescription = this.info.jobDescription;
            this.jobData.jobExpiryDate = this.info.jobExpiryDate;
            this.jobData.jobHiringType = this.info.jobHiringType;
            this.jobData.jobId = this.info.jobId;
            this.jobData.jobSubTypeId = this.info.jobSubTypeId;
            this.jobData.jobSubTypeTitle = this.info.jobSubTypeTitle;
            this.jobData.jobTitle = this.info.jobTitle;
            this.jobData.jobTypeDetailId = this.info.jobTypeDetailId;
            this.jobData.jobTypeDetailTitle = this.info.jobTypeDetailTitle;
            this.jobData.jobTypeId = this.info.jobTypeId;
            this.jobData.category = this.info.category;
            this.jobData.noOfJobs = this.info.noOfJobs;
            this.jobData.packageFrom = this.info.packageFrom;
            this.jobData.packageTo = this.info.packageTo;
            this.jobData.preference = this.info.preference;
            this.jobData.projectDescription = this.info.projectDescription;
            this.jobData.projectTitle = this.info.projectTitle;
            this.jobData.requiredJobExperience = this.info.requiredJobExperience;
            this.jobData.travelMiles = this.info.travelMiles;
            this.jobData.travelTimePercent = this.info.travelTimePercent;
            this.jobData.weeklyWorkingHour = this.info.weeklyWorkingHour;
            this.jobData.workLocation = this.info.workLocation;*/



          })
          .catch((error) => {
            console.log(error);
            this.$bvToast.toast("No Data Found", {
              toaster: "b-toaster-top-center",
              variant: "primary",

              solid: true,
            });
          })
          .finally(() =>{
            this.loading=false;
          });
    },
    getDegreeLevel() {
      this.degreeLevels = null;
      axios.get('/api/DegreeLevel/GetAll')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.degreeLevels = res.data.data;
            console.log("degreeLevel : ", this.degreeLevels);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },

    getAllEmployees(){
      this.rerender();
      //this.profileStrength = 0;
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }



            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);


            this.employeeId = this.employeelist.employeeId;
            this.job.expectedSalary = this.employeelist.expectedSalary;

            this.employeeSkill = res.data.eMPSkills;
            console.log("employeeSkill : ", this.employeeSkill);
            this.employeeExperience = res.data.eMPExperiences;



            this.employeeEducation = res.data.empEducations;
            console.log("employeeEducation : ", this.employeeEducation);


            this.CalculatePercentage();
            //this.appliedJobsStatus();

            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
    postCompanyId(cId){
      this.$router.push(
          { name: 'AllJobs', params:{cId} })
      console.log("companyId: ", cId)
      // this.$store.dispatch("getJobsByCompanyId", cId);
    },
    showOrHideModal(jobrecord){
      this.jobRecord = jobrecord;
      if(this.$auth.check()){
        console.log("chek : ",this.$auth.check());
        //this.$router.push({ name: "JobDetail" });




        if(this.profileStrength > 80){
          console.log("total strength inside if statement after Employee Skill section: ", this.profileStrength)

          this.isModalVisible = true;

          this.isSignInOrSignUpSection = false;
          this.isEmployeeProfileFormSection = true;
          this.isShowAlertMsg = false;
          this.isShowProfileInfoCard = true;
          this.isApplyJobConfirmationSection = true;
          console.log("job strength inside if statement: ",this.profileStrength)
          console.log("jobRecord inside if statement: ",this.jobRecord)
        }
        else{
          this.$bvToast.toast("Your profile Strength Must be Over 80%, Please Update your Profile", {
            toaster: "b-toaster-top-center",
            variant: "warning",

            solid: true
          });

          this.isModalVisible = true;
          console.log("job strength inside else statement: ",this.profileStrength)

          this.isSignInOrSignUpSection = false;
          this.isEmployeeProfileFormSection = true;
          this.isShowAlertMsg = true;
          this.isShowProfileInfoCard = false;
          this.isApplyJobConfirmationSection = false;



        }


      }
      else{
        console.log("chek : ",this.$auth.check());
        this.isModalVisible = true;
        this.isEmployeeProfileFormSection = false;
        //this.modalTitle = "Attention";
        this.isSignInOrSignUpSection=true;
        this.isShowAlertMsg = false;
        this.isShowProfileInfoCard = false;
        this.isApplyJobConfirmationSection = false;
        //this.modalDescription = "Please Register/Login Before Applying a Job"
      }
    },

    onSavingJob(jobrecord){
      this.jobRecord = jobrecord;
      if(this.$auth.check()){
        this.saveJob.jobId = Number(jobrecord.jobId);
        this.saveJob.employeeId = Number(this.employeeId);
        console.log("jobId + Emp ID : ", this.saveJob.jobId + this.saveJob.employeeId)
        axios
            .post("/api/Job/SaveJob", this.saveJob)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info.msg);
            })
            .catch(error => {
              console.log("Errorrrrrr : ",error);
              this.errored = true;
            })
            .finally(() => {


              if(this.info.msg == "Job Already Save"){
                this.$bvToast.toast("You have Saved to the Job Already. ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
                });
              }
              else {
                this.$bvToast.toast("Job Saved Successfully", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });
              }
              this.getAll();
              this.getAllEmployees();


            });
      }
      else {
        this.$bvToast.toast("Please Sign Up / Sign In, Before Saving a job", {
          toaster: "b-toaster-top-center",
          variant: "warning",

          solid: true
        });
        setTimeout(this.showSignUpOrSignInModal(),1000)
      }

    },
    showSignUpOrSignInModal(){
      this.isModalVisible = true;
      this.isSaveJobStatus = true;
      this.isEmployeeProfileFormSection = false;

      this.isSignInOrSignUpSection=true;
      this.isShowAlertMsg = false;
      this.isShowProfileInfoCard = false;
      this.isApplyJobConfirmationSection = false;
    },

    toggleModal(){
      this.isModalVisible = !this.isModalVisible;
      this.getAllEmployees();
    },



    recievedStrength(value){
      this.profileStrength = value;
      console.log("On Home Page: ", this.profileStrength);

    },
    //Rerender Component
    rerender() {
      this.componentKey += 1;
    },
    CalculatePercentage() {

      //Employee

      let profilePercentUponSignUp = 20;
      let profilePercent = 30;
      let expPercent = 20;
      let eduPercent = 20;
      let skillPercent = 10;

      let percent = 0;

      if(this.employeelist != {}){
        if((this.employeelist.fullName != "" || this.employeelist.fullName != null) && (this.employeelist.mobileNumber != "" || this.employeelist.mobileNumber != null)){
          percent += profilePercentUponSignUp;
          console.log("percent in signup : ", percent)
        }

        this.profileStrength = Math.ceil(percent);
        console.log("total after Employee section : ", this.profileStrength);



        if (this.employeelist.fatherName != "" && this.employeelist.fatherName != null && this.employeelist.cnic != "" && this.employeelist.dateofBirth != null && this.employeelist.gender != 0 && this.employeelist.gender != null) {
          percent += profilePercent;

          console.log("percent in Profile : ", percent)
        }
        this.profileStrength = Math.ceil(percent);
        console.log("total after Employee section : ", this.profileStrength);



      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee section : ", this.profileStrength);




      if(this.employeeExperience.length != 0){
        percent += expPercent;

      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee Experience section : ", this.profileStrength);





      if(this.employeeEducation.length != 0){
        percent += eduPercent;

      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee Education section : ", this.profileStrength);





      if(this.employeeSkill.length != 0){
        percent += skillPercent;

      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee Skill section : ", percent);


    },
  }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'

import axios from "axios";
import moment from "moment";
import _ from "underscore";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    searchString:"",
    jobList:[],
      countries:[],
      cities:[],
      adds:[],
      topbarAdd:[],
      homeAfterSliderAdd:[],
      homeBeforeFooterAdd:[],
      sMWCoursesAdd:[],
      employerDashboardLeftSide:[],
      employerDashboardRightSide:[],
      employerDashboardCenter:[],
      categories:[],
      jobTypes:[],
      experiences:[],
      languages:[],
      filteredCities:[
          { key: 716, value: "Lahore"},
          { key: 743, value: "Islamabad"},
          { key: 687, value: "Rawalpindi"},
          { key: 729, value: "Karachi"},
          { key: 751, value: "Faisalabad"},
          { key: 749, value: "Gujranwala"},
          { key: 696, value: "Peshawar"},
          { key: 709, value: "Multan"}
      ],
      degreeLevels:[],

  },
  mutations: {
    /*setSearchString(state, searchString){
      state.searchString = searchString
    },*/
    setJobs(state, list){
      state.jobList = list.slice().reverse();
    },
      setCountries(state, countries){
          state.countries = countries;
      },
      setCities(state, cities){
          state.cities = cities;
      },
      setAdds(state, adds){
        state.adds = adds;
      },
      setCategories(state, categories){
          state.categories = categories;
      },
      setJobTypes(state, jobTypes){
          state.jobTypes = jobTypes;
      },
      setExperiences(state, experiences){
          state.experiences = experiences;
      },
      setLanguages(state, languages){
          state.languages = languages;
      },
      /*setFilteredCities(state, filteredCities){
          state.filteredCities = filteredCities;
      },*/
      setDegreeLevels(state, degreeLevels){
          state.degreeLevels = degreeLevels;
      },
  },
  actions: {

    getAll(context){
      //this.rerender();
      let list = [];


      axios.get('/api/Job/GetAllHecancyjobsOfEmployeer')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/

            let allJobList = res.data.data;
            let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
            for(let job in allJobList){
              if(allJobList[job].status == "Approved" && moment(String(allJobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                list.push(allJobList[job]);
              }
            }

            console.log("get all list : ", list);




            context.commit("setJobs", list)

            /* for(let i in this.list){
               this.insertionDates[i] = this.list[i].insertionDate;

             }
             console.log("insertionDate : ", this.insertionDates);
             for(let t in this.insertionDates){
               var a = moment(this.currentDate);
               var b = moment(this.insertionDates[t]);
               this.dateDifferences[t] = a.diff(b,'days');
             }*/


            //console.log("dateDifferences : ", this.dateDifferences);
            /*console.log("duplicate items : ", _.keys(_.countBy(this.list, function(data) { return data.jobTypeTitle; })));
            console.log("count : ",_.countBy(this.list, function(data) { return data.jobTypeTitle; }))*/


            /*const obj1 = _.keys(_.countBy(this.list, function(data) { return data.jobTypeTitle; }));
            this.categories = Object.entries(obj1).map(([key, value]) => ({id : Number(key), name : value}));
*/
            /*let catList = [];
            this.list.forEach((item, index) => {
              const scate = {
                id: parseInt(item.jobTypeId),
                catName: item.jobTypeTitle
              };
              catList.push(scate);
            });
            console.log("this.categories : ",catList);*/






            /*const obj2 = _.countBy(this.list, function(data) { return data.jobTypeId; });


            this.categoriesCount = Object.entries(obj2).map(([key, value]) => ({id : Number(key), text: String(value)}));*/


            //console.log("this.categoriesCount : ",this.categoriesCount);
             /* context.dispatch("getCategories", list)
              context.dispatch("getJobTypes", list)
              context.dispatch("getExperiences", list)
              context.dispatch("getFilteredCities", list)*/

            /*if(this.$route.params.searchString != "list"){
              console.log("jobTitle : ",this.$route.params.searchString)
              console.log("selectedCity : ",this.$route.params.selectedCity)
              this.jobSearchByNameOrCity.jobTitle = "";
              this.jobSearchByNameOrCity.cityId = null;
              this.jobSearchByNameOrCity.jobTitle = this.$route.params.searchString;
              this.jobSearchByNameOrCity.cityId = (this.$route.params.selectedCity == 0 || this.$route.params.selectedCity == null) ? null : Number(this.$route.params.selectedCity);
              this.getJobsByNameAndCityOnHomePage();
            }


            console.log("this.$route.params : ", this.$route.params);

            if (this.$route.params.setCompanyId != undefined){
              this.getJobsByCompanyId(this.list);
            }*/



            //this.appliedJobsStatus();

            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },


    getJobsByNameAndCityId(context, searchFilter){
        console.log("search Filter : ", searchFilter)
      //this.rerender();
      let list = [];
      if(searchFilter != {}){
        let model = {
          jobTitle : searchFilter.jobTitle,
          cityId : searchFilter.cityId
        }




        axios
            .post("/api/Job/GetJobsSearch", model)
            .then(res => {
              // code that we will 'try' to run

              let jobList = res.data.data;
              let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
              for(let job in jobList){
                if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                  list.push(jobList[job]);
                }
              }


              console.log("Add Response portion");

              console.log("response : ", list);
              context.commit("setJobs", list)
             /* this.getCategories(this.list);
              this.getJobTypes(this.list);
              this.getExperiences(this.list);
              this.getFilteredCities(this.list);*/
              //this.getDegreeLevel(this.list);

              if(list.length == 0){
                this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
                });
              }

            })
            .catch(error => {
              console.log("Errorrrrrr : ",error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {

                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {

                /*this.$bvToast.toast("Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });*/


              }
            });
      }
      else {

        /*this.$route.params.jobTitle = undefined;
        this.$route.params.selectedCity = undefined;
        this.getAll();*/
      }
    },

    /*fetchSearchString(context, searchStirng){
      console.log("searchString in store", searchStirng);
      if(searchStirng != ""){
        context.commit("setSearchString", searchStirng);
      }else {
        console.log("No Search String Entered")
      }
    },*/

      //Get Dropdowns List
      getCountryList(context){
          let countries = [];
          let rawCountries = [];
          let model = {
              type: "Country"
          };

          axios
              .post("/api/KeyValuePair/GetDropDownListByIds", model)
              .then(res => {
                  // code that we will 'try' to run
                  rawCountries = res.data.data;

                  console.log("Add Response portion");
                  countries = _.sortBy(rawCountries, 'value');
                  context.commit("setCountries", countries)
                  console.log("cities : ", countries);
              })
              .catch(error => {
                  console.log("Errorrrrrr : ",error);
                  this.errored = true;
              })
              .finally(() => {
                  if (this.errored) {

                      console.log("Error : Sorry there is no response");
                      // this.$bvToast.toast("Sorry there is no response ", {
                      //   toaster: "b-toaster-top-center",
                      //   variant: "danger",

                      //   solid: true,
                      // });
                  } else {






                  }
              });
      },
      getCityList(context){
          let cities = [];
          let rawCities = [];
          let model = {
              type: "City"
          };

          axios
              .post("/api/KeyValuePair/GetDropDownListByIds", model)
              .then(res => {
                  // code that we will 'try' to run
                  rawCities = res.data.data;

                  console.log("Add Response portion");
                  cities = _.sortBy(rawCities, 'value');
                  context.commit("setCities", cities)
                  console.log("cities : ", cities);
              })
              .catch(error => {
                  console.log("Errorrrrrr : ",error);
                  this.errored = true;
              })
              .finally(() => {
                  if (this.errored) {

                      console.log("Error : Sorry there is no response");
                      // this.$bvToast.toast("Sorry there is no response ", {
                      //   toaster: "b-toaster-top-center",
                      //   variant: "danger",

                      //   solid: true,
                      // });
                  } else {






                  }
              });
      },

      getCategories(context) {



          let categories = [];
          var rawCategories = [];
          let model = {
              type: "Category"
          };

          axios
              .post("/api/KeyValuePair/GetDropDownListByIds", model)
              .then(res => {
                  // code that we will 'try' to run
                  rawCategories = res.data.data;

                  console.log("Add Response portion");
                  // categories = _.sortBy(rawCategories, 'value');
                  context.commit("setCategories", rawCategories)
                  console.log("this.categories in getall : ", rawCategories);
              })
              .catch(error => {
                  console.log("Errorrrrrr : ",error);
                  this.errored = true;
              })
              .finally(() => {
                  if (this.errored) {

                      console.log("Error : Sorry there is no response");
                      // this.$bvToast.toast("Sorry there is no response ", {
                      //   toaster: "b-toaster-top-center",
                      //   variant: "danger",

                      //   solid: true,
                      // });
                  } else {






                  }
              });
          /* const map = new Map();
          //  New
          for (const item of jobRecord) {
              if(!map.has(item.categoryId)){
                  map.set(item.categoryId, true);    // set any value to Map
                  categories.push({
                      id: item.categoryId,
                      name: item.category
                  });
              }
          }*/




      },
      getJobTypes(context) {


          let jobTypes = [];
          let model = {
              type: "JobType"
          };

          axios
              .post("/api/KeyValuePair/GetDropDownListByIds", model)
              .then(res => {
                  // code that we will 'try' to run
                  jobTypes = res.data.data;

                  console.log("Add Response portion");

                  context.commit("setJobTypes", jobTypes)
                  console.log("this.jobTypes in getall : ", jobTypes);
              })
              .catch(error => {
                  console.log("Errorrrrrr : ",error);
                  this.errored = true;
              })
              .finally(() => {
                  if (this.errored) {

                      console.log("Error : Sorry there is no response");
                      // this.$bvToast.toast("Sorry there is no response ", {
                      //   toaster: "b-toaster-top-center",
                      //   variant: "danger",

                      //   solid: true,
                      // });
                  } else {






                  }
              });
          /*const map = new Map();
          // New
          for (const item of jobRecord) {
              if(!map.has(item.jobTypeId)){
                  map.set(item.jobTypeId, true);    // set any value to Map
                  jobTypes.push({
                      jstid: item.jobTypeId,
                      name: item.jobTypeTitle
                  });
              }
          }*/


      },
      getExperiences(context) {
          let filteredExperiences = [];
          var rawExperience = [];
          let model = {
              type: "ExperienceLevel"
          };

          axios
              .post("/api/KeyValuePair/GetDropDownListByIds", model)
              .then(res => {
                  // code that we will 'try' to run
                  rawExperience = res.data.data;

                  console.log("Add Response portion");

                  //filteredExperiences = _.sortBy(rawExperience, 'value');
                  context.commit("setExperiences", rawExperience)
                  console.log("this.filteredExperiences in getall : ", rawExperience);

              })
              .catch(error => {
                  console.log("Errorrrrrr : ",error);
                  this.errored = true;
              })
              .finally(() => {
                  if (this.errored) {

                      console.log("Error : Sorry there is no response");
                      // this.$bvToast.toast("Sorry there is no response ", {
                      //   toaster: "b-toaster-top-center",
                      //   variant: "danger",

                      //   solid: true,
                      // });
                  } else {






                  }
              });
          /*const map = new Map();
          //New
          for (const item of jobRecord) {
              if(!map.has(item.experienceRequiredId)){
                  map.set(item.experienceRequiredId, true);    // set any value to Map
                  rawExperience.push({
                      id: Number(item.experienceRequiredId),
                      name: item.experienceRequired
                  });
              }
          }*/



      },
      getLanguageList(context){
          let languages = [];
          let rawLanguages = [];
          let langs = [];
          let model = {
              type: "Languages"
          };

          axios
              .post("/api/KeyValuePair/GetDropDownListByIds", model)
              .then(res => {
                  // code that we will 'try' to run

                  rawLanguages = _.sortBy(res.data.data, 'value');

                  for (let lang in rawLanguages) {

                      languages.push(rawLanguages[lang].value);

                  }
                  console.log("Add Response portion");


                  context.commit("setLanguages", languages)
                  console.log("languages : ", languages);
              })
              .catch(error => {
                  console.log("Errorrrrrr : ",error);
                  this.errored = true;
              })
              .finally(() => {
                  if (this.errored) {

                      console.log("Error : Sorry there is no response");
                      // this.$bvToast.toast("Sorry there is no response ", {
                      //   toaster: "b-toaster-top-center",
                      //   variant: "danger",

                      //   solid: true,
                      // });
                  } else {






                  }
              });
      },
      /*getFilteredCities(context) {

          let filteredCities = [];
          const map = new Map();
          for (const item of jobRecord) {
              if(!map.has(item.cityId)){
                  map.set(item.cityId, true);    // set any value to Map
                  filteredCities.push({
                      cid: item.cityId,
                      name: item.cityName
                  });
              }
          }

          context.commit("setFilteredCities", jobTypes)
          console.log("this.filteredCities in getall : ", filteredCities);


      },*/

      getDegreeLevel(context) {

          let degreeLevels = [];
          let model = {
              type: "DegreeLevel"
          };

          axios
              .post("/api/KeyValuePair/GetDropDownListByIds", model)
              .then(res => {
                  // code that we will 'try' to run
                  degreeLevels = res.data.data;

                  console.log("Add Response portion");

                  context.commit("setDegreeLevels", degreeLevels)
                  console.log("this.degreeLevels in getall : ", degreeLevels);
              })
              .catch(error => {
                  console.log("Errorrrrrr : ",error);
                  this.errored = true;
              })
              .finally(() => {
                  if (this.errored) {

                      console.log("Error : Sorry there is no response");
                      // this.$bvToast.toast("Sorry there is no response ", {
                      //   toaster: "b-toaster-top-center",
                      //   variant: "danger",

                      //   solid: true,
                      // });
                  } else {






                  }
              });
          /*const map = new Map();
          for (const item of jobRecord) {
              if(!map.has(item.degreeLevelId)){
                  map.set(item.degreeLevelId, true);    // set any value to Map
                  degreeLevels.push({
                      dLid: item.degreeLevelId,
                      name: item.degreeLevelTitle
                  });
              }
          }*/

      },
      getJobsByFilters(context, filter){
          //this.rerender();
          let list = [];
              console.log("filter : ", filter);

              if(filter != {}){
                  // filter.isOthersSelected = !filter.isOthersSelected;
                  let model = {
                      jobTypes: filter.JobTypes,
                      degreelevels: filter.DegreeLevels,
                      jobSubTypes: filter.JobSubTypes,
                      cities: filter.Cities,
                      empExperiences: filter.EmpExperiences,
                      isOthersSelected: filter.isOthersSelected
                  };
                  console.log("model : ", model);
                  axios
                      .post("/api/Job/JobFilter", model)
                      .then(res => {
                          // code that we will 'try' to run


                          let jobList = res.data.data;
                          let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
                          for(let job in jobList){
                              if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                                  list.push(jobList[job]);
                              }
                          }

                          console.log("Add Response portion");

                          console.log("response : ", list);
                          context.commit("setJobs", list);
                          if(list.length == 0){
                              this.$bvToast.toast("No Record Found ", {
                                  toaster: "b-toaster-top-center",
                                  variant: "warning",

                                  solid: true
                              });
                          }

                      })
                      .catch(error => {
                          console.log("Errorrrrrr : ",error);
                          this.errored = true;
                      })
                      .finally(() => {
                          if (this.errored) {

                              console.log("Error : Sorry there is no response");
                              // this.$bvToast.toast("Sorry there is no response ", {
                              //   toaster: "b-toaster-top-center",
                              //   variant: "danger",

                              //   solid: true,
                              // });
                          } else {

                              /*this.$bvToast.toast("Updated Successfully ", {
                                toaster: "b-toaster-top-center",
                                variant: "success",

                                solid: true
                              });*/


                          }
                      });
              }


      },


      getJobsByCompanyId(context, companyId){

          let list = [];


          console.log("company Id In store : ", companyId)
          axios.get('/api/Job/GetAllHecancyjobsOfEmployeer')
              .then(res =>{
                  if (res.status != '200') {
                      console.log('error.....')
                      const error = new Error('Failed to fetch!');
                      return error;
                  }


                  let jobRecord = res.data.data;

                  console.log("jobRecord in company : ", jobRecord)


                  let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
                  console.log("currentDate Format : ",currentDate)
                  for (let data in jobRecord){

                      console.log("expired date: ", moment(String(jobRecord[data].jobExpiryDate)).format('YYYY-MM-DD') ,": current date : ", currentDate)
                      if((jobRecord[data].companyId == companyId && jobRecord[data].status == "Approved") && moment(String(jobRecord[data].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                          /*this.list[data] = jobRecord [data];*/
                          list.push(jobRecord [data]);
                      }

                  }
                  console.log("this.list in company : ",list);

                  context.commit("setJobs", list)


              }).catch(error => {
              console.log(error);
              this.errored = true;
          })
              .finally(() => {
                  if (this.errored) {

                      console.log("Error : Sorry there is no response");
                      // this.$bvToast.toast("Sorry there is no response ", {
                      //   toaster: "b-toaster-top-center",
                      //   variant: "danger",

                      //   solid: true,
                      // });
                  }
              });

      },

      fetchAdds(context){
          let adds = [];

              let addsModel = {
                  "pageSize": 0,
                  "pageNumber": 1,
                  "totalRecord": 0,
                  "text": ""
              }

              axios
                  .post("/api/Adds/GetAdd", addsModel)
                  .then(res => {
                      // code that we will 'try' to run

                      let jobList = res.data.data;
                      adds = jobList
                     /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
                      for(let job in jobList){
                          if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                              adds.push(jobList[job]);
                          }
                      }*/


                      console.log("Add Response portion");

                      console.log("response : ", adds);
                      context.commit("setAdds", adds)
                      /* this.getCategories(this.list);
                       this.getJobTypes(this.list);
                       this.getExperiences(this.list);
                       this.getFilteredCities(this.list);*/
                      //this.getDegreeLevel(this.list);

                      /*if(adds.length == 0){
                          this.$bvToast.toast("No Record Found ", {
                              toaster: "b-toaster-top-center",
                              variant: "warning",

                              solid: true
                          });
                      }*/

                  })
                  .catch(error => {
                      console.log("Errorrrrrr : ",error);
                      this.errored = true;
                  })
                  .finally(() => {
                      if (this.errored) {

                          console.log("Error : Sorry there is no response");
                          // this.$bvToast.toast("Sorry there is no response ", {
                          //   toaster: "b-toaster-top-center",
                          //   variant: "danger",

                          //   solid: true,
                          // });
                      } else {

                          /*this.$bvToast.toast("Updated Successfully ", {
                            toaster: "b-toaster-top-center",
                            variant: "success",

                            solid: true
                          });*/


                      }
                  });


      }


  },
  getters:{

    showAllJobs(state, getters){
      if(state.jobList != []){
        return state.jobList;
      }

    },
      availableCities(state, getters){
        return state.cities;
      },
      availableCategories(state, getters){
          /*let categories = [];
          const map = new Map();
          //  New
          for (const item of state.jobList) {
              if(!map.has(item.categoryId)){
                  map.set(item.categoryId, true);    // set any value to Map
                  categories.push({
                      id: item.categoryId,
                      name: item.category
                  });
              }
          }*/
          console.log("this.categories in getall : ", state.categories);
          return state.categories
      },
      availableJobTypes(state, getters){
          /*let jobTypes = [];
          const map = new Map();
          // New
          for (const item of state.jobList) {
              if(!map.has(item.jobTypeId)){
                  map.set(item.jobTypeId, true);    // set any value to Map
                  jobTypes.push({
                      jstid: item.jobTypeId,
                      name: item.jobTypeTitle
                  });
              }
          }*/
          console.log("this.jobTypes in getall : ", state.jobTypes);
          return state.jobTypes
      },
      availableExperiences(state, getters){
          /*let filteredExperiences = [];
          var rawExperience = [];
          const map = new Map();
          //New
          for (const item of state.jobList) {
              if(!map.has(item.experienceRequiredId)){
                  map.set(item.experienceRequiredId, true);    // set any value to Map
                  rawExperience.push({
                      id: Number(item.experienceRequiredId),
                      name: item.experienceRequired
                  });
              }
          }
          filteredExperiences = _.sortBy(rawExperience, 'name');
*/
          console.log("this.filteredExperiences in getall : ", state.experiences);
          // return filteredExperiences.filter(exp => exp.id > 0)
          return state.experiences;
      },
      availableFilteredCities(state, getters){
          /*let filteredCities = [];
          const map = new Map();
          for (const item of state.jobList) {
              if(!map.has(item.cityId)){
                  map.set(item.cityId, true);    // set any value to Map
                  filteredCities.push({
                      cid: item.cityId,
                      name: item.cityName
                  });
              }
          }*/

          console.log("this.filteredCities in getall : ", state.filteredCities);
          return state.filteredCities
      },
      availableDegreeLevels(state, getters){
          /*let filteredCities = [];
          const map = new Map();
          for (const item of state.jobList) {
              if(!map.has(item.cityId)){
                  map.set(item.cityId, true);    // set any value to Map
                  filteredCities.push({
                      cid: item.cityId,
                      name: item.cityName
                  });
              }
          }*/

          console.log("this.filteredCities in getall : ", state.degreeLevels);
          return state.degreeLevels
      },

      //    available adds
      availableTopBarAdds(state, getters){
          let topbarAdd = []


              for (const item of state.adds) {
                  if(item.title == "JobPortal TopBar"){
                      topbarAdd.push({
                          id : item.addsId,
                          title : item.title,
                          image : item.addImage,
                          url: item.bannerUrl
                      });
                  }
              }



          return topbarAdd.filter(item => item.id > 0)
      },
      availableHomeAfterSliderAdd(state, getters){
          let homeAfterSliderAdd = []


          for (const item of state.adds) {
              if(item.title == "HomePage After Slider"){
                  homeAfterSliderAdd.push({
                      id : item.addsId,
                      title : item.title,
                      image : item.addImage,
                      url: item.bannerUrl
                  });
              }
          }



          return homeAfterSliderAdd.filter(item => item.id > 0)
      },
      availableHomeUpSkillVlearnAdd(state, getters){
          let homeUpSkillVlearnAdd = []


          for (const item of state.adds) {
              if(item.title == "JobPortal/UPSkill with Vii Learn"){
                  homeUpSkillVlearnAdd.push({
                      id : item.addsId,
                      title : item.title,
                      image : item.addImage,
                      url: item.bannerUrl
                  });
              }
          }



          return homeUpSkillVlearnAdd.filter(item => item.id > 0)
      },
      availableHomeBeforeFooterAdd(state, getters){
          let homeBeforeFooterAdd = []


          for (const item of state.adds) {
              if(item.title == "Home Page Before Footer"){
                  homeBeforeFooterAdd.push({
                      id : item.addsId,
                      title : item.title,
                      image : item.addImage,
                      url: item.bannerUrl
                  });
              }
          }



          return homeBeforeFooterAdd.filter(item => item.id > 0)
      },
      availableSMWCoursesAdd(state, getters){
          let sMWCoursesAdd = []


          for (const item of state.adds) {
              if(item.title == "SMW Courses Add"){
                  sMWCoursesAdd.push({
                      id : item.addsId,
                      title : item.title,
                      image : item.addImage,
                      url: item.bannerUrl
                  });
              }
          }



          return sMWCoursesAdd.filter(item => item.id > 0)
      },
      availableEmployeeDashboardCenterAdd(state, getters){
          let sMWCoursesAdd = []


          for (const item of state.adds) {
              if(item.title == "JobPortal/EmployeeDashboardCenter"){
                  sMWCoursesAdd.push({
                      id : item.addsId,
                      title : item.title,
                      image : item.addImage,
                      url: item.bannerUrl
                  });
              }
          }



          return sMWCoursesAdd.filter(item => item.id > 0)
      },
      availableEmployerDashboardLeftSide(state, getters){
          let employerDashboardLeftSide = []


          for (const item of state.adds) {
              if(item.title == "Employer Dashboard Left Side"){
                  employerDashboardLeftSide.push({
                      id : item.addsId,
                      title : item.title,
                      image : item.addImage,
                      url: item.bannerUrl
                  });
              }
          }



          return employerDashboardLeftSide.filter(item => item.id > 0)
      },
      availableEmployerDashboardRightSide(state, getters){
          let employerDashboardRightSide = []


          for (const item of state.adds) {
              if(item.title == "Employer Dashboard Right"){
                  employerDashboardRightSide.push({
                      id : item.addsId,
                      title : item.title,
                      image : item.addImage,
                      url: item.bannerUrl
                  });
              }
          }



          return employerDashboardRightSide.filter(item => item.id > 0)
      },
      availableEmployerDashboardCenter(state, getters){
          let employerDashboardCenter = []


          for (const item of state.adds) {
              if(item.title == "Employer Dashboard Center"){
                  employerDashboardCenter.push({
                      id : item.addsId,
                      title : item.title,
                      image : item.addImage,
                      url: item.bannerUrl
                  });
              }
          }



          return employerDashboardCenter.filter(item => item.id > 0)
      },

  },
  modules: {
  }
})

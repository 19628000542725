<template>
  <div
      class="custom-sidebar w3-sidebar w3-bar-block w3-animate-left"
      id="mySidebar"
      style="width:100%;"
  >
    <div
        id="sidebar-tabs"
        class="nav nav-tabs custom-sidebar-nav-tabs"
        role="tablist"
    >


      <!-- <a
          href="#"
          class="w3-bar-item w3-button custom-bar-item custom-active"
          data-toggle="collapse"
          data-target="#id-mainMenu"
          aria-expanded="false"
          ariacontrols="id-mainMenu"
      >
        <div class="custom-sidebar-heading">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="shop"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>Main Menu</span>
            </div>
          </div>
          <div class="custom-sidebar-icons custom-add-btn">
            <a href="#" class="custom-sidebar-link">
              <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
            </a>
          </div>
        </div>
      </a> 
              id="id-mainMenu"
          class="collapse custom-collapse"
    -->
      <div
 
          aria-labelledby="sidebar-tabs"
          data-parent="#sidebar-tabs"
          style="width: 100%;"
      >
        <router-link
            :to="{ name: 'Home'}"
            class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
                <img src="../assets/images/MainMenuLogos/smw512.png" style="height:50px;width:50px;margin-left: 10px;" />
                <!-- <b-icon icon="house-door"></b-icon> -->
              </div>
              <div class="custom-sidebar-text" style="margin-left:20%">
                <span style="font-weight: 700;">Home</span>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
            :to="{ name: 'AllJobs'}"

            class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
                <img src="../assets/images/MainMenuLogos/board.png" style="height:50px;width:50px;margin-left:10%">
              </div>
              <div class="custom-sidebar-text" style="margin-left:20%">
                <span style="font-weight: 700;"> Job Board</span>
              </div>
            </div>
          </div>
        </router-link>
        <a
            href="https://ventureher.com" target="_blank"
            class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
                <img src="../assets/images/MainMenuLogos/ventureher.png" style="height:50px;width:50px;margin-left:10%"/>
                <!-- <b-icon icon="grid"></b-icon> -->
              </div>
              <div class="custom-sidebar-text" style="margin-left:20%">
                <span style="font-weight: 700;">Venture Her</span>
              </div>
            </div>
          </div>
        </a>
        <router-link
            to="/"
            class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
                <img src="../assets/images/MainMenuLogos/hecency.png" style="height:50px;width:50px; margin-left:10%"/>
                <!-- <b-icon icon="grid"></b-icon> -->
              </div>
              <div class="custom-sidebar-text" style="margin-left:20%"> 
                <span style="font-weight: 700;">Hecancy</span>
              </div>
            </div>
          </div>
        </router-link>
        <a
            href="https://viilearn.com" target="_blank"
            class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
                <img src="../assets/images/MainMenuLogos/viilearn.png" style="height:50px;width:50px;margin-left:10%"/>
                <!-- <b-icon icon="grid"></b-icon> -->
              </div>
              <div class="custom-sidebar-text" style="margin-left:20%">
                <span style="font-weight: 700;">Viilearn</span>
              </div>
            </div>
          </div>
        </a>
        <a
            href="https://harshehar.com/#/" target="_blank"
            class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
                <img src="../assets/images/MainMenuLogos/hershar.png" style="height:50px;width:50px;margin-left:10%;"/>
                <!-- <b-icon icon="grid"></b-icon> -->
              </div>
              <div class="custom-sidebar-text" style="margin-left:20%;">
                <span style="font-weight: 700;">Har Shehar</span>
              </div>
            </div>
          </div>
        </a>
        <router-link
            to="/about-us"
            class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
                <img src="../assets/images/MainMenuLogos/aboutus.png" style="height:50px;width:50px;margin-left:10%;"/>
                <!-- <b-icon icon="grid"></b-icon> -->
              </div>
              <div class="custom-sidebar-text" style="margin-left:20%;">
                <span style="font-weight: 700;">About Us</span>
              </div>
            </div>
          </div>
        </router-link>
      </div>


      <template v-if="$auth.check()">
        <div  v-if="$auth.user().user.role == 'Employer'">
          <a
              href="#"
              class="w3-bar-item w3-button custom-bar-item custom-active"
              data-toggle="collapse"
              data-target="#id-employerMenu"
              aria-expanded="false"
              ariacontrols="id-employerMenu"
          >
            <div class="custom-sidebar-heading">
              <div class="custom-row">
                <div class="custom-sidebar-icons">
                  <b-icon icon="shop"></b-icon>
                </div>
                <div class="custom-sidebar-text">
                  <span>Employer SubMenu</span>
                </div>
              </div>
              <div class="custom-sidebar-icons custom-add-btn">
                <a href="#" class="custom-sidebar-link">
                  <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                </a>
              </div>
            </div>
          </a>
          <div
              id="id-employerMenu"
              class="collapse custom-collapse"
              aria-labelledby="sidebar-tabs"
              data-parent="#sidebar-tabs"
          >
            <router-link
                to="/employer-dashboard"
                class="w3-bar-item w3-button custom-bar-item custom-active"
            >
              <div class="custom-sidebar-heading" @click="w3_close">
                <div class="custom-row">
                  <div class="custom-sidebar-icons">
                    <b-icon icon="house-door"></b-icon>
                  </div>
                  <div class="custom-sidebar-text">
                    <span>Dashboard</span>
                  </div>
                </div>
              </div>
            </router-link>


            <div
                id="myJobs-tabs"
                class="nav nav-tabs custom-sidebar-nav-tabs"
                role="tablist"
            >


              <a
                  href="#"
                  class="w3-bar-item w3-button custom-bar-item"
                  data-toggle="collapse"
                  data-target="#id-myJobs"
                  aria-expanded="false"
                  ariacontrols="id-myJobs"
              >
                <div class="custom-sidebar-heading">
                  <div class="custom-row">
                    <div class="custom-sidebar-icons">
                      <b-icon icon="shop"></b-icon>
                    </div>
                    <div class="custom-sidebar-text">
                      <span>My Jobs</span>
                    </div>
                  </div>
                  <div class="custom-sidebar-icons custom-add-btn">
                    <a href="#" class="custom-sidebar-link">
                      <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                    </a>
                  </div>
                </div>
              </a>
              <div
                  id="id-myJobs"
                  class="collapse custom-collapse"
                  aria-labelledby="myJobs-tabs"
                  data-parent="#myJobs-tabs"
              >
                <router-link
                    to="/employer-jobs"
                    class="w3-bar-item w3-button custom-bar-item custom-active"
                >
                  <div class="custom-sidebar-heading" @click="w3_close">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
<!--                        <b-icon icon="house-door"></b-icon>-->
                      </div>
                      <div class="custom-sidebar-text">
                        <span>All Listed Jobs</span>
                      </div>
                    </div>
                  </div>
                </router-link>

                <router-link
                    to="/employer-candidates/0"
                    class="w3-bar-item w3-button custom-bar-item custom-active"
                >
                  <div class="custom-sidebar-heading" @click="w3_close">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
<!--                        <b-icon icon="person-check"></b-icon>-->
                      </div>
                      <div class="custom-sidebar-text">
                        <span>All Employee Profile</span>
                      </div>
                    </div>
                  </div>
                </router-link>

              </div>
            </div>
            <div
                id="myTools-tabs"
                class="nav nav-tabs custom-sidebar-nav-tabs"
                role="tablist"
            >


              <a
                  href="#"
                  class="w3-bar-item w3-button custom-bar-item"
                  data-toggle="collapse"
                  data-target="#id-myTools"
                  aria-expanded="false"
                  ariacontrols="id-myTools"
              >
                <div class="custom-sidebar-heading">
                  <div class="custom-row">
                    <div class="custom-sidebar-icons">
                      <b-icon icon="shop"></b-icon>
                    </div>
                    <div class="custom-sidebar-text">
                      <span>My Tools</span>
                    </div>
                  </div>
                  <div class="custom-sidebar-icons custom-add-btn">
                    <a href="#" class="custom-sidebar-link">
                      <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                    </a>
                  </div>
                </div>
              </a>
              <div
                  id="id-myTools"
                  class="collapse custom-collapse"
                  aria-labelledby="myTools-tabs"
                  data-parent="#myTools-tabs"
              >
                <router-link
                    to="/employer-profile"
                    class="w3-bar-item w3-button custom-bar-item custom-active"
                >
                  <div class="custom-sidebar-heading" @click="w3_close">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
<!--                        <b-icon icon="house-door"></b-icon>-->
                      </div>
                      <div class="custom-sidebar-text">
                        <span>Update Company Profile</span>
                      </div>
                    </div>
                  </div>
                </router-link>

                <router-link
                    to="/change-password"
                    class="w3-bar-item w3-button custom-bar-item custom-active"
                >
                  <div class="custom-sidebar-heading" @click="w3_close">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
<!--                        <b-icon icon="person-check"></b-icon>-->
                      </div>
                      <div class="custom-sidebar-text">
                        <span>Account Settings</span>
                      </div>
                    </div>
                  </div>
                </router-link>
                <router-link
                    to="/employer-accountsetting"
                    class="w3-bar-item w3-button custom-bar-item custom-active"
                >
                  <div class="custom-sidebar-heading" @click="w3_close">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
<!--                        <b-icon icon="person-check"></b-icon>-->
                      </div>
                      <div class="custom-sidebar-text">
                        <span>Alerts / Notifications</span>
                      </div>
                    </div>
                  </div>
                </router-link>
                <router-link
                    to="/employer-packagehistory"
                    class="w3-bar-item w3-button custom-bar-item custom-active"
                >
                  <div class="custom-sidebar-heading" @click="w3_close">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
<!--                        <b-icon icon="person-check"></b-icon>-->
                      </div>
                      <div class="custom-sidebar-text">
                        <span>Purchase History</span>
                      </div>
                    </div>
                  </div>
                </router-link>
                <router-link
                    to="/employer-package"
                    class="w3-bar-item w3-button custom-bar-item custom-active"
                >
                  <div class="custom-sidebar-heading" @click="w3_close">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
<!--                        <b-icon icon="person-check"></b-icon>-->
                      </div>
                      <div class="custom-sidebar-text">
                        <span>Packages</span>
                      </div>
                    </div>
                  </div>
                </router-link>

              </div>
            </div>
            <router-link
                to="/cv-search"
                class="w3-bar-item w3-button custom-bar-item custom-active"
            >
              <div class="custom-sidebar-heading" @click="w3_close">
                <div class="custom-row">
                  <div class="custom-sidebar-icons">
                    <b-icon icon="person-check"></b-icon>
                  </div>
                  <div class="custom-sidebar-text">
                    <span>CV Search</span>
                  </div>
                </div>
              </div>
            </router-link>
            <router-link
                to="/employer-postjob/0"
                class="w3-bar-item w3-button custom-bar-item custom-active"
            >
              <div class="custom-sidebar-heading" @click="w3_close">
                <div class="custom-row">
                  <div class="custom-sidebar-icons">
                    <b-icon icon="grid"></b-icon>
                  </div>
                  <div class="custom-sidebar-text">
                    <span>Post A Job</span>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else>
          <a
              href="#"
              class="w3-bar-item w3-button custom-bar-item custom-active"
              data-toggle="collapse"
              data-target="#id-employeeMenu"
              aria-expanded="false"
              ariacontrols="id-employeeMenu"
          >
            <div class="custom-sidebar-heading">
              <div class="custom-row">
                <div class="custom-sidebar-icons">
                  <b-icon icon="shop"></b-icon>
                </div>
                <div class="custom-sidebar-text">
                  <span>Employee SubMenu</span>
                </div>
              </div>
              <div class="custom-sidebar-icons custom-add-btn">
                <a href="#" class="custom-sidebar-link">
                  <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                </a>
              </div>
            </div>
          </a>
          <div
              id="id-employeeMenu"
              class="collapse custom-collapse"
              aria-labelledby="sidebar-tabs"
              data-parent="#sidebar-tabs"
          >
            <router-link
                to="/employee-dashboard"
                class="w3-bar-item w3-button custom-bar-item custom-active"
            >
              <div class="custom-sidebar-heading" @click="w3_close">
                <div class="custom-row">
                  <div class="custom-sidebar-icons">
                    <b-icon icon="house-door"></b-icon>
                  </div>
                  <div class="custom-sidebar-text">
                    <span>Dashboard</span>
                  </div>
                </div>
              </div>
            </router-link>


            <div
                id="myAccount-tabs"
                class="nav nav-tabs custom-sidebar-nav-tabs"
                role="tablist"
            >


              <a
                  href="#"
                  class="w3-bar-item w3-button custom-bar-item"
                  data-toggle="collapse"
                  data-target="#id-myAccount"
                  aria-expanded="false"
                  ariacontrols="id-myAccount"
              >
                <div class="custom-sidebar-heading">
                  <div class="custom-row">
                    <div class="custom-sidebar-icons">
                      <b-icon icon="shop"></b-icon>
                    </div>
                    <div class="custom-sidebar-text">
                      <span>MyAccount</span>
                    </div>
                  </div>
                  <div class="custom-sidebar-icons custom-add-btn">
                    <a href="#" class="custom-sidebar-link">
                      <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                    </a>
                  </div>
                </div>
              </a>
              <div
                  id="id-myAccount"
                  class="collapse custom-collapse"
                  aria-labelledby="myAccount-tabs"
                  data-parent="#myAccount-tabs"
              >
                <router-link
                    to="/employee-profile"
                    class="w3-bar-item w3-button custom-bar-item custom-active"
                >
                  <div class="custom-sidebar-heading" @click="w3_close">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
<!--                        <b-icon icon="house-door"></b-icon>-->
                      </div>
                      <div class="custom-sidebar-text">
                        <span>Profile</span>
                      </div>
                    </div>
                  </div>
                </router-link>

                <router-link
                    to="/employee-jobs"
                    class="w3-bar-item w3-button custom-bar-item custom-active"
                >
                  <div class="custom-sidebar-heading" @click="w3_close">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
<!--                        <b-icon icon="person-check"></b-icon>-->
                      </div>
                      <div class="custom-sidebar-text">
                        <span>Applications</span>
                      </div>
                    </div>
                  </div>
                </router-link>
                <router-link
                    :to="{ name: 'ChangePassword'}"
                    class="w3-bar-item w3-button custom-bar-item custom-active"
                >
                  <div class="custom-sidebar-heading" @click="w3_close">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
<!--                        <b-icon icon="grid"></b-icon>-->
                      </div>
                      <div class="custom-sidebar-text">
                        <span>Change Password</span>
                      </div>
                    </div>
                  </div>
                </router-link>

              </div>
            </div>
            <router-link
                to="/add-profile-detail"
                class="w3-bar-item w3-button custom-bar-item custom-active"
            >
              <div class="custom-sidebar-heading" @click="w3_close">
                <div class="custom-row">
                  <div class="custom-sidebar-icons">
                    <b-icon icon="person-check"></b-icon>
                  </div>
                  <div class="custom-sidebar-text">
                    <span>Complete Your Profile</span>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>

      </template>


    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  // mounted() {
  //   this.$nextTick(() => {
  //     $(document).ready(function() {
  //       $(".custom-active").click(function() {
  //         $(this).addClass("active");
  //         $(".custom-active")
  //           .not(this)
  //           .removeClass("active");
  //       });
  //     });
  //   });
  // },

  methods:{
    w3_close() {
      debugger
      document.getElementById("mySidebar").style.display = "none";

      document.getElementById("btn-open-sidebar").style.display = "block";
      document.getElementById("btn-close-sidebar").style.display = "none";
     
      console.log("clicked")
    },
    }
  };
</script>

<style></style>

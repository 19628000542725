

<template>
  <header  class="custom-mainmenu" :class="[!$auth.check() ? '':'', $auth.check() ? 'sticky-top':'']">
    <div class="custom-header">
      <div class="">
        <nav class="custom-navbar navbar navbar-expand-lg navbar-light">



          <div
              class="navbar-collapse custom-multi-navbar"
              id="headerSupportedContent"
          >


            <template v-if="$auth.check()" >
              <!-- <ul v-if="$auth.check()" class="navbar-nav mr-auto"> -->

              <div v-if="$auth.user().user.role == 'Employer'"  class="navbar mr-auto w-100 pl-0 pr-0">
                <ul  class="navbar-nav mr-auto">
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-dashboard">
                      Dashboard
                    </router-link
                    >
                  </li>
                  <li class="nav-item dropdown mr-2">
                    <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="myJobsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      My Jobs
                    </a>
                    <div class="dropdown-menu w3-animate-opacity" aria-labelledby="myJobsDropdown">
                      <router-link class="dropdown-item" to="/employer-jobs">All Listed Jobs</router-link>
                      <router-link class="dropdown-item" to="/employer-candidates/0">All Employee Profile</router-link>


                    </div>
                  </li>

                  <!--              <li class="nav-item dropdown mr-2">
                                  <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    CV Folder
                                  </a>
                                  <div class="dropdown-menu w3-animate-opacity" aria-labelledby="navbarDropdown">
                                    <router-link class="dropdown-item" to="/employer-jobs">Description of Jobs</router-link>
                                    <router-link class="dropdown-item" to="/employer-candidates">CV via Unassisted Hiring</router-link>
                                    <router-link class="dropdown-item" to="/employer-candidates">CV via Assisted Hiring</router-link>
                                    <router-link class="dropdown-item" to="/employer-candidates">CV upload within a Department</router-link>

                                  </div>
                                </li>-->


                  <li class="nav-item dropdown mr-2">
                    <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="myToolsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      My Tools
                    </a>
                    <div class="dropdown-menu w3-animate-opacity" aria-labelledby="myToolsDropdown">
                      <!--                  <router-link class="dropdown-item" to="/employer-analytics">Company</router-link>-->
                      <!--                  <router-link class="dropdown-item" to="/employer-about-us">Company Public Profile</router-link>-->
                      <!--                  <router-link class="dropdown-item" to="/employer-teamlist">Department</router-link>-->


                      <router-link class="dropdown-item" to="/employer-profile">Update Company Profile</router-link>
                      <!--                  <router-link class="dropdown-item" to="/employer-teamlist">Team Members</router-link>-->
                      <router-link class="dropdown-item" to="/change-password">Account Settings</router-link>
                      <router-link class="dropdown-item" to="/employer-accountsetting">Alerts / Notifications </router-link>
                      <router-link class="dropdown-item" to="/employer-packagehistory">Purchase History </router-link>
                      <router-link class="dropdown-item" to="/employer-package">Packages </router-link>


                    </div>
                  </li>
                  <!--              <li class="nav-item mr-2">
                                  <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                                    My Account
                                  </router-link>

                                </li>-->

                  <!-- <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                      Employer
                    </router-link>
                  </li> -->

                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/cv-search">
                      CV Search
                    </router-link>
                  </li>
                  <!--              <li class="nav-item mr-2">
                                  <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-about-us/0">
                                    About Us
                                  </router-link>
                                </li>-->
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-postjob/0">
                      Post A Job
                    </router-link>
                  </li>
                </ul>

              </div>
              <div v-else class="navbar mr-auto w-100 pl-0 pr-0">
                <ul  class="navbar-nav mr-auto">
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employee-dashboard">
                      Dashboard
                    </router-link
                    >
                  </li>
                  <li class="nav-item dropdown mr-2">
                    <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      My Account
                    </a>
                    <div class="dropdown-menu w3-animate-opacity" aria-labelledby="navbarDropdown">
                      <router-link class="dropdown-item" to="/employee-profile">Profile</router-link>
                      <router-link class="dropdown-item" to="/employee-jobs">Applications</router-link>
                      <!--                  <router-link class="dropdown-item" to="/purchase-history">Purchase History</router-link>-->
                      <router-link class="dropdown-item" :to="{ name: 'ChangePassword'}">Change Password</router-link>

                    </div>
                  </li>
                  <!--              <li class="nav-item mr-2">
                                  <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                                    My Account
                                  </router-link>

                                </li>-->
                  <!--              <li class="nav-item mr-2">
                                  <router-link
                                      class="nav-link custom-menu-link py-1 px-0 mr-3"
                                      to="/all-jobs"
                                  >
                                    Find Job
                                  </router-link>
                                </li>
                                <li class="nav-item mr-2">
                                  <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                                    Employer
                                  </router-link>
                                </li>-->


                  <!--              <li class="nav-item mr-2">
                                  <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/about-us">
                                    About Us
                                  </router-link>
                                </li>-->
                  <li class="nav-item mr-2">
                    <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/add-profile-detail">
                      Complete Your Profile
                    </router-link>
                  </li>
                </ul>
              </div>

            </template>
          </div>

          <div></div>
        </nav>

      </div>
    </div>

  </header>
</template>

<script>
// var sidebar = document.getElementById("mySidebar");

export default {
  name: "EmployerMenu",
  components:{

  },
  data(){
    return{


    };

  },
  computed:{

  },
  mounted(){


  },



  methods: {



  },
};
</script>

<style>
/*#ad-banner-id{
  transition: all 0.2s ease-in-out;
  display: block;
}
#ad-banner-id.active{
  display: none;
  transition: all 0.2s ease-in-out;
}*/
</style>

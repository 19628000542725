<template>
  <div class="container">
    <div class="">
      <nav class="mt-30 filter-nav">

        <div
            class="nav nav-tabs custom-nav-tabs"
            id="nav-tab"
            role="tablist"
        >

            <!--             <router-link class="nav-item nav-link "  to="/employer-jobs">My Applications</router-link>-->
            <a class="nav-item nav-link custom-nav-link custom-nav-heading active"
               id="myApplication-tab"
               data-toggle="tab"
               href="#myApplication"
               role="tab"

               aria-controls="myApplication"
               aria-selected="true"
               @click="getProfileInfo(0)">My Applications </a>
            <!-- <a class="nav-item nav-link" href="#">Analytics</a> -->
            <!-- <router-link  :to="{ name: 'EmpCandidates', params: { id: Interviewed }}"  class="nav-item nav-link" >Interviewed</router-link> -->
            <a class="nav-item nav-link custom-nav-link custom-nav-heading"
               id="interviewed-tab"
               data-toggle="tab"
               href="#interviewed"
               role="tab"
               aria-controls="interviewed"
               aria-selected="true"
               @click="getProfileInfo(Interviewed)">Interviewed </a>
            <!-- <router-link  :to="{ name: 'EmpCandidates', params: { id: Shortlisted }}"  class="nav-item nav-link" >Shortlisted</router-link>
            <router-link  :to="{ name: 'EmpCandidates', params: { id: Hired }}"  class="nav-item nav-link" >Hired</router-link>
  -->
            <a class="nav-item nav-link custom-nav-link custom-nav-heading"
               id="shortlisted-tab"
               data-toggle="tab"
               href="#shortlisted"
               role="tab"
               aria-controls="shortlisted"
               aria-selected="true"
               @click="getProfileInfo(Shortlisted)" >Shortlisted</a>
            <a class="nav-item nav-link custom-nav-link custom-nav-heading"
               id="hired-tab"
               data-toggle="tab"
               href="#hired"
               role="tab"
               aria-controls="hired"
               aria-selected="true"
               @click="getProfileInfo(Hired)">Hired</a>


        </div>
      </nav>
    </div>

    <div class="row mt-30"></div>

    <div class="row">
      <div class="col-md-6 mb-2" v-for="appli in arrays" :key="appli.jobApplicantsId">
        <div class="w3-card-2 pt-3 pb-3 ml-auto mr-auto" style="min-height: 300px">
          <header class="w3-container custom-row">
            <h3 class="text-capitalize">{{appli.empName}}</h3>
            <!-- <router-link
              to="/all-applications"
              class="btn btn-outline-secondary ml-auto"
              >View Job</router-link
            > -->
            <router-link  :to="{ name: 'CandidatesApplied', params: { id: appli.jobId, empId: appli.empId }}" class="btn btn-outline-secondary ml-auto">View Profile</router-link>
          </header>
          <hr />
          <div class="w3-container row no-gutters align-items-start">
            <div class="col-lg-2">
              <span v-if="appli.employeeImage">
            <img :src="url+appli.employeeImage" alt=""
                 class="w3-left w3-circle w3-margin-right"
                 style="width: 60px;height: 60px">
              </span>
              <span v-else>
              <img
                  src="../../assets/images/no-image.png"
                  alt="Avatar"
                  class="w3-left w3-circle w3-margin-right"
                  style="width: 60px"
              />
            </span>
            </div>


            <div class="col-lg-7" >
              <p class="font-weight-bold w3-text-pink m-0">
                <span class="text-dark">Position:</span> {{appli.jobTitle}}
              </p>
              <div v-if="appli.applicantStatus == 'Applied'">
                <span > Applied Date: {{appli.appliedDate | formatDate}}</span>
              </div>
              <div v-if="appli.applicantStatus == 'ShortListed'">
                <span > Shortlisted Date: {{appli.shortListedDate | formatDate}}</span>
                <br />



              </div>
              <div v-if="appli.applicantStatus == 'Rejected'">
                <span > Rejected Date: {{appli.rejectedDate | formatDate}}</span>
                <br />


              </div>
              <div v-if="appli.applicantStatus == 'Hired'">
                <span > Hired Date: {{appli.hiredDate | formatDate}}</span>
                <br />



              </div>
              <div v-if="appli.applicantStatus == 'SetupInterView'">
                <span > Interviewed Date: {{appli.setupInterViewDate | formatDate}}</span>
                <br />

                <span > Interview Time: {{appli.setupInterViewDate  | formatTime}}</span>
                <br />


              </div>
              <div v-if="appli.applicantStatus == 'Interviewed'">
                <span > Interviewed Date: {{appli.interViewDate | formatDate}}</span>
                <br />

                <span > Interview Time: {{appli.interViewDate  | formatTime}}</span>
                <br />


              </div>
              <div v-if="appli.applicantStatus == 'Contacted'">
                <span > Contacted Date: {{appli.contactedDate | formatDate}}</span>
                <br />


              </div>
            </div>


            <div class="col-lg-3 text-right">
              <p v-if="appli.applicantStatus == 'Applied'" class="font-weight-bold text-black m-0 text-primary">
                Applied
              </p>
              <p v-if="appli.applicantStatus == 'ShortListed'" class="font-weight-bold text-black m-0 text-primary">
                Shortlisted
              </p>
              <p v-if="appli.applicantStatus == 'Rejected'" class="font-weight-bold text-black m-0 text-danger">
                Rejected
              </p>
              <p v-if="appli.applicantStatus == 'Hired'" class="font-weight-bold text-black m-0 text-success">
                Hired
              </p>
              <p v-if="appli.applicantStatus == 'SetupInterView'" class="font-weight-bold text-black m-0 text-primary">
                Interview Scheduled
              </p>
              <p v-if="appli.applicantStatus == 'Interviewed'" class="font-weight-bold text-black m-0 text-primary">
                Interviewed
              </p>
              <p v-if="appli.applicantStatus == 'Contacted'" class="font-weight-bold text-black m-0 text-primary">
                Contacted
              </p>

            </div>
          </div>
          <div class="w3-container row no-gutters align-items-start">
            <div class="col-lg-2"></div>


            <div class="col-lg-10" >


              <div v-if="appli.applicantStatus == 'ShortListed'">


                <b> Shortlisted Remarks: </b>

                <span> {{appli.shortListedRemarks }}</span>

              </div>
              <div v-if="appli.applicantStatus == 'Rejected'">


                <b> Rejected Remarks: </b>

                <span> {{appli.rejectedRemarks }}</span>

              </div>
              <div v-if="appli.applicantStatus == 'Hired'">

                <b> Hired Remarks: </b>

                <span> {{appli.hiredRemarks }}</span>

              </div>
              <div v-if="appli.applicantStatus == 'SetupInterView'">

                <b> Interview Remarks: </b>

                <span> {{appli.setupInterViewRemarks }}</span>
                <br />
                <b> Interview Address: </b>

                <span> {{appli.interviewAddress }}</span>

              </div>
              <div v-if="appli.applicantStatus == 'Interviewed'">


                <b> Interview Remarks: </b>

                <span> {{appli.interviewRemarks }}</span>
                <b> Interview Address: </b>

                <span> {{appli.interviewAddress }}</span>
              </div>
              <div v-if="appli.applicantStatus == 'Contacted'">


                <b> Contacted Remarks: </b>

                <span> {{appli.contactedRemarks }}</span>

              </div>
            </div>



          </div>
        </div>
      </div>
      </div>


    <div class="style-spacer"></div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "EmpCandidates",
  components: {},
  data() {
    return {
      url:axios.defaults.baseURL,
      has_error: false,
      isDataFetched: false,
      isLoading: false,
      EmployerId: this.$auth.user().employeeId,
      arrays: [],
      employerObj: null,
      statusId : 0,
      Interviewed: 6,
      Hired : 4,
      Shortlisted : 2,
      body: {
        employerCompanyId: 0,
        companyEmail: null,
        companyName: null,
        ceoName: null,
        employeeId: this.$auth.user().employeeId,
        industryId: 0,
        ownerShipTypeId: 0,
        companyDesciption: null,
        countryId: 106,
        cityId: 0,
        address: null,
        establishedInYear: 0,
        websiteUrl: null,
        contactNumber: null,
        googleMapIframe: null,
      },
      bodyJobFilter: {
        ApplicantStatus: 0
      },
    };
  },
  computed: {
    sortedArray: function () {
      function compare(a, b) {
        if (a.jobId < b.jobId) return -1;
        if (a.jobId > b.jobId) return 1;
        return 0;
      }

      return this.arrays.sort(compare);
    },
  },
  mounted() {
    this.statusId = this.$route.params.id;
    // /EmployerCompany/GetEmployerCompany
    this.getProfileInfo(this.statusId);
  },
  methods: {
    //   formatDate(date) {
    //   return moment(date).format("Do MMM YYYY")
    // },
    // sortArrays(arrays) {
    //         return _.orderBy(arrays, 'insertionDate', 'desc');
    //     },
    getProfileInfo(js) {
      this.arrays = []

        this.bodyJobFilter.ApplicantStatus = Number(js)

      axios
        .post("/api/EmployerCompany/GetFilteredCandidates", this.bodyJobFilter)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          let resdata = res.data.data;
          console.log("res.data : ", res.data.data);

          if (res.data != 0) {
            //this.employerObj = resdata;
            this.arrays = resdata;
          } else {
          }
          this.isDataFetched = true;

          // });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    postData() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      ///this.body.jobTitle = this.jobTitle;

      var app = this;
      this.body.industryId = Number(this.body.industryId);
      this.body.cityId = Number(this.body.cityId);
      this.body.ownerShipTypeId = Number(this.body.ownerShipTypeId);
      this.body.countryId = Number(this.body.countryId);
      this.body.establishedInYear = Number(this.body.establishedInYear);

      console.log(this.body);

      axios
        .post("/api/EmployerCompany/InsertEmployerCompany", this.body)
        .then((res) => {
          // code that we will 'try' to run
          this.info = res.data;

          console.log("Add Response portion");

          console.log("response : ", this.info);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.isLoading = false;
          if (this.errored) {
            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {
            this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",
              title: "Company Profile",
              solid: true,
            });
            /*if(this.selectedCategory==1){
                  this.$router.push({name:'Graduate'});
                }
                else if(this.selectedCategory==2){
                  this.$router.push({name:'MumsReturntoWork'});
                }
                else if(this.selectedCategory==3){
                  this.$router.push({name:'SpecialneedPerson'});
                }
                else if(this.selectedCategory==4){
                  this.$router.push({name:'LabourManPower'});
                }*/
          }
        });
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).fromNow();
    },

    moment1: function (date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>
<style scoped>
.custom-back-btn {
  padding: 0 1.5rem;
  font-size: 1.2rem;
  /* color: white;
  background-color: #212529; */
  border-radius: 0;
}
/* .custom-back-btn:hover {

  color: white;
  background-color: #212529;

} */
.custom-owner-name {
  margin: 10px 20px;
}
.custom-nav-link {
  padding: 0 0.5rem !important;
  opacity: 1;
}

.custom-nav-tabs .custom-nav-link {
  height: 100%;
  padding: 10px !important;
   margin-bottom: 0px;

  border: 0px solid transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.custom-nav-tabs {
  /* border-bottom: 3px solid #19aa4b !important; */
}
.custom-nav-link.custom-nav-heading{
  color: #fff !important;
}
.custom-nav-tabs .custom-nav-link:hover {
  color: #fff;
}

.custom-nav-tabs .custom-nav-link.active {
  font-weight: 700;
  font-size: 15px;
  color: #fff !important;
  background-color: #72147e;
  /*border-color: #0047ab;*/
}
.custom-table td,
.custom-table th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
  width: 50%;
}
.custom-table-stock td,
.custom-table-stock th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
}
svg {
  color: white;
}
</style>

<template>
  <div class="jumbotron text-center " style="background-color: white;">
    <h1 class=" font-weight-bold">ABOUT US</h1>
    <p style="font-size: large;margin-top: 5rem;text-align: justify;"> 
      Hecancy about us
SMW cares for everyone in the community which is why we introduced Hecancy for
the male audience of Pakistan. Here on our portal, we offer jobs specifically designed for the male
community. It can be very challenging to find a job that suits your needs and qualifications. We will give you the job opportunities and support that you need right now.

Finding jobs has become easy because we have the jobs description that will match your requirements. Hecancy encourages the male to work according to their qualification. Take one step closer in securing your dream job through Hecancy. Build your career through our career portals and prove your
expertise. Find your profession and make it your passion. 

This platform brings competitive industries and potential candidates on a single platform so you find the right fit for your job. Our platform is available to all types of potential male candidates. We have allowed experienced employees as well as fresh graduate jobs in Pakistan to find their dream job on Hecancy. We bring the solutions to you with our top-notch male dedicated job portal website. Unlimited career opportunities as well as the right Jobs for you. Our services remain unparalleled and maintain the same quality standard for both genders. Apply for jobs, get selected, and start your career again. Our purpose is to guide you in your career through job portal and connecting competitive industries with the best employees.  

    </p>
  </div>
</template>
<script>
export default {
  name:"AboutUs"
}
</script>
<template>
  <!-- prettier-ignore -->
  <div class="container" :key="componentKey">

    <!--content area start-->
    <form id="employeeProfileForm" class="needs-validation" :class="[!isValidated  ? '':'', isValidated ? 'was-validated':'']"  @reset="onResetModal" v-if="show" autocomplete="off" novalidate>
      <div class="row">
        <div class="col-md-6">


          <div class="mb-3 form-group row no-gutters" v-if="fname_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="fullName_id">
              Full Name
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">

              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="fullName_id"
                  placeholder="Full Name"
                  v-model="emp.FullName"
                  required
                  pattern="^[a-zA-Z\s]*$"

              />
              <div class="invalid-feedback">
                <span v-if="emp.FullName == ''" class="w3-animate-opacity">Please Enter the Full Name</span>
                <span v-if="emp.FullName != '' && !emp.FullName.match(/^[a-zA-Z\s]*$/)" class="w3-animate-opacity">Invalid Full Name, Only Characters are allowed Here</span>
              </div>
            </div>

          </div>




        </div>
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="fathername_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="fathername_id">
              Father Name
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="fathername_id"
                  placeholder="Father Name"
                  v-model="emp.FatherName"
                  required
                  pattern="^[a-zA-Z\s]*$"

              />
              <div class="invalid-feedback">
                <span v-if="emp.FatherName == ''" class="w3-animate-opacity">Please Enter the Father Name</span>
                <span v-if="emp.FatherName == '' && !emp.FatherName.match(/^[a-zA-Z\s]*$/)" class="w3-animate-opacity">Invalid Father Name, Only Characters are allowed Here</span>
              </div>
            </div>

          </div>


        </div>

      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3  form-group row  no-gutters" v-if="cnic_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="cnic_id">
              CNIC
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="cnic_id"
                  v-model="emp.CNIC"
                  v-mask="'#####-#######-#'"
                  placeholder="00000-000000-0"
                  required
              />
              <div class="invalid-feedback">
                <span class="w3-animate-opacity">Please Enter the CNIC</span>

              </div>
            </div>

          </div>


        </div>
        <div class="col-md-6">
          <div class="mb-3  form-group row no-gutters" v-if="dob_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" >
              Date Of Birth
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <input
                  type="date"

                  class="form-control form-control-sm custom-input-gotopage custom-datepicker"
                  autocomplete="off"
                  required

                  v-model="emp.DateofBirth"
                  min="1900-01-02"
                  :max="maxDate | formatDateMaxOrMin"

              />
              <div class="invalid-feedback">
                <span class="w3-animate-opacity">Please Enter the Date of Birth</span>

              </div>

            </div>


          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="gender_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="genderType_id">
              Gender
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <select id="genderType_id"
                      class="custom-select-option form-control form-control-sm"
                      v-model="selectedGenderType"
                      required
              >
                <option value = '' >Select Gender</option>
                <option
                    v-for="option in genderType"
                    :key="option.key"
                    :value="option.key"
                >
                  {{ option.value }}
                </option>
              </select>
              <div class="invalid-feedback">
                <span v-if="selectedGenderType == ''" class="w3-animate-opacity">Please Select your Gender</span>

              </div>
            </div>

          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3  form-group row no-gutters" v-if="maritalstatus_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" >
              Marital Status
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <select
                  class="custom-select-option form-control form-control-sm"
                  v-model="selectedMaritalStatus"
                  required
              >
                <option value = '' >Select Marital Status</option>
                <option
                    v-for="option in maritalStatus"
                    :key="option.key"
                    :value="option.key"
                >
                  {{ option.value }}
                </option>
              </select>
              <div class="invalid-feedback">
                <span v-if="selectedMaritalStatus == ''" class="w3-animate-opacity">Please Select Marital Status</span>

              </div>
            </div>

          </div>
        </div>
      </div>


      <hr>

      <div class="row">
        <div class="col-md-12">
          <label class="mb-3 ">Address:</label>
        </div>
      </div>





      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="address_input1">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="address_id1">Line 1</label>
            <div class="col-sm-9">
              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="address_id1"
                  v-model="emp.Address"
                  placeholder="Line 1"

              />
            </div>

          </div>


        </div>
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="address_input2">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="address_id2">Line 2</label>
            <div class="col-sm-9">
              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="address_id2"
                  v-model="emp.Address1"
                  placeholder="Line 2"

              />
            </div>

          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="city_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="city_id">
              City
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <select id="city_id"
                      class="custom-select-option form-control form-control-sm"
                      v-model="selectedCity"
                      required
              >
                <option value = '' >Select City</option>
                <option
                    v-for="option in cities"
                    :key="option.key"
                    :value="option.key"
                >
                  {{ option.value }}
                </option>
              </select>
              <div class="invalid-feedback">
                <span v-if="selectedCity == ''" class="w3-animate-opacity">Please Select the City</span>

              </div>
            </div>

          </div>
          <div class="mb-3 form-group row no-gutters" v-if="country_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="country_id">Country</label>
            <div class="col-sm-9">
              <select id="country_id"
                      class="custom-select-option form-control form-control-sm"
                      v-model="selectedCountry"
              >
                <option
                    v-for="option in countries"
                    :key="option.key"
                    :value="option.key"
                >
                  {{ option.value }}
                </option>
              </select>
            </div>

          </div>
          <!--    City Input            -->
          <div class="mb-3 form-group row no-gutters" v-if="city_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="cityInput_id">City</label>
            <div class="col-sm-9">
              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="cityInput_id"
                  placeholder="City"
                  v-model="emp.CityName"
              />
            </div>

          </div>

        </div>
        <div class="col-md-6">
          <div class="mb-3 form-group text-right">
            <a href="javascript:void(0);" class="mb-0 custom-text-color" @click="getOtherCountries"><span v-if="city_dd == true">Not in Pakistan?</span><span v-else="city_dd == false">In Pakistan?</span></a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="area_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="area_id">
              Area
<!--              <span class="text-danger">*</span>-->
            </label>
            <div class="col-sm-9">
              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="area_id"
                  placeholder="Area"
                  v-model="emp.Area"

              />
<!--              <div class="invalid-feedback">
                <span  class="w3-animate-opacity">Please Specify your area</span>

              </div>-->
            </div>

          </div>


        </div>
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="sector_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="sector_id">Sector (If Applicable)</label>
            <div class="col-sm-9">
              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="sector_id"
                  placeholder="Sector"
                  v-model="emp.Sector"
                  pattern="^[a-zA-Z\s]*$"

              />
              <div class="invalid-feedback">

                  <span v-if="emp.Sector == '' && !emp.Sector.match(/^[a-zA-Z\s]*$/)" class="w3-animate-opacity">Invalid Sector Name, Only Characters are allowed Here</span>


              </div>
            </div>

          </div>



        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="city_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="domicile_id">
              Domicile
<!--              <span class="text-danger">*</span>-->
            </label>
            <div class="col-sm-9">

              <select id="domicile_id"
                      class="custom-select-option form-control form-control-sm"
                      v-model="selectedDomicileCity"
              >
                <option value = '' >Select Domicile</option>
                <option
                    v-for="option in cities"
                    :key="option.key"
                    :value="option.value"
                >
                  {{ option.value }}

                </option>
              </select>
<!--              <div class="invalid-feedback">
                <span v-if="selectedDomicileCity == ''" class="w3-animate-opacity">Please Select the Domicile</span>

              </div>-->

            </div>

          </div>


        </div>
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="phone_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" >Phone #</label>
            <div class="col-sm-9">
              <vue-tel-input v-bind="vueTellProps"  v-model="emp.mobileNumber" ></vue-tel-input>
<!--              <div class="custom-row no-gutters">
                <div class="">
                  <select id="countryCode_id"
                          class="custom-select-option form-control form-control-sm"
                          v-model="selectedCountryCode"
                  >
                    <option
                        v-for="option in countryCodes"
                        :key="option.value"
                        :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </div>
                <div class="">
                  <select id="networkCode_id"
                          class="custom-select-option form-control form-control-sm"
                          v-model="selectedNetworkCode"
                  >
                    <option
                        v-for="option in networkCodes"
                        :key="option.value"
                        :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </div>
                <div class="flex-grow-1">
                  <input
                      type="text"
                      class="form-control form-control-sm custom-input-gotopage"
                      id="phoneno_id"
                      placeholder="Phone #"
                      v-mask="'#######'"
                      v-model="emp.mobileNumber"
                  />
                </div>

              </div>-->

            </div>

          </div>



        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="language_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" >
              Language
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
<!--              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="language_id"
                  placeholder="Language"
                  v-model="emp.Languages"
              />-->
              <multiselect class="custom-multiselect" :class="isCustomMultiselect" @input="onSelectedLanguage(selectedLanguages)" :max-height="150" v-model="selectedLanguages" tag-placeholder="Add this as new tag" placeholder="Search or add Language"  :options="languages" :multiple="true"  ></multiselect>
              <div class="">
                <span v-if="isCustomMultiselect == 'custom-multiselect-invalid'" class="w3-animate-opacity " style="width: 100%; margin-top: 0.25rem; font-size: 80%; color: #dc3545;">Please Select the Languages</span>

              </div>
            </div>

          </div>



        </div>
        <div class="col-md-6">

          <div class="mb-3 form-group row no-gutters" v-if="department_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="department_id">
              Department
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <select id="department_id"
                      class="custom-select-option form-control form-control-sm"
                      v-model="selectedDepartment"
                      required
              >
                <option value = '' >Select Department</option>
                <option
                    v-for="option in departments"
                    :key="option.key"
                    :value="option.key"
                >
                  {{ option.value }}
                </option>
              </select>
              <div class="invalid-feedback">
                <span v-if="selectedDepartment == ''" class="w3-animate-opacity">Please Select the Department</span>

              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="experience_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="experience_id">
              Experience
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">

              <select id="experience_id"
                      class="custom-select-option form-control form-control-sm"
                      v-model="selectedYearsOfExperience"
                      required
              >
                <option value = '' >Select Experience</option>
                <option
                    v-for="option in yearsOfExperiences"
                    :key="option.key"
                    :value="option.key"
                >
                  {{ option.value }}
                </option>
              </select>
              <div class="invalid-feedback">
                <span v-if="selectedYearsOfExperience == ''" class="w3-animate-opacity">Please Select the Experience</span>

              </div>
            </div>

          </div>

        </div>
        <div class="col-md-6">
<!--          <div class="mb-3 form-group row no-gutters" v-if="experiencelevel_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="experiencelevel_id">Experience Level</label>
            <div class="col-sm-9">
              <select id="experiencelevel_id"
                      class="custom-select-option form-control form-control-sm"
                      v-model="selectedExperienceLevel"
              >
                <option
                    v-for="option in experienceLevels"
                    :key="option.value"
                    :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>

            </div>

          </div>-->
          <div class="mb-3 form-group row no-gutters" v-if="careerlevel_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="careerlevel_id">
              Career Level
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <select id="careerlevel_id"
                      class="custom-select-option form-control form-control-sm"
                      v-model="selectedCareerLevel"
                      required
              >
                <option value = '' >Select Experience</option>
                <option
                    v-for="option in careerLevels"
                    :key="option.key"
                    :value="option.key"
                >
                  {{ option.value }}
                </option>
              </select>
              <div class="invalid-feedback">
                <span v-if="selectedCareerLevel == ''" class="w3-animate-opacity">Please Select the Career Level</span>

              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="currentsalary_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="currentsalary_id">Current Salary (PKR)</label>
            <div class="col-sm-9">
              <input
                  type="number"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="currentsalary_id"
                  placeholder="Current Salary"
                  v-model="emp.CurrentSalary"
              />
            </div>

          </div>

        </div>
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="expectedsalary_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="expectedsalary_id">Expected Salary (PKR)</label>
            <div class="col-sm-9">
              <input
                  type="number"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="expectedsalary_id"
                  placeholder="Expected Salary"
                  v-model="emp.ExpectedSalary"
              />
            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">


        </div>
        <div class="col-md-6"></div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="mb-3 form-group row no-gutters" v-if="category_dd">




            <label class="col-sm-1 col-form-label col-form-label-sm" >Category</label>
            <div class="col-sm-11">
              <div class="custom-row ml-5">
                <div class="mr-2">
                  <b-form-checkbox
                      id="checkbox-Graduate"
                      v-model="selectedGraduate"
                      name="checkbox-Graduate"
                      @change="toggleValues(selectedGraduate, 24)"
                      class="custom-checkbox-color"
                      value="24"
                      unchecked-value="0"
                      :disabled="disabledGraduate"
                  >

                    Graduate
                  </b-form-checkbox>
                </div>

                <div class="mr-2">
                  <b-form-checkbox
                      id="checkbox-MumsReturnToWork"
                      v-model="selectedMumsReturnToWork"
                      name="checkbox-MumsReturnToWork"
                      @change="toggleValues(selectedMumsReturnToWork, 25)"
                      class="custom-checkbox-color"
                      value="25"
                      unchecked-value="0"
                      :disabled="disabledMumsReturnToWork"
                  >
                    Mums Return To Work
                  </b-form-checkbox>
                </div>

                <div class="mr-2">
                  <b-form-checkbox
                      id="checkbox-SpecialNeedPerson"
                      v-model="selectedSpecialNeedPerson"
                      name="checkbox-SpecialNeedPerson"
                      @change="toggleValues(selectedSpecialNeedPerson, 26)"
                      class="custom-checkbox-color"
                      value="26"
                      unchecked-value="0"
                      :disabled="disabledSpecialNeedPerson"
                  >
                    Persons With Disabilities (PWD’s)
                  </b-form-checkbox>
                </div>

                <div class="mr-2">
                  <b-form-checkbox
                      id="checkbox-LabourManPower"
                      v-model="selectedLabourManPower"
                      name="checkbox-LabourManPower"
                      @change="toggleValues(selectedLabourManPower, 27)"
                      class="custom-checkbox-color"
                      value="27"
                      unchecked-value= "0"
                      :disabled="disabledLabourManPower"
                  >
                    Labour Man Power
                  </b-form-checkbox>
                </div>
                <div class="mr-2">
                  <b-form-checkbox

                      id="checkbox-Hecancy"
                      v-model="selectedHecancy"
                      name="checkbox-Hecancy"
                      @change="toggleValues(selectedHecancy, 30)"
                      class="custom-checkbox-color"
                      value="30"
                      unchecked-value= "0"
                      :disabled="disabledHecancy"

                  >
                    Hecancy
                  </b-form-checkbox>
                </div>


              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="mb-3" v-if="professionalSummary_input">
            <label class="" for="professionalSummary_id">
              Professional Summary
              <span class="text-muted"><i>(Max. 2000 Characters)</i></span>

            </label>
            <div class="">

                <textarea
                    type="text"
                    class="form-control form-control-sm custom-input-gotopage"
                    id="professionalSummary_id"
                    v-model="emp.ProfessionalSummary"
                    placeholder="Professional Summary"

                    maxlength="2000"

                />
            </div>

          </div>
        </div>
      </div>
      <label >Social Links</label>
      <div class="row">
        <div class= "col-md-6">
          <div class="mb-3" v-if="linkedin_input">
            <div class="input-group input-group-sm custom-input-gotopage">
              <div class="input-group-prepend">
                      <span class="input-group-text custom-linkedin-icon" id="linkedin_id">
                        <i class="fab fa-linkedin-in fa-md fa-fw"></i>
                      </span>
              </div>
              <input type="url" pattern="https?://.+.com/.+" class="form-control custom-input-gotopage" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="emp.LinkedInLink" placeholder="https://www.linkedin.com">
              <div class="invalid-feedback">
                <span class="w3-animate-opacity">Wrong URL Written</span>

              </div>
            </div>
          </div>



        </div>
        <div class= "col-md-6">
          <div class="mb-3" v-if="facebook_input">
            <div class="input-group input-group-sm custom-input-gotopage">
              <div class="input-group-prepend">
                      <span class="input-group-text custom-facebook-icon" id="facebook_id">
                        <i class="fab fa-facebook-f fa-md fa-fw"></i>
                      </span>
              </div>
              <input type="url" pattern="https?://.+.com/.+" class="form-control custom-input-gotopage" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="emp.FacebookLink" placeholder="https://www.facebook.com">
              <div class="invalid-feedback">
                <span class="w3-animate-opacity">Wrong URL Written</span>

              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="row">
        <div class= "col-md-6">


          <div class="mb-3" v-if="google_input">
            <div class="input-group input-group-sm custom-input-gotopage">
              <div class="input-group-prepend">
                      <span class="input-group-text custom-googleplus-icon" id="google_id">
                        <i class="fab fa-google-plus-g fa-md fa-fw"></i>
                      </span>
              </div>
              <input type="url" pattern="https?://.+.com/.+" class="form-control custom-input-gotopage" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="emp.GooglePlusLink" placeholder="https://www.google.com">
              <div class="invalid-feedback">
                <span class="w3-animate-opacity">Wrong URL Written</span>

              </div>
            </div>
          </div>

        </div>
        <div class= "col-md-6">

          <div class="mb-3" v-if="twitter_input">
            <div class="input-group input-group-sm custom-input-gotopage">
              <div class="input-group-prepend">
                      <span class="input-group-text custom-twitter-icon" id="twitter_id">
                        <i class="fab fa-twitter fa-md fa-fw"></i>
                      </span>
              </div>
              <input type="url" pattern="https?://.+.com/.+" class="form-control custom-input-gotopage" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="emp.TwitterLink" placeholder="https://www.twitter.com">
              <div class="invalid-feedback">
                <span class="w3-animate-opacity">Wrong URL Written</span>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="graduateSection" class="container">
        <!--content area start-->
        <div class="mt-2">


          <div
              class="custom-row custom-options-menu justify-content-between align-items-center"
          >
            <div class="customHeading">
              <nav>
                <div
                    class="nav nav-tabs custom-nav-tabs"
                    id="nav-tab2"
                    role="tablist"
                >
                  <a
                      class="nav-item nav-link custom-nav-link custom-nav-heading active"
                      id="graduate-tab"
                      data-toggle="tab"
                      href="#graduate"
                      role="tab"
                      aria-controls="graduate"
                      aria-selected="true"
                  >
                    <span> Graduate </span>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div class="custom-accordion-separator">
          <div class="custom-separator"></div>
        </div>

        <div >
          <div class="tab-content" id="nav-tabContent2">
            <div
                class="tab-pane fade show active"
                id="graduate"
                role="tabpanel"
                aria-labelledby="graduate-tab"
            >

              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3" v-if="availableDate_dd">
                    <label >
                      Availability of Internship
                      <span class="text-danger">*</span>
                    </label>
                    <input
                        type="date"

                        class="form-control form-control-sm custom-input-gotopage custom-datepicker"
                        autocomplete="off"
                        required=""

                        v-model="emp.AvailableDate"
                        :min="minDate | formatDateMaxOrMin"

                    />
                    <div class="invalid-feedback">
                      <span class="w3-animate-opacity">Please enter the date of your internship availability</span>

                    </div>
<!--                    <b-form-datepicker
                        id="availableDate_id"
                        size="sm"

                        class="custom-input-gotopage custom-datepicker"
                        placeholder="Choose Date"
                        v-model="emp.AvailableDate"

                    ></b-form-datepicker>-->

                  </div>



                </div>

                <div class="col-md-6">
                  <div class="mb-3" v-if="workPlacement_dd">
                    <label for="workPlacement_id">
                      Please select the Internship category
                      <span class="text-danger">*</span>
                    </label>
                    <select id="workPlacement_id"
                            class="custom-select-option form-control form-control-sm"
                            v-model="selectedWorkPlacement"
                            @change="onChangeWorkPlacement(selectedWorkPlacement)"
                            required
                    >
                      <option value = '' >Select Internship Category</option>
                      <option
                          v-for="option in workPlacements"
                          :key="option.graduateWorkPlacementTypeId"
                          :value="option.graduateWorkPlacementTypeId"
                      >
                        {{ option.graduateWorkPlacementTitle }}
                      </option>
                    </select>
                    <div class="invalid-feedback">
                      <span v-if="selectedWorkPlacement == ''" class="w3-animate-opacity">Please enter the Work Placement You Are Interested In</span>

                    </div>
                  </div>

                </div>

              </div>
              <div class="mb-3" v-if="lookingDescription_input">
                <h4>Remote and Flexible</h4>
                <span class="text-muted"><i>Kindly describe your skill suitable for remote and flexible projects. If you have already executed freelancing projects, please mention it below. This information will help the employee in making a selection.</i></span>
                <textarea
                    type="text"
                    class="form-control form-control-sm custom-input-gotopage"
                    id="lookingDescription_id"
                    v-model="emp.ServiceDescription"
                    rows="5"
                    placeholder="I'm looking for..."
                    maxlength="2000"

                />
              </div>

              <div class="row">
                <div class= "col-md-6">
                  <div class="mb-3" v-if="category_dd2">
                    <label for="category_id2">
                      Choose a category:
                      <span class="text-danger">*</span>
                    </label>
                    <select id="category_id2"
                            class="custom-select-option form-control form-control-sm"
                            v-model="selectedSkillCategory"
                            required
                    >
                      <option value = '' >Choose a Category</option>
                      <option
                          v-for="option in skillCategories"
                          :key="option.key"
                          :value="option.key"
                      >
                        {{ option.value }}
                      </option>
                    </select>
                    <div class="invalid-feedback">
                      <span v-if="selectedSkillCategory == ''" class="w3-animate-opacity">Please choose the category</span>

                    </div>
                  </div>

                </div>
                <div class= "col-md-6">
                  <div class="mb-3" v-if="subCategory_input">
                    <label for="subCategory_id">
                      Other
                      <span class="text-muted"><i>(Please specify category (Max. 200 Characters))</i></span>

                    </label>
                    <input
                        type="text"
                        class="form-control form-control-sm custom-input-gotopage"
                        id="subCategory_id"
                        v-model="emp.SubCategory"
                        maxlength="200"
                        placeholder="Sub-Category"
                        pattern="^[a-zA-Z\s]*$"


                    />
                    <div class="invalid-feedback">

                      <span v-if="emp.SubCategory == ''" class="w3-animate-opacity">Please Enter the Full Name</span>
<!--                      <span v-if="emp.SubCategory != '' && !emp.SubCategory.match(/^[a-zA-Z\s]*$/)" class="w3-animate-opacity">Invalid Specialization, Only Characters are allowed Here</span>-->


                    </div>
                    <!--                                    <select id="subCategory_id"
                                                                class="custom-select-option form-control form-control-sm"
                                                                v-model="selectedSubCategory"
                                                        >
                                                          <option
                                                              v-for="option in subCategories"
                                                              :key="option.skillsSubCategoryId"
                                                              :value="option.skillsSubCategoryId"
                                                          >
                                                            {{ option.skillsSubCategoryTitle }}
                                                          </option>
                                                        </select>-->
                  </div>


                </div>
              </div>



            </div>
          </div>
        </div>
        <!--content area end-->
      </div>
      <div v-if="mumsReturnToWorkSection" class="container">
        <!--content area start-->
        <div class="mt-2">


          <div
              class="custom-row custom-options-menu justify-content-between align-items-center"
          >
            <div class="customHeading">
              <nav>
                <div
                    class="nav nav-tabs custom-nav-tabs"
                    id="nav-tab3"
                    role="tablist"
                >
                  <a
                      class="nav-item nav-link custom-nav-link custom-nav-heading active"
                      id="mumsReturnToWork-detail-tab"
                      data-toggle="tab"
                      href="#mumsReturnToWork-detail"
                      role="tab"
                      aria-controls="mumsReturnToWork-detail"
                      aria-selected="true"
                  >
                    <span> Mums Return To Work </span>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div class="custom-accordion-separator">
          <div class="custom-separator"></div>
        </div>

        <div class="mb-5">
          <div class="tab-content" id="nav-tabContent3">
            <div
                class="tab-pane fade show active"
                id="mumsReturnToWork-detail"
                role="tabpanel"
                aria-labelledby="mumsReturnToWork-detail-tab"
            >
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <h2></h2>

                      <form @reset="onResetModal" v-if="show" autocomplete="off">
                        <h5 class="card-title">What's your current employment status?</h5>

                        <div class="row mb-3 mt-4">
                          <div class="col-md-6">
                            <div class="mb-3 form-group row no-gutters" v-if="employementStatus_dd">
                              <label class="col-sm-3 col-form-label col-form-label-sm" for="employementStatus_id">
                                Employee Status
                                <span class="text-danger">*</span>
                              </label>
                              <div class="col-sm-9">
                                <select id="employementStatus_id"
                                        class="custom-select-option form-control form-control-sm"
                                        v-model="selectedEmployeeStatus"
                                        required
                                >
                                  <option value=''>Select Employee Status</option>
                                  <option
                                      v-for="option in employeeStatuses"
                                      :key="option.value"
                                      :value="option.value"
                                  >
                                    {{ option.text }}
                                  </option>
                                </select>
                                <div class="invalid-feedback">
                                  <span v-if="selectedEmployeeStatus == ''" class="w3-animate-opacity">Please Enter your current employment status</span>

                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="col-md-6"></div>


                        </div>
                        <div class="mt-4 mb-3">
                          <span class="card-title custom-card-title">Career Pause </span>
                          <span class="text-muted"><i>(If you have taken a break from full-time / part-time employment, please state the period.)</i></span>
                        </div>

                        <div class="row mb-3 mt-4">


                          <div class="col-md-6">
                            <div class="mb-3 form-group row no-gutters" v-if="startCareer_dd">
                              <label class="col-sm-3 col-form-label col-form-label-sm" >Start Career</label>
                              <div class="col-sm-9">
                                <input
                                    type="date"

                                    class="form-control form-control-sm custom-input-gotopage custom-datepicker"
                                    autocomplete="off"
                                    required=""

                                    v-model="emp.StartCareer"

                                />
<!--                                <select id="startMonth_id"
                                        class="custom-select-option form-control form-control-sm"
                                        v-model="selectedStartMonth"
                                >

                                  <option
                                      v-for="option in months"
                                      :key="option.value"
                                      :value="option.value"
                                  >
                                    {{ option.text }}
                                  </option>
                                </select>-->
                              </div>

                            </div>


                          </div>
<!--                          <div class="col-md-6">
                            <div class="mb-3 form-group row no-gutters" v-if="startYear_dd">
                              <label class="col-sm-3 col-form-label col-form-label-sm" for="startYear_id">Start Year</label>
                              <div class="col-sm-9">
                                <select id="startYear_id"
                                        class="custom-select-option form-control form-control-sm"
                                        v-model="selectedStartYear"
                                >
                                  <option value="-1">Select Year</option>
                                  <option
                                      v-for="(option, index ) in yearList"
                                      :key="index"
                                      :value="option"
                                  >
                                    {{ option }}
                                  </option>
                                </select>
                              </div>

                            </div>

                          </div>-->
                          <div class="col-md-6">
                            <div class="mb-3 form-group row no-gutters" v-if="endCareer_dd">
                              <label class="col-sm-3 col-form-label col-form-label-sm" >End Career</label>
                              <div class="col-sm-9">
                                <input
                                    type="date"

                                    class="form-control form-control-sm custom-input-gotopage custom-datepicker"
                                    autocomplete="off"
                                    required=""
                                    :min="emp.StartCareer"
                                    v-model="emp.EndCareer"

                                />
<!--                                <select id="endMonth_id"
                                        class="custom-select-option form-control form-control-sm"
                                        v-model="selectedEndMonth"
                                >

                                  <option
                                      v-for="option in months"
                                      :key="option.value"
                                      :value="option.value"
                                  >
                                    {{ option.text }}
                                  </option>
                                </select>-->
                              </div>

                            </div>

                          </div>
<!--                          <div class="col-md-6">
                            <div class="mb-3 form-group row no-gutters" v-if="endYear_dd">
                              <label class="col-sm-3 col-form-label col-form-label-sm" for="endYear_id">End Year</label>
                              <div class="col-sm-9">
                                <select id="endYear_id"
                                        class="custom-select-option form-control form-control-sm"
                                        v-model="selectedEndYear"
                                >
                                  <option value="-1">Select Year</option>
                                  <option
                                      v-for="(option, index ) in yearList"
                                      :key="index"
                                      :value="option"
                                  >
                                    {{ option }}
                                  </option>
                                </select>
                              </div>

                            </div>
                          </div>-->
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="mb-3" v-if="description_input">
                              <label class="" for="description_id">
                                Description
                                <span class="text-muted"><i>(Please state your Employment / Career Break / Why you want to come back to work?)</i></span>
                              </label>
                              <div class="">
                                                <textarea
                                                    type="text"
                                                    class="form-control form-control-sm custom-input-gotopage"
                                                    id="description_id"
                                                    v-model="emp.CPDescription"
                                                    placeholder="Description"

                                                    maxlength="2000"
                                                />
                              </div>

                            </div>
                          </div>
                        </div>


                        <div class="row mb-3 mt-4">
                          <div class="col-md-6">
                            <h5 class="card-title">
                              Preferred working hours
                              <span class="text-danger">*</span>
                            </h5>
                            <div class="mb-3 form-group" v-if="employementWorkHours_dd">
                              <!--                                            <label class="col-sm-3 col-form-label col-form-label-sm" for="employementWorkHours_id">Work Hours</label>-->
                              <div class="">
                                <select id="employementWorkHours_id"
                                        class="custom-select-option form-control form-control-sm"
                                        v-model="selectedEmployementWorkHours"
                                        required
                                >
                                  <option value=''>Select Work Hours</option>
                                  <option
                                      v-for="option in employementWorkHours"
                                      :key="option.key"
                                      :value="option.key"
                                  >
                                    {{ option.value }}
                                  </option>
                                </select>
                                <div class="invalid-feedback">
                                  <span v-if="selectedEmployementWorkHours == ''" class="w3-animate-opacity">Please select the how many hours you can work</span>

                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="col-md-6">
                            <h5 class="card-title">
                              Preferred work place
                              <span class="text-danger">*</span>
                            </h5>
                            <div class="mb-3 form-group" v-if="employementWorkWhere_dd">
                              <!--                                            <label class="col-sm-3 col-form-label col-form-label-sm" for="employementWorkWhere_id">Work Place</label>-->
                              <div class="">
                                <select id="employementWorkWhere_id"
                                        class="custom-select-option form-control form-control-sm"
                                        v-model="selectedEmployementWorkWhere"
                                        required
                                >
                                  <option value=''>Select Work Place</option>
                                  <option
                                      v-for="option in employementWorkWhere"
                                      :key="option.value"
                                      :value="option.value"
                                  >
                                    {{ option.text }}
                                  </option>
                                </select>
                                <div class="invalid-feedback">
                                  <span v-if="selectedEmployementWorkWhere == ''" class="w3-animate-opacity">Please select working preference</span>

                                </div>
                              </div>

                            </div>
                          </div>


                        </div>

<!--                        <h5 class="card-title">Travel Preferences</h5>-->
                        <div class="row mb-3 mt-4">
                          <div class="col-md-6">

                            <p class="card-subtitle mb-2 text-muted">
                              Travel Preferences
<!--                              How far are you willing to commute?-->
                              <span class="text-danger">*</span>
                            </p>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="mb-3 " v-if="miles_dd">
                                  <!--                    <label class="col-sm-3 col-form-label col-form-label-sm" for="miles_id">Miles</label>-->
<!--                                  <div class="">
                                    <select id="miles_id"
                                            class="custom-select-option form-control form-control-sm"
                                            v-model="selectedKM"
                                            required
                                    >
                                      <option value=''>Select KMs</option>
                                      <option
                                          v-for="(option, index ) in kms"
                                          :key="index"
                                          :value="option.value"
                                      >
                                        {{ option.text }}
                                      </option>
                                    </select>
                                    <div class="invalid-feedback">
                                      <span v-if="selectedKM == ''" class="w3-animate-opacity">Please select your travel preference</span>

                                    </div>
                                  </div>-->
                                  <div class="">
                                    <b-form-group class="border-0" >
                                      <b-form-radio-group
                                          v-model="selectedTravelPreference"
                                          :options="travelPreferences"
                                          class="custom-checkbox-color"
                                          name="travelPreferences-radio-inline"
                                          required
                                      ></b-form-radio-group>
                                    </b-form-group>
                                    <div class="invalid-feedback">
                                      <span class="w3-animate-opacity">Please select your travel preference</span>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <p class="card-subtitle mb-2 text-muted">
                              Percentage of Time Travelling
<!--                              <span class="text-danger">*</span>-->
                            </p>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="mb-3 " v-if="travelPercent_dd">
                                  <!--                    <label class="col-sm-3 col-form-label col-form-label-sm" for="miles_id">Miles</label>-->
                                  <div class="">
                                    <select id="travelPercent_id"
                                            class="custom-select-option form-control form-control-sm"
                                            v-model="selectedTravelPercent"

                                    >
                                      <option value=''>Select Travel Percentage</option>
                                      <option
                                          v-for="(option, index ) in travels"
                                          :key="index"
                                          :value="option.value"
                                      >
                                        {{ option.text }}
                                      </option>
                                    </select>
<!--                                    <div class="invalid-feedback">
                                      <span v-if="selectedTravelPercent == ''" class="w3-animate-opacity">Please select how many present you can travel out of town</span>

                                    </div>-->
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>


                        </div>



                        <!--              <h4 class="card-title">Travel Preferences</h4>-->


                      </form>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!--content area end-->

      </div>
      <div v-if="specialNeedPersonSection" class="container">
        <!--content area start-->
        <div class="mt-2">


          <div
              class="custom-row custom-options-menu justify-content-between align-items-center"
          >
            <div class="customHeading">
              <nav>
                <div
                    class="nav nav-tabs custom-nav-tabs"
                    id="nav-tab4"
                    role="tablist"
                >
                  <a
                      class="nav-item nav-link custom-nav-link custom-nav-heading active"
                      id="specialNeedPerson-detail-tab"
                      data-toggle="tab"
                      href="#specialNeedPerson-detail"
                      role="tab"
                      aria-controls="specialNeedPerson-detail"
                      aria-selected="true"
                  >
                    <span> Persons with Disabilities (PWD’s) </span>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div class="custom-accordion-separator">
          <div class="custom-separator"></div>
        </div>

        <div class="mb-5">
          <div class="tab-content" id="nav-tabContent4">
            <div
                class="tab-pane fade show active"
                id="specialNeedPerson-detail"
                role="tabpanel"
                aria-labelledby="specialNeedPerson-detail-tab"
            >
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">


                      <div class="row mb-3 mt-4">

                        <div class="col-md-6">
                          <div class="mb-3 " v-if="disabilityType_dd">
                            <label class="" for="disabilityType_id">
                              Disability Type
                              <span class="text-danger">*</span>
                            </label>
                            <div class="">
                              <select id="disabilityType_id"
                                      class="custom-select-option form-control form-control-sm"
                                      v-model="selectedDisabilityType"
                                      required
                              >
                                <option value=''>Select Type</option>
                                <option
                                    v-for="option in disabilityType"
                                    :key="option.key"
                                    :value="option.key"
                                >
                                  {{ option.value }}
                                </option>
                              </select>
                              <div class="invalid-feedback">
                                <span v-if="selectedDisabilityType == ''" class="w3-animate-opacity">Please select your Disability type</span>

                              </div>
                            </div>

                          </div>

                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 " v-if="disabilityInformation_input" >
                            <label class="" for="disabilityInformation_id">
                              Disability Information
<!--                              <span class="text-danger">*</span>-->
                              <span class="text-muted"><i>(Kindly state the disability information.)</i></span>
                            </label>
                            <div class="">
                              <input
                                  type="text"
                                  class="form-control form-control-sm custom-input-gotopage"
                                  id="disabilityInformation_id"
                                  placeholder="Disability Information"
                                  v-model="emp.DisabilityInformationTitle"


                              />
                              <div class="invalid-feedback">
                                <span  class="w3-animate-opacity">Please Enter your Disability Information</span>

                              </div>
                            </div>

                          </div>



                        </div>


                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mb-3" v-if="disabilityCertificate_radio">
                            <label class="" >
                              Disability Certificate Obtained
                              <span class="text-danger">*</span>
                            </label>
                            <div class="">
                              <b-form-group class="border-0" >
                                <b-form-radio-group
                                    v-model="selectedDisabilityCertificate"
                                    :options="disabilityCertificateList"
                                    class="custom-checkbox-color"
                                    name="radio-inline"
                                    required
                                ></b-form-radio-group>
                              </b-form-group>
                              <div class="invalid-feedback">
                                <span class="w3-animate-opacity">This field is mandatory</span>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mb-3" v-if="disabilitySeverity_radio">
                            <label class="" >Disability Severity</label>
                            <div class="">
                              <b-form-group class="border-0" >
                                <b-form-radio-group
                                    v-model="selectedDisabilitySeverity"
                                    :options="disabilitySeverities"
                                    class="custom-checkbox-color"
                                    name="disabilitySeverity-radio-inline"
                                    required
                                ></b-form-radio-group>
                              </b-form-group>
                              <div class="invalid-feedback">
                                <span class="w3-animate-opacity">This field is mandatory</span>

                              </div>
                            </div>
<!--                            <div class="custom-row">
                              <div class="mr-2">
                                <b-form-checkbox
                                    id="checkbox-SeverityPhysical"
                                    v-model="selectedDisabilitySeverityPhysical"
                                    name="checkbox-SeverityPhysical"
                                    class="custom-checkbox-color"
                                    value=1
                                    unchecked-value=0
                                >
                                  Physical
                                </b-form-checkbox>
                              </div>

                              <div class="mr-2">
                                <b-form-checkbox
                                    id="checkbox-SeverityHearingSpeech"
                                    v-model="selectedDisabilitySeverityHearingSpeech"
                                    name="checkbox-SeverityHearingSpeech"
                                    class="custom-checkbox-color"
                                    value=1
                                    unchecked-value=0
                                >
                                  Hearing & Speech
                                </b-form-checkbox>
                              </div>

                              <div class="mr-2">
                                <b-form-checkbox
                                    id="checkbox-SeverityVisual"
                                    v-model="selectedDisabilitySeverityVisual"
                                    name="checkbox-SeverityVisual"
                                    class="custom-checkbox-color"
                                    value=1
                                    unchecked-value=0
                                >
                                  Visual
                                </b-form-checkbox>
                              </div>

                              <div class="mr-2">
                                <b-form-checkbox
                                    id="checkbox-SeverityIntellectual"
                                    v-model="selectedDisabilitySeverityIntellectual"
                                    name="checkbox-SeverityIntellectual"
                                    class="custom-checkbox-color"
                                    value=1
                                    unchecked-value= 0
                                >
                                  Intellectual
                                </b-form-checkbox>
                              </div>


                            </div>-->

                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mb-3" v-if="signLanguage_radio">
                            <label class="" >
                              <span>Knowledge of sign language</span>
                              <span class="text-muted"><i>(In case of deaf and dumb)</i></span>
                            </label>
                            <div class="">
                              <b-form-group class="border-0" >
                                <b-form-radio-group
                                    v-model="selectedSignLanguage"
                                    :options="signLanguageList"
                                    class="custom-checkbox-color"
                                    name="radio-inline2"
                                ></b-form-radio-group>
                              </b-form-group>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div class="row mb-3 mt-4">


                        <div class="col-md-12">

                          <div class="mb-3 " v-if="causeOfDisability_dd">
                            <label class="" for="causeOfDisability_id">
                              Cause Of Disability
                              <span class="text-danger">*</span>
                            </label>
                            <div class="">
                              <select id="causeOfDisability_id"
                                      class="custom-select-option form-control form-control-sm"
                                      v-model="selectedCauseOfDisability"
                                      required
                              >
                                <option value=''>Select Cause Of Disability</option>
                                <option
                                    v-for="option in causesOfDisability"
                                    :key="option.key"
                                    :value="option.key"
                                >
                                  {{ option.value }}
                                </option>
                              </select>
                              <div class="invalid-feedback">
                                <span v-if="selectedCauseOfDisability == ''" class="w3-animate-opacity">Please select cause of disability</span>

                              </div>
                            </div>

                          </div>


                        </div>
                        <div class="col-md-12">
                          <div class="mb-3 " v-if="specialAssistance_input" >
                            <label class="" for="specialAssistance_id">
                              Special Assistance Required at WorkPlace
                              <span class="text-danger">*</span>
                            </label>
                            <div class="">
                              <select id="specialAssistance_id"
                                      class="custom-select-option form-control form-control-sm"
                                      v-model="emp.SpecialAssistanceRequirment"
                                      required
                              >
                                <option value=''>Select Special Assistance</option>
                                <option
                                    v-for="option in specialFacilities"
                                    :key="option.key"
                                    :value="option.value"
                                >
                                  {{ option.value }}
                                </option>
                              </select>
                              <div class="invalid-feedback">
                                <span v-if="emp.SpecialAssistanceRequirment == ''" class="w3-animate-opacity">Please Select if you require any Special Assistance at Workplace</span>

                              </div>
<!--                              <input
                                  type="text"
                                  class="form-control form-control-sm custom-input-gotopage"
                                  id="specialAssistance_id"
                                  placeholder="Type Here"
                                  v-model="emp.SpecialAssistanceRequirment"
                                  required
                              />
                              <div class="invalid-feedback">
                                <span  class="w3-animate-opacity">Please Select if you require any Special Assistance at Workplace</span>

                              </div>-->
                            </div>

                          </div>


                        </div>
                        <div class="col-md-12">
                          <div class="mb-3 " v-if="jobType_input" >
                            <label class="" >
                              Job Preference
                              <span class="text-danger">*</span>
                            </label>
                            <div class="">
                              <b-form-group class="border-0" >
                                <b-form-radio-group
                                    v-model="emp.KindofJob"
                                    :options="jobPreferenceList"
                                    class="custom-checkbox-color"
                                    name="kind-of-job-radio-inline"
                                ></b-form-radio-group>
                              </b-form-group>
                            </div>


                          </div>


                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mb-3" v-if="fillForm_radio">
                            <label class="" >
                              Transportation Required
                              <span class="text-danger">*</span>
                            </label>
                            <div class="">
                              <b-form-group class="border-0" >
                                <b-form-radio-group
                                    v-model="selectedTransportation"
                                    :options="transportationList"

                                    name="radio-inline3"
                                    required
                                ></b-form-radio-group>
                              </b-form-group>
                              <div class="invalid-feedback">
                                <span  class="w3-animate-opacity">Please select from the option above</span>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!--content area end-->

      </div>
      <div class="modal-footer">
        <div>
          <b-button
              id="resetbtn"
              class="form-control custom-form-btn custom-btn-gotopage"

              type="reset"

              variant="outline-dark"
          >Reset
          </b-button>
        </div>

        <div>
          <b-button
              class="form-control custom-form-btn custom-btn-gotopage"
              @click="updateProfile"

              variant="outline-dark"
          >Save
          </b-button>
        </div>
      </div>
    </form>


    <!-- Modal -->
    <div class="modal fade" id="workPlacementModal" tabindex="-1" role="dialog" aria-labelledby="workPlacementModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">{{ modalTitle }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-justify">
            <div v-if="workPlacementId === 1" class="w3-animate-opacity">
              <p>
                With our Premium Internship program students gain a competitive advantage. We combine both work with Industry Specific training that help our graduates in their seamless and confident transition from study to work.
                Arrangements under Premium Internship Program
              </p>
              <ol>
                <li>A complete 12-week internship program with your host company.</li>
                <li>3-5 days a week with specific hours agreed upon between you and the host company.</li>
                <li>Flexibility in start and end date, to be agreed between you and Host Company.</li>
                <li>Ability to join the premium internship program any time of the year.</li>
              </ol>


            </div>
            <div v-else-if="workPlacementId === 2" class="w3-animate-opacity">
              <p>
                The Carat program is the Graduate Coaching Program, designed to integrate smoothly into workplace
                After completing our Carat Mentor Program, you will learn
              </p>
              <ol>
                <li>The Techniques and ways to make effective resumes matching to the job requirement.</li>
                <li>Interview Training and Mock Interviews.</li>
                <li>Learn how to conduct yourself professionally in a Workplace Environment.</li>
                <li>Managing the Employer</li>
                <li>Developing Confidence in yourself and how to navigate through Professional challenges.</li>
                <li>We guide you in assisting in setting up your professional social media profile such as LinkedIn.</li>

              </ol>
            </div>
            <div v-else-if="workPlacementId === 3" class="w3-animate-opacity">
              <p>
                This internship can be taken anytime, anywhere and mostly prevails in Information Technology and Engineering Industries. Remote internship brings the real-life experience coupled with flexibility and freedom. One of projects are posted by employers in our Remote and Flexible Internship section. If, you feel that you qualify to complete the project, please choose this option.<br>
                Employers will filter students with one of paid Remote and Flexible projects<br>
                Candidate’s fill-in the profile form in particular to section regarding remote internship.
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--content area end-->
<!--    <CPModal v-if="isModalVisible" :workPlacementsSection="isWorkPlacementsSection" :title="modalTitle" :workPlacementId="workPlacementId"  @toggle-modal="toggleModal">
      &lt;!&ndash;        <div v-if="showAlertMsg">
                <span class="mr-3"><b-icon icon="exclamation-triangle" scale="2" variant="danger"></b-icon></span>
                Please <router-link :to="{ name: 'SignUp'}" class="custom-text-color"><i>Register</i></router-link> / <router-link :to="{ name: 'SignIn'}" class="custom-text-color"><i>Login</i></router-link> before applying a Job.
              </div>&ndash;&gt;
    </CPModal>-->
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import {mask} from 'vue-the-mask'
import moment from "moment";
import Multiselect from 'vue-multiselect'

Vue.use(VueAxios, axios);

//import vSelect from "vue-select";
// import { ModelListSelect } from 'vue-search-select'

// import 'vue-search-select/dist/VueSearchSelect.css'



export default {
  name: "AddProfileDetailComponent",
  components: {
    Multiselect
    //CPModal,
    // ArrowLeftIcon,
    // ModelListSelect,
    //vSelect
  },
  directives: {mask},
  data() {
    return {
      userLogo:"",

      apiURL: "",
      currentPage: 1,
      total: 1,

      list: [],
      PageOfItems: [],
      info: [],

      option: 0,
      //bool

      twitter_input:true,
      google_input:true,
      facebook_input:true,
      linkedin_input:true,
      professionalSummary_input:true,
      category_dd:true,
      sector_input:true,
      expectedsalary_input:true,
      currentsalary_input:true,
      careerlevel_dd:true,
      department_dd:true,
      experiencelevel_dd:true,
      experience_dd:true,
      language_input:true,
      phone_input:true,
      domicile_input:true,
      area_input:true,
      city_dd:true,
      city_input:false,
      country_dd:false,
      address_input1:true,
      address_input2:true,
      maritalstatus_dd:true,
      gender_dd:true,
      dob_dd:true,
      cnic_input:true,
      fathername_input:true,
      fname_input:true,

      // address:null,



      //Form Validation Bool
      isValidated:false,
      isFname_input:null,
      isProfessionalSummary_input:null,

      isCustomMultiselect:"",



      categories:null,

      selectedCategory: [], // Must be an array reference!
      selectedGraduate:0,
      selectedMumsReturnToWork:0,
      selectedSpecialNeedPerson:0,
      selectedLabourManPower:0,
      selectedHecancy:0,
      cityInput:"",

      //Employee Dropdowns List

     /* vueTellProps:{
        mode: "international",
        defaultCountry: "PK",





        preferredCountries: ["PK"],
        onlyCountries: [],
        ignoredCountries: [],



        wrapperClasses: "",
        inputClasses: "",

        inputOptions: {
          showDialCode: true,
          name: "telephone",
          autocomplete: "off",
          placeholder: "Enter a phone number",
          required: true,
          type:'tel',
          maxlength:13

        }
      },*/
      vueTellProps:{
        mode: "international",
        defaultCountry: "PK",





        preferredCountries: ["PK"],
        onlyCountries: [],
        ignoredCountries: [],



        wrapperClasses: "",
        inputClasses: "",

        inputOptions: {
          showDialCode: true,
          name: "telephone",
          autocomplete: "off",
          placeholder: "Enter a phone number",
          required: true,
          type:'tel',
          maxlength:15

        },
        validCharactersOnly: true
      },

      selectedCountryCode:1,
      countryCodes:[
        {value: 1,text:"+92"},
        {value: 2,text:"+93"},
        {value: 3,text:"+94"},
      ],
      selectedNetworkCode:1,
      networkCodes:[
        {value:1,text:"300"},
        {value:2,text:"301"},
        {value:3,text:"302"},
        {value:4,text:"303"},
        {value:5,text:"304"},
        {value:6,text:"305"},
        {value:7,text:"306"},
        {value:8,text:"307"},
        {value:9,text:"308"},
        {value:10,text:"309"},
        {value:11,text:"311"},
        {value:12,text:"312"},
        {value:13,text:"313"},
        {value:14,text:"314"},
        {value:15,text:"315"},
        {value:16,text:"320"},
        {value:17,text:"321"},
        {value:18,text:"322"},
        {value:19,text:"323"},
        {value:20,text:"324"},
        {value:21,text:"325"},
        {value:22,text:"330"},
        {value:23,text:"331"},
        {value:24,text:"332"},
        {value:25,text:"333"},
        {value:26,text:"334"},
        {value:27,text:"335"},
        {value:28,text:"336"},
        {value:29,text:"337"},

        {value:30,text:"340"},
        {value:31,text:"341"},
        {value:32,text:"342"},
        {value:33,text:"343"},
        {value:34,text:"344"},
        {value:35,text:"345"},
        {value:36,text:"346"},
        {value:37,text:"347"},

      ],
      selectedGenderType:null,
      genderType:[],
      selectedMaritalStatus:null,
      maritalStatus:[],
      selectedCountry:null,
      //countries:null,
      selectedCity:null,
      selectedDomicileCity:null,
      // cities:null,

      selectedDepartment:null,
      departments:[],
      selectedLanguages:[],




      // languages:[],
      selectedCareerLevel:null,
      careerLevels:[],

      selectedYearsOfExperience:null,
      yearsOfExperiences:[],


      selectedExperienceLevel:null,
      experienceLevels:[
        { value: 0, text: "Select Experience Level" },
        { value: 1, text: "Intern/Student" },
        { value: 2, text: "Entry Level" },
        { value: 3, text: "Experienced Professional" },
        { value: 4, text: "Department Head" },
        { value: 5, text: "GM/CEO/Country Head/President" },
        { value: 6, text: "Technical staff" },
        { value: 7, text: "Volunteer experience" },
        { value: 8, text: "No experience" }
      ],

      employeelist:null,
      employeeId:0,

      maxDate:new Date(),
      minDate:new Date(),
      emp:{
        FullName:"",
        FatherName:"",
        CNIC:"",
        DateofBirth:new Date(),
        Gender:null,
        MaritalStatusId:null,

        Address:"",
        Address1:"",
        Area:"",
        CityId:null,
        EmployeeImage:"",
        Resume:"",
        CountryId:null,
        CityName:"",

        Code:null,
        NetworkCode:null,
        mobileNumber:null,
        PhoneNumber:"",
        Domicile:"",
        Languages:"",
        Sector:"",
        YearOfExperienceId:null,

        CurrentSalary:'',
        ExpectedSalary:'',
        DepartmentId: null,
        CareerLevelId: null,
        JobTypId:null,
        ProfessionalSummary:"",
        LinkedInLink:"",
        FacebookLink:"",
        GooglePlusLink:"",
        TwitterLink:"",
        MomReturn:0,
        SpecialNeedyPerson:0,
        Graduate:0,
        LabourManPower:0,
        Hecancy:0,
        status:1,
        //Graduate
        AvailableDate: null,
        GraduateWorkPlacementTypeId: null,
        ServiceDescription: "",
        SkillsCategoryId: null,
        SubCategory: "",


        //Special Needy
        DisabilityInformationTitle: "",
        DisabilityTypeId: null,
        DisabilityCertificate: 0,
       /* DisabilitySeverityPhysical: 0,
        DisabilitySeverityHearingSpeech: 0,
        DisabilitySeverityVisual: 0,
        DisabilitySeverityIntellectual: 0,*/
        DisabilitySeverity: 0,
        IsKnowSignLangauge: 0,
        CauseOfDiabilityTypeId: null,
        SpecialAssistanceRequirment: "",
        KindofJob: "",
        FillBy:0,

        //Mom Return
        CurrentEmployementStatus: 0,
        StartCareer:null,
        EndCareer:null,
        /*CPStartMonth: 0,
        CPStartYear: 0,
        CPEndMonth:0,
        CPEndYear: 0,*/
        CPDescription: "",
        WeeklyWorkingHour: 0,
        WorkLocation: 0,
        TravelMiles: 0,
        TravelTimePercent: 0,


      },


      //Graduate Section Start
      graduateSection:false,
      graduateState:true,
      //Graduate bool
      availableDate_dd:true,
      workPlacement_dd:true,
      lookingDescription_input:false,
      category_dd2:true,
      subCategory_input:true,



      //Graduate Dropdown Lists
      selectedWorkPlacement:null,
      workPlacements:[],
      selectedSubCategory:null,
      subCategories: null,

      selectedSkillCategory:null,
      skillCategories:[],


//Graduate Section End
//Mums Return To work Start
      mumsReturnToWorkSection:false,
      mumReturnToWorkState:true,
      //bool
      startCareer_dd:true,
      endCareer_dd:true,
      startYear_dd:true,
      endYear_dd:true,
      miles_dd:true,
      employementStatus_dd:true,
      employementWorkHours_dd:true,
      employementWorkWhere_dd:true,
      travelPercent_dd:true,
      description_input:true,


      //MUMS Return Dropdowns List

      selectedEmployeeStatus:'',
      employeeStatuses:[

        { value: 1, text:"Full Time" },
        { value: 2, text:"Part Time" },
        { value: 3, text:"Freelancing or On/Off projects" },
        { value: 4, text:"Not Currently Working" },
      ],
      selectedEmployementWorkHours:'',
      employementWorkHours:[
        /*{ value: 1, text:"1-20 hours per week" },
        { value: 2, text:"21-30 hours per week" },
        { value: 3, text:"31-40 hours per week" },
        { value: 4, text:"40 hours per week" },*/
      ],

      selectedEmployementWorkWhere:'',
      employementWorkWhere:[

        { value: 1, text:"At the office" },
        { value: 2, text:"Remote" },
        { value: 3, text:"Combination" },
        { value: 4, text:"Unsure right now" },
      ],

      yearList:[],
      selectedStartMonth:0,
      selectedEndMonth:0,
      selectedStartYear:0,
      selectedEndYear:0,

      months:[
        { value: 0, text:"Select Month" },
        { value: 1, text:"1" },
        { value: 2, text:"2" },
        { value: 3, text:"3" },
        { value: 4, text:"4" },
        { value: 5, text:"5" },
        { value: 6, text:"6" },
        { value: 7, text:"7" },
        { value: 8, text:"8" },
        { value: 9, text:"9" },
        { value: 10, text:"10" },
        { value: 11, text:"11" },
        { value: 12, text:"12" }

      ],
      selectedTravelPreference:'',
      travelPreferences:[
        {value : 1, text: "Yes"},
        {value : 2, text: "No"}
        /*{value : 3, text: "10-20"},
        {value : 4, text: "20-30"},
        {value : 5, text: "30-40"},
        {value : 6, text: "40-50"},
        {value : 7, text: "50-60"},
        {value : 8, text: "60-70"},
        {value : 9, text: "70-80"},
        {value : 10, text: "80-90"},
        {value : 11, text: "90-100"},
        {value : 12, text: "100-150"},
        {value : 13, text: "150-200"},
        {value : 14, text: "200-250"},
        {value : 15, text: "250-300"},
        {value : 16, text: "300-350"},
        {value : 17, text: "350-400"},
        {value : 18, text: "400-450"},
        {value : 19, text: "450-500"},
        {value : 20, text: "500-600"},
        {value : 21, text: "600-700"},
        {value : 22, text: "700-800"},
        {value : 23, text: "800-900"},
        {value : 24, text: "900-1000"}*/
      ],
      selectedTravelPercent:'',
      travels:[
        {value : 1, text: "not at all"},
        {value : 2, text: "Up to 10%"},
        {value : 3, text: "Up to 20%"},
        {value : 4, text: "Up to 30%"},
        {value : 5, text: "Up to 40%"},
        {value : 6, text: "Up to 50%"},
        {value : 7, text: "Up to 60%"},
        {value : 8, text: "Up to 70%"},
        {value : 9, text: "Up to 80%"},
        {value : 10, text: "Up to 90%"},
        {value : 11, text: "Up to 100%"}

      ],





      //Mums Return to work end
      //Special Need Person Start
      specialNeedPersonSection:false,
      pWDState:true,
      //bool
      disabilityInformation_input:true,
      disabilityType_dd:true,
      disabilityCertificate_radio:true,
      disabilitySeverity_radio:true,
      signLanguage_radio:true,
      causeOfDisability_dd:true,
      specialAssistance_input:true,
      jobType_input:true,
      fillForm_radio:true,

      disabilityInformation:"",


      //PWD Dropdown Lists
      selectedDisabilityType:null,
      disabilityType:null,

      selectedCauseOfDisability:null,
      causesOfDisability:null,

      selectedSpecialFacility:null,
      specialFacilities:null,

      specialAssistance:"",



      selectedDisabilitySeverityPhysical:0,
      selectedDisabilitySeverityHearingSpeech:0,
      selectedDisabilitySeverityVisual:0,
      selectedDisabilitySeverityIntellectual:0,

      selectedDisabilitySeverity:0,
      disabilitySeverities:[
        { text: 'Mild', value: 1 },
        { text: 'Moderate', value: 2 },
        { text: 'Severe', value: 3 },
        { text: 'Profound', value: 4 }
      ],

      selectedDisabilityCertificate: 1,
      disabilityCertificateList: [
        { text: 'Yes', value: 1 },
        { text: 'No', value: 2 }
      ],
      selectedSignLanguage:1,
      signLanguageList:[
        { text: 'Yes', value: 1 },
        { text: 'No', value: 2 },
        { text: 'Not Applicable', value: 3 }
      ],

      jobPreferenceList:[
        { text: 'On Premises' , value: 'On Premises' },
        { text: 'Off Premises', value: 'Off Premises' }
      ],
      selectedTransportation:1,
      transportationList:[
        { text: 'Yes', value: 1 },
        { text: 'No', value: 2 }
      ],




      //Spacial need person End


      //Labour man Power start
      labourManPowerState:true,
      //Labour man Power End

      //hecancyState start
      hecancyState:true,
      //hecancyState End

      //form
      show: true,

      loading: true,
      errored: false,

      rowError: false,
      rowid: 0,

      totalCount: 0,
      componentKey: 0,


      uploadFile:null,

      counter:1,
      //Complete Profile Modal
      isModalVisible:false,
      isWorkPlacementsSection:false,
      workPlacementId:0,
      modalTitle:"",
      isProfileSection:true,
      //searchable dropdown
      options:[],

    };
  },
  props:{
    isGraduateSection:{
      type: Boolean

    },
    isMumsReturnToWorkSection:{
      type: Boolean
    },
    isSpecialNeedPersonSection:{
      type: Boolean
    }
  },


  computed:{
    cities(){
      return this.$store.getters.availableCities
    },
    countries(){
      return this.$store.state.countries;
    },
    languages(){
      return this.$store.state.languages;
    },
    disabledGraduate() {
      return this.graduateState === false;
    },
    disabledMumsReturnToWork() {
      return this.mumReturnToWorkState === false;
    },
    disabledSpecialNeedPerson() {
      return this.pWDState === false;
    },
    disabledLabourManPower() {
      return this.labourManPowerState === false;
    },
    disabledHecancy() {
      return this.hecancyState === false;
    }
  },


  //shows record first time with default values
  mounted() {
    this.getAll();
    //this.getCategories();
    /*this.getCity();
    this.getCountries();*/


    this.getGraduateWorkPlacementType();
/*    this.getDisabilityCauses();
    this.getDisabilityTypes();*/

    //this.getYears();

    //Dropdowns
    this.getSkillCategoriesList();
    this.getCityList();
    this.getCountryList();
    this.getCareerLevelList();
    this.getExperienceLevelList();
    this.getDepartmentList();
    this.getLanguageList();
    this.getGendersList();
    this.getMaritalStatusList();
    this.getYearOfExperienceList();
    this.getJobTypeList();
    this.getDisabilityTypeList();
    this.getCauseOfDisabilityList();

    this.getSpecialFacilityList();
  },

  methods: {
    getAll() {
      this.rerender();
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res => {
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);


            this.employeeId = Number(this.employeelist.employeeId);

            this.emp.FullName = (this.employeelist.fullName == null) ? "" : this.employeelist.fullName;
            this.emp.FatherName = (this.employeelist.fatherName == null) ? "" : this.employeelist.fatherName;
            this.selectedCountryCode = Number(this.employeelist.code);
            this.selectedNetworkCode = Number(this.employeelist.networkCode);
            this.emp.mobileNumber = this.employeelist.mobileNumber;
            this.emp.Address = this.employeelist.address;
            this.emp.Address1 = this.employeelist.address1;
            this.emp.EmployeeImage = this.employeelist.employeeImage;
            this.emp.Resume = this.employeelist.resume;
            this.emp.Area = this.employeelist.area;
            // this.emp.Languages = this.employeelist.languages;
            /*if(this.employeelist.languages == "" || this.employeelist.languages == null){
              this.selectedLanguages = []
            }
            else {
              this.selectedLanguages = (this.employeelist.languages == "" || this.employeelist.languages == null )? [] : this.employeelist.languages.split(",");
            }*/
            this.selectedLanguages = (this.employeelist.languages == "" || this.employeelist.languages == null) ? [] : this.employeelist.languages.split(",");

            this.emp.DateofBirth = this.employeelist.dateofBirth;
            this.emp.CNIC = this.employeelist.cnic;
            this.emp.FacebookLink = this.employeelist.facebookLink;
            this.emp.LinkedInLink = this.employeelist.linkedInLink;
            this.emp.TwitterLink = this.employeelist.twitterLink;
            this.emp.GooglePlusLink = this.employeelist.googlePlusLink;


            this.emp.CurrentSalary = (this.employeelist.currentSalary == 0 || this.employeelist.currentSalary == null) ? "" : Number(this.employeelist.currentSalary);


            this.selectedDomicileCity = (this.employeelist.domicile == null) ? "" : this.employeelist.domicile;
            this.selectedMaritalStatus = (this.employeelist.maritalStatusId == 0 || this.employeelist.maritalStatusId == null) ? "" : Number(this.employeelist.maritalStatusId);

            this.selectedGenderType = (this.employeelist.gender == 0 || this.employeelist.gender == null) ? "" : Number(this.employeelist.gender);


            this.emp.ExpectedSalary = (this.employeelist.expectedSalary == 0 || this.employeelist.expectedSalary == null) ? "" : Number(this.employeelist.expectedSalary);
            this.selectedYearsOfExperience = (this.employeelist.yearOfExperienceId == 0 || this.employeelist.yearOfExperienceId == null) ? "" : Number(this.employeelist.yearOfExperienceId);
            //this.selectedExperienceLevel = this.employeelist.experienceLevel;
            this.selectedCareerLevel = (this.employeelist.careerLevelId == 0 || this.employeelist.careerLevelId == null) ? "" : Number(this.employeelist.careerLevelId);
            this.selectedDepartment = (this.employeelist.departmentId == 0 || this.employeelist.departmentId == null) ? "" : Number(this.employeelist.departmentId);

            this.selectedCountry = (this.employeelist.countryId == 0 || this.employeelist.countryId == null) ? "" : Number(this.employeelist.countryId);
            this.emp.CityName = this.employeelist.cityName;
            this.selectedCity = (this.employeelist.cityId == 0 || this.employeelist.cityId == null) ? "" : Number(this.employeelist.cityId);

            this.emp.Sector = (this.employeelist.sector == null) ? "" : this.employeelist.sector;
            this.emp.ProfessionalSummary = this.employeelist.professionalSummary;
            this.selectedGraduate = Number(this.employeelist.graduate);
            if (this.selectedGraduate == 24) {
              console.log("this.isGraduateSection : ", this.isGraduateSection)
              this.graduateSection = this.isGraduateSection;
              this.toggleValues(this.selectedGraduate, 24);
            }
            this.selectedMumsReturnToWork = this.employeelist.momReturn
            if (this.selectedMumsReturnToWork == 25) {
              this.mumsReturnToWorkSection = this.isMumsReturnToWorkSection;
              this.toggleValues(this.selectedMumsReturnToWork, 25);
            }
            this.selectedSpecialNeedPerson = this.employeelist.specialNeedyPerson
            if (this.selectedSpecialNeedPerson == 26) {
              this.specialNeedPersonSection = this.isSpecialNeedPersonSection;
              this.toggleValues(this.selectedSpecialNeedPerson, 26);
            }

            this.selectedLabourManPower = this.employeelist.labourManPower;
            if (this.selectedLabourManPower == 27) {
              this.toggleValues(this.selectedLabourManPower, 27);
            }


            this.selectedHecancy = this.employeelist.hecancy;
            if (this.selectedHecancy == 30) {
              this.toggleValues(this.selectedHecancy, 30);
            }
            //Graduate
            console.log("date Format : ", moment(String(this.employeelist.availableDate)).format('YYYY-MM-DD'))
            this.emp.AvailableDate = (this.employeelist.availableDate == 0 || this.employeelist.availableDate == null) ? moment(String(new Date())).format('YYYY-MM-DD') : moment(String(this.employeelist.availableDate)).format('YYYY-MM-DD');
            this.selectedWorkPlacement = (this.employeelist.graduateWorkPlacementTypeId == 0 || this.employeelist.graduateWorkPlacementTypeId == null) ? "" : Number(this.employeelist.graduateWorkPlacementTypeId);
            if (this.selectedWorkPlacement == 3) {
              this.lookingDescription_input = true;
            } else {
              this.lookingDescription_input = false;
            }
            this.emp.ServiceDescription = this.employeelist.serviceDescription;
            this.selectedSkillCategory = (this.employeelist.skillsCategoryId == 0 || this.employeelist.skillsCategoryId == null) ? "" : Number(this.employeelist.skillsCategoryId);
            this.emp.SubCategory = this.employeelist.subCategory;


            //Special Needy
            this.emp.DisabilityInformationTitle = this.employeelist.disabilityInformationTitle;
            this.selectedDisabilityType = (this.employeelist.disabilityTypeId == 0 || this.employeelist.disabilityTypeId == null) ? "" : Number(this.employeelist.disabilityTypeId);
            this.selectedDisabilityCertificate = Number(this.employeelist.disabilityCertificate);
            /*this.selectedDisabilitySeverityPhysical = Number(this.employeelist.disabilitySeverityPhysical);
            this.selectedDisabilitySeverityHearingSpeech = Number(this.employeelist.disabilitySeverityHearingSpeech);
            this.selectedDisabilitySeverityVisual = Number(this.employeelist.disabilitySeverityVisual);*/
            this.selectedDisabilitySeverity = Number(this.employeelist.disabilitySeverity);
            this.selectedSignLanguage = Number(this.employeelist.isKnowSignLangauge);
            this.selectedCauseOfDisability = (this.employeelist.causeOfDiabilityTypeId == 0 || this.employeelist.causeOfDiabilityTypeId == null) ? "" : Number(this.employeelist.causeOfDiabilityTypeId);
            this.emp.SpecialAssistanceRequirment = this.employeelist.specialAssistanceRequirment;
            this.emp.KindofJob = this.employeelist.kindofJob;
            this.selectedTransportation = Number(this.employeelist.fillBy);

            //Mom Return
            this.selectedEmployeeStatus = (this.employeelist.currentEmployementStatus == 0 || this.employeelist.currentEmployementStatus == null) ? "" : Number(this.employeelist.currentEmployementStatus);
            this.emp.StartCareer = (this.employeelist.startCareer == 0 || this.employeelist.startCareer == null) ? null : moment(String(this.employeelist.startCareer)).format('YYYY-MM-DD');
            this.emp.EndCareer = (this.employeelist.endCareer == 0 || this.employeelist.endCareer == null) ? null : moment(String(this.employeelist.endCareer)).format('YYYY-MM-DD')
            /*this.selectedStartMonth = Number(this.employeelist.cpStartMonth);
            this.selectedStartYear = Number(this.employeelist.cpStartYear);
            this.selectedEndMonth = Number(this.employeelist.cpEndMonth);
            this.selectedEndYear = Number(this.employeelist.cpEndYear);*/
            this.emp.CPDescription = this.employeelist.cpDescription;
            this.selectedEmployementWorkHours = (this.employeelist.weeklyWorkingHour == 0 || this.employeelist.weeklyWorkingHour == null) ? "" : Number(this.employeelist.weeklyWorkingHour);
            console.log("this.selectedEmployementWorkHours : ", this.selectedEmployementWorkHours)
            this.selectedEmployementWorkWhere = (this.employeelist.workLocation == 0 || this.employeelist.workLocation == null) ? "" : Number(this.employeelist.workLocation);
            this.selectedTravelPreference = (this.employeelist.travelMiles == 0 || this.employeelist.travelMiles == null) ? "" : Number(this.employeelist.travelMiles);
            this.selectedTravelPercent = (this.employeelist.travelTimePercent == 0 || this.employeelist.travelTimePercent == null) ? "" : Number(this.employeelist.travelTimePercent);


            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
    //Profile Section Start


    //Get Dropdowns List
    getCityList() {
      // this.cities = null;
      this.$store.dispatch("getCityList");
      /*let model = {
        type: "City"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.cities = res.data.data;

            console.log("Add Response portion");

            console.log("cities : ", this.cities);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });*/
    },
    getCountryList() {
      this.$store.dispatch("getCountryList");
      /*this.countries = null;
      let model = {
        type: "Country"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.countries = res.data.data;

            console.log("Add Response portion");

            console.log("countries : ", this.countries);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });*/
    },

    getCareerLevelList() {
      this.careerLevels = [];
      let model = {
        type: "CareeerLevel"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.careerLevels = res.data.data;

            console.log("Add Response portion");

            console.log("careerLevels : ", this.careerLevels);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },
    getExperienceLevelList() {
      this.experienceLevels = [];
      let model = {
        type: "ExperienceLevel"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.experienceLevels = res.data.data;

            console.log("Add Response portion");

            console.log("experienceLevels : ", this.experienceLevels);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },
    getDepartmentList() {
      this.departments = [];
      let model = {
        type: "Department"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.departments = res.data.data;

            console.log("Add Response portion");

            console.log("departments : ", this.departments);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },
    getLanguageList() {
      this.$store.dispatch("getLanguageList");
      /*this.languages = [];
      let model = {
        type: "Languages"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run


            let lList = res.data.data;
            for (let lang in lList) {

              this.languages.push(lList[lang].value);

            }

            console.log("Add Response portion");

            console.log("languages : ", this.languages);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });*/
    },
    addTag(newTag) {

      console.log("new Tag : ", newTag)
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.languages.push(tag)
      console.log("this.languages : ", this.languages)
      this.selectedLanguages.push(tag)
      console.log("this.languages : ", this.languages)

    },
    getGendersList() {
      this.genderType = [];
      let model = {
        type: "Gender"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.genderType = res.data.data;

            console.log("Add Response portion");

            console.log("genderType : ", this.genderType);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },
    getMaritalStatusList() {
      this.maritalStatus = [];
      let model = {
        type: "MaritalStatus"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.maritalStatus = res.data.data;

            console.log("Add Response portion");

            console.log("maritalStatus : ", this.maritalStatus);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },
    getYearOfExperienceList() {
      this.yearsOfExperiences = [];
      let model = {
        type: "YearofExperience"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.yearsOfExperiences = res.data.data;

            console.log("Add Response portion");

            console.log("yearsOfExperiences : ", this.yearsOfExperiences);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },
    getSkillCategoriesList() {
      this.skillCategories = [];
      let model = {
        type: "SkillsCategory"
      };
      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {


            this.skillCategories = res.data.data;
            console.log("skillCategories : ", this.skillCategories);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },
    getJobTypeList() {
      this.employementWorkHours = [];
      let model = {
        type: "JobType"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.employementWorkHours = res.data.data;

            console.log("Add Response portion");

            console.log("employementWorkHours : ", this.employementWorkHours);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },
    getDisabilityTypeList() {
      this.disabilityType = null;
      let model = {
        type: "DisabilityType"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.disabilityType = res.data.data;

            console.log("Add Response portion");

            console.log("disabilityType : ", this.disabilityType);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },
    onInput(text){
      if(text != /^[a-zA-Z\s]*$/){

      }
    },
    getCauseOfDisabilityList() {
      this.causesOfDisability = null;
      let model = {
        type: "CauseofDisability"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.causesOfDisability = res.data.data;

            console.log("Add Response portion");

            console.log("causesOfDisability : ", this.causesOfDisability);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },
    getSpecialFacilityList() {
      this.specialFacilities = null;

      let model = {
        type: "SpecialFacilities"
      };
      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.specialFacilities = res.data.data;

            console.log("Add Response portion");

            console.log("specialFacilities : ", this.specialFacilities);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },


    updateProfile() {
      this.info = [];



        /* }
      if ( this.selectedGraduate ) {
        if(





        ){}*/
        // || this.selectedGenderType == null
        //
        //   || this.selectedYearsOfExperience == null
        //   || this.selectedCareerLevel == null
        //   || this.selectedDepartment == null
        //   || this.selectedMaritalStatus == null
        //   || this.selectedCity == null
        //   || this.selectedDomicileCity == null

        if (this.selectedGraduate != 0 && this.selectedMumsReturnToWork != 0) {
          if (
              this.emp.FullName != "" &&
              // this.emp.FullName == /^[a-zA-Z\s]*$/ &&
              this.emp.FatherName != "" &&
              // this.emp.FatherName == /^[a-zA-Z\s]*$/ &&
              this.emp.CNIC != "" &&
              this.emp.DateofBirth != null &&



              this.selectedLanguages != [] &&


              this.selectedGenderType != '' &&
              this.selectedYearsOfExperience != '' &&
              this.selectedCareerLevel != '' &&
              this.selectedDepartment != '' &&
              this.selectedMaritalStatus != '' &&


              this.emp.AvailableDate != null &&

              this.selectedWorkPlacement != '' &&
              this.selectedSkillCategory != '' &&


              this.selectedEmployeeStatus != '' &&
              this.selectedEmployementWorkWhere != '' &&
              this.selectedEmployementWorkHours != '' &&
              this.selectedTravelPreference != ''
              // this.selectedTravelPercent != ''
          ) {
            //Profile Section

            this.emp.CountryId = (this.selectedCountry == 0 || this.selectedCountry == null) ? null : Number(this.selectedCountry)


            this.emp.Code = Number(this.selectedCountryCode);
            this.emp.NetworkCode = String(this.selectedNetworkCode);
            this.emp.CityId = (this.selectedCity == 0 || this.selectedCity == null) ? null : Number(this.selectedCity)
            this.emp.Gender = (this.selectedGenderType == 0 || this.selectedGenderType == null) ? null : Number(this.selectedGenderType)
            this.emp.MaritalStatusId = (this.selectedMaritalStatus == 0 || this.selectedMaritalStatus == null) ? null : Number(this.selectedMaritalStatus)

            this.emp.Domicile = this.selectedDomicileCity;
            this.emp.YearOfExperienceId = (this.selectedYearsOfExperience == 0 || this.selectedYearsOfExperience == null) ? null : Number(this.selectedYearsOfExperience)


            // this.emp.ExperienceLevel = this.selectedExperienceLevel;
            this.emp.CurrentSalary = Number(this.emp.CurrentSalary);
            this.emp.CareerLevelId = (this.selectedCareerLevel == 0 || this.selectedCareerLevel == null) ? null : Number(this.selectedCareerLevel)
            this.emp.DepartmentId = (this.selectedDepartment == 0 || this.selectedDepartment == null) ? null : Number(this.selectedDepartment)
            this.emp.Languages = String(this.selectedLanguages);

            console.log("languages = ", this.emp.Languages)
            this.emp.ExpectedSalary = Number(this.emp.ExpectedSalary);
            this.emp.Graduate = Number(this.selectedGraduate);
            this.emp.MomReturn = Number(this.selectedMumsReturnToWork);
            this.emp.SpecialNeedyPerson = Number(this.selectedSpecialNeedPerson);
            this.emp.LabourManPower = Number(this.selectedLabourManPower);
            this.emp.Hecancy = Number(this.selectedHecancy);

            // Graduate Section
            this.emp.GraduateWorkPlacementTypeId = (this.selectedWorkPlacement == 0 || this.selectedWorkPlacement == null) ? null : Number(this.selectedWorkPlacement)
            this.emp.SkillsCategoryId = (this.selectedSkillCategory == 0 || this.selectedSkillCategory == null) ? null : Number(this.selectedSkillCategory)

            // Special Need Section

            console.log("this.selectedDisabilityType", this.selectedDisabilityType)
            this.emp.DisabilityTypeId = (this.selectedDisabilityType == 0 || this.selectedDisabilityType == null) ? null : Number(this.selectedDisabilityType),
                this.emp.DisabilityCertificate = Number(this.selectedDisabilityCertificate),
                /*this.emp.DisabilitySeverityPhysical= Number(this.selectedDisabilitySeverityPhysical),
                this.emp.DisabilitySeverityHearingSpeech= Number(this.selectedDisabilitySeverityHearingSpeech),
                this.emp.DisabilitySeverityVisual= Number(this.selectedDisabilitySeverityVisual),*/

                this.emp.DisabilitySeverity = Number(this.selectedDisabilitySeverity),
                this.emp.IsKnowSignLangauge = Number(this.selectedSignLanguage),
                this.emp.CauseOfDiabilityTypeId = (this.selectedCauseOfDisability == 0 || this.selectedCauseOfDisability == null) ? null : Number(this.selectedCauseOfDisability)

            this.emp.FillBy = Number(this.selectedTransportation)

            //Mums Return To Work Section
            this.emp.CurrentEmployementStatus = Number(this.selectedEmployeeStatus);

            this.emp.StartCareer = (this.emp.StartCareer == '' || this.emp.StartCareer == null) ? null : new Date(this.emp.StartCareer);
            this.emp.EndCareer = (this.emp.EndCareer == '' || this.emp.EndCareer == null) ? null : new Date(this.emp.EndCareer);
            /*        this.emp.CPStartMonth = Number(this.selectedStartMonth);
                    this.emp.CPStartYear = Number(this.selectedStartYear);
                    this.emp.CPEndMonth = Number(this.selectedEndMonth);
                    this.emp.CPEndYear = Number(this.selectedEndYear);*/

            this.emp.WeeklyWorkingHour = Number(this.selectedEmployementWorkHours);
            this.emp.WorkLocation = Number(this.selectedEmployementWorkWhere);
            this.emp.TravelMiles = Number(this.selectedTravelPreference);
            this.emp.TravelTimePercent = Number(this.selectedTravelPercent);
            console.log("Employee : ", this.emp);
            axios
                .post("/api/Employee/UpdateEmployeePersonalInfo", this.emp)
                .then(res => {
                  // code that we will 'try' to run
                  this.info = res.data;

                  console.log("Add Response portion");

                  console.log("response : ", this.info);
                })
                .catch(error => {
                  console.log("Errorrrrrr : ", error);
                  this.errored = true;
                })
                .finally(() => {
                  if (this.errored) {

                    console.log("Error : Sorry there is no response");
                    // this.$bvToast.toast("Sorry there is no response ", {
                    //   toaster: "b-toaster-top-center",
                    //   variant: "danger",

                    //   solid: true,
                    // });
                  } else {

                    this.$bvToast.toast("Updated Successfully ", {
                      toaster: "b-toaster-top-center",
                      variant: "success",

                      solid: true
                    });
                    if (this.isGraduateSection && this.isMumsReturnToWorkSection) {
                      this.$router.push({name: 'EmployeeDashboard'});
                    }
                    else {
                      this.profileSectionState(false);
                    }

                    this.resetPage();


                  }
                });
          } else {
            this.isValidated = true;
            this.$bvToast.toast("Please Fill all required fields", {
              toaster: "b-toaster-top-center",
              variant: "danger",
              solid: true
            });
            if (this.isGraduateSection) {
              console.log("graduateSection: ", this.isGraduateSection)
              this.scrollTo("employeeProfileForm");
            } else {
              console.log("graduateSection: ", this.isGraduateSection)
              this.navigate();
            }
          }
        }
        else if (this.selectedGraduate != 0 && this.selectedSpecialNeedPerson != 0) {
        if (
            this.emp.FullName != "" &&
            // this.emp.FullName == /^[a-zA-Z\s]*$/ &&
            this.emp.FatherName != "" &&
            // this.emp.FatherName == /^[a-zA-Z\s]*$/ &&
            this.emp.CNIC != "" &&
            this.emp.DateofBirth != null &&

            // this.emp.Area != "" &&

            this.selectedLanguages != [] &&


            this.selectedGenderType != '' &&
            this.selectedYearsOfExperience != '' &&
            this.selectedCareerLevel != '' &&
            this.selectedDepartment != '' &&
            this.selectedMaritalStatus != '' &&

            // this.selectedCity != '' &&
            // this.selectedDomicileCity != '' &&



            this.emp.AvailableDate != null &&

            this.selectedWorkPlacement != '' &&
            this.selectedSkillCategory != '' &&

            // this.emp.DisabilityInformationTitle != "" &&

            this.emp.SpecialAssistanceRequirment != "" &&
            this.emp.KindofJob != "" &&
            this.selectedDisabilityType != '' &&
            this.selectedCauseOfDisability != '' &&
            this.emp.SpecialAssistanceRequirment != '' &&

            this.selectedDisabilityCertificate != 0 &&
            this.selectedTransportation != 0
        ) {
          //Profile Section

          this.emp.CountryId = (this.selectedCountry == 0 || this.selectedCountry == null) ? null : Number(this.selectedCountry)


          this.emp.Code = Number(this.selectedCountryCode);
          this.emp.NetworkCode = String(this.selectedNetworkCode);
          this.emp.CityId = (this.selectedCity == 0 || this.selectedCity == null) ? null : Number(this.selectedCity)
          this.emp.Gender = (this.selectedGenderType == 0 || this.selectedGenderType == null) ? null : Number(this.selectedGenderType)
          this.emp.MaritalStatusId = (this.selectedMaritalStatus == 0 || this.selectedMaritalStatus == null) ? null : Number(this.selectedMaritalStatus)

          this.emp.Domicile = this.selectedDomicileCity;
          this.emp.YearOfExperienceId = (this.selectedYearsOfExperience == 0 || this.selectedYearsOfExperience == null) ? null : Number(this.selectedYearsOfExperience)


          // this.emp.ExperienceLevel = this.selectedExperienceLevel;
          this.emp.CurrentSalary = Number(this.emp.CurrentSalary);
          this.emp.CareerLevelId = (this.selectedCareerLevel == 0 || this.selectedCareerLevel == null) ? null : Number(this.selectedCareerLevel)
          this.emp.DepartmentId = (this.selectedDepartment == 0 || this.selectedDepartment == null) ? null : Number(this.selectedDepartment)
          this.emp.Languages = String(this.selectedLanguages);

          console.log("languages = ", this.emp.Languages)
          this.emp.ExpectedSalary = Number(this.emp.ExpectedSalary);
          this.emp.Graduate = Number(this.selectedGraduate);
          this.emp.MomReturn = Number(this.selectedMumsReturnToWork);
          this.emp.SpecialNeedyPerson = Number(this.selectedSpecialNeedPerson);
          this.emp.LabourManPower = Number(this.selectedLabourManPower);
          this.emp.Hecancy = Number(this.selectedHecancy);

          // Graduate Section
          this.emp.GraduateWorkPlacementTypeId = (this.selectedWorkPlacement == 0 || this.selectedWorkPlacement == null) ? null : Number(this.selectedWorkPlacement)
          this.emp.SkillsCategoryId = (this.selectedSkillCategory == 0 || this.selectedSkillCategory == null) ? null : Number(this.selectedSkillCategory)

          // Special Need Section

          console.log("this.selectedDisabilityType", this.selectedDisabilityType)
          this.emp.DisabilityTypeId = (this.selectedDisabilityType == 0 || this.selectedDisabilityType == null) ? null : Number(this.selectedDisabilityType),
              this.emp.DisabilityCertificate = Number(this.selectedDisabilityCertificate),
              /*this.emp.DisabilitySeverityPhysical= Number(this.selectedDisabilitySeverityPhysical),
              this.emp.DisabilitySeverityHearingSpeech= Number(this.selectedDisabilitySeverityHearingSpeech),
              this.emp.DisabilitySeverityVisual= Number(this.selectedDisabilitySeverityVisual),*/

              this.emp.DisabilitySeverity = Number(this.selectedDisabilitySeverity),
              this.emp.IsKnowSignLangauge = Number(this.selectedSignLanguage),
              this.emp.CauseOfDiabilityTypeId = (this.selectedCauseOfDisability == 0 || this.selectedCauseOfDisability == null) ? null : Number(this.selectedCauseOfDisability)

          this.emp.FillBy = Number(this.selectedTransportation)

          //Mums Return To Work Section
          this.emp.CurrentEmployementStatus = Number(this.selectedEmployeeStatus);

          this.emp.StartCareer = (this.emp.StartCareer == '' || this.emp.StartCareer == null) ? null : new Date(this.emp.StartCareer);
          this.emp.EndCareer = (this.emp.EndCareer == '' || this.emp.EndCareer == null) ? null : new Date(this.emp.EndCareer);
          /*        this.emp.CPStartMonth = Number(this.selectedStartMonth);
                  this.emp.CPStartYear = Number(this.selectedStartYear);
                  this.emp.CPEndMonth = Number(this.selectedEndMonth);
                  this.emp.CPEndYear = Number(this.selectedEndYear);*/

          this.emp.WeeklyWorkingHour = Number(this.selectedEmployementWorkHours);
          this.emp.WorkLocation = Number(this.selectedEmployementWorkWhere);
          this.emp.TravelMiles = Number(this.selectedTravelPreference);
          this.emp.TravelTimePercent = Number(this.selectedTravelPercent);
          console.log("Employee : ", this.emp);
          axios
              .post("/api/Employee/UpdateEmployeePersonalInfo", this.emp)
              .then(res => {
                // code that we will 'try' to run
                this.info = res.data;

                console.log("Add Response portion");

                console.log("response : ", this.info);
              })
              .catch(error => {
                console.log("Errorrrrrr : ", error);
                this.errored = true;
              })
              .finally(() => {
                if (this.errored) {

                  console.log("Error : Sorry there is no response");
                  // this.$bvToast.toast("Sorry there is no response ", {
                  //   toaster: "b-toaster-top-center",
                  //   variant: "danger",

                  //   solid: true,
                  // });
                } else {

                  this.$bvToast.toast("Updated Successfully ", {
                    toaster: "b-toaster-top-center",
                    variant: "success",

                    solid: true
                  });

                  if (this.isGraduateSection && this.isSpecialNeedPersonSection) {
                    this.$router.push({name: 'EmployeeDashboard'});
                  }
                  else {
                    this.profileSectionState(false);
                  }
                  this.resetPage();


                }
              });
        } else {
          this.isValidated = true;
          this.$bvToast.toast("Please Fill all required fields", {
            toaster: "b-toaster-top-center",
            variant: "danger",
            solid: true
          });
          if (this.isGraduateSection) {
            console.log("graduateSection: ", this.isGraduateSection)
            this.scrollTo("employeeProfileForm");
          } else {
            console.log("graduateSection: ", this.isGraduateSection)
            this.navigate();
          }
        }
      }
        else if (this.selectedMumsReturnToWork != 0 && this.selectedSpecialNeedPerson != 0) {
          if (
              this.emp.FullName != "" &&
              // this.emp.FullName == /^[a-zA-Z\s]*$/ &&
              this.emp.FatherName != "" &&
              // this.emp.FatherName == /^[a-zA-Z\s]*$/ &&
              this.emp.CNIC != "" &&
              this.emp.DateofBirth != null &&

              // this.emp.Area != "" &&

              this.selectedLanguages != [] &&


              this.selectedGenderType != '' &&
              this.selectedYearsOfExperience != '' &&
              this.selectedCareerLevel != '' &&
              this.selectedDepartment != '' &&
              this.selectedMaritalStatus != '' &&

              // this.selectedCity != '' &&
              // this.selectedDomicileCity != '' &&


              this.selectedEmployeeStatus != '' &&
              this.selectedEmployementWorkWhere != '' &&
              // this.selectedEmployementWorkHours != '' &&
              this.selectedTravelPreference != '' &&
              // this.selectedTravelPercent != '' &&


              // this.emp.DisabilityInformationTitle != "" &&

              this.emp.SpecialAssistanceRequirment != "" &&
              this.emp.KindofJob != "" &&
              this.selectedDisabilityType != '' &&
              this.selectedCauseOfDisability != '' &&
              this.emp.SpecialAssistanceRequirment != '' &&

              this.selectedDisabilityCertificate != 0 &&
              this.selectedTransportation != 0
          ) {
            //Profile Section

            this.emp.CountryId = (this.selectedCountry == 0 || this.selectedCountry == null) ? null : Number(this.selectedCountry)


            this.emp.Code = Number(this.selectedCountryCode);
            this.emp.NetworkCode = String(this.selectedNetworkCode);
            this.emp.CityId = (this.selectedCity == 0 || this.selectedCity == null) ? null : Number(this.selectedCity)
            this.emp.Gender = (this.selectedGenderType == 0 || this.selectedGenderType == null) ? null : Number(this.selectedGenderType)
            this.emp.MaritalStatusId = (this.selectedMaritalStatus == 0 || this.selectedMaritalStatus == null) ? null : Number(this.selectedMaritalStatus)

            this.emp.Domicile = this.selectedDomicileCity;
            this.emp.YearOfExperienceId = (this.selectedYearsOfExperience == 0 || this.selectedYearsOfExperience == null) ? null : Number(this.selectedYearsOfExperience)


            // this.emp.ExperienceLevel = this.selectedExperienceLevel;
            this.emp.CurrentSalary = Number(this.emp.CurrentSalary);
            this.emp.CareerLevelId = (this.selectedCareerLevel == 0 || this.selectedCareerLevel == null) ? null : Number(this.selectedCareerLevel)
            this.emp.DepartmentId = (this.selectedDepartment == 0 || this.selectedDepartment == null) ? null : Number(this.selectedDepartment)
            this.emp.Languages = String(this.selectedLanguages);

            console.log("languages = ", this.emp.Languages)
            this.emp.ExpectedSalary = Number(this.emp.ExpectedSalary);
            this.emp.Graduate = Number(this.selectedGraduate);
            this.emp.MomReturn = Number(this.selectedMumsReturnToWork);
            this.emp.SpecialNeedyPerson = Number(this.selectedSpecialNeedPerson);
            this.emp.LabourManPower = Number(this.selectedLabourManPower);
            this.emp.Hecancy = Number(this.selectedHecancy);

            // Graduate Section
            this.emp.GraduateWorkPlacementTypeId = (this.selectedWorkPlacement == 0 || this.selectedWorkPlacement == null) ? null : Number(this.selectedWorkPlacement)
            this.emp.SkillsCategoryId = (this.selectedSkillCategory == 0 || this.selectedSkillCategory == null) ? null : Number(this.selectedSkillCategory)

            // Special Need Section

            console.log("this.selectedDisabilityType", this.selectedDisabilityType)
            this.emp.DisabilityTypeId = (this.selectedDisabilityType == 0 || this.selectedDisabilityType == null) ? null : Number(this.selectedDisabilityType),
                this.emp.DisabilityCertificate = Number(this.selectedDisabilityCertificate),
                /*this.emp.DisabilitySeverityPhysical= Number(this.selectedDisabilitySeverityPhysical),
                this.emp.DisabilitySeverityHearingSpeech= Number(this.selectedDisabilitySeverityHearingSpeech),
                this.emp.DisabilitySeverityVisual= Number(this.selectedDisabilitySeverityVisual),*/

                this.emp.DisabilitySeverity = Number(this.selectedDisabilitySeverity),
                this.emp.IsKnowSignLangauge = Number(this.selectedSignLanguage),
                this.emp.CauseOfDiabilityTypeId = (this.selectedCauseOfDisability == 0 || this.selectedCauseOfDisability == null) ? null : Number(this.selectedCauseOfDisability)

            this.emp.FillBy = Number(this.selectedTransportation)

            //Mums Return To Work Section
            this.emp.CurrentEmployementStatus = Number(this.selectedEmployeeStatus);

            this.emp.StartCareer = (this.emp.StartCareer == '' || this.emp.StartCareer == null) ? null : new Date(this.emp.StartCareer);
            this.emp.EndCareer = (this.emp.EndCareer == '' || this.emp.EndCareer == null) ? null : new Date(this.emp.EndCareer);
            /*        this.emp.CPStartMonth = Number(this.selectedStartMonth);
                    this.emp.CPStartYear = Number(this.selectedStartYear);
                    this.emp.CPEndMonth = Number(this.selectedEndMonth);
                    this.emp.CPEndYear = Number(this.selectedEndYear);*/

            this.emp.WeeklyWorkingHour = Number(this.selectedEmployementWorkHours);
            this.emp.WorkLocation = Number(this.selectedEmployementWorkWhere);
            this.emp.TravelMiles = Number(this.selectedTravelPreference);
            this.emp.TravelTimePercent = Number(this.selectedTravelPercent);
            console.log("Employee : ", this.emp);
            axios
                .post("/api/Employee/UpdateEmployeePersonalInfo", this.emp)
                .then(res => {
                  // code that we will 'try' to run
                  this.info = res.data;

                  console.log("Add Response portion");

                  console.log("response : ", this.info);
                })
                .catch(error => {
                  console.log("Errorrrrrr : ", error);
                  this.errored = true;
                })
                .finally(() => {
                  if (this.errored) {

                    console.log("Error : Sorry there is no response");
                    // this.$bvToast.toast("Sorry there is no response ", {
                    //   toaster: "b-toaster-top-center",
                    //   variant: "danger",

                    //   solid: true,
                    // });
                  } else {

                    this.$bvToast.toast("Updated Successfully ", {
                      toaster: "b-toaster-top-center",
                      variant: "success",

                      solid: true
                    });
                    if (this.isMumsReturnToWorkSection && this.isSpecialNeedPersonSection) {
                      this.$router.push({name: 'EmployeeDashboard'});
                    } else {
                      this.profileSectionState(false);
                    }

                    this.resetPage();


                  }
                });
          } else {
            this.isValidated = true;
            this.$bvToast.toast("Please Fill all required fields", {
              toaster: "b-toaster-top-center",
              variant: "danger",
              solid: true
            });
            if (this.isGraduateSection) {
              console.log("graduateSection: ", this.isGraduateSection)
              this.scrollTo("employeeProfileForm");
            } else {
              console.log("graduateSection: ", this.isGraduateSection)
              this.navigate();
            }
          }
        }

        else if (this.selectedGraduate != 0) {
          if (
              this.emp.FullName != "" &&
              // this.emp.FullName == /^[a-zA-Z\s]*$/ &&
              this.emp.FatherName != "" &&
              // this.emp.FatherName == /^[a-zA-Z\s]*$/ &&
              this.emp.CNIC != "" &&
              this.emp.DateofBirth != null &&

              // this.emp.Area != "" &&

              this.selectedLanguages != [] &&


              this.selectedGenderType != '' &&
              this.selectedYearsOfExperience != '' &&
              this.selectedCareerLevel != '' &&
              this.selectedDepartment != '' &&
              this.selectedMaritalStatus != '' &&

              // this.selectedCity != '' &&
              // this.selectedDomicileCity != '' &&



              this.emp.AvailableDate != null &&

              this.selectedWorkPlacement != '' &&
              this.selectedSkillCategory != ''
          ) {
            //Profile Section

            this.emp.CountryId = (this.selectedCountry == 0 || this.selectedCountry == null) ? null : Number(this.selectedCountry)


            this.emp.Code = Number(this.selectedCountryCode);
            this.emp.NetworkCode = String(this.selectedNetworkCode);
            this.emp.CityId = (this.selectedCity == 0 || this.selectedCity == null) ? null : Number(this.selectedCity)
            this.emp.Gender = (this.selectedGenderType == 0 || this.selectedGenderType == null) ? null : Number(this.selectedGenderType)
            this.emp.MaritalStatusId = (this.selectedMaritalStatus == 0 || this.selectedMaritalStatus == null) ? null : Number(this.selectedMaritalStatus)

            this.emp.Domicile = this.selectedDomicileCity;
            this.emp.YearOfExperienceId = (this.selectedYearsOfExperience == 0 || this.selectedYearsOfExperience == null) ? null : Number(this.selectedYearsOfExperience)


            // this.emp.ExperienceLevel = this.selectedExperienceLevel;
            this.emp.CurrentSalary = Number(this.emp.CurrentSalary);
            this.emp.CareerLevelId = (this.selectedCareerLevel == 0 || this.selectedCareerLevel == null) ? null : Number(this.selectedCareerLevel)
            this.emp.DepartmentId = (this.selectedDepartment == 0 || this.selectedDepartment == null) ? null : Number(this.selectedDepartment)
            this.emp.Languages = String(this.selectedLanguages);

            console.log("languages = ", this.emp.Languages)
            this.emp.ExpectedSalary = Number(this.emp.ExpectedSalary);
            this.emp.Graduate = Number(this.selectedGraduate);
            this.emp.MomReturn = Number(this.selectedMumsReturnToWork);
            this.emp.SpecialNeedyPerson = Number(this.selectedSpecialNeedPerson);
            this.emp.LabourManPower = Number(this.selectedLabourManPower);
            this.emp.Hecancy = Number(this.selectedHecancy);

            // Graduate Section
            this.emp.GraduateWorkPlacementTypeId = (this.selectedWorkPlacement == 0 || this.selectedWorkPlacement == null) ? null : Number(this.selectedWorkPlacement)
            this.emp.SkillsCategoryId = (this.selectedSkillCategory == 0 || this.selectedSkillCategory == null) ? null : Number(this.selectedSkillCategory)

            // Special Need Section

            console.log("this.selectedDisabilityType", this.selectedDisabilityType)
            this.emp.DisabilityTypeId = (this.selectedDisabilityType == 0 || this.selectedDisabilityType == null) ? null : Number(this.selectedDisabilityType),
                this.emp.DisabilityCertificate = Number(this.selectedDisabilityCertificate),
                /*this.emp.DisabilitySeverityPhysical= Number(this.selectedDisabilitySeverityPhysical),
                this.emp.DisabilitySeverityHearingSpeech= Number(this.selectedDisabilitySeverityHearingSpeech),
                this.emp.DisabilitySeverityVisual= Number(this.selectedDisabilitySeverityVisual),*/

                this.emp.DisabilitySeverity = Number(this.selectedDisabilitySeverity),
                this.emp.IsKnowSignLangauge = Number(this.selectedSignLanguage),
                this.emp.CauseOfDiabilityTypeId = (this.selectedCauseOfDisability == 0 || this.selectedCauseOfDisability == null) ? null : Number(this.selectedCauseOfDisability)

            this.emp.FillBy = Number(this.selectedTransportation)

            //Mums Return To Work Section
            this.emp.CurrentEmployementStatus = Number(this.selectedEmployeeStatus);

            this.emp.StartCareer = (this.emp.StartCareer == '' || this.emp.StartCareer == null) ? null : new Date(this.emp.StartCareer);
            this.emp.EndCareer = (this.emp.EndCareer == '' || this.emp.EndCareer == null) ? null : new Date(this.emp.EndCareer);
            /*        this.emp.CPStartMonth = Number(this.selectedStartMonth);
                    this.emp.CPStartYear = Number(this.selectedStartYear);
                    this.emp.CPEndMonth = Number(this.selectedEndMonth);
                    this.emp.CPEndYear = Number(this.selectedEndYear);*/

            this.emp.WeeklyWorkingHour = Number(this.selectedEmployementWorkHours);
            this.emp.WorkLocation = Number(this.selectedEmployementWorkWhere);
            this.emp.TravelMiles = Number(this.selectedTravelPreference);
            this.emp.TravelTimePercent = Number(this.selectedTravelPercent);
            console.log("Employee : ", this.emp);
            axios
                .post("/api/Employee/UpdateEmployeePersonalInfo", this.emp)
                .then(res => {
                  // code that we will 'try' to run
                  this.info = res.data;

                  console.log("Add Response portion");

                  console.log("response : ", this.info);
                })
                .catch(error => {
                  console.log("Errorrrrrr : ", error);
                  this.errored = true;
                })
                .finally(() => {
                  if (this.errored) {

                    console.log("Error : Sorry there is no response");
                    // this.$bvToast.toast("Sorry there is no response ", {
                    //   toaster: "b-toaster-top-center",
                    //   variant: "danger",

                    //   solid: true,
                    // });
                  } else {

                    this.$bvToast.toast("Updated Successfully ", {
                      toaster: "b-toaster-top-center",
                      variant: "success",

                      solid: true
                    });
                   if (this.isGraduateSection){
                      this.$router.push({name: 'EmployeeDashboard'});
                    }
                    else {
                      this.profileSectionState(false);
                    }

                    this.resetPage();


                  }
                });
          } else {
            this.isValidated = true;
            this.$bvToast.toast("Please Fill all required fields", {
              toaster: "b-toaster-top-center",
              variant: "danger",
              solid: true
            });
            if (this.isGraduateSection) {
              console.log("graduateSection: ", this.isGraduateSection)
              this.scrollTo("employeeProfileForm");
            } else {
              console.log("graduateSection: ", this.isGraduateSection)
              this.navigate();
            }
          }
        }
        else if (this.selectedMumsReturnToWork != 0) {
          if (

              this.emp.FullName != "" &&
              // this.emp.FullName == /^[a-zA-Z\s]*$/ &&
              this.emp.FatherName != "" &&
              // this.emp.FatherName == /^[a-zA-Z\s]*$/ &&
              this.emp.CNIC != "" &&
              this.emp.DateofBirth != null &&

              // this.emp.Area != "" &&

              this.selectedLanguages != [] &&


              this.selectedGenderType != '' &&
              this.selectedYearsOfExperience != '' &&
              this.selectedCareerLevel != '' &&
              this.selectedDepartment != '' &&
              this.selectedMaritalStatus != '' &&

              // this.selectedCity != '' &&
              // this.selectedDomicileCity != '' &&


              this.selectedEmployeeStatus != '' &&
              this.selectedEmployementWorkWhere != '' &&
              this.selectedEmployementWorkHours != '' &&
              this.selectedTravelPreference != ''
              // this.selectedTravelPercent != ''
          ) {
            //Profile Section

            this.emp.CountryId = (this.selectedCountry == 0 || this.selectedCountry == null) ? null : Number(this.selectedCountry)


            this.emp.Code = Number(this.selectedCountryCode);
            this.emp.NetworkCode = String(this.selectedNetworkCode);
            this.emp.CityId = (this.selectedCity == 0 || this.selectedCity == null) ? null : Number(this.selectedCity)
            this.emp.Gender = (this.selectedGenderType == 0 || this.selectedGenderType == null) ? null : Number(this.selectedGenderType)
            this.emp.MaritalStatusId = (this.selectedMaritalStatus == 0 || this.selectedMaritalStatus == null) ? null : Number(this.selectedMaritalStatus)

            this.emp.Domicile = this.selectedDomicileCity;
            this.emp.YearOfExperienceId = (this.selectedYearsOfExperience == 0 || this.selectedYearsOfExperience == null) ? null : Number(this.selectedYearsOfExperience)


            // this.emp.ExperienceLevel = this.selectedExperienceLevel;
            this.emp.CurrentSalary = Number(this.emp.CurrentSalary);
            this.emp.CareerLevelId = (this.selectedCareerLevel == 0 || this.selectedCareerLevel == null) ? null : Number(this.selectedCareerLevel)
            this.emp.DepartmentId = (this.selectedDepartment == 0 || this.selectedDepartment == null) ? null : Number(this.selectedDepartment)
            this.emp.Languages = String(this.selectedLanguages);

            console.log("languages = ", this.emp.Languages)
            this.emp.ExpectedSalary = Number(this.emp.ExpectedSalary);
            this.emp.Graduate = Number(this.selectedGraduate);
            this.emp.MomReturn = Number(this.selectedMumsReturnToWork);
            this.emp.SpecialNeedyPerson = Number(this.selectedSpecialNeedPerson);
            this.emp.LabourManPower = Number(this.selectedLabourManPower);
            this.emp.Hecancy = Number(this.selectedHecancy);

            // Graduate Section
            this.emp.GraduateWorkPlacementTypeId = (this.selectedWorkPlacement == 0 || this.selectedWorkPlacement == null) ? null : Number(this.selectedWorkPlacement)
            this.emp.SkillsCategoryId = (this.selectedSkillCategory == 0 || this.selectedSkillCategory == null) ? null : Number(this.selectedSkillCategory)

            // Special Need Section

            console.log("this.selectedDisabilityType", this.selectedDisabilityType)
            this.emp.DisabilityTypeId = (this.selectedDisabilityType == 0 || this.selectedDisabilityType == null) ? null : Number(this.selectedDisabilityType),
                this.emp.DisabilityCertificate = Number(this.selectedDisabilityCertificate),
                /*this.emp.DisabilitySeverityPhysical= Number(this.selectedDisabilitySeverityPhysical),
                this.emp.DisabilitySeverityHearingSpeech= Number(this.selectedDisabilitySeverityHearingSpeech),
                this.emp.DisabilitySeverityVisual= Number(this.selectedDisabilitySeverityVisual),*/

                this.emp.DisabilitySeverity = Number(this.selectedDisabilitySeverity),
                this.emp.IsKnowSignLangauge = Number(this.selectedSignLanguage),
                this.emp.CauseOfDiabilityTypeId = (this.selectedCauseOfDisability == 0 || this.selectedCauseOfDisability == null) ? null : Number(this.selectedCauseOfDisability)

            this.emp.FillBy = Number(this.selectedTransportation)

            //Mums Return To Work Section
            this.emp.CurrentEmployementStatus = Number(this.selectedEmployeeStatus);

            this.emp.StartCareer = (this.emp.StartCareer == '' || this.emp.StartCareer == null) ? null : new Date(this.emp.StartCareer);
            this.emp.EndCareer = (this.emp.EndCareer == '' || this.emp.EndCareer == null) ? null : new Date(this.emp.EndCareer);
            /*        this.emp.CPStartMonth = Number(this.selectedStartMonth);
                    this.emp.CPStartYear = Number(this.selectedStartYear);
                    this.emp.CPEndMonth = Number(this.selectedEndMonth);
                    this.emp.CPEndYear = Number(this.selectedEndYear);*/

            this.emp.WeeklyWorkingHour = Number(this.selectedEmployementWorkHours);
            this.emp.WorkLocation = Number(this.selectedEmployementWorkWhere);
            this.emp.TravelMiles = Number(this.selectedTravelPreference);
            this.emp.TravelTimePercent = Number(this.selectedTravelPercent);
            console.log("Employee : ", this.emp);
            axios
                .post("/api/Employee/UpdateEmployeePersonalInfo", this.emp)
                .then(res => {
                  // code that we will 'try' to run
                  this.info = res.data;

                  console.log("Add Response portion");

                  console.log("response : ", this.info);
                })
                .catch(error => {
                  console.log("Errorrrrrr : ", error);
                  this.errored = true;
                })
                .finally(() => {
                  if (this.errored) {

                    console.log("Error : Sorry there is no response");
                    // this.$bvToast.toast("Sorry there is no response ", {
                    //   toaster: "b-toaster-top-center",
                    //   variant: "danger",

                    //   solid: true,
                    // });
                  } else {

                    this.$bvToast.toast("Updated Successfully ", {
                      toaster: "b-toaster-top-center",
                      variant: "success",

                      solid: true
                    });
                    if(this.isMumsReturnToWorkSection){
                      this.$router.push({name: 'EmployeeDashboard'});
                    }
                    else {
                      this.profileSectionState(false);
                    }

                    this.resetPage();


                  }
                });
          } else {
            this.isValidated = true;
            this.$bvToast.toast("Please Fill all required fields", {
              toaster: "b-toaster-top-center",
              variant: "danger",
              solid: true
            });
            if (this.isGraduateSection) {
              console.log("graduateSection: ", this.isGraduateSection)
              this.scrollTo("employeeProfileForm");
            } else {
              console.log("graduateSection: ", this.isGraduateSection)
              this.navigate();
            }
          }
        }


        else if (this.selectedSpecialNeedPerson != 0) {
          if (
              this.emp.FullName != "" &&
              // this.emp.FullName == /^[a-zA-Z\s]*$/ &&
              this.emp.FatherName != "" &&
              // this.emp.FatherName == /^[a-zA-Z\s]*$/ &&
              this.emp.CNIC != "" &&
              this.emp.DateofBirth != null &&

              // this.emp.Area != "" &&

              this.selectedLanguages != [] &&


              this.selectedGenderType != '' &&
              this.selectedYearsOfExperience != '' &&
              this.selectedCareerLevel != '' &&
              this.selectedDepartment != '' &&
              this.selectedMaritalStatus != '' &&

              // this.selectedCity != '' &&
              // this.selectedDomicileCity != '' &&




              // this.emp.DisabilityInformationTitle != "" &&

              this.emp.SpecialAssistanceRequirment != "" &&
              this.emp.KindofJob != "" &&
              this.selectedDisabilityType != '' &&
              this.selectedCauseOfDisability != '' &&
              this.emp.SpecialAssistanceRequirment != '' &&

              this.selectedDisabilityCertificate != 0 &&
              this.selectedTransportation != 0
          ) {
            //Profile Section

            this.emp.CountryId = (this.selectedCountry == 0 || this.selectedCountry == null) ? null : Number(this.selectedCountry)


            this.emp.Code = Number(this.selectedCountryCode);
            this.emp.NetworkCode = String(this.selectedNetworkCode);
            this.emp.CityId = (this.selectedCity == 0 || this.selectedCity == null) ? null : Number(this.selectedCity)
            this.emp.Gender = (this.selectedGenderType == 0 || this.selectedGenderType == null) ? null : Number(this.selectedGenderType)
            this.emp.MaritalStatusId = (this.selectedMaritalStatus == 0 || this.selectedMaritalStatus == null) ? null : Number(this.selectedMaritalStatus)

            this.emp.Domicile = this.selectedDomicileCity;
            this.emp.YearOfExperienceId = (this.selectedYearsOfExperience == 0 || this.selectedYearsOfExperience == null) ? null : Number(this.selectedYearsOfExperience)


            // this.emp.ExperienceLevel = this.selectedExperienceLevel;
            this.emp.CurrentSalary = Number(this.emp.CurrentSalary);
            this.emp.CareerLevelId = (this.selectedCareerLevel == 0 || this.selectedCareerLevel == null) ? null : Number(this.selectedCareerLevel)
            this.emp.DepartmentId = (this.selectedDepartment == 0 || this.selectedDepartment == null) ? null : Number(this.selectedDepartment)
            this.emp.Languages = String(this.selectedLanguages);

            console.log("languages = ", this.emp.Languages)
            this.emp.ExpectedSalary = Number(this.emp.ExpectedSalary);
            this.emp.Graduate = Number(this.selectedGraduate);
            this.emp.MomReturn = Number(this.selectedMumsReturnToWork);
            this.emp.SpecialNeedyPerson = Number(this.selectedSpecialNeedPerson);
            this.emp.LabourManPower = Number(this.selectedLabourManPower);
            this.emp.Hecancy = Number(this.selectedHecancy);

            // Graduate Section
            this.emp.GraduateWorkPlacementTypeId = (this.selectedWorkPlacement == 0 || this.selectedWorkPlacement == null) ? null : Number(this.selectedWorkPlacement)
            this.emp.SkillsCategoryId = (this.selectedSkillCategory == 0 || this.selectedSkillCategory == null) ? null : Number(this.selectedSkillCategory)

            // Special Need Section

            console.log("this.selectedDisabilityType", this.selectedDisabilityType)
            this.emp.DisabilityTypeId = (this.selectedDisabilityType == 0 || this.selectedDisabilityType == null) ? null : Number(this.selectedDisabilityType),
                this.emp.DisabilityCertificate = Number(this.selectedDisabilityCertificate),
                /*this.emp.DisabilitySeverityPhysical= Number(this.selectedDisabilitySeverityPhysical),
                this.emp.DisabilitySeverityHearingSpeech= Number(this.selectedDisabilitySeverityHearingSpeech),
                this.emp.DisabilitySeverityVisual= Number(this.selectedDisabilitySeverityVisual),*/

                this.emp.DisabilitySeverity = Number(this.selectedDisabilitySeverity),
                this.emp.IsKnowSignLangauge = Number(this.selectedSignLanguage),
                this.emp.CauseOfDiabilityTypeId = (this.selectedCauseOfDisability == 0 || this.selectedCauseOfDisability == null) ? null : Number(this.selectedCauseOfDisability)

            this.emp.FillBy = Number(this.selectedTransportation)

            //Mums Return To Work Section
            this.emp.CurrentEmployementStatus = Number(this.selectedEmployeeStatus);

            this.emp.StartCareer = (this.emp.StartCareer == '' || this.emp.StartCareer == null) ? null : new Date(this.emp.StartCareer);
            this.emp.EndCareer = (this.emp.EndCareer == '' || this.emp.EndCareer == null) ? null : new Date(this.emp.EndCareer);
            /*        this.emp.CPStartMonth = Number(this.selectedStartMonth);
                    this.emp.CPStartYear = Number(this.selectedStartYear);
                    this.emp.CPEndMonth = Number(this.selectedEndMonth);
                    this.emp.CPEndYear = Number(this.selectedEndYear);*/

            this.emp.WeeklyWorkingHour = Number(this.selectedEmployementWorkHours);
            this.emp.WorkLocation = Number(this.selectedEmployementWorkWhere);
            this.emp.TravelMiles = Number(this.selectedTravelPreference);
            this.emp.TravelTimePercent = Number(this.selectedTravelPercent);
            console.log("Employee : ", this.emp);
            axios
                .post("/api/Employee/UpdateEmployeePersonalInfo", this.emp)
                .then(res => {
                  // code that we will 'try' to run
                  this.info = res.data;

                  console.log("Add Response portion");

                  console.log("response : ", this.info);
                })
                .catch(error => {
                  console.log("Errorrrrrr : ", error);
                  this.errored = true;
                })
                .finally(() => {
                  if (this.errored) {

                    console.log("Error : Sorry there is no response");
                    // this.$bvToast.toast("Sorry there is no response ", {
                    //   toaster: "b-toaster-top-center",
                    //   variant: "danger",

                    //   solid: true,
                    // });
                  } else {

                    this.$bvToast.toast("Updated Successfully ", {
                      toaster: "b-toaster-top-center",
                      variant: "success",

                      solid: true
                    });
                    if (this.isSpecialNeedPersonSection) {
                      this.$router.push({name: 'EmployeeDashboard'});
                    } else {
                      this.profileSectionState(false);
                    }

                    this.resetPage();


                  }
                });
          } else {
            this.isValidated = true;
            this.$bvToast.toast("Please Fill all required fields", {
              toaster: "b-toaster-top-center",
              variant: "danger",
              solid: true
            });
            if (this.isGraduateSection) {
              console.log("graduateSection: ", this.isGraduateSection)
              this.scrollTo("employeeProfileForm");
            } else {
              console.log("graduateSection: ", this.isGraduateSection)
              this.navigate();
            }
          }
        }


        else{
          //Profile Section
          if (
              this.emp.FullName != "" &&
              // this.emp.FullName == /^[a-zA-Z\s]*$/ &&
              this.emp.FatherName != "" &&
              // this.emp.FatherName == /^[a-zA-Z\s]*$/ &&
              this.emp.CNIC != "" &&
              this.emp.DateofBirth != null &&

              // this.emp.Area != "" &&

              this.selectedLanguages != [] &&


              this.selectedGenderType != '' &&
              this.selectedYearsOfExperience != '' &&
              this.selectedCareerLevel != '' &&
              this.selectedDepartment != '' &&
              this.selectedMaritalStatus != ''

              // this.selectedCity != ''
              // this.selectedDomicileCity != ''
          ) {
          this.emp.CountryId = (this.selectedCountry == 0 || this.selectedCountry == null) ? null : Number(this.selectedCountry)


          this.emp.Code = Number(this.selectedCountryCode);
          this.emp.NetworkCode = String(this.selectedNetworkCode);
          this.emp.CityId = (this.selectedCity == 0 || this.selectedCity == null) ? null : Number(this.selectedCity)
          this.emp.Gender = (this.selectedGenderType == 0 || this.selectedGenderType == null) ? null : Number(this.selectedGenderType)
          this.emp.MaritalStatusId = (this.selectedMaritalStatus == 0 || this.selectedMaritalStatus == null) ? null : Number(this.selectedMaritalStatus)

          this.emp.Domicile = this.selectedDomicileCity;
          this.emp.YearOfExperienceId = (this.selectedYearsOfExperience == 0 || this.selectedYearsOfExperience == null) ? null : Number(this.selectedYearsOfExperience)


          // this.emp.ExperienceLevel = this.selectedExperienceLevel;
          this.emp.CurrentSalary = Number(this.emp.CurrentSalary);
          this.emp.CareerLevelId = (this.selectedCareerLevel == 0 || this.selectedCareerLevel == null) ? null : Number(this.selectedCareerLevel)
          this.emp.DepartmentId = (this.selectedDepartment == 0 || this.selectedDepartment == null) ? null : Number(this.selectedDepartment)
          this.emp.Languages = String(this.selectedLanguages);

          console.log("languages = ", this.emp.Languages)
          this.emp.ExpectedSalary = Number(this.emp.ExpectedSalary);
          this.emp.Graduate = Number(this.selectedGraduate);
          this.emp.MomReturn = Number(this.selectedMumsReturnToWork);
          this.emp.SpecialNeedyPerson = Number(this.selectedSpecialNeedPerson);
          this.emp.LabourManPower = Number(this.selectedLabourManPower);
          this.emp.Hecancy = Number(this.selectedHecancy);

          // Graduate Section
          this.emp.GraduateWorkPlacementTypeId = (this.selectedWorkPlacement == 0 || this.selectedWorkPlacement == null) ? null : Number(this.selectedWorkPlacement)
          this.emp.SkillsCategoryId = (this.selectedSkillCategory == 0 || this.selectedSkillCategory == null) ? null : Number(this.selectedSkillCategory)

          // Special Need Section

          console.log("this.selectedDisabilityType", this.selectedDisabilityType)
          this.emp.DisabilityTypeId = (this.selectedDisabilityType == 0 || this.selectedDisabilityType == null) ? null : Number(this.selectedDisabilityType),
              this.emp.DisabilityCertificate = Number(this.selectedDisabilityCertificate),
              /*this.emp.DisabilitySeverityPhysical= Number(this.selectedDisabilitySeverityPhysical),
                this.emp.DisabilitySeverityHearingSpeech= Number(this.selectedDisabilitySeverityHearingSpeech),
                this.emp.DisabilitySeverityVisual= Number(this.selectedDisabilitySeverityVisual),*/

              this.emp.DisabilitySeverity = Number(this.selectedDisabilitySeverity),
              this.emp.IsKnowSignLangauge = Number(this.selectedSignLanguage),
              this.emp.CauseOfDiabilityTypeId = (this.selectedCauseOfDisability == 0 || this.selectedCauseOfDisability == null) ? null : Number(this.selectedCauseOfDisability)

          this.emp.FillBy = Number(this.selectedTransportation)

          //Mums Return To Work Section
          this.emp.CurrentEmployementStatus = Number(this.selectedEmployeeStatus);

          this.emp.StartCareer = (this.emp.StartCareer == '' || this.emp.StartCareer == null) ? null : new Date(this.emp.StartCareer);
          this.emp.EndCareer = (this.emp.EndCareer == '' || this.emp.EndCareer == null) ? null : new Date(this.emp.EndCareer);
          /*        this.emp.CPStartMonth = Number(this.selectedStartMonth);
                    this.emp.CPStartYear = Number(this.selectedStartYear);
                    this.emp.CPEndMonth = Number(this.selectedEndMonth);
                    this.emp.CPEndYear = Number(this.selectedEndYear);*/

          this.emp.WeeklyWorkingHour = Number(this.selectedEmployementWorkHours);
          this.emp.WorkLocation = Number(this.selectedEmployementWorkWhere);
          this.emp.TravelMiles = Number(this.selectedTravelPreference);
          this.emp.TravelTimePercent = Number(this.selectedTravelPercent);
          console.log("Employee : ", this.emp);
          axios
              .post("/api/Employee/UpdateEmployeePersonalInfo", this.emp)
              .then(res => {
                // code that we will 'try' to run
                this.info = res.data;

                console.log("Add Response portion");

                console.log("response : ", this.info);
              })
              .catch(error => {
                console.log("Errorrrrrr : ", error);
                this.errored = true;
              })
              .finally(() => {
                if (this.errored) {

                  console.log("Error : Sorry there is no response");
                  // this.$bvToast.toast("Sorry there is no response ", {
                  //   toaster: "b-toaster-top-center",
                  //   variant: "danger",

                  //   solid: true,
                  // });
                } else {

                  this.$bvToast.toast("Updated Successfully ", {
                    toaster: "b-toaster-top-center",
                    variant: "success",

                    solid: true
                  });
                  if (this.isGraduateSection) {
                    this.$router.push({name: 'EmployeeDashboard'});
                  } else {
                    this.profileSectionState(false);
                  }

                  this.resetPage();


                }
              });
        } else {
          this.isValidated = true;
          this.$bvToast.toast("Please Fill all required fields", {
            toaster: "b-toaster-top-center",
            variant: "danger",
            solid: true
          });
          if (this.isGraduateSection) {
            console.log("graduateSection: ", this.isGraduateSection)
            this.scrollTo("employeeProfileForm");
          } else {
            console.log("graduateSection: ", this.isGraduateSection)
            this.navigate();
          }
        }
        }






    },


    addElement(id){
      this.selectedCategory.push(id)
    },
    removeElement(index){
      this.selectedCategory.splice(index,1);
    },


    toggleValues(id, delId){
      console.log("checked : ",id);
      console.log("unchecked : ",delId);



      if(id != 0){
        this.addElement(Number(id));
        console.log("1st inside if count : ", this.selectedCategory)
        if(this.selectedCategory.length <= 2){
          console.log("2st inside if count : ", this.selectedCategory)
          if(this.selectedGraduate == 24 && this.selectedMumsReturnToWork == 25){

            console.log("3rd inside if count : ", this.selectedCategory)
            this.graduateSection = true;
            this.graduateState = true;

            this.mumsReturnToWorkSection = true;
            this.mumReturnToWorkState = true;

            this.pWDState = false;
            this.specialNeedPersonSection = false;

            this.labourManPowerState = false;
            this.hecancyState = false;
          }
          else if(this.selectedGraduate == 24 && this.selectedSpecialNeedPerson == 26){
            console.log("3rd inside 1 elseif count : ", this.selectedCategory)
            this.graduateSection = true;
            this.graduateState = true;

            this.specialNeedPersonSection = true;
            this.pWDState = true;

            this.mumsReturnToWorkSection = false;
            this.mumReturnToWorkState = false;

            this.labourManPowerState = false;
            this.hecancyState = false;

          }
         else if(this.selectedGraduate == 24 && this.selectedLabourManPower == 27){
            console.log("3rd inside 2 elseif count : ", this.selectedCategory)
            this.graduateSection = true;
            this.graduateState = true;

            this.mumsReturnToWorkSection = false;
            this.mumReturnToWorkState = false;

            this.specialNeedPersonSection = false;
            this.pWDState = false;

            this.labourManPowerState = true;
            this.hecancyState = false;
          }
          else if(this.selectedGraduate == 24 && this.selectedHecancy == 30){
            console.log("3rd inside 3 elseif count : ", this.selectedCategory)
            this.graduateSection = true;
            this.graduateState = true;

            this.mumsReturnToWorkSection = false;
            this.mumReturnToWorkState = false;

            this.specialNeedPersonSection = false;
            this.pWDState = false;

            this.labourManPowerState = false;
            this.hecancyState = true;
          }

          else if(this.selectedMumsReturnToWork == 25 && this.selectedSpecialNeedPerson == 26){
            console.log("3rd inside 4 elseif count : ", this.selectedCategory)
            this.graduateSection = false;
            this.graduateState = false;

            this.mumsReturnToWorkSection = true;
            this.mumReturnToWorkState = true;

            this.specialNeedPersonSection = true;
            this.pWDState = true;

            this.labourManPowerState = false;
            this.hecancyState = false;
          }
          else if(this.selectedMumsReturnToWork == 25 && this.selectedLabourManPower == 27){
            console.log("3rd inside 5 elseif count : ", this.selectedCategory)
            this.graduateSection = false;
            this.graduateState = false;

            this.mumsReturnToWorkSection = true;
            this.mumReturnToWorkState = true;

            this.specialNeedPersonSection = false;
            this.pWDState = false;

            this.labourManPowerState = true;
            this.hecancyState = false;
          }
          else if(this.selectedMumsReturnToWork == 25 && this.selectedHecancy == 30){
            console.log("3rd inside 6 elseif count : ", this.selectedCategory)
            this.graduateSection = false;
            this.graduateState = false;

            this.mumsReturnToWorkSection = true;
            this.mumReturnToWorkState = true;

            this.specialNeedPersonSection = false;
            this.pWDState = false;

            this.labourManPowerState = false;
            this.hecancyState = true;


          }
          else if(this.selectedSpecialNeedPerson == 26 && this.selectedLabourManPower == 27){
            console.log("3rd inside 7 elseif count : ", this.selectedCategory)
            this.graduateSection = false;
            this.graduateState = false;

            this.mumsReturnToWorkSection = false;
            this.mumReturnToWorkState = false;

            this.specialNeedPersonSection = true;
            this.pWDState = true;

            this.labourManPowerState = true;
            this.hecancyState = false;


          }
          else if(this.selectedSpecialNeedPerson == 26 && this.selectedHecancy == 30){
            console.log("3rd inside 8 elseif count : ", this.selectedCategory)
            this.graduateSection = false;
            this.graduateState = false;

            this.mumsReturnToWorkSection = false;
            this.mumReturnToWorkState = false;

            this.specialNeedPersonSection = true;
            this.pWDState = true;

            this.labourManPowerState = false;
            this.hecancyState = true;


          }
          else if(this.selectedLabourManPower == 27 && this.selectedHecancy == 30){
            console.log("3rd inside 9 elseif count : ", this.selectedCategory)
            this.graduateSection = false;
            this.graduateState = false;

            this.mumsReturnToWorkSection = false;
            this.mumReturnToWorkState = false;

            this.specialNeedPersonSection = false;
            this.pWDState = false;

            this.labourManPowerState = true;
            this.hecancyState = true;


          }
          else if(this.selectedGraduate == 24){
            console.log("3rd inside 10 elseif count : ", this.selectedCategory)
            console.log("this.workPlacements in 10 elseif : ", this.workPlacements)
            this.graduateSection = true;
            // this.mumsReturnToWorkSection = true;
            this.graduateState = true;
            this.mumReturnToWorkState = true;
            this.pWDState = true;
            this.labourManPowerState = true;
            this.hecancyState = true;
          }
          else if(this.selectedMumsReturnToWork == 25){
            console.log("3rd inside 11 elseif count : ", this.selectedCategory)
            this.mumsReturnToWorkSection = true;
            // this.specialNeedPersonSection = true;
            this.graduateState = true;
            this.mumReturnToWorkState = true;
            this.pWDState = true;
            this.labourManPowerState = true;
            this.hecancyState = true;
          }
          else if(this.selectedSpecialNeedPerson == 26){
            console.log("3rd inside 12 elseif count : ", this.selectedCategory)
            this.specialNeedPersonSection = true;
            this.graduateState = true;
            this.mumReturnToWorkState = true;
            this.pWDState = true;
            this.labourManPowerState = true;
            this.hecancyState = true;
          }
          else if(this.selectedLabourManPower == 27){
            console.log("3rd inside 13 elseif count : ", this.selectedCategory)
            this.graduateState = true;
            this.mumReturnToWorkState = true;
            this.pWDState = true;
            this.labourManPowerState = true;
            this.hecancyState = true;
          }

        }
      }
      else {
        if(this.selectedCategory.length != 0) {
          console.log("index no", this.selectedCategory.indexOf(Number(delId)))

          this.removeElement(this.selectedCategory.indexOf(delId));

          if (this.selectedCategory.length <= 2) {
            if (this.selectedGraduate == 24) {
              this.graduateSection = true;

            } else {
              this.graduateSection = false;
              this.graduateState = true;
            }
            if (this.selectedMumsReturnToWork == 25) {
              this.mumsReturnToWorkSection = true;

            } else {
              this.mumsReturnToWorkSection = false;
              this.mumReturnToWorkState = true;
            }
            if (this.selectedSpecialNeedPerson == 26) {
              this.specialNeedPersonSection = true;

            } else {
              this.specialNeedPersonSection = false;
              this.pWDState = true;
            }
            if (this.selectedLabourManPower == 27) {

            } else {
              this.labourManPowerState = true;
            }
            if (this.selectedHecancy == 30) {

            } else {
              this.hecancyState = true;
            }


          }


          console.log("inside else count : ", this.selectedCategory)


        }
      }
      // if(checked.length != 0)



    },




    //Profile Section End

    //Graduate Section Start


    getGraduateWorkPlacementType() {
      this.workPlacements = null;
      axios.get('/api/GraduateWorkPlacementType/GetGraduateWorkPlacementTypes')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            res.data
            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.workPlacements = res.data.data;
            console.log("workPlacements : ", this.workPlacements);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },
    onChangeWorkPlacement(id){
      console.log(id)
      this.workPlacementId = 0

      if(id == 1){
        this.workPlacementId = id;
        this.modalTitle = "Premium Internship Program";
        $("#workPlacementModal").modal("show");
        this.lookingDescription_input = false;
      }
      else if(id == 2){
        this.workPlacementId = id;
        this.modalTitle = "Carat Mentorship Program";
        $("#workPlacementModal").modal("show");
        this.lookingDescription_input = false;
      }
      else if(id == 3){
        this.workPlacementId = id;
        this.modalTitle = "Remote & Flexible Internship Program";
        $("#workPlacementModal").modal("show");
        this.lookingDescription_input = true;
      }


    },
    /*getSkillsSubCategory(id) {
      this.subCategories = null;
      console.log("event : ",id);
      axios.get('/SkillsSubCategorys/GetSkillsSubCategorys/' + id + ''
      ).then(res => {
        if (res.status != '200') {
          console.log('error.....')
          const error = new Error('Failed to fetch!');
          return error;
        }
        if (res.data != null){
          this.subCategories = res.data.data;
          console.log("subCategories : ",this.subCategories)
        }
        else {
          console.log("Error : Sorry there is no response");
        }

      }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },*/
    //Graduate Section End
    //MumsReturn To WOrk Start

    //MumsReturn To Work End
    //Special Need person Start
    //Special Need Person End
    getOtherCountries(){
      if(this.city_dd == true){
        this.city_dd= false;
        this.country_dd = true;
        this.city_input = true;
      }
      else {
        this.city_dd= true;
        this.country_dd = false;
        this.city_input = false;
      }

    },
    // //Reset
    onResetModal() {

        this.emp.FatherName="";
        this.emp.CNIC="";
        this.emp.DateofBirth=null;


        this.emp.Address="";
        this.emp.Address1="";
        this.emp.Area="";



        this.emp.CityName="";

        this.emp.PhoneNumber="";


        this.emp.Sector="";


        this.emp.CurrentSalary=0;
        this.emp.ExpectedSalary=0;

        this.emp.JobTypId=null;
        this.emp.ProfessionalSummary="";
        this.emp.LinkedInLink="";
        this.emp.FacebookLink="";
        this.emp.GooglePlusLink="";
        this.emp.TwitterLink="";


          //Graduate
        this.emp.AvailableDate= null;

        this.emp.ServiceDescription= "";

        this.emp.SubCategory= "";
        //Special Needy
        this.emp.DisabilityInformationTitle= "";



        this.emp.SpecialAssistanceRequirment= "";
        this.emp.KindofJob= "";


        //Mom Return

        this.emp.StartCareer=null;
        this.emp.EndCareer=null;

        this.emp.CPDescription= "";









      this.selectedGraduate=0;
      this.selectedMumsReturnToWork=0;
      this.selectedSpecialNeedPerson=0;
      this.selectedLabourManPower=0;

      this.selectedHecancy=0;

      this.selectedGenderType="";
      this.selectedYearsOfExperience="";
      this.selectedCareerLevel="";
      this.selectedDepartment="";
      this.selectedMaritalStatus="";
      this.selectedCountry="";
      this.selectedCity="";
      this.selectedDomicileCity="";
      this.selectedLanguages = [];
      this.selectedWorkPlacement="";
      this.selectedSkillCategory="";
      this.selectedSubCategory="";

      this.selectedEmployeeStatus="";
      this.selectedEmployementWorkWhere="";
      this.selectedEmployementWorkHours="";
      this.selectedTravelPreference="";
      this.selectedTravelPercent="";
      this.selectedDisabilityType="";
      this.selectedCauseOfDisability="";
      this.selectedSpecialFacility=null;

      /*this.selectedDisabilitySeverityPhysical=0;
      this.selectedDisabilitySeverityHearingSpeech=0;
      this.selectedDisabilitySeverityVisual=0;
      this.selectedDisabilitySeverityIntellectual=0;*/
      this.selectedDisabilitySeverity = 0;

      this.selectedDisabilityCertificate= 1;
      this.selectedSignLanguage=1;
      this.selectedTransportation=1;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    // Toggle Modal
    toggleModal(){
      this.isModalVisible = !this.isModalVisible;
    },
    //Rerender
    rerender() {
      this.componentKey += 1;

    },
    resetPage(){
      this.$emit('reset-page');
    },
    navigate(){
      this.$emit('navigate-form');
      console.log("navigate: triggerrd")
    },
    profileSectionState(value){
      this.isProfileSection = value;
      this.$emit('change-state', this.isProfileSection);
      console.log("state triggered")
    },


    onSelectedLanguage(sl){
      console.log("selected Language : ",sl)
      if(sl.length == 0){
        this.isCustomMultiselect = "custom-multiselect-invalid";
      }
      else {
        this.isCustomMultiselect = "custom-multiselect-valid";
      }
    },
    scrollTo(id) {
      //var id=event.target.attr("data-id");
      console.log(id)

      const yOffset = -315;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});

    },




  }

};
</script>

<style>
.custom-multiselect{
  font-size: .875rem;
  border: 1px solid #ced4da;

  border-radius: 0.2rem;

}
.custom-multiselect-valid{
  border: 1px solid #28a745;

  border-radius: 0.2rem;

}
.custom-multiselect-invalid{
  border: 1px solid #dc3545;
  border-radius: 0.2rem;

}
</style>


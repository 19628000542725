<template>
  <div v-if="isDataFetched" class="container" :key="componentKey">
    <div class="mt-2">
      <div
        class="custom-row custom-options-menu justify-content-between align-items-center"
      >
        <div class="customHeading">
          <nav>
            <div
              class="nav nav-tabs custom-nav-tabs"
              id="nav-tab"
              role="tablist"
            >
              <a
                class="nav-item nav-link custom-nav-link custom-nav-heading active"
                id="employee-detail-tab"
                data-toggle="tab"
                href="#employee-detail"
                role="tab"
                aria-controls="employee-detail"
                aria-selected="true"
              >
                <span> Employer Information </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div class="mb-5">
      <div class="tab-content cusform" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="employee-detail"
          role="tabpanel"
          aria-labelledby="employee-detail-tab"
        >
          <div class="  mt-30">
            <div class="w3-card">
              <div class="card-body">
                <form  class="custom-validation"
                novalidate>
                  <div class="row">
                    <div class="col">
                      <!-- <h3>Company Information</h3> -->
                      <!---->
                    </div>
                  </div>
                  <!-- <div class="row">
              <div class="col-md-6">
                <div class="formrow">
                  <label>Company Logo</label><br /><img
                    src="/img/no-image.876f94f7.png"
                    alt=""
                    title=""
                    style="max-width: 100px; max-height: 100px"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="formrow mt-5 text-center">
                  <label class="btn btn-info col-8">
                    Select Company Logo
                    <input
                      type="file"
                      name="logo"
                      id="ItemImageFile"
                      required=""
                      style="display: none"
                  /></label>
                </div>
              </div>
            </div> -->
                  <h3>Company Info</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Company Logo</label
                        >
                        <div class="col-sm-9">
                          <img
                            src="../../assets/images/no-image.png"
                            v-if="!disCompanyLogo"
                            style="max-width:100px; max-height:100px;"
                            alt=""
                            title=""
                          />
                          <img
                            :src="disCompanyLogo"
                            v-else
                            style="max-width:100px; max-height:100px;"
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Change Company Logo</label
                        >
                        <div class="col-sm-9">
                          <input
                            type="file"
                            accept="image/*"
                            @change="getCompanyImage($event)"
                            id="file-input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Company Name</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"
                            v-model="body.companyName"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.companyName.$error,
                            }"
                            placeholder="Company Name"
                            name="name"
                            type="text"
                          />

                          <div
                            v-if="submitted && !$v.body.companyName.required"
                            class="invalid-feedback"
                          >
                            Company Name is required
                          </div>
                          <div
                            v-if="!$v.body.companyName.minLength"
                            class="invalid-feedback"
                          >
                            Company Name must contain at least 3 characters
                          </div>
                          <div
                            v-if="!$v.body.companyName.maxLength"
                            class="invalid-feedback"
                          >
                            The maximum length of 350 characters is reached
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Company Email</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"
                            id="name"

                            v-model="body.companyEmail"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.companyEmail.$error,
                            }"
                            placeholder="Company Email"
                            name="name"
                            type="email"
                          />

                          <div
                            v-if="submitted && !$v.body.companyEmail.required"
                            class="invalid-feedback"
                          >
                            Company Email is required
                          </div>
                          <div
                              v-if="!$v.body.companyEmail.email"
                              class="invalid-feedback"
                          >
                            Please enter a valid Email Address
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >NTN</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"
                            id="ceo"

                            v-model="body.ceoName"
                            :class="{
                              'is-invalid': submitted && $v.body.ceoName.$error,
                            }"
                            placeholder="Company CEO"
                            name="NTN"
                            type="text"
                          />
                          <div
                            v-if="submitted && !$v.body.ceoName.required"
                            class="invalid-feedback"
                          >
                            NTN is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Industry</label
                        >
                        <div class="col-sm-9">
                          <select
                            id="industry_id"
                            class="custom-select-option form-control form-control-sm"
                            v-model="body.industryId"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.industryId.$error,
                            }"
                          >
                            <option disabled value="">Select Industry</option>
                            <option
                              v-for="option in industries"
                              :key="option.key"
                              :value="option.key"
                            >
                              {{ option.value }}
                            </option>
                          </select>
                          <div
                            v-if="submitted && !$v.body.industryId.required"
                            class="invalid-feedback"
                          >
                            Industry is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Who's Hiring</label
                        >
                        <div class="col-sm-9">
                          <select
                            class="form-control"
                            required=""
                            v-model="selectedOwnership"
                            :class="{
                              'is-invalid':
                                submitted && $v.selectedOwnership.$error,
                            }"
                            id="ownership_type_id"
                            name="ownership_type_id"
                          >
                            <option
                              v-for="option in ownerships"
                              :key="option.value"
                              :value="option.value"
                            >
                              {{ option.text }}
                            </option>
                          </select>
                          <div
                            v-if="submitted && !$v.selectedOwnership.required"
                            class="invalid-feedback"
                          >
                            Who's Hiring is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Company Established In</label
                        >
                        <div class="col-sm-9">
                          <!--                    <input
                        type="date"
                        class="form-control"
                        min="1900-01-02"
                        autocomplete="off"
                        required=""
                        v-model="body.establishedInYear"
                    />-->
                          <select
                            class="form-control"

                            v-model="body.establishedInYear"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.establishedInYear.$error,
                            }"
                            id="established_in"
                            name="established_in"
                          >
                            <option value="-1">Select Year</option>
                            <option
                              v-for="(option, index) in yearList"
                              :key="index"
                              :value="option"
                            >
                              {{ option }}
                            </option>
                          </select>
                          <div
                            v-if="
                              submitted && !$v.body.establishedInYear.required
                            "
                            class="invalid-feedback"
                          >
                            Year is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="col-md-12">
                      <div class="mb-3  ">
                        <label>Description</label>
                        <div class=" ">
                          <textarea
                            class="form-control"

                            v-model="body.companyDesciption"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.companyDesciption.$error,
                            }"
                            id="description"
                            placeholder="Company details"
                            name="description"
                            rows="5"
                            maxlength="2000"
                          >
                          </textarea>
                          <div
                            v-if="
                              submitted && !$v.body.companyDesciption.required
                            "
                            class="invalid-feedback"
                          >
                            Description is required
                          </div>
                          <div
                            v-if="!$v.body.companyDesciption.minLength"
                            class="invalid-feedback"
                          >
                            Description must contain at least 3 characters
                          </div>
                          <div
                            v-if="!$v.body.companyDesciption.maxLength"
                            class="invalid-feedback"
                          >
                            The maximum length of 2000 characters is reached
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Address</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"

                            v-model="body.address"
                            :class="{
                              'is-invalid': submitted && $v.body.address.$error,
                            }"
                            placeholder="Address"
                            name="location"
                            type="text"
                          />
                          <div
                            v-if="submitted && !$v.body.address.required"
                            class="invalid-feedback"
                          >
                            Address is required
                          </div>
                          <div
                            v-if="!$v.body.address.minLength"
                            class="invalid-feedback"
                          >
                            Address must contain at least 3 characters
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Website URL</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"
                            v-model="body.websiteUrl"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.websiteUrl.$error,
                            }"
                            placeholder="http://www.comapnydomain.com"
                            name="website"
                            type="url"
                          />
                          <!-- <div
                            v-if="submitted && !$v.body.websiteUrl.required"
                            class="invalid-feedback"
                          >
                            Website URL is required
                          </div> -->
                          <div
                              v-if="!$v.body.websiteUrl.url"
                              class="invalid-feedback"
                          >
                            Please enter valid URL
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Phone</label
                        >
                        <div class="col-sm-9">
                          <vue-tel-input
                            v-bind="vueTellProps"
                            v-model="body.contactNumber"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.contactNumber.$error,
                            }"
                          ></vue-tel-input>
                          <div
                            v-if="submitted && !$v.body.contactNumber.required"
                            class="invalid-feedback"
                          >
                            Phone is required
                          </div>

                          <!--                  <input
                    class="form-control"
                    required=""  v-model="body.mobileNumber"
                    id="phone"
                    placeholder="Phone"
                    name="phone"
                    type="text"
                  />-->
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Country</label
                        >
                        <div class="col-sm-9">
                          <select
                            class="form-control"

                            v-model="body.countryId"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.countryId.$error,
                            }"
                            id="country_id"
                            name="country_id"
                          >
                            <option disabled value="">Select Country</option>
                            <option
                              v-for="option in countries"
                              :key="option.key"
                              :value="option.key"
                            >
                              {{ option.value }}
                            </option>
                          </select>
                          <div
                            v-if="submitted && !$v.body.countryId.required"
                            class="invalid-feedback"
                          >
                            Country is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >City</label
                        >
                        <div class="col-sm-9">
                          <select
                            id="city_id"

                            v-model="body.cityId"
                            :class="{
                              'is-invalid': submitted && $v.body.cityId.$error,
                            }"
                            class="form-control"
                            name="city_id"
                          >
                            <option disabled value="">Select City</option>
                            <option
                              v-for="option in cities"
                              :key="option.key"
                              :value="option.key"
                            >
                              {{ option.value }}
                            </option>
                          </select>
                          <div
                            v-if="submitted && !$v.body.cityId.required"
                            class="invalid-feedback"
                          >
                            City is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >linkedin</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"

                            v-model="body.linkedInLink"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.linkedInLink.$error,
                            }"
                            placeholder="linkedin url"
                            name="linkedin"
                            type="url"
                          />
                          <!-- <div
                            v-if="submitted && !$v.body.linkedInLink.required"
                            class="invalid-feedback"
                          >
                            Linkedin is required
                          </div> -->
                          <div
                              v-if="!$v.body.linkedInLink.url"
                              class="invalid-feedback"
                          >
                            Please enter valid URL
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >facebook</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"

                            v-model="body.facebookLink"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.facebookLink.$error,
                            }"
                            placeholder="facebook url"
                            name="facebook"
                            type="url"
                          />
                          <!-- <div
                            v-if="submitted && !$v.body.facebookLink.required"
                            class="invalid-feedback"
                          >
                            Facebook link is required
                          </div> -->
                          <div
                              v-if="!$v.body.facebookLink.url"
                              class="invalid-feedback"
                          >
                            Please enter valid URL
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Google Plus</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"

                            v-model="body.googlePlusLink"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.googlePlusLink.$error,
                            }"
                            id="location"
                            placeholder="google plus url"
                            name="linkedin"
                            type="url"
                          />
                          <!-- <div
                            v-if="submitted && !$v.body.googlePlusLink.required"
                            class="invalid-feedback"
                          >
                            Google link is required
                          </div> -->
                          <div
                              v-if="!$v.body.googlePlusLink.url"
                              class="invalid-feedback"
                          >
                            Please enter valid URL
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Twitter</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"

                            v-model="body.twitterLink"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.twitterLink.$error,
                            }"
                            placeholder="twitter url"
                            name="website"
                            type="url"
                          />
                          <!-- <div
                            v-if="submitted && !$v.body.twitterLink.required"
                            class="invalid-feedback"
                          >
                            Twitter link is required
                          </div> -->
                          <div
                              v-if="!$v.body.twitterLink.url"
                              class="invalid-feedback"
                          >
                            Please enter valid URL
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--              <div class="col-md-12">
                <div class="mb-3  ">
                  <label class=" ">Google Map Iframe</label
                  >
                  <div class=" ">
                  <textarea
                    class="form-control"
                    id="map" v-model="body.googleMapIframe"
                    placeholder='<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d8310.686427880151!2d-74.00585671025667!3d40.7098868133123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1536851502771"  frameborder="0" style="border:0" allowfullscreen></iframe>'
                    name="map"
                    cols="50"
                    rows="10"
                  ></textarea>
                  </div>
                </div>
              </div>-->
                    <hr />

                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Employee Picture</label
                        >
                        <div class="col-sm-9">
                          <img
                            src="../../assets/images/no-image.png"
                            v-if="!disEmployeeLogo"
                            style="max-width:100px; max-height:100px;"
                            alt=""
                            title=""
                          />
                          <img
                            :src="disEmployeeLogo"
                            v-else
                            style="max-width:100px; max-height:100px;"
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Change Employee Picture</label
                        >
                        <div class="col-sm-9">
                          <input
                            type="file"
                            accept="image/*"
                            @change="getEmployeeImage($event)"
                            id="file-input1"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Who's Making this profile</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"

                            v-model="body.fullName"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.fullName.$error,
                            }"
                            placeholder="Employee Name"
                            name="empname"
                            type="text"
                          />
                          <div
                            v-if="submitted && !$v.body.fullName.required"
                            class="invalid-feedback"
                          >
                            Employee Name is required
                          </div>
                          <div
                            v-if="!$v.body.fullName.minLength"
                            class="invalid-feedback"
                          >
                            Employee Name must contain at least 3 characters
                          </div>
                          <div
                            v-if="!$v.body.fullName.maxLength"
                            class="invalid-feedback"
                          >
                            The maximum length of 100 characters is reached
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Employee Email</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"

                            v-model="body.email"
                            :class="{
                              'is-invalid': submitted && $v.body.email.$error,
                            }"
                            id="facebook"
                            placeholder="Employee Email"
                            name="empemail"
                            type="text"
                          />
                          <div
                            v-if="submitted && !$v.body.email.required"
                            class="invalid-feedback"
                          >
                            Employee Email is required
                          </div>
                          <div
                              v-if="!$v.body.email.email"
                              class="invalid-feedback"
                          >
                            Please enter valid Email Address
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Employee Mobile Number</label
                        >
                        <div class="col-sm-9">
                          <vue-tel-input
                            v-bind="vueTellProps"
                            v-model="body.mobileNumber"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.mobileNumber.$error,
                            }"
                          ></vue-tel-input>
                          <div
                            v-if="submitted && !$v.body.mobileNumber.required"
                            class="invalid-feedback"
                          >
                            Mobile is required
                          </div>
                          <!--                  <input
                    class="form-control"
                    required=""
                    v-model="body.mobileNumber"

                    placeholder="employee mobile number"
                    name="empnum"
                    type="email"
                  />-->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm"
                          >Designation</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"

                            v-model="body.designation"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.designation.$error,
                            }"
                            placeholder="Designation"
                            type="text"
                          />
                          <div
                            v-if="submitted && !$v.body.designation.required"
                            class="invalid-feedback"
                          >
                            Designation is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-4">
                      <div class="formrow text-center">
                        <!-- <button type="submit" :disabled="isLoading" class="btn col-12 btn-primary">
                    Update Profile and Save
                    <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                  </button> -->
                      </div>
                    </div>
                    <hr />
                    <div
                      class="modal-footer"
                      style=" width: 100%;border-top: 0;"
                    >

                      <div>
                        <b-button
                            @click.prevent="handleSubmit"
                          class="form-control custom-form-btn custom-btn-gotopage"
                          :disabled="isLoading"
                          variant="outline-dark"
                          >Save
                        </b-button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="style-spacer"></div>
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  minLength,
  maxLength,
  email,
    url
} from "vuelidate/lib/validators";
export default {
  name: "EmployerProfileInfo",
  components: {},
  data() {
    return {
      url: axios.defaults.baseURL,
      has_error: false,
      isDataFetched: false,
      isLoading: false,
      submitted:false,

      vueTellProps: {
        mode: "international",
        defaultCountry: "PK",

        preferredCountries: ["PK"],
        onlyCountries: [],
        ignoredCountries: [],

        wrapperClasses: "",
        inputClasses: "",

        inputOptions: {
          showDialCode: true,
          name: "telephone",
          autocomplete: "off",
          placeholder: "Enter a phone number",
          required: true,
          type: "tel",
          maxlength: 15,
        },
        validCharactersOnly: true,
      },

      // cities:[],
      // countries:[],
      industries: [],
      departments: [],
      uploadCompanyImg: null,
      disCompanyLogo: "",
      uploadEmployeeImage: null,
      disEmployeeLogo: "",
      list: null,
      info: null,
      yearList: [],
      componentKey: 0,
      dropDownBody: {
        type: "",
        value: "",
      },
      body: {
        employerCompanyId: 0,
        companyEmail: "",
        companyName: "",
        ceoName: "",
        employeeId: 0,
        industryId: null,
        ownerShipTypeId: 0,
        companyDesciption: "",
        companyLogoAddress: "",
        countryId: null,
        cityId: null,
        address: "",
        establishedInYear: 0,
        websiteUrl: "",
        contactNumber: "",
        googleMapIframe: "",
        linkedInLink: "",
        facebookLink: "",
        googlePlusLink: "",
        twitterLink: "",
        employeeImage: "",
        fullName: "",
        email: "",
        mobileNumber: null,

        designation: "",

        // companyStatus: 1
      },
      selectedIndustry: null,

      selectedOwnership: 0,
      ownerships: [
        { value: 0, text: "Your role in the hiring process" },
        { value: 1, text: "Owner / CEO" },
        { value: 2, text: "Recruiter / Talent Acquisition" },
        { value: 3, text: "Department Head" },
        { value: 4, text: "Assistant / Office Manager" },
        { value: 5, text: "Human Resource Manager" },
        { value: 6, text: "Others" },
      ],
    };
  },
  validations: {
    body: {
      companyName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      companyEmail: {
        required,
        email,
      },
      ceoName: {
        required,
      },
      industryId: {
        required,
      },
      establishedInYear: {
        required,
      },
      companyDesciption: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(2000),
      },
      address: { required, minLength: minLength(3) },
      websiteUrl: {
        
        url
      },
      contactNumber: { required },
      countryId: { required },
      cityId: { required },
      linkedInLink: {
      
        url
      },
      facebookLink: {
        
        url
      },
      googlePlusLink: {
        
        url
      },
      twitterLink: {
        
        url
      },
      fullName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      email: {
        required,
        email,
      },
      mobileNumber: { required },
      designation: {
        required,
      },
    },
    selectedOwnership: { required },
  },
  computed: {
    cities() {
      return this.$store.getters.availableCities;
    },
    countries() {
      return this.$store.state.countries;
    },
  },
  mounted() {
    // /EmployerCompany/GetEmployerCompany
    console.log(this.$auth.user());
    this.body.employeeId = this.$auth.user().employeeId;
    this.getProfileInfo();
    this.getCities();
    this.getCountries();
    this.getYears();
    this.getIndustries();
  },
  methods: {
    getEmployeeImage(event) {
      this.uploadEmployeeImage = null;
      this.uploadEmployeeImage = event.target.files[0];

      this.disEmployeeLogo = URL.createObjectURL(this.uploadEmployeeImage);
      console.log("disEmployeeLogo : ", this.disEmployeeLogo);

      this.uploadEmpImage();
    },

    uploadEmpImage() {
      const URL = "/api/Employee/UpdateImages";

      if (this.uploadEmployeeImage != null) {
        let data = new FormData();

        data.append("employeeImage", this.uploadEmployeeImage);

        axios.post(URL, data).then((response) => {
          console.log("image upload response > ", response);
        });
      } else {
        console.log("Error : Sorry there is no response");
        // this.$bvToast.toast("Sorry there is no response ", {
        //   toaster: "b-toaster-top-center",
        //   variant: "danger",

        //   solid: true,
        // });
      }
    },
    /*uploadCompanyImage(event) {

    const URL = 'EmployerCompany/UpdateCompanyLogo';

    let data = new FormData();
    data.append('employeeImage', 'employeeImage');
    data.append('file', event.target.files[0]);

    let config = {
      header : {
        //'Content-Type' : 'image/png'
      }
    }

    axios.post(
      URL,
      data,
      config
    ).then(
      response => {
        console.log('image upload response > ', response)
      }
    )
  },*/

    getCompanyImage(e) {
      this.uploadCompanyImg = null;
      this.uploadCompanyImg = e.target.files[0];
      console.log("uploadCompanyImg : ", e.target.files[0]);
      console.log("uploadFileName : ", this.uploadCompanyImg.name);

      this.disCompanyLogo = URL.createObjectURL(this.uploadCompanyImg);
      console.log("disCompanyLogo : ", this.disCompanyLogo);
    },
    //File Upload
    uploadCompanyImage() {
      if (this.uploadCompanyImg != null) {
        console.log("this.uploadCompanyImg : ", this.uploadCompanyImg);
        var formData = new FormData();

        formData.append("companyLogoAddress", this.uploadCompanyImg);
        console.log("formData ; ", formData);
        axios
          .post("/api/EmployerCompany/UpdateCompanyLogo", formData)
          .then((res) => {
            // code that we will 'try' to run

            console.log("Add Response portion");

            console.log("response : ", res.data);
            setTimeout(this.navigateToDashboard, 2000);
          })
          .catch((error) => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {
              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {
              console.log("Image uploaded successfully");
              /*this.$bvToast.toast("Company Profile Image Uploaded Successfully ", {
                    toaster: "b-toaster-top-center",
                    variant: "success",

                    solid: true
                  });*/
            }
          });
      } else {
        console.log("Error : Sorry there is no response");
        // this.$bvToast.toast("Sorry there is no response ", {
        //   toaster: "b-toaster-top-center",
        //   variant: "danger",

        //   solid: true,
        // });
      }
    },

    getProfileInfo() {
      setTimeout(this.rerender, 1000);
      this.list = null;
      axios
        .get("/api/EmployerCompany/GetEmployerCompany")
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          this.list = res.data.data;
          console.log("res.data : ", this.list);

          if (this.list != 0) {
            //this.body = resdata
            this.body.employerCompanyId = this.list.employerCompanyId;
            this.body.industryId = this.list.industryId;
            this.body.cityId = this.list.cityId;
            this.selectedOwnership = this.list.ownerShipTypeId;
            this.body.countryId = this.list.countryId;
            this.body.establishedInYear = this.list.establishedInYear;
            this.body.companyEmail = this.list.companyEmail;
            this.body.companyName = this.list.companyName;
            this.body.ceoName = this.list.ceoName;
            this.body.companyDesciption = this.list.companyDesciption;
            this.body.address = this.list.address;
            this.body.websiteUrl = this.list.websiteUrl;
            this.body.contactNumber = this.list.contactNumber;
            this.body.googleMapIframe = this.list.googleMapIframe;
            this.body.linkedInLink = this.list.linkedInLink;
            this.body.facebookLink = this.list.facebookLink;
            this.body.twitterLink = this.list.twitterLink;
            this.body.googlePlusLink = this.list.googlePlusLink;
            this.body.companyLogoAddress = this.list.companyLogoAddress;
            this.disCompanyLogo = this.url + this.list.companyLogoAddress;

            this.body.fullName = this.list.employee.fullName;
            this.body.designation = this.list.employee.designation;
            this.body.mobileNumber = this.list.employee.mobileNumber;
            this.body.email = this.list.employee.email;
            this.disEmployeeLogo = this.url + this.list.employee.employeeImage;
          } else {
            /*this.$bvToast.toast("No Data Found ", {
                toaster: "b-toaster-top-center",
                variant: "warning",

                solid: true,
              });*/
            this.body.fullName = this.$auth.user().user.fullName;

            this.body.mobileNumber = this.$auth.user().user.phoneNumber;
            this.body.email = this.$auth.user().user.email;
            console.log("No Data Found...");
          }
          this.isDataFetched = true;

          // });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    postData() {
      this.info = null;
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      ///this.body.jobTitle = this.jobTitle;

      this.body.industryId = Number(this.body.industryId);
      this.body.cityId =
        this.body.cityId == "0" || this.body.cityId == null
          ? null
          : Number(this.body.cityId);
      this.body.ownerShipTypeId = Number(this.selectedOwnership);
      this.body.countryId = Number(this.body.countryId);
      this.body.establishedInYear = Number(this.body.establishedInYear);

      console.log("company Modal: ", this.body);

      let url = "";

      /*if(this.body.employerCompanyId != 0){
        url = "/EmployerCompany/UpdateEmployerCompany";
        axios
            .post(url, this.body)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              this.isLoading = false
              if (this.errored) {
                this.getProfileInfo();
                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {

                this.$bvToast.toast("Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  title: "Company Profile",
                  solid: true
                });

                this.uploadCompanyImage();
                this.getProfileInfo();
              }
            });

      }
      else {*/
      url = "/api/EmployerCompany/InsertEmployerCompany";
      axios
        .post(url, this.body)
        .then((res) => {
          // code that we will 'try' to run
          this.info = res.data;

          console.log("Add Response portion");

          console.log("response : ", this.info);
          //this.getProfileInfo();
          console.log("uploadCompanyImg : ", this.uploadCompanyImg);
          if (this.uploadCompanyImg != null) {
            setTimeout(this.uploadCompanyImage, 2000);
          } else {
            setTimeout(this.navigateToDashboard, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.isLoading = false;
          if (this.errored) {
            this.getProfileInfo();
            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {
            this.$bvToast.toast(
              "Your Profile have been saved successfully and redirecting to your Dashboard ",
              {
                toaster: "b-toaster-top-center",
                variant: "success",
                title: "Company Profile",
                solid: true,
              }
            );
            //this.uploadCompanyImage();
            //this.uploadEmpImage();
          }
        });

      // }
    },

    handleSubmit() {
      this.submitted = true;
      this.body.companyName = this.body.companyName==="" ? null : this.body.companyName ;
      this.body.ceoName = this.body.ceoName==="" ? null : this.body.ceoName ;
      this.body.companyEmail = this.body.companyEmail==="" ? null : this.body.companyEmail;
      this.body.companyDesciption = this.body.companyDesciption==="" ? null : this.body.companyDesciption;
      this.body.address = this.body.address==="" ? null : this.body.address;
      this.body.ceoName = this.body.ceoName==="" ? null : this.body.ceoName ;
      this.body.websiteUrl = this.body.websiteUrl==="" ? null : this.body.websiteUrl ;
      this.body.linkedInLink = this.body.linkedInLink==="" ? null : this.body.linkedInLink ;
      this.body.facebookLink = this.body.facebookLink==="" ? null : this.body.facebookLink ;
      this.body.twitterLink = this.body.twitterLink==="" ? null : this.body.twitterLink ;
      this.body.googlePlusLink = this.body.googlePlusLink==="" ? null : this.body.googlePlusLink;
      this.body.fullName = this.body.fullName==="" ? null : this.body.fullName;
      this.body.email = this.body.email==="" ? null : this.body.email;
      this.body.designation = this.body.designation==="" ? null : this.body.designation;

      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.postData();
    },

    navigateToDashboard() {
      this.$router.push({ name: "EmployerDashboard" });
    },
    getCountries() {
      this.$store.dispatch("getCountryList");
      /*this.countries = [];
      let ddBody = {
        type : "Country"
      }
      // console.log("selected Country : ",country);
      axios.post('/api/KeyValuePair/GetDropDownListByIds', ddBody)
      .then(res => {
        if (res.status != '200') {
          console.log('error.....')
          const error = new Error('Failed to fetch!');
          return error;
        }

        console.log("countries new: ",res)
        this.countries = res.data.data;
      }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");

            }
          });*/
    },
    getCities() {
      this.$store.dispatch("getCityList");
      /*this.cities = null;
      this.dropDownBody.type = "City";
      // console.log("selected Country : ",country);
      axios.post('/api/KeyValuePair/GetDropDownListByIds', this.dropDownBody)
      .then(res => {
        if (res.status != '200') {
          console.log('error.....')
          const error = new Error('Failed to fetch!');
          return error;
        }

        console.log("cities new: ",res)
        this.cities = res.data.data;
      }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });*/
      /*if (country === 106){
        this.city_input = false;
        this.city_dd = true;

      }
      else{
        this.city_input = true;
        this.city_dd = false;
      }*/
    },

    getIndustries() {
      this.industries = null;
      let ddBody = {
        type: "Industry",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          console.log("industries new: ", res.data.data[0].key);
          this.industries = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },
    getYears() {
      var startYear = 2000;
      var i = 0;
      for (i = startYear; i <= new Date().getFullYear(); i++) {
        this.yearList.push(i);
      }
      console.log("year List: ", this.yearList);
    },
    //Rerender Component
    rerender() {
      this.componentKey += 1;
    },
  },
};
</script>
